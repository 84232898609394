import React from 'react'
import styles from './createProgressionSystem.module.sass'
import ProgressionSystemInfo from './ProgressionSystemInfo'

const CreateProgressionSystem = ({isChanged, setIsChanged}) => {
  return (
    <>
        <div className={styles.row}>
            <div className={styles.col}>
                <ProgressionSystemInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
            </div>
        </div>
    </>
  )
}

export default CreateProgressionSystem