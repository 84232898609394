import {GET_DEFAULT_EVENTS, GET_CUSTOM_EVENTS, ADD_CUSTOM_EVENTS, EDIT_DEFAULT_EVENTS, EDIT_CUSTOM_EVENTS, FILTER_EVENT} from '../../../actionTypes/actionTypes'

const initialState = {
    defaultEvents : [],
    customEvents : [],
    filteredEvent : [],
    isSearched : true,
    isLoading : true,
    isDefaultFetched : [],
    isCustomFetched : [],
    totalCustomEventCount : null,
    totalDefaultEventCount : null
};

export const eventReducer =( state=initialState, action )=>{
    switch(action.type){

        case GET_DEFAULT_EVENTS : {
            return {
                ...state,
                defaultEvents: action.payload,
                totalDefaultEventCount : action.totalCount,
                filteredEvent : [],
                isSearched : true
            }
        }

        case FILTER_EVENT : {
            return {
                ...state,
                filteredEvent : action.payload,
                isSearched : false
            }
        }

        case GET_CUSTOM_EVENTS : {
            return {
                ...state,
                customEvents: action.payload,
                totalCustomEventCount : action.totalCount,
                filteredEvent : [],
                isSearched : true
            }
        }

        case ADD_CUSTOM_EVENTS : {
            return {
                ...state,
                customEvents : action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        case EDIT_DEFAULT_EVENTS : {
            return {
                ...state,
                defaultEvents: action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        case EDIT_CUSTOM_EVENTS : {
            return {
                ...state,
                customEvents: action.payload,
                filteredEvent : [],
                isSearched : true
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   