import axios from '../../../utils/interceptor'
import { BASE_URL, GET_ORG_PROFILE, EDIT_ORG_PROFILE, GET_USER_PROFILE, EDIT_USER_PROFILE, CHANGE_MEMBER_PASSWORD } from '../../../resources/APIEndpoints'

function ProfileServices(){
    this.getOrgProfile = (body) => axios.post(BASE_URL + GET_ORG_PROFILE, body)
    this.editOrgProfile = (body) => axios.post(BASE_URL + EDIT_ORG_PROFILE, body)
    this.getUserProfile = (body) => axios.post(BASE_URL + GET_USER_PROFILE, body)
    this.editUserProfile = (body) => axios.post(BASE_URL + EDIT_USER_PROFILE, body)
    this.changeMemberPassword = (body) => axios.post(BASE_URL + CHANGE_MEMBER_PASSWORD, body)
}

export default new ProfileServices();