import React from "react";

import {
  CurrentTimeIndicator,
  CurrentTime,
  CurrentTimeBox,
  CurrentTimeContent,
} from "@nessprim/planby-pro";
import moment from "moment-timezone";

export function LiveTime({
  isVerticalMode,
  isBaseTimeFormat,
  isRTL,
  time,
  styles,
  selectedZone,
  CurrentTimeIndicator
}) {
  // const newTopPosition = styles.position.top + 102;
  const leftPosition = styles.position.left

  function getCurrentTimeAndZoneCode(timezone) {
    const now = moment.tz(timezone);
    const time = now.format('HH:mm');
    const timezoneCode = now.format('z');
    return `${time} ${timezoneCode}`;
}

function getNewCurrentTimeAndZoneCode(timezone) {
  const now = moment.tz(timezone.zoneName);
  const time = now.format('HH:mm');
  
  return time+" ("+timezone.abbreviation+")";
}

// Example Usage
    const timeInIST = getCurrentTimeAndZoneCode(selectedZone.zoneName);  
  
  return (
    <CurrentTimeBox {...styles.position} 
    
    >
    <CurrentTimeContent
      isVerticalMode={isVerticalMode}
      isBaseTimeFormat={isBaseTimeFormat}
      isRTL={isRTL}
      style={{
        position:"absolute",
        fontSize: "13px",
        fontWeight: "500",
        color: "#FFFF",
        backgroundColor: "#33383F",
        top: "80px",
        width: "84px",
        left: "-84px",
        textAlign: "center",
        }}
    >
      {getNewCurrentTimeAndZoneCode(selectedZone)}
    </CurrentTimeContent>
  </CurrentTimeBox>
  );
}
