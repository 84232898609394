import React, {useEffect, useState} from 'react'
import cn from "classnames";
import styles from './OrgProfile.module.sass'
import Card from "../../../../../components/Card";
import Form from '../../../../../components/Form';
import Profile from './Profile'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Icon from '../../../../../components/Icon';
import TextInput from '../../../../../components/TextInput';
import Toast from '../../../../../components/Toast';
import { getOrgProfileAction, getUserProfileAction } from '../../../../../redux/action/organisation/ProfileAction/profileAction';
import ProfilePlaceHolder from '../../../../../media/images/placeholders/Profile.png'

const OrgProfile = ({className, setAppName, setShowLoader}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    const [UID, setUID] = useState('')
    
    const copyUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = UID;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
    }

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)}>
                <div className={styles.groups}>
                    <div className={styles.playerInformation}>
                        <div className={styles.preview}>
                            <div className={styles.primaryInfo}>
                                <div className={styles.profile}>
                                    <img src={OrgProfile && OrgProfile.defaultLogoUrl != null && OrgProfile.defaultLogoUrl != '' ? OrgProfile.defaultLogoUrl : ProfilePlaceHolder} alt={`Org Icon`} />
                                </div>
                                <div className={styles.otherInfo}>
                                    <div className={styles.orgName}>
                                        <span className={styles.userName}>{OrgProfile && OrgProfile.name != null && OrgProfile.name != '' ? OrgProfile.name : '-'}</span>
                                    </div>
                                    <div className={styles.secondaryInfo}>
                                        <div className={styles.playerId}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Org ID:&nbsp;</span><span className={styles.UIDNO}>{OrgProfile && OrgProfile.id != null && OrgProfile.id != '' ? OrgProfile.id : '-'}<span className={styles.copyToClipboard} onClick={copyUIDClipboard}><Icon name='copy' size='16' /></span></span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>No of Members:&nbsp;</span><span className={styles.UUIDNO}>10</span>
                                            </div>
                                        </div>
                                        <div className={styles.playerLocation}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Location:&nbsp;</span><span className={styles.UUIDNO}>{OrgProfile && OrgProfile.location != null && OrgProfile.location != '' ? OrgProfile.location : '-'}</span>
                                            </div>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Created At:&nbsp;</span><span className={styles.UUIDNO}>{OrgProfile && OrgProfile.createdAt != null && OrgProfile.createdAt != '' ? moment(OrgProfile.createdAt).format('DD/MM/YYYY HH:mm A') : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Card>

            <Profile data={OrgProfile} setAppName={setAppName} setShowLoader={setShowLoader}/>

        </>
    )
}

export default OrgProfile