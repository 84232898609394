import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../../resources/APIEndpoints";
// import Tooltip from "../Tooltip";

const FileVideos = ({ className, disabled, label, value, setValues, data, tooltip, title, success, error, accept, ...props }) => {

    let videoHolder = []

    const [total, setTotal] =  useState()
    const [loaded, setLoaded] = useState()
    const [percentage, setPercentage] = useState()
    const [length, setLength] = useState(0)

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percentage = Math.floor((loaded*100)/total)
        for(var i = 1 ; i <= document.getElementsByClassName('progress-bar-videos').length; i++){
            if(document.getElementById(`successfull_video${i}`)){
                document.getElementById(`successfull_video${i}`).style.width = `${percentage}%`
                if(percentage == 100){
                    document.getElementById(`percentage_video${i}`).style.display = 'none'
                    document.getElementById(`view_video${i}`).style.display = 'block'
                }
            }
        }
    }

    const onVideoUpload = async(e) => {
        for(var i = 0; i< e.target.files.length; i++){
            videoHolder.push(e.target.files[i])
        }
        setLength(videoHolder.length)
        videoHolder.forEach(async (element, index) => {
            const file_binary = await convertbase64(element)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=octet-stream/mp4`)
            let videoUrls = res.data.getUrl
            setValues(prevState => [...prevState, videoUrls])
            var config = {
                headers : {'Content-Type' : `${element.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
                onUploadProgress
              }
              axios(config)
              .then(function (response) {
                toast.success(`Video ${index+1} uploaded successfully`)
                e.target.value = ''
                })
              .catch(function (error) {toast.error("Please upload the file again")});
            });
    }
    
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    return (
        <div className={cn(styles.file, className)}>
            <div className={styles.wrap}>
                <input disabled={disabled} ref={data} multiple className={styles.input} type="file" accept="video/mp4, video/avi, .mp4, .avi" onChange={(e)=>{onVideoUpload(e)}}/>
                <div className={styles.box}>
                    <label><Icon name="upload" size="24"/>Click or drop Video</label>
                </div>
            </div>
        </div>
    );
};

export default FileVideos;
