import React from "react";
import styles from './EditCompetitionSchedule.module.sass'
import ScheduleCompetitionInformation from './ScheduleCompetitionInformation';
import { ToastContainer } from "react-toastify";

const EditCompetitionSchedule = ({isChanged, setIsChanged}) => {
    return (
      <>
        <ToastContainer />
        <div className={styles.row}>
            <div className={styles.col}>
                <ScheduleCompetitionInformation isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
            </div>
        </div>
      </>
    )
  }
  
  export default EditCompetitionSchedule