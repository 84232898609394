import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import Task from '../../../../../media/images/placeholders/TAsk.png'
import Mission from '../../../../../media/images/placeholders/Mission.png'
import SS from '../../../../../media/images/placeholders/Step_Series.png'
import moment from "moment";
import TooltipGlodal from "../../../../TooltipGlodal";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                        <>
                            <div className={styles.item}>
                                <div className={styles.preview}>
                                    <img src={item.task && item.task !== null && item.task.iconUrl !== null ? item.task.iconUrl : item.task && item.task !== null && item.task.iconUrl === null ? Task : item.taskGroups && item.taskGroups !== null && item.taskGroups.iconUrl !== null ? item.taskGroups.iconUrl : item.taskGroups && item.taskGroups !== null && item.taskGroups.iconUrl === null && item.taskGroups.typeId !== null && item.taskGroups.typeId === 2  ? Mission : item.taskGroups && item.taskGroups !== null && item.taskGroups.iconUrl === null && item.taskGroups.typeId !== null && item.taskGroups.typeId === 3  ? SS : ''} alt="task"/>
                                </div>
                            </div>
                        </>
                </div>
                <div className={styles.col}>
                    <TooltipGlodal title={(item.task != null && item.task.name != null) ? item.task.name : (item.taskGroups != null && item.taskGroups.name != null) ? item.taskGroups.name : '-'} width={150} itemId={`taskName${item.id}`}><div id={`taskName${item.id}`} className={styles.name}>{(item.task != null && item.task.name != null) ? item.task.name : (item.taskGroups != null && item.taskGroups.name != null) ? item.taskGroups.name : '-'}</div></TooltipGlodal>
                    <div className={styles.id}>{(item.task != null && item.task.id != null) ? item.task.id : (item.taskGroups != null && item.taskGroups.id != null) ? item.taskGroups.id : '-'}</div>
                </div>
                <div className={styles.col}><div className={styles.taskId}>{(item.task != null && item.task.taskId != null) ? item.task.taskId : (item.taskGroups != null && item.taskGroups.taskGroupId != null) ? item.taskGroups.taskGroupId : '-'}</div></div>
                <div className={styles.col}>
                    <div className={styles.name}>{(item.task != null && item.task.defaultEventId != null) ? item.task.defaultEvent.name : (item.task != null && item.task.customEventId != null) ? item.task.customEvent.name : '-'}</div>
                    <div className={styles.id}>{(item.task != null && item.task.defaultEventId != null) ? item.task.defaultEventId : (item.task != null && item.task.customEventId != null) ? item.task.customEventId : '-'}</div>
                </div>
                <div className={styles.col}>{item.status != null ? (item.status == 'completed' ? 'Pending' : 'Claimed') : '-'}</div>
                <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
            </div>
        </>
    );
};

export default Row;
