import React, { useState, useEffect } from 'react'
import cn from 'classnames';
import { Link, useParams } from "react-router-dom";
import styles from './rewards.module.sass'
import Icon from '../../../../../../../components/Icon';
import TextInput from '../../../../../../../components/TextInput';
import Form from '../../../../../../../components/Form/FormDropdown'
import { useDispatch, useSelector } from 'react-redux';
import {getAllCurrencyAction} from '../../../../../../../redux/action/builds/economy/currency/currencyAction'
import axios from 'axios';
import { BASE_URL, GET_CURRENCY } from '../../../../../../../resources/APIEndpoints';
import currencyPlaceHolder from '../../../../../../../media/images/placeholders/Currency.png'
import bundlePlaceHolder from '../../../../../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../../../../../media/images/placeholders/Progression_Marker.png'
import itemPlaceHolder from '../../../../../../../media/images/placeholders/Items.png'
import {ReactComponent as Divider} from '../../../../../../../media/images/icons/Config_Divider.svg'
import {ReactComponent as RemoveIcon } from '../../../../../../../media/images/icons/remove.svg';

const Reawards = ({ className, visible, setVisible, onClose, level, type, setPSLevels }) => {

    const dispatch = useDispatch();
    const {appid} = useParams();

    const [startRank, setStartRank] = useState()
    const [endRank, setEndRank] = useState()
    const [ranks, setRanks] = useState()

    const [currencies, setCurrencies] = useState()
    const [selectedRewards, setSelectedRewards] = useState([])
    const [showReward, setShowReward] = useState(false)

    useEffect(()=>{
        getLevels();
        resetFields();
        getAllRewards();
    }, [visible])

    const filterObjectToGetId = (data) => {
        const categorizedRewards = {
            rewardDetails : {
                items: { ids: [] },
                bundles: { ids: [] },
                currencies: { ids: [] },
                progressionMarkers: { ids: [] },
                progressionSystem: { ids: [] }
            },
            projectId : appid
        };

        data.forEach(reward => {
            switch (reward.rewardType) {
            case 'Items':
                categorizedRewards.rewardDetails.items.ids.push(reward.id);
                break;
            case 'Bundle':
                categorizedRewards.rewardDetails.bundles.ids.push(reward.id);
                break;
            case 'Currency':
                categorizedRewards.rewardDetails.currencies.ids.push(reward.id);
                break;
            case 'PM':
                categorizedRewards.rewardDetails.progressionMarkers.ids.push(reward.id);
                break;
            default:
                break;
            }
        });

        return categorizedRewards;
    }

    const mergeRewards = (arr1, arr2) => {
        const rewardMap = arr2.reduce((map, reward) => {
          map[reward.id] = reward;
          return map;
        }, {});
      
        return arr1.map(reward => {
          const matchingReward = rewardMap[reward.id];
          if (matchingReward) {
            return {
              ...reward,
              id: matchingReward.id,
              iconUrl: matchingReward.iconUrl,
              description : matchingReward.description,
              uuid : matchingReward.uuid,
              name : matchingReward.name
            };
          }
          return reward;
        });
    };

    const getLevels = () => {
        if(type == 'add'){
            const totalRanks = JSON.parse(localStorage.getItem('PDRanks'))
            if(totalRanks && level != undefined){
                setRanks(totalRanks)
                setStartRank(totalRanks[level].startRank)
                setEndRank(totalRanks[level].endRank)
            }
        }else{
            const totalRanks = JSON.parse(localStorage.getItem('PDRanks'))
            if(totalRanks && level != undefined){
                setRanks(totalRanks)
                const allRewards = [...totalRanks[level].rewardDetails.currencies, ...totalRanks[level].rewardDetails.items, ...totalRanks[level].rewardDetails.bundles, ...totalRanks[level].rewardDetails.progressionMarkers]
                let rewardIds = filterObjectToGetId(allRewards)
                const token = localStorage.getItem('token')
                const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
                axios.post(`${BASE_URL}/rewards-details/get`, rewardIds, headers)
                .then(res=>{
                    let currencies = res.data.currencies.map(i => ({uuid : i.id, id : i.currencyId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
                    let items = res.data.items.map(i => ({uuid : i.id, id : i.itemId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Items'}));
                    let bundles = res.data.bundles.map(i => ({uuid : i.id, id : i.bundleId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
                    let progressionMarkers = res.data.progressionMarkers.map(i => ({uuid : i.id, id : i.progressionMarkerId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Items'}));
                    const rewardList = [...currencies, ...items, ...bundles, ...progressionMarkers]
                    console.log(rewardList)
                    const mergedRewards = mergeRewards(allRewards, rewardList);
                    setSelectedRewards(mergedRewards)
                })
                setStartRank(totalRanks[level].startRank)
                setEndRank(totalRanks[level].endRank)
            }
            // if(totalRanks && level != undefined){
            //     setRanks(totalRanks)
            //     setSelectedRewards(totalRanks[level].rewardDetails)
            //     setStartRank(totalRanks[level].startRank)
            //     setEndRank(totalRanks[level].endRank)
            // }
        }
    }

    const getAllRewards = () => {
        let search = {
            projectId : appid,
            search : ''
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        axios.post(`${BASE_URL}/inventory/search`, search, headers)
        .then(res=>{
            let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
            let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
            let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
            let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
            const rewardList = [...currencies, ...items, ...bundles, ...pm]
            setCurrencies(rewardList)
        })
    }

    const searchReward = (e) => {
        if(e == ''){
            getAllRewards()
        }else{
            let search = {
                projectId : appid,
                search : e
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
                let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
                let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
                let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
                const rewardList = [...currencies, ...items, ...bundles, ...pm]
                setCurrencies(rewardList)
            })
        }
    }


    const onAdd = (e, index) => {
        e.status = true
        setSelectedRewards(prevState => [...prevState, e])
    }

    const onRemove = (e,index) => {
       let removedReward = [...selectedRewards]
       let remainingRewards = removedReward.filter((e,i,c)=> {
            return i != index
        })
        setSelectedRewards(remainingRewards)
    }

    const onChangeQuantity = (e, index) => {
        let currencyQuantity = [...selectedRewards]
        currencyQuantity[index].amount = parseInt(e.target.value)
        setSelectedRewards(currencyQuantity)
    }
    

    const resetFields = () => {
        setCurrencies([])
        if(type == 'add'){
            setSelectedRewards([])
        }
    }

    const convertToRewardDetails = (rewardsArray) => {
        // Initialize the rewardDetails object
        const rewardDetails = {items: [],currencies: [],bundles: [],progressionMarkers: []}; 
        // Mapping object to map type to category in rewardDetails
        const typeToCategoryMap = {'Items': 'items','Currency': 'currencies','Bundle': 'bundles','PM': 'progressionMarkers'};
        // Iterate over the rewards array and categorize each reward
        rewardsArray.forEach(reward => {
          const category = typeToCategoryMap[reward.rewardType];
          if (category) {
            rewardDetails[category].push(reward);
          }
        });
        return rewardDetails;
    };

    const save = () => {
        let totalLevels = JSON.parse(localStorage.getItem('PDRanks'))
        const details = convertToRewardDetails(selectedRewards)
        totalLevels[level].rewardDetails = details
        setPSLevels(totalLevels)
        localStorage.setItem('PDRanks', JSON.stringify(totalLevels))
        setVisible(false)
        // let totalLevels = JSON.parse(localStorage.getItem('PDRanks'))
        // totalLevels[level].rewardDetails = selectedRewards
        // setPSLevels(totalLevels)
        // localStorage.setItem('PDRanks', JSON.stringify(totalLevels))
        // setVisible(false)
    }

    const edit = () => {
        setVisible(false)
    }
    

    if(type == 'add'){
        return (
            <>
                <div className={cn(styles.help, className, {[styles.active]: visible,})}>
                    <div className={styles.head}>
                        <div className={styles.header}>
                            <div className={styles.headingDash}></div>
                            <div className={styles.heading}>Prize Configuration</div>
                        </div>
                        <button className={styles.close} onClick={() => setVisible(false)}>
                            <Icon name="close" size="24" />
                        </button>
                    </div>
                    <span>
                    <Divider className={styles.divider}/>
                    </span>
                    <div className={styles.menu}>
                        <div className={styles.menuContainer}>
                            <div className={styles.rewardMenu}>
                                <div className={styles.group}>
                                    <TextInput value={startRank} className={styles.field} label='Start Rank' disabled/>
                                </div>
    
                                <div className={styles.group}>
                                    <TextInput value={endRank} className={styles.field} label='End Rank' disabled/>
                                </div>
                                <div className={styles.groupSearch}>
                                    <div className={styles.inputGroup}>
                                        <div>
                                            <Form id='searchReward' size='sm' placeholder="Search Reward" type="text" name="search" icon="search" setValue={(e)=>{searchReward(e)}} focus={showReward} setFocus={setShowReward} options={currencies} reward={selectedRewards} setReward={setSelectedRewards} />
                                        </div>
                                        <div className={styles.filter}>
                                            <Icon name='filter' size='24' />
                                        </div>
                                    </div>
                                </div>

                                {selectedRewards && selectedRewards.length > 0 ?
                                    <div className={styles.group}>
                                        <div className={styles.heading}>
                                            <h4>Rewards</h4>
                                        </div>
                                        {selectedRewards && selectedRewards.map((e,i)=>{
                                        return(
                                                
                                                <div className={styles.rewards}>
                                                    <div className={styles.rewardsIcon}>
                                                        <img src={e.iconUrl} alt="icon" width={60}/>
                                                    </div>
                                                    <div className={styles.rewardsName}>
                                                        <span className={styles.name}>{e.name}</span> <br  />
                                                        <span className={styles.id}>{e.id}</span>
                                                    </div>
                                                    <div className={styles.rewardsInput}>
                                                        <label>Quantity</label>
                                                        <input key={e.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={e.amount} onChange={(e)=>{onChangeQuantity(e,i)}}/>
                                                    </div>
                                                    <div>
                                                        <span className={styles.icon} onClick={(e)=>{onRemove(e,i)}}><RemoveIcon /></span>
                                                    </div>
                                                </div>
                                        )
                                        })}
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </div>
                    {selectedRewards.length > 0 &&
                        <div className={styles.groups}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={save}>Save</button>
                                <button className={styles.cancel} onClick={() => setVisible(false)}>Cancel</button>
                            </div>
                        </div>
                    }
                </div>
                <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
            </>
        );
    }else{
        return (
            <>
                <div className={cn(styles.help, className, {[styles.active]: visible})}>
                    <div className={styles.head}>
                        <div className={styles.header}>
                            <div className={styles.headingDash}></div>
                            <div className={styles.heading}>Config Level</div>
                        </div>
                        <button className={styles.close} onClick={() => setVisible(false)}>
                            <Icon name="close" size="24" />
                        </button>
                    </div>
                    <div className={styles.menu}>
                        <div className={styles.menuContainer}>
                            <div className={styles.rewardMenu}>
                            
                            <div className={styles.group}>
                                    <TextInput value={startRank} className={styles.field} label='Level Name' disabled/>
                                </div>
                                <div className={styles.group}>
                                    <TextInput value={endRank} className={styles.field} label='Level Parameter Value' disabled/>
                                </div>
    
                                <div className={styles.groupSearch}>
                                    <div className={styles.inputGroup}>
                                        <div>
                                            <Form id='searchReward' size='sm' placeholder="Search Reward" type="text" name="search" icon="search" setValue={(e)=>{searchReward(e)}} focus={showReward} setFocus={setShowReward} options={currencies} reward={selectedRewards} setReward={setSelectedRewards}/>
                                        </div>
                                        <div className={styles.filter}>
                                            <Icon name='filter' size='24' />
                                        </div>
                                    </div>
                                </div>

                                {selectedRewards && selectedRewards.length > 0 ?
                                    <>
                                        <div className={styles.group}>
                                            <div className={styles.heading}>
                                                <h4>Rewards</h4>
                                            </div>
                                            {selectedRewards && selectedRewards.map((e,i)=>{
                                                return( 
                                                    <div key={i} className={styles.rewards}>
                                                        <div className={styles.rewardsIcon}>
                                                            <img src={e.iconUrl} alt="icon" width={60}/>
                                                        </div>
                                                        <div className={styles.rewardsName}>
                                                            <span className={styles.name}>{e.name}</span> <br  />
                                                            <span className={styles.id}>{e.id}</span>
                                                        </div>
                                                        <div key={i} className={styles.rewardsInput}>
                                                            <label>Quantity</label>
                                                            <input key={e.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={e.amount} onChange={(e)=>{onChangeQuantity(e,i)}}/>
                                                        </div>
                                                        <div>
                                                            <span className={styles.icon} onClick={(e)=>{onRemove(e,i)}}><RemoveIcon /></span>
                                                        </div>
                                                    </div>
                                                    )
                                            })}
                                        </div>
                                    </>
                                : ''}                                
                            </div>
                        </div>
                    </div>
                    <div className={styles.groups}>
                        <div className={styles.btnGroup}>
                            <button className={styles.save} onClick={save}>Edit</button>
                            <button className={styles.cancel} onClick={() => setVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
            </>
        );
    }
}

export default Reawards