import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import {ReactComponent as AddUser} from '../../../../../../media/images/icons/addUser.svg'
import {ReactComponent as Trophy} from '../../../../../../media/images/icons/trophy.svg'
import Modal from "../../../../../Modals/ModalComponent";
import AddMemberModal from "../../../../../AppSettingComponent/MemberComponent/AddMembersModal";
import DeleteMembers from "../../../../../AppSettingComponent/MemberComponent/DeleteMemberModal";

const Control = ({className, usedFor, id, setLoaderType, name, archiveStatus, setShowLoader, setAppName}) => {

  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const actions = [
    {
      icon: "edit",
      pathName : `/app-information/${id}`
    },
    {
      icon: 'user',
      action:  () => setVisibleModal(true),
    },
    {
      icon: "trash",
      action:  () => console.log("Trash"),
    }
  ];

  const refreshPage = (name,x) => {
    setAppName(name)
    setLoaderType(4)
    localStorage.setItem('AppIcon', x.iconUrl)
    localStorage.setItem('App', name)
    localStorage.setItem('environment', "Development")
    setShowLoader(true)
    setTimeout(() => {
        setShowLoader(false)
    }, 4000)
}

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => {
            return(
              <div onClick={x.icon == 'edit' ? () => {refreshPage(name,x)} : null}>
                <Link to={x.pathName} className={styles.button} key={index} onClick={x.action}>
                  {x.icon == 'user' ? <AddUser /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
                </Link>
              </div>
            )
        })}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddMemberModal usedFor={usedFor} id={id} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
