import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import AddContentModal from "../../../../../../components/Modals/Build/Economy/Bundle/AddContent";

const Control = ({pageItem, pageBundle, pageCurrency, className, type, width, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundles, setBundle, bundleCount, bundlePage, value, setValue,setCalled}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div>
              <span onClick={x.action} className={styles.add}><AddMetaIcon /></span>
            </div>
        ))}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddContentModal onClose={() => setVisibleModal(false)} pageItem={pageItem} pageBundle={pageBundle} pageCurrency={pageCurrency} type={type} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundles={bundles} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} value={value} setValue={setValue} setCalled={setCalled} />
      </Modal>
    </>
  );
};

export default Control;
