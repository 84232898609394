import {GET_CURRENCY, ADD_CURRENCY, EDIT_CURRENCY, GET_CURRENCY_BY_ID, FILTER_CURRENCY_SUCCESS, FILTER_CURRENCY_ERROR, GET_CURRENCY_ERROR, GET_CURRENCY_SUCCESS} from "../../../../actionTypes/actionTypes";
import currencyService from "../../../../../services/buildServices/economyServices/currencyServices/currencyService";
import { toast } from 'react-toastify';
import Toast from "../../../../../components/Toast";
import 'react-toastify/dist/ReactToastify.css';

export const getAllCurrencyAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        await currencyService.getAllCurrency(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(getAllCurrency(res.data.data.currenciesDetails, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_CURRENCY_SUCCESS : GET_CURRENCY_SUCCESS)))
        }).catch(err=>{
            dispatch(getAllCurrency(err.response, null,  (body?.showArchived ? FILTER_CURRENCY_ERROR : GET_CURRENCY_ERROR)))
        })
    }
}

export const filterCurrencyAction = (body, configureSkeleton, isFiltered) => {
    return async (dispatch) => {
        await currencyService.getAllCurrency(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
           dispatch(filterCurrency(res.data.data.currenciesDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_CURRENCY_SUCCESS))
        }).catch(err=>{
            dispatch(filterCurrency(err, null, FILTER_CURRENCY_ERROR))
        })
    }
}

export const getCurrencyByIdAction = (body) => {
    return async (dispatch) => {
        await currencyService.getCurrencyById(body)
        .then(res=>{
            dispatch(getCurrencyById(res.data.data.currenciesDetails))
        }).catch(err=>{
            console.log(err, "Error")
        })
    }
}

export const addCurrecyAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await currencyService.addCurrency(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                if(navigate){
                    navigate(`/currency/${appid}`)
                }
            }, 2000)
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editCurrecyAction = (body, navigate, appid,setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await currencyService.editCurrency(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            if(navigate){
                setTimeout(()=>{
                    if(setDisable){
                        setDisable(false)
                    }
                    navigate(`/currency/${appid}`)
                }, 2000)
            }
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archiveCurrencyAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await currencyService.archiveCurrency(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                let data = {projectId: appid, showArchived: status, sortField: "updated_at", sortOrder: "asc", offset: 0, limit: 8}
                dispatch(getAllCurrencyAction(data))
                if(onClose){
                    onClose()
                }
            }
        }catch (error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            throw error; 
        }
    }
}

export const getAllCurrency = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getCurrencyById = (data) => {
    return {
        type : GET_CURRENCY_BY_ID,
        payload : data
    }
}


export const addCurrency = (data) => {
    return {
        type : ADD_CURRENCY,
        payload : data
    }
}

export const editCurrency = (data) => {
    return {
        type : EDIT_CURRENCY,
        payload : data
    }
}

export const filterCurrency = (data, count, type) => {
    return {
        type : type,
        totalCount : count,
        payload : data
    }
}