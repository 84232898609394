import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../Tooltip";

const Dropdown = ({className, disabled, check, groupBy, error,ind, classLabel, classDropdownHead, classDropdownLabel, placeholder, value, setValue, setId, setType, setDisplayId, setField, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState('');
    const outClick = () => {
      setVisible(false)
      setFilterText('')
    }

    const handleClick = (selectedOption) => {
          let val = [...value]
          val[ind].lockedLevel=selectedOption.levelNo;
          setValue(val);
          setVisible(false);
            if(error){
                error(false)
            }
      };
      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => outClick()}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (
                    <Tooltip
                    className={styles.tooltip}
                    title={tooltip}
                    icon="info"
                    place="right"
                    />
                )}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible}, {[styles.disabled] : disabled})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)} style={{display:"flex"}}>
                <div className={styles.selection}>{(value[ind].lockedLevel == null || value[ind].lockedLevel == undefined || value[ind].lockedLevel == '') ? <span className={styles.placeholder}>{placeholder}</span> : (options&& options.find(e=>e.levelNo==value[ind].lockedLevel)?.levelName)&&(options&& options.find(e=>e.levelNo==value[ind].lockedLevel)?.levelName)}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody }, {[styles.disabled] : disabled})}>
                {options && options.filter(option => option.levelName&& option.levelName.toLowerCase().includes(filterText.toLowerCase())).map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value[ind].lockedLevel,})} key={index}>
                        {x.levelName && x.levelName}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown