import { BASE_URL, GET_BUNDLES, ADD_BUNDLES, EDIT_BUNDLES } from "../../../../resources/APIEndpoints";
import axios from "../../../../utils/interceptor";

function BundleServices(){
    this.getAllBundles = (body) => axios.post(BASE_URL + GET_BUNDLES, body)
    this.getBundleById = (body) => axios.post(BASE_URL + GET_BUNDLES, body)
    this.addBundle = (body) => axios.post(BASE_URL + ADD_BUNDLES, body)
    this.editBundle = (body) => axios.post(BASE_URL + EDIT_BUNDLES, body)
    this.archiveBundle = (body) => axios.post(BASE_URL + EDIT_BUNDLES, body)
}

export default new BundleServices()