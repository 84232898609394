import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../Checkbox";
import Row from "./Row";

const Table = ({usedFor, disable, items, access, title, setValue, allCurrency, setAllCurrency, allItems, setAllItems, allBundles, setAllBundle, allPM, setAllPM}) => {

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Display Name</div>
          <div className={styles.col}>Quantity</div>
          <div className={styles.col}>Item Type</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row usedFor={usedFor} disable={disable} access={access} total={items} setValue={setValue} item={x} key={x.id} index={index} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={allBundles} setAllBundle={setAllBundle} allPM={allPM} setAllPM={setAllPM}/>))}
      </div>
    </div>
  );
};

export default Table;
