import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './delete.module.sass'
import { deleteMemberAction } from '../../../../redux/action/AppSettings/Members/MembersAction'

const DeleteMembers = ({className, onClose, id}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onDelete = () => {
    let member = {
      memberId: id,
      organisationId : localStorage.getItem('orgId')
    }
    dispatch(deleteMemberAction(member))
    onClose()
  }
  
  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>
              <h5>Warning!</h5>
          </div>
          <div className={styles.warningContent}>
              <p>Are you sure you want to delete <span className={styles.currencyName}></span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.deleteBtn} onClick={onDelete}>Delete</button>
        <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}

export default DeleteMembers