import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './scheduleModal.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import Radio from '../Radio'
import moment from 'moment'
import Checkbox from '../Checkbox'
import DatePicker from "react-datepicker";
import Item from '../Schedule/Item'
import { format } from "date-fns";
import Dropdown from '../../components/Dropdown';
import TimezoneDropdown from '../../components/Dropdown/TimezoneDropdown';
import TooltipTitle from '../../Tooltip/TooltipTitle'
import TextInput from '../TextInput'
import { getAllMetaDataAction } from '../../redux/action/common/commonAction'
import { createScheduleAchievementAction, createScheduleLiveOpsAction, createScheduleTaskAction, createScheduleTaskGroupAction, rescheduleLiveOpsAction, scheduleCompetition } from '../../redux/action/engage/LiveOps/LiveOpsAction'
import Tooltips from '../Tooltip'
import {ReactComponent as Internet} from '../../media/images/icons/internet.svg'
import Timezones from '../../jsonMockData/Timezones'

const ScheduleModal = ({className, newStartDate, setNewStartDate, newEndDate, setNewEndDate, onClose}) => {


  console.log("newStartDate",newStartDate)
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')

  const [visibleStartDate, setVisibleStartDate] = useState(false);
  const [visibleStartTime, setVisibleStartTime] = useState(false);
  const [visibleEndDate, setVisibleEndDate] = useState(false);
  const [visibleEndTime, setVisibleEndTime] = useState(false);

  const save = () => {
      let formatedStartDate = null
      let formatedEndDate = null
      if(startDate != '' && startTime !=''){
        const parsedDate = moment(startDate);
        const parsedTime = moment(startTime);
        const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
        const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
        formatedStartDate = formattedDateTime.concat('+00:00')
      }

      if(endDate != '' && endTime != ''){
        const parsedDate = moment(endDate);
        const parsedTime = moment(endTime);
        const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
        const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
        formatedEndDate = formattedDateTime.concat('+00:00')
      }
      setNewStartDate(formatedStartDate)
      setNewEndDate(formatedEndDate)
      onClose()
  }

  const reset = () => {
    let formatedStartDate = null
    let formatedEndDate = null
    setNewStartDate(formatedStartDate)
    setNewEndDate(formatedEndDate)
    onClose()
}
  

  const [minStart, setMinStart] = useState(null)
  const [maxStart, setMaxStart] = useState(new Date())
  const [minEnd, setMinEnd] = useState(new Date())
  const [maxEnd, setMaxEnd] = useState(new Date())

  const onStartDate = (date, type) => {
    setStartDate(date);
    setVisibleStartDate(false)
    setStartTime('')
    setEndDate('')
    setEndTime('')
    // Parse the date strings into Moment objects
    const formattedDate1 = moment(date).format('DD-MM-YYYY');
    const formattedDate2 = moment(new Date()).format('DD-MM-YYYY');
    // Compare the dates
    if(formattedDate1 === formattedDate2) {
      // setMinStart(new Date().setMinutes(new Date().getMinutes()))
      setMaxStart((new Date()).setHours(23, 59, 59, 999))
    }else {
      // setMinStart((new Date()).setHours(0, 0, 0, 999))
      setMaxStart((new Date()).setHours(23, 59, 59, 999))
    } 
  }

  const onStartTime = (time) => {
    setStartTime(time);
    setVisibleStartTime(false)
    setEndDate('')
    setEndTime('')
  }

  const onEndDate = (date, type) => {
    setEndDate(date);
    setVisibleEndDate(false);
      const formattedDate1 = moment(date).format('DD-MM-YYYY');
      const formattedDate2 = moment(new Date(startDate)).format('DD-MM-YYYY');
      // Compare the dates
      if(formattedDate1 === formattedDate2) {
        setMinEnd(new Date(startTime).setMinutes(new Date(startTime).getMinutes()))
        setMaxEnd((new Date()).setHours(23, 59, 59, 999))
      }else {
        setMinEnd((new Date()).setHours(0, 0, 0, 999))
        setMaxEnd((new Date()).setHours(23, 59, 59, 999))
      }
  }

  // useEffect(() => {
  //   const parseDate = (dateTime) => {
  //     if (!dateTime) return { date: '', time: '' };

  //     let dateObj;
      
  //     if (typeof dateTime === 'string') {
  //       // Handle the format "YYYY-MM-DD HH:MM:SS+00:00"
  //       const [datePart, timePartWithZone] = dateTime.split(' ');
  //       if (timePartWithZone) {
  //         const timePart = timePartWithZone.split('+')[0];
  //         dateObj = new Date(`${datePart}T${timePart}Z`);
  //       } else {
  //         dateObj = new Date(dateTime);
  //       }
  //     } else if (dateTime instanceof Date) {
  //       dateObj = dateTime;
  //     } else {
  //       return { date: '', time: '' };
  //     }

  //     if (isNaN(dateObj.getTime())) return { date: '', time: '' }; // Check for valid date

  //     // Extract the date and time parts
  //     const datePart = dateObj.toISOString().split('T')[0];
  //     const timePart = dateObj.toTimeString().split(' ')[0];

  //     return { date: datePart, time: timePart };
  //   };

  //   const { date: startDateValue, time: startTimeValue } = parseDate(newStartDate);
  //   setStartDate(new Date(startDateValue));
  //   setStartTime(new Date(startTimeValue));

  //   const { date: endDateValue, time: endTimeValue } = parseDate(newEndDate);
  //   setEndDate(new Date(endDateValue));
  //   setEndTime(new Date(endTimeValue));
  // }, [newStartDate, newEndDate]);

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>

            <div className={styles.modalBody}>
              <div className={styles.modalContent}>

                  <>
                    <div className={styles.group}>
                      <div className={styles.field}>
                        <span className={styles.label}>Set Start Date and Start Time<span><Tooltips className={styles.tooltip} title={`Select the start date & time. This is the date & time when your schedule will begin.`} icon="info"/></span></span>
                      </div>
                      <div className={styles.field}>
                        <span className={styles.label}>Set End Date and End Time<span><Tooltips className={styles.tooltip} title={`Select the end date & time. This is the date & time when your schedule will end.`} icon="info"/></span></span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      
                      <Item className={styles.field} category="Start Date" icon="calendar" value={startDate && format(startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                        <div className={styles.date}>
                          <DatePicker selected={startDate} onChange={(date) => {onStartDate(date)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                      <Item disabled={(startDate == '' || startDate == null|| startTime == null || startTime == '') ? true : false} className={styles.field} category="End Date" icon="calendar" value={endDate && format(endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={new Date(startDate)} selected={endDate} onChange={(date) => {onEndDate(date, 'Normal')}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>
                    </div>

                    <div className={styles.group}>

                      <Item disabled={(startDate == '' || startDate == null) ? true : false} className={styles.field} category="Start Time" icon="clock" value={startTime && format(startTime, "h:mm aa")} visible={visibleStartTime} setVisible={setVisibleStartTime}>
                        <div className={styles.date}>
                          <DatePicker selected={startTime} onChange={(time) => {onStartTime(time)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>

                      <Item disabled={(startDate == '' || startDate == null || startTime == null || startTime == '') ? true : false} className={styles.field} category="End Time" icon="clock" value={endTime && format(endTime, "h:mm aa")} visible={visibleEndTime} setVisible={setVisibleEndTime}>
                        <div className={styles.date}>
                          <DatePicker minTime={minEnd} maxTime={maxEnd} selected={endTime} onChange={(time) => {setEndTime(time); setVisibleEndTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>
                    </div>
                  </>

              </div>

              {/* {showError && <><p className={styles.error}>Selected Start date and time exceeded current date and time. Please select new start date and time to continue.</p></>} */}
            </div>

            <div className={styles.cardAction}>
              <div className={styles.cardSave}>
                  <button className={styles.Save} onClick={()=>save()}>Apply</button>
              </div>
              <div className={styles.cardCancel}>
                  <button className={styles.Cancel} onClick={()=>reset()}>Reset</button>
              </div>
          </div>

          </div>
        </div>
  )
}

export default ScheduleModal