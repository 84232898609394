import React from "react";
import cn from "classnames";
import styles from "./textInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const TextInput = ({className, position, verify, fieldName, classLabel, classInput, label, icon, copy, currency, style, tooltip, place, icons, errorMessage, groupBy,isId, ...props}) => {

  const handleChange = (e) => {
    const { value } = e.target;
    if (isId) {
      const filteredValue = value.replace(/[^a-zA-Z0-9_]/g, "");
      props.onChange({target:{value:filteredValue}});
    } else {
      props.onChange(e);
    }
  };

  return (
    <div className={groupBy == 'value' ? cn(styles.fieldRule, { [styles.fieldIcon]: icon }, { [styles.fieldCopy]: copy }, { [styles.fieldCurrency]: currency }, className ) : cn(styles.field, { [styles.fieldIcon]: icon }, { [styles.fieldCopy]: copy }, { [styles.fieldCurrency]: currency }, className )}>
      {label && (
        <div className={styles.groupLabel}>
          <div className={cn(classLabel, styles.label)}>
            {label}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place={place ? place : "right"}
              />
            )}
          </div>
          {position != 'bottom' &&
            <div className={styles.error}>
              {errorMessage}
            </div>
          }
        </div>
      )}
      <div className={errorMessage ? styles.wrap_error : styles.wrap}>
        <input className={style == 'verify' ? cn(classInput, styles.verifyInput) : style == 'grey' ? cn(classInput, styles.labelInput) : (groupBy === 'value' || groupBy === 'noOfRecords') ? cn(classInput, styles.inputs) : cn(classInput, styles.input)} {...props} onChange={handleChange}></input>
        {currency && <div className={style == 'grey' ? styles.level : styles.currency}>{currency}</div>}
        {verify && (props.value != '') && <div className={styles.verify}>{verify}</div>}
      </div>
      {position === 'bottom' &&
        <div className={styles.error}>
          {errorMessage}
        </div>
      }
    </div>
  );
};

export default TextInput;
