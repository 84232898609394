import React, {useState} from 'react'
import cn from 'classnames'
import styles from './deleteModal.module.sass'

const DeleteStepModal = ({modalFor, className, setStepSeries, onClose, index}) => {

    const Delete = () => {
        let totalSteps = JSON.parse(sessionStorage.getItem('step-series'))
        let removeField = [...totalSteps]
        removeField[index] = { ...removeField[index], archive: true, sortingOrder : null};
        for(var i = 0 ; i < removeField.length ; i++){
          if(i > index){
            removeField[i] = { ...removeField[i], sortingOrder : removeField[i].sortingOrder-1};
          }
        }
        sessionStorage.setItem('step-series', JSON.stringify(removeField))
        setStepSeries(removeField)
        // let steps = totalSteps && totalSteps.filter((ele, i) => {
        //   return i != index
        // })
        // localStorage.setItem('step-series', JSON.stringify(steps))
        // setStepSeries(steps)
        onClose()
        document.body.style.overflow = 'auto';
    }

    return (
      <div className={styles.deleteBody}>
        <div className={styles.deleteContent}>
            <div className={styles.warning}>
                <h5>Warning!</h5>
            </div>
            <div className={styles.warningContent}>
                <p>Are you sure you want to delete this {modalFor == 'Mission' ? 'Task' : 'Step'}?</p>
            </div>
        </div>
        <div className={styles.btns}>
          <button className={styles.deleteBtn} onClick={Delete}>Delete</button>
          <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
        </div>
      </div>
    )
}

export default DeleteStepModal