import React, {useEffect, useState} from 'react'
import cn from "classnames";
import styles from './UseProfile.module.sass'
import Card from "../../../../../components/Card";
import Form from '../../../../../components/Form';
import Profile from './Profile'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Icon from '../../../../../components/Icon';
import TextInput from '../../../../../components/TextInput';
import Toast from '../../../../../components/Toast';

const UserProfile = ({className, setShowLoader}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    

    return (
        <>
            <ToastContainer />
            <Profile setShowLoader={setShowLoader} />
        </>
    )
}

export default UserProfile