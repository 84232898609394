import { GET_MEDIA, GET_MEDIA_SUCESS, GET_MEDIA_ERROR, ADD_MEDIA, ADD_MEDIA_SUCESS, ADD_MEDIA_ERROR, DELETE_MEDIA, DELETE_MEDIA_SUCESS, DELETE_MEDIA_ERROR } from "../../../actionTypes/actionTypes";
import MediaServices from '../../../../services/appSettingsServices/MediaServices/MediaServices'
import Toast from "../../../../components/Toast";
import { toast } from "react-toastify";

export const getAllMediaAction = (body) => {
    return async (dispatch) => {
        await MediaServices.getMedia(body)
        .then(res => {
            dispatch(getMedia(res.data.data, GET_MEDIA_SUCESS))
        }).catch(err => {
            dispatch(getMedia(err.response, GET_MEDIA_ERROR))
        })
    }
}

export const getMedia = (data, type) => {
    return {
        payload : data,
        type : type
    }
}

export const addMediaAction = (body, setContent, appid, folderName,shouldDispatch=true) => {
    return async (dispatch) => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        await MediaServices.addMedia(body,headers)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            if(setContent != null){
                setContent([])
            }
            shouldDispatch&& setTimeout(() => {
                dispatch(getAllMediaAction({projectId : appid, ...(folderName&&{filters : {"categories" :[folderName]}})}))
            }, 500)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            dispatch(addMedia(error.response, ADD_MEDIA_ERROR))
        })
    }
}

export const addMedia = (data, type) => {
    return {
        payload : data,
        type : type
    }
}

export const deleteMediaAction = (body, appid,folderName,shouldDispatch=true) => {
    return async (dispatch) => {
        await MediaServices.deleteMedia(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 1500, style : {background : '#DEF1E5'}})
            shouldDispatch&&dispatch(getAllMediaAction({projectId : appid, ...(folderName&&{filters : {"categories" :[folderName]}})}))
        }).catch(err => {
 
        })
    }
}

export const deleteMedia = (data, type) => {
    return {
        payload : data,
        type : type
    }
}