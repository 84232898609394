import React, { useState, useEffect, useContext } from 'react'
import cn from 'classnames'
import { Link, useParams, useNavigate } from 'react-router-dom'
import styles from './getProgressionSystem.module.sass'
import Card from '../../../../../components/Card'
import addIcon from '../../../../../media/images/icons/light.png'
import { useDispatch, useSelector } from 'react-redux'
import { filterPSAction, getAllProgressionSystemAction } from '../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction'
import ProgressionSystemTable from '../../../../../components/BuildComponents/Progression/ProgressionSystemComponent/ProgressionSystemTableLayout'
import ProgressionSystemCard from '../../../../../components/BuildComponents/Progression/ProgressionSystemComponent/ProgressionSystemCardLayout'
import Icon from '../../../../../components/Icon'
import Form from '../../../../../components/Form'
import { ReactComponent as EmptyCurrencyState } from "../../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from "../../../../../components/Loader";
import EmptyState from '../../../../../components/EmptyStates'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import Control from './Filters/Control'
import { ToastContainer } from 'react-toastify'

const sorting = ["list", "grid"];

const GetProgressionSystem = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { appid } = useParams()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Filters
  const [parameter, setParameter] = useState([])
  const [psType, setPSType] = useState([])
  const filters = {parameter, setParameter, psType, setPSType}
  // Search
  const [search, setSearch] = useState(null)
  // Sort
  const sortList = [{id: 1, name: 'PS Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getAllProgressionSystem = async(search) => {
    let getAllPS = {
      projectId: appid,
      offset: ((page-1)*limit),
      limit: limit,
      // Archive
      showArchived: showArchive,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      typeIds : psType,
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
    }
    let keysToCheck = ['psType']
    let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
    if(!search && isFiltered){
      await dispatch(getAllProgressionSystemAction(getAllPS, configureSkeleton))
    }else{
      await dispatch(filterPSAction(getAllPS, configureSkeleton, !isFiltered))
    }
  }

  let progressionSystem = useSelector((state) => {
    return state.progressionSystem.progressionSystem
  })

  let totalPS = useSelector((state)=>{
    return state.progressionSystem.totalCount
  })

  let isLoading = useSelector((state) => {
    return state.progressionSystem.isLoading
  })

  let isFiltered = useSelector((state) => {
    return state.progressionSystem.isFiltered
  })

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if(selectedSort !== null){
      getAllProgressionSystem(search)
    }
  }, [page, selectedSort, showArchive])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllProgressionSystem(null)
    }else{
      setSearch(search)
      getAllProgressionSystem(search)
    }
  }

  return(
    <>
      <ToastContainer />
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={180} height={32}/> : 'Progression Systems'} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              <div className={styles.add}>
                {showSkeleton && (progressionSystem?.length > 0 || (progressionSystem?.length === 0 && isFiltered)) ?
                  <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                : !showSkeleton && (progressionSystem?.length > 0 || (progressionSystem?.length === 0 && isFiltered)) ?
                  <>
                  {!userAccess &&
                    <div className={styles.createButton}>
                      <Link to={`/create-progression-system/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon' width={20} height={20} />Create</Link>
                    </div>
                  }
                  </>
                : ''}
              </div>
            </>
          )
        }
      >
        
        <div className={styles.wrapper}>

          <div className={styles.seprator}></div>
          <div className={styles.options}>
            {showSkeleton && progressionSystem.length > 0 &&
              <>
                <div className={styles.filters}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={120} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                </div>

                <div className={styles.searchForm}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                </div>
              </>
            }
                    
            {!showSkeleton &&
              (progressionSystem?.length > 0 || (progressionSystem?.length === 0 && isFiltered) ?
                <>
                  <div className={styles.filters}>
                    <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
                      <ArchiveSvg className={styles.icon} />
                      <span className={styles.buttonText}>Show Archived</span>
                    </div>
                    <Control setPage={setPage} filters={filters} action={getAllProgressionSystem}  data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Form className={styles.form} placeholder="Search Progression System" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                  </div>
                </>
              : '')
            }
          </div>

          {/* PS Table View */}
          {showSkeleton && progressionSystem?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && progressionSystem?.length > 0 && 
            <SkeletonTable rows={progressionSystem.length} column={4} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[44, 100, 100, 40]} colHeight={[44, 25, 25, 40]} />
          }
          {!showSkeleton && !isFiltered && progressionSystem?.length === 0 &&
            <EmptyState access={userAccess} route={`/create-progression-system/${appid}`} type="PS" />
          }
          {!showSkeleton && !isFiltered && progressionSystem?.length > 0 &&
            <ProgressionSystemTable access={userAccess} items={progressionSystem} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (progressionSystem?.length > 0 ?
              <ProgressionSystemTable access={userAccess} items={progressionSystem} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>

        {!isLoading && progressionSystem.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalPS/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about progression system <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/progression/progression-systems" target="_blank">Click Here</a></p>
      </div>
    </>
  )

  // return (
  //   <>
  //     <ToastContainer />
  //     <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={180} height={32}/> : 'Progression Systems'} classTitle={cn("title-purple", styles.title)}
  //       head={
  //         (
  //           <>
  //             <div className={styles.add}>
  //               {showSkeleton && progressionSystem.length > 0 ?
  //                 <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
  //               : !showSkeleton && progressionSystem.length > 0 ?
  //                 <>
  //                 {!userAccess &&
  //                   <div className={styles.createButton}>
  //                     <Link to={`/create-progression-system/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon' width={20} height={20} />Create</Link>
  //                   </div>
  //                 }
  //                 </>
  //               : ''}
  //             </div>
  //           </>
  //         )
  //       }
  //     >
  //       <div className={styles.wrapper}>
  //           {progressionSystem.length > 0 && <div className={styles.seprator}></div>}
  //             <div className={styles.options}>
  //               <div className={styles.filters}>
  //                 {(showSkeleton && progressionSystem.length > 0) ?
  //                   <>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={74} height={34}/>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={130} height={34}/>
  //                   </>
  //                 : !showSkeleton && progressionSystem.length > 0 ?
  //                   <>
  //                     <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
  //                       <ArchiveSvg className={styles.icon} />
  //                       <span className={styles.buttonText}>Show Archived</span>
  //                     </div>
  //                     <Control filters={filters} action={getAllProgressionSystem}  data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
  //                   </>
  //                 : ''}
                  
  //               </div>
  //               <div className={styles.searchForm}>
  //               {showSkeleton && progressionSystem.length > 0 ?
  //                 <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40}/>
  //               : !showSkeleton && progressionSystem.length > 0 ?
  //                 <Form className={styles.form} placeholder="Search Progression System" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
  //               : ''}
  //               </div>
  //           </div>
  //           {showSkeleton && 
  //             (progressionSystem.length == 0 ?
  //               <SkeletonEmptyState />
  //             : 
  //               <SkeletonTable rows={progressionSystem.length} column={5} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[44, 100, 100, 100, 40]} colHeight={[44, 25, 25, 25, 40]} />
  //             )
  //           }

  //           {!showSkeleton && 
  //             (progressionSystem.length == 0 ?
  //               <EmptyState access={userAccess} route={`/create-progression-system/${appid}`} type="PS" />
  //               : searchFlag ?
  //               <>
  //                 {isSearched && <div className={styles.textCenter}><Loader /></div>}
  //                 {!isSearched && filteredPS.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
  //                 {!isSearched && filteredPS.length > 0 && <ProgressionSystemTable items={filteredPS} title="Last edited" />}
  //               </>
  //             : <ProgressionSystemTable access={userAccess} items={progressionSystem} title="Last edited" />
  //             )
  //           }
            
  //           {!isLoading && progressionSystem.length > 0 &&
  //             <div className={styles.pagination}>
  //               <Stack spacing={2}>
  //                 <Pagination renderItem={(item) => (
  //                   <PaginationItem
  //                     {...item}
  //                     sx={{
  //                       '&.Mui-selected': {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       },
  //                       '&.MuiPaginationItem-page.Mui-selected:hover' : {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       }
  //                     }}
  //                   />
  //                 )} siblingCount={1} count={Math.ceil(totalPS/limit)} color="primary" onChange={handlePageChange} />
  //               </Stack>
  //             </div>
  //           }
  //         </div>
  //     </Card>
  //     <div className="divider"></div>
  //     {activeIndex === 1 && (
  //       <>
  //         <div className={styles.list}>
  //           {progressionSystem && progressionSystem.map((x, index) => (
  //             <ProgressionSystemCard className={styles.product} item={x} key={index} released />
  //           ))}
  //         </div>
  //       </>
  //     )}
  //     {/* {isLoading == false && progressionSystem.length == 0 && */}
  //       <div className={styles.msg}>
  //         <p className={styles.alert}>Learn more about progression system <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/progression/progression-systems" target="_blank">Click Here</a></p>
  //       </div>
  //     {/* } */}
  //   </>
  // )

}

export default GetProgressionSystem