import { BASE_URL, GET_PROGRESSION_SYSTEM, ADD_PROGRESSION_SYSTEM, EDIT_PROGRESSION_SYSTEM } from "../../../../resources/APIEndpoints";
import axios from "../../../../utils/interceptor";

function progressionSystemServices() {
    this.getAllProgressionSystem = (body) => axios.post(BASE_URL + GET_PROGRESSION_SYSTEM, body)
    this.createProgressionSystem = (body) => axios.post(BASE_URL + ADD_PROGRESSION_SYSTEM, body)
    this.editProgressionSystem = (body) => axios.post(BASE_URL + EDIT_PROGRESSION_SYSTEM, body)
    this.archivePS = (body) => axios.post(BASE_URL + EDIT_PROGRESSION_SYSTEM, body)
}

export default new progressionSystemServices()