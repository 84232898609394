import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}></div>
                <div className={styles.col}>{item.collectionId}</div>
                <div className={styles.col}>{item.count}</div>
                <div className={styles.col}>{item.updatedAt ? moment(item.updatedAt).format('DD-MM-YYYY hh:mm A') : '-'}</div>
            </div>
        </>
    );
};

export default Row;
