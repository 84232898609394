import React, { useEffect, useState } from 'react'
import bcrypt from 'bcryptjs'
import styles from './login.module.sass'
import TextInput from '../../../../components/TextInput'
import {ReactComponent as GoogleSVG} from '../../../../media/images/icons/google.svg'
import Icon from '../../../../components/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../../../redux/action/authenticate/authenticationAction'
import { ToastContainer } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom'
import './style.css'
import Spectre from '../../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import {ReactComponent as Office} from '../../../../media/images/icons/office.svg'
import {ReactComponent as Group} from '../../../../media/images/icons/group.svg'
import {ReactComponent as Internet} from '../../../../media/images/icons/internet.svg'
import FilledCrossed from '../../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../../media/images/x_fill.svg'
import Lottie from 'lottie-react'
import Dropdown from '../../../../components/Dropdown'
import SearchDropdown from '../../../../components/Dropdown/SearchDropdown'
import Checkbox from '../../../../components/Checkbox'
import TimezoneDropdown from '../../../../components/Dropdown/TimezoneDropdown'
import { getAllMetaDataAction } from '../../../../redux/action/common/commonAction'
import {ReactComponent as Check} from '../../../../media/images/icons/Vector 408 (Stroke).svg'
// import Timezones from '../../../../jsonMockData/Timezones'
import newTimezones from '../../../../components/CustomTimeZones'
import moment from 'moment'

const SignIn = ({onSave, showPersonal, user, setUser, props}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedZone, setSelectedZone] = React.useState("");
    const [selectedZoneID, setSelectedZoneID] = React.useState("");

    const teamSizeOption = [{id: 1, name : '1'}, {id: 2, name : '2-50'}, {id: 3, name : '51-200'}, {id: 4, name : '201-500'}, {id: 5, name : '500-1000'}, {id: 6, name : '1000+'}]

    const [solo, setSolo] = useState(false)
    const [location, setLocation] = useState(user.location)
    const [locationId, setLocationId] = useState(null)
    const [teamSize, setTeamSize] = useState(user.teamSize)
    const [teamId, setTeamId] = useState(null)
    const [orgName, setOrgName] = useState('')

    // const [selectedZone, setSelectedZone] = React.useState(user.timezone);

    const style = {
        width : 560,
        height : 608,
    }

    function getCurrentTimeAndZoneCode(timezone) {
        const now = moment.tz(timezone.zoneName);
      
      return " ("+timezone.abbreviation+")";
    }

    const back = () => {
        if(showPersonal){
            onSave(prevState => prevState - 1)
        }
    }

    const changeToggle = (e) => {
        if(e.target.checked){
            setSolo(!solo)
            setOrgName(`${user.firstName} ${user.lastName}'s Organization` )
            setTeamSize('1')
            setUser((user) => ({...user, solo : !user.solo, typeId : 1, name : `${user.firstName} ${user.lastName}'s Organization`, teamSize : '1'}))
        }else{
            setSolo(!solo)
            setOrgName('')
            setTeamSize('')
            setUser((user) => ({...user, solo : !user.solo, typeId : 2, name : ''}))
        }
    }

    const next = () => {
        setUser((user) => ({...user, location : location, teamSize : teamSize, timezone: selectedZone.zoneName}))
        // console.log("selectedZone",selectedZone.zoneName)
        onSave(prevState => prevState + 1)
    }

    const onChangeOrganization = (e) => {
        setOrgName(e.target.value)
        setUser((user) => ({...user, name : e.target.value}))
    }

    useEffect(() => {
        getAllLocation()
    }, [])

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }

    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    return (
    <>
        <ToastContainer />
        <div className={styles.personalInfo}>
            <div className={styles.formContainer}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <div className={styles.formTitle}>Create your Organization Hub.</div>
                        <div className={styles.formContent}>Set up your organization's hub for teamwork and collaboration.</div>
                    </div>
                    <div className={styles.formBody}>
                        <div className={styles.single}>
                            <TextInput verify={<Check />} className={styles.fields} value={user.name} onChange={(e)=>{onChangeOrganization(e)}} type='text' placeHolder={`What's your organization called?`} autoComplete="off" currency={<Office />}/>
                        </div>
                        <Checkbox className={styles.checkbox} content={`Flying solo? I'm working independently, not with a larger team.`} value={user.solo} onChange={(e)=>{changeToggle(e)}}/>
                        <div className={styles.single}>
                            <Dropdown disable={user.solo ? true : false} currency={<Group />} className={styles.fields} placeholder='How big is your team?' options={teamSizeOption} value={teamSize} setValue={setTeamSize} setId={setTeamId}/>
                        </div>
                        <div style={{marginTop:"24px"}}>
                            <Dropdown position="bottom" customFunc={()=>{""}} currency={<Internet fill="#000000" size="16"/>} placeholder={"Preferred Time Zone"} setSelectedZone={setSelectedZone} setId={setSelectedZoneID} value={selectedZone.zoneName&& selectedZone.zoneName+getCurrentTimeAndZoneCode(selectedZone)} options={
                                newTimezones.map((place)=>{
                                return {name:place.zoneName+getCurrentTimeAndZoneCode(place), value:place}
                                })
                            }/></div>
                        {/* <div style={{marginTop:"24px"}}>
                            <TimezoneDropdown usedFor={`user`} icon={<Internet fill="#000000" size="16"/>} placeholder={"Preferred Time Zone"} options={Timezones} value={selectedZone} setValue={setSelectedZone}/>
                        </div> */}
                        <div className={styles.single}>
                            <SearchDropdown currency={<Internet />} className={styles.fields} placeholder='Where are you based?' options={locationOption} value={location} setValue={setLocation} setId={setLocationId}/>
                        </div>
                    </div>
                    <div className={styles.formFooter}>
                        <button className={styles.Back} onClick={back}>Back</button>
                        <button className={styles.Next} onClick={next}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SignIn