import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import DateTooltip from "../../../../../components/CustomTooltips/DateTooltip";
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
            <div className={styles.col}><div className={styles.itemName}>{(item.currencies != null && item?.currencies?.name!= null) ? item.currencies.name : (item.inventoryBundles != null && item?.inventoryBundles?.name != null) ? item.inventoryBundles.name : (item?.inventoryItems != null && item?.inventoryItems?.name != null) ? item.inventoryItems.name : (item.progressionMarker != null && item?.progressionMarker?.name!=null) ? item.progressionMarker.name  : ''}</div></div>
                <div className={styles.col}><div className={styles.ids}>{item.id}</div></div>
                <div className={styles.col}>{item?.currencies?.name ? <div className={styles.currency}>Currency</div> : item?.inventoryItems?.name ? <div className={styles.item}>Item</div> : item?.inventoryBundles?.name ? <div className={styles.bundle}>Bundle</div> : item?.progressionMarker?.name ? <div className={styles.bundle}>PM</div> : ''}</div>
                <div className={styles.col}>{item.amount}</div>
                <div className={styles.col}><div className={styles.source}>{item.taskId != null ? 'Task' : item.taskGroupId != null ? 'Task Group' : item.levelSystemId != null ? `${item.levelSystem && item.levelSystem.name ? item.levelSystem.name : ''}` : item.leaderboardId !== null ? 'Leaderboard' : item.competitionId !== null ? 'Competition' : 'Custom'}</div></div>
                <div className={styles.col}>
                    {/* <div className={styles.ids}> */}
                        {(item.taskId != null && item.tasks != null) ? <div className={styles.ids}>{item.tasks.taskId}</div>
                        : (item.taskGroupId != null && item.taskGroup != null) ? <div className={styles.ids}>{item.taskGroup.taskGroupId}</div>
                        : (item.levelSystemLevelId != null && item.levelSystemLevel != null) ? <div className={styles.ids}>{item.levelSystemLevel.levelName}</div>
                        : (item.leaderboardId != null && item.leaderboard != null) ? 
                            <DateTooltip startDate={item.instanceDetails?.startDate} endDate={item.instanceDetails?.endDate}><div className={styles.ids}>{item.leaderboard.leaderboardId}</div></DateTooltip>
                        : (item.competitionId != null && item.competition != null) ? 
                            <DateTooltip startDate={item.instanceDetails.startDate} endDate={item.instanceDetails.endDate}><div className={styles.ids}>{item.competition.competitionId}</div></DateTooltip> 
                        : <div className={styles.ids}>{item.instanceId}</div>}
                    {/* </div> */}
                </div>
                <div className={styles.col}>{(item.status == 'Completed' || item.status == 'completed') ? <div className={styles.success}>Completed</div> : (item.status == 'Failed' || item.status == 'failed') ? <div className={styles.danger}>Failed</div> : (item.status == 'Pending' || item.status == 'pending') ? <div className={styles.warning}>Pending</div> : ''}</div>
                <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
            </div>
        </>
    );
};

export default Row;
