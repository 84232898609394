import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../Modals/ModalComponent";
import JsonEditor from "../EngageComponents/PlayersComponent/Modals/JsonEditor";
import AceJsonEditor from "../EngageComponents/PlayersComponent/Modals/AceEditor";
import JsonTextInput from '../JsonTextInput'
import Icon from '../Icon'

const JSONMetaDataValueInput = ({className, disabled, index, data, value, setValue, type, width}) => {

  const [metaDataModal, setMetaDataModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setMetaDataModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div onClick={!disabled ? x.action : null}>
              <JsonTextInput currency="null" value={typeof data == 'string' ? JSON.parse(data) : data} className={styles.field} type='text'/>
            </div>
        ))}
      </div>
      <Modal id='metaData' visible={metaDataModal} onClose={() => setMetaDataModal(false)}>
        <AceJsonEditor id='metaDataInput' index={index} data={data} value={value} setValue={setValue} onClose={() => setMetaDataModal(false)}/>
      </Modal>
    </>
  );
};

export default JSONMetaDataValueInput;