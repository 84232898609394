import React, { useEffect, useState } from 'react'
import styles from './resetPassword.module.sass'
import TextInput from '../../../components/TextInput'
import Icon from '../../../components/Icon'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordAction } from '../../../redux/action/authenticate/authenticationAction'
import { ToastContainer, toast } from 'react-toastify'
import Spectre from '../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../media/images/x_fill.svg'
import Lottie from 'lottie-react'
import Toast from '../../../components/Toast'

const ResetPassword = ({setShowLoader, setLoaderType}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [email, setEmail] = useState(null)
    const [token, setToken] = useState(null)

    const [rules, setRules] = useState({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})

    const style = {
        width: 560,
        height: 608,
    }
  
    useEffect(() => {
      const userToken = queryParams.get('token');
      const userEmail = queryParams.get('email');
      setToken(userToken)
      setEmail(userEmail) 
    }, [location.search]);

    const [passWord, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    const [errors, setErrors] = useState({passWordErr : ''})

    const validation = (passWord, confirmPassword) => {
        let error = {}
        let isValid = true
        if(passWord !== confirmPassword){
            error.passWordErr = 'Password does not match'
            isValid = false
            toast.error(<Toast type='Error' messages={`Password does not match`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
        }

        setErrors(error)
        if(isValid){
            return true
        }
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
        setRules(validatePassword(e.target.value))
    }

    const validatePassword = (password) => {
        const rules = {
            lengthRule: password.length >= 8,
            capitalRule: /[A-Z]/.test(password),
            smallRule: /[a-z]/.test(password),
            numberRule: /\d/.test(password),
            specialRule: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        }
        return rules
    }

    const changePassword = () => {
        if(validation(passWord, confirmPassword)){
            if(rules.lengthRule && rules.smallRule && rules.capitalRule && rules.numberRule && rules.specialRule){
                let resetPassword = {
                    newPassword : confirmPassword,
                    email : email,
                    token : token
                }
                dispatch(ResetPasswordAction(resetPassword, navigate, setShowLoader, setLoaderType))
            }    
        }
    }

    return (
        <>
            <ToastContainer />
            <div className={styles.resetPassword}>
                <div className={styles.reset_container_outer}>
                    <div className={styles.logo}>
                        <SpecterLogo/>
                    </div>
                    <div className={styles.reset_container_inner}>
                        <div className={styles.Branding}>
                            <HalfCircle className={styles.halfCircle}/>
                            <Cross className={styles.crossFilled}/>
                            <FullCircle className={styles.fullCircle}/>
                            <FullHollowCircle className={styles.hollowCircle}/>
                            <div className={styles.animation}>
                                <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true}
                                        autoPlay={true} style={style}/>
                            </div>
                        </div>
                        <div className={styles.form}>
                            <HollowCross className={styles.hollowCross}/>
                            <div className={styles.formResetPassword}>
                                <img src={FilledCrossed} className={styles.filledCross}/>
                                <SmallerFullCircle className={styles.fullCircle}/>
                                <div className={styles.resetPasswordForm}>
                                    <div className={styles.formTitle}>
                                        <p className={styles.title}>Reset your password</p>
                                    </div>

                                    <div className={styles.formFiedls}>
                                        <TextInput disabled value={email} type='email' placeholder='Email' fieldName='email' currency={<Icon name='mail' size='24' />} />
                                    </div>

                                    <div className={styles.formFiedls}>
                                        <TextInput type='password' errorMessage={errors.passWordErr} placeholder='New Password' fieldName='newPassword' currency={<Icon name='lock' size='24' />} onChange={(e) => {onChangePassword(e)}}/>
                                    </div>

                                    <div className={styles.formFiedls}>
                                        <TextInput type='password' errorMessage={errors.passWordErr} placeholder='Confirm Password' fieldName='confirmPassword' currency={<Icon name='lock' size='24' />} onChange={(e) => {setConfirmPassword(e.target.value)}}/>
                                    </div>

                                    <div className={styles.rules}>
                                        <div className={styles.passwordRule}>
                                            <div className={styles.heading}>
                                                <span>Password must contain: </span>
                                            </div>
                                            <div className={styles.ruleGroup}>
                                                <div className={styles.rule}>
                                                    {rules.lengthRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.lengthRule != null && !rules.lengthRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.lengthRule != null && rules.lengthRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least 8 characters</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.smallRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.smallRule != null && !rules.smallRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.smallRule != null && rules.smallRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one lowercase letter (a-z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.capitalRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.capitalRule != null && !rules.capitalRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.capitalRule != null && rules.capitalRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one uppercase letter (A-Z)</span>
                                                </div>
                                                <div className={styles.rule}>
                                                    {rules.numberRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.numberRule != null && !rules.numberRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.numberRule != null && rules.numberRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one number (0-9)</span>
                                                </div>
                                                <div className={styles.ruleFull}>
                                                    {rules.specialRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                                    {rules.specialRule != null && !rules.specialRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                                    {rules.specialRule != null && rules.specialRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                                    <span>at least one special character (! @ # $ % ^ & )</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.resetPassword}>
                                        <button className={styles.Save} onClick={changePassword}>Change Password</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword