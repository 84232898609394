import react from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './economy.module.sass'
import {ReactComponent as Seperator} from '../../../media/images/icons/Seperator.svg'
import { Link, useParams } from 'react-router-dom'

const Economy = ({className, data}) => {

    const {appid} = useParams()

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Economy" classTitle="title-green">
                    <div className={styles.appStatistic}>
                        <div className={styles.wrapper}>
                            <div className={styles.currencyContainer}>
                                <div className={styles.title}>Currencies</div>
                                <Link to={`/currency/${appid}`}><div className={styles.countContainer}><div className={styles.count}>{(data != null && data.currenciesCount != null) ? data.currenciesCount : ''}</div></div></Link>
                            </div>
                            <div className={styles.seprator}><Seperator /></div>
                            <div className={styles.itemContainer}>
                                <div className={styles.title}>Items</div>
                                <Link to={`/item/${appid}`}><div className={styles.countContainer}><div className={styles.count}>{(data != null && data.itemsCount != null) ? data.itemsCount : ''}</div></div></Link>
                            </div>
                            <div className={styles.seprator}><Seperator /></div>
                            <div className={styles.bundleContainer}>
                                <div className={styles.title}>Bundles</div>
                                <Link to={`/bundle/${appid}`}><div className={styles.countContainer}><div className={styles.count}>{(data != null && data.bundlesCount != null) ? data.bundlesCount : ''}</div></div></Link>
                            </div>
                            <div className={styles.seprator}><Seperator /></div>
                            <div className={styles.storeContainer}>
                                <div className={styles.title}>Stores</div>
                                <Link to={`/store/${appid}`}><div className={styles.countContainer}><div className={styles.count}>{(data != null && data.storesCount != null) ? data.storesCount : ''}</div></div></Link>
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Economy