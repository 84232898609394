import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './LeaderboardFilter.module.sass'
import Icon from "../../../Icon";
import axios from "axios";
import { BASE_URL } from "../../../../resources/APIEndpoints";
import Checkbox from "../../../Checkbox";
import Form from "../../../Form";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Radio from "../../../Radio";
import moment from 'moment'
import DatePicker from "react-datepicker";
import Item from '../../../Schedule/Item'
import { format } from "date-fns";
import TextInput from "../../../TextInput";
import Dropdown from '../../../Dropdown';
import { Range, getTrackBackground } from "react-range";
import { getAllLeadboardAction } from "../../../../redux/action/engage/Leaderboard/leaderboardAction";

const STEP = 1;
const MIN = 0;
const MAX = 500;

const CompetitionFilterModal = ({className, action, filters, onClose}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    let filterList = [{id: 1, name: 'Source'}, {id: 2, name: 'Leaderboard Ranking'}, {id: 3, name: 'Recurring'}, {id: 4, name: 'Prize Configuration'}, {id: 5, name: 'Schedule Type'}, {id: 6, name: 'Date'}, {id: 7, name: 'Frequency'}, {id: 8, name: 'Status'}, {id: 9, name: 'Instance Status'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)
    // Event Toggle
    const [isEventToggle, setIsEventToggle] = useState(true)
    // Filter Options
    const [sourceOption, setSourceOption] = useState([])
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState([])
    const [scheduleOption, setScheduleOption] = useState([{id: 1, name: 'All Time'}, {id: 2, name: 'Time Bound'}])
    const [pricingOption, setPricingOption] = useState([{id: 1, name: 'Virtual Currency'}, {id: 2, name: 'IAP'}, {id: 3, name: 'RMG'}])
    const [recurringOptions, setRecurringOption] = useState([{id: 7, name: 'Minutes'}, {id: 8, name: 'Hours'}, {id: 1, name: 'Days'}, {id: 2, name: 'Week'}])
    const intervalOption = [{id: 1, name: 'Minutes'}, {id: 2, name: 'Hours'}, {id: 3, name: 'Days'}, {id: 4, name: 'Weeks'}]
    const statusOption  = [{id: 1, name: 'Yet To Start'}, {id: 2, name: 'In Progress'}, {id: 3, name: 'Stopped'}, {id: 4, name: 'Completed'}]
    const instanceStatusOption  = [{id: 1, name: 'Yet To Start'}, {id: 2, name: 'In Progress'}, {id: 3, name: 'Stopped'}, {id: 4, name: 'Completed'}]

    const getMetaData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setSourceOption(res.data.data.leaderboardSourceTypeMasterIds && res.data.data.leaderboardSourceTypeMasterIds.map((obj) => {
            return {
                id: obj.id,
                name: (obj.name == 'match' ? 'Match' : obj.name == 'custom' ? 'Custom': obj.name === 'statistics' ? 'Statistics' : ''),
            };
        }))
        setLeaderboardOutcomeOption(res.data.data.leaderboardOutcomeIds && res.data.data.leaderboardOutcomeIds.map((obj) => {
            return {
              id: obj.id,
              name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            };
        }))
    }

    useEffect(() => {
        getMetaData()
    }, [])

    // Start Date and End Date
    const [visibleStartDate, setVisibleStartDate] = useState(false)
    const [visibleEndDate, setVisibleEndDate] = useState(false)

   
    // Filter
    const [filterSource, setFilterSource] = useState('')
    const [filterSchedule, setFilterSchedule] = useState('')
    const [filterPriceType, setFilterPriceType] = useState('')
    const [filterRanking, setFilterRanking] = useState('')
    const [filterStatus, setFilterStatus] = useState('')
    const [filterInstance, setFilterInstance] = useState('')

    const onSourceChange = (e, id) => {
        if(e.target.checked){
            filters.setSource([...filters.source, id])
        }else{
            filters.setSource(filters.source.filter(item => item !== id))
        }
    }

    const onOutcomeChange = (e, id) => {
        if(e.target.checked){
            filters.setLeaderboardRanking([...filters.leaderboardRanking, id])
        }else{
            filters.setLeaderboardRanking(filters.leaderboardRanking.filter(item => item !== id))
        }
    }

    const onSchduleChange = (e, name) => {
        if(e.target.checked){
            filters.setSchduleType([...filters.schduleType, name])
        }else{
            filters.setSchduleType(filters.schduleType.filter(item => item !== name))
        }
    }

    const onPriceTypeChange = (e, name) => {
        if(e.target.checked){
            filters.setPriceType([...filters.priceType, name])
        }else{
            filters.setPriceType(filters.priceType.filter(item => item !== name))
        }
    }

    const onChangeEntry = (e) => {
        if(e.target.value === ''){
            filters.setMaxEntryAllowed('')
        }else{
            filters.setMaxEntryAllowed(parseInt(e.target.value))
        }
    }

    const onChangeAttempt = (e) => {
        if(e.target.value === ''){
            filters.setMaxAttemptAllowed('')
        }else{
            filters.setMaxAttemptAllowed(parseInt(e.target.value))
        }
    }

    const onChangeRecurrance = (e) => {
        if(e.target.value === ''){
            filters.setRecurrance('')
        }else{
            filters.setRecurrance(parseInt(e.target.value))
        }
    }

    const onCompetitionStatus = (e, name) => {
        if(e.target.checked){
            filters.setStatus([...filters.status, (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')])
        }else{
            filters.setStatus(filters.status.filter(item => item !== (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')))
        }
    }

    const onCompetitionInstanceStatus = (e, name) => {
        if(e.target.checked){
            filters.setInstanceStatus([...filters.instanceStatus, (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')])
        }else{
            filters.setInstanceStatus(filters.instanceStatus.filter(item => item !== (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')))
        }
    }


    const saveFilter = () => {
        action()
        onClose()
    }

    const resetFilter = () => {  
        filters.setSource([])
        filters.setLeaderboardRanking([])
        filters.setIsRecurring(null)
        filters.setIsPrizeConfigured(null)
        filters.setSchduleType([])
        filters.setStartDate('')
        filters.setEndDate('')
        filters.setRecurranceInterval(null)
        filters.setRecurranceIntervalId(null)
        filters.setStatus([])
        filters.setInstanceStatus([])     
        let leaderboard = {
            projectId : appid,
            // Sort
            sortOrder : 'asc',
            sortField : 'updated_at',
            offset: 0,
            limit: 8
        }
        dispatch(getAllLeadboardAction(leaderboard))
        onClose()
    }

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index===activeIndex})} key={index} onClick={() => {setActiveIndex(index)}}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Source' && filters.source?.length > 0 && <span className={styles.countPill}>{filters.source?.length}</span>}
                                                    {e.name == 'Leaderboard Ranking' && filters.leaderboardRanking?.length > 0 && <span className={styles.countPill}>{filters.leaderboardRanking?.length}</span>}
                                                    {e.name == 'Recurring' && filters.isRecurring !== null && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Prize Configuration' && filters.isPrizeConfigured !== null && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Schedule Type' && filters.schduleType?.length > 0 && <span className={styles.countPill}>{filters.schduleType?.length}</span>}
                                                    {e.name == 'Date' && (filters.startDate !== '' || filters.endDate !== '') && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Frequency' && (filters.recurrance !== null || filters.recurranceIntervalId !== null) && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Status' && filters.status?.length > 0 && <span className={styles.countPill}>{filters.status?.length}</span>}
                                                    {e.name == 'Instance Status' && filters.instanceStatus?.length > 0 && <span className={styles.countPill}>{filters.instanceStatus?.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='20'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Leaderboard Source" type="text" name="search" icon="search" onChange={(e) => {setFilterSource(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {sourceOption && sourceOption.filter(option => option.name && option.name.toLowerCase().includes(filterSource.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.source.includes(x.id) || filters.source.includes(x.id)} onChange={(e)=>{onSourceChange(e, x.id)}}/>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 1 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Leaderboard Ranking" type="text" name="search" icon="search" onChange={(e) => {setFilterRanking(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {leaderboardOutcomeOption && leaderboardOutcomeOption.filter(option => option.name && option.name.toLowerCase().includes(filterRanking.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.leaderboardRanking.includes(x.id) || filters.leaderboardRanking.includes(x.id)} onChange={(e)=>{onOutcomeChange(e, x.id)}}/>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 2 &&
                                        <>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Is Recurring?</span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="isRecurring" content="Yes" value={filters.isRecurring != null ? filters.isRecurring : null} onChange={() => {filters.setIsRecurring(true)}}/>
                                                    <Radio className={styles.radio} name="isRecurring" content="No" value={filters.isRecurring != null ? !filters.isRecurring : null} onChange={() => {filters.setIsRecurring(false)}}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 3 &&
                                        <>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Is Prize Configured?</span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="isPrizeConfigured " content="Yes" value={filters.isPrizeConfigured != null ? filters.isPrizeConfigured : null} onChange={() => {filters.setIsPrizeConfigured(true)}}/>
                                                    <Radio className={styles.radio} name="isPrizeConfigured " content="No" value={filters.isPrizeConfigured != null ? !filters.isPrizeConfigured : null} onChange={() => {filters.setIsPrizeConfigured(false)}}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 4 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Schedule Type" type="text" name="search" icon="search" onChange={(e) => {setFilterSchedule(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {scheduleOption && scheduleOption.filter(option => option.name && option.name.toLowerCase().includes(filterSchedule.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.schduleType.includes(x.name) || filters.schduleType.includes(x.name)} onChange={(e)=>{onSchduleChange(e, x.name)}}/>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 5 && 
                                        <>
                                           <div className={styles.group}>
                                                <div className={styles.field}>
                                                    <span className={styles.label}>Start Date</span>
                                                </div>
                                            </div>
                                            <div className={styles.group}>
                                                <Item className={styles.field} category="Start Date" icon="calendar" value={filters.startDate && format(filters.startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                                                    <div className={styles.date}>
                                                        <DatePicker selected={filters.startDate} onChange={(date) => {filters.setStartDate(date); setVisibleStartDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline/>
                                                    </div>
                                                </Item>                                                
                                            </div>

                                            <div className={styles.group}>
                                                <div className={styles.field}>
                                                    <span className={styles.label}>End Date</span>
                                                </div>
                                            </div>
                                            <div className={styles.group}>
                                                <Item className={styles.field} category="End Date" icon="calendar" value={filters.endDate && format(filters.endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                                                    <div className={styles.date}>
                                                        <DatePicker selected={filters.endDate} onChange={(date) => {filters.setEndDate(date); setVisibleEndDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline/>
                                                    </div>
                                                </Item>                                                
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 6 &&
                                        <>
                                            <div className={styles.group}>
                                                <TextInput label='Interval Length' className={styles.textInput} value={filters.recurrance} onChange={(e) => {onChangeRecurrance(e)}}/>
                                            </div>
                                            <Dropdown label='Interval Unit' placeholder='Select Interval Unit' value={filters.recurranceInterval} options={recurringOptions} setValue={filters.setRecurranceInterval} setId={filters.setRecurranceIntervalId}/>
                                        </>
                                    }
                                    {activeIndex === 7 &&
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Status" type="text" name="search" icon="search" onChange={(e) => {setFilterStatus(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {statusOption && statusOption.filter(option => option.name && option.name.toLowerCase().includes(filterStatus.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.status.includes(x.name === 'Yet To Start' ? 'yet to start' : x.name === 'In Progress' ? 'in progress' : x.name === 'Completed' ? 'completed' : x.name === 'Stopped' ? 'stopped'  : '')} onChange={(e)=>{onCompetitionStatus(e, x.name)}} />
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 8 &&
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Instance Status" type="text" name="search" icon="search" onChange={(e) => {setFilterInstance(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {instanceStatusOption && instanceStatusOption.filter(option => option.name && option.name.toLowerCase().includes(filterInstance.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.instanceStatus.includes((x.name === 'Yet To Start' ? 'yet to start' : x.name === 'In Progress' ? 'in progress' : x.name === 'Completed' ? 'completed' : x.name === 'Stopped' ? 'stopped'  : ''))} onChange={(e)=>{onCompetitionInstanceStatus(e, x.name)}} />
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn("button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CompetitionFilterModal