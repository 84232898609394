import { Line as ILine } from "@nessprim/planby-pro";

export function Line({ styles,containerHeight,isFullscreen }) {
  const height = styles.position.height ? styles.position.height + 24 : 24;
  return (
    <div
      style={{
        ...styles.position,
        // minHeight:isFullscreen?"90vh":"316px",
        height:containerHeight,
        // height:"-webkit-fill-available",
        background: "#33383f",
        width:"1px",
        pointerEvents: "none",
      }}
    />
  );
}
