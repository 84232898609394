import React, { useState, useEffect } from 'react'
import styles from './verticalOptions.module.sass'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import Radio from '../Radio';
import {ReactComponent as ArchiveSvg} from '../../media/images/icons/archive.svg'
import {ReactComponent as Trophy} from '../../media/images/icons/trophy.svg'
import Icon from '../Icon';

const Tooltip = ({className, usedFor, isLast, data, children, PD, status, scheduleInstance, instanceId, name, interval}) => {

    const [visible, setVisible] = useState(false)

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
                    <div className={styles.children}>{children}</div>
                        <div className={!isLast ? styles.tooltipContainer: styles.invertedContainer}>
                            {data && data.slice(1).map((x, i) => {
                                return(
                                    <>
                                        <Link to={x.pathName} state={{PD: PD, usedFor: usedFor, interval : interval, name : name, instanceId: instanceId, scheduleInstance:scheduleInstance, status:status}} className={styles.text} key={i} onClick={x.action}>
                                            <p className={styles.textBlock} style={{margin:"4px 0px",paddingLeft:"20px"}}>{x.icon == 'download' ?  <ArchiveSvg height={"16"} width={"16"}/> : x.icon == 'trophy' ? <Trophy height={"16"} width={"16"} /> : <Icon name={x.icon} size="16" className={styles.icon}/>}<span style={{marginLeft:"12px"}}>{x.name}</span></p> 
                                        </Link>
                                    </>
                                )
                            })}
                        </div>
                    </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip