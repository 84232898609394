import axios from "../../../../utils/interceptor";
import { BASE_URL, GET_STEP_SERIES, CREATE_STEP_SERIES, EDIT_STEP_SERIES, DELETE_STEP_SERIES } from "../../../../resources/APIEndpoints";

function StepSeriesServices(){
    this.getAllStepSeries = (body) => axios.post(BASE_URL + GET_STEP_SERIES, body)
    this.getStepSeriesById = (body) => axios.post(BASE_URL + GET_STEP_SERIES, body)
    this.createStepSeries = (body) => axios.post(BASE_URL + CREATE_STEP_SERIES, body)
    this.editStepSeries = (body) => axios.post(BASE_URL + EDIT_STEP_SERIES, body)
    this.archiveTaskGroup = (body) => axios.post(BASE_URL + EDIT_STEP_SERIES, body)
    this.deleteTaskGroup = (body) => axios.post(BASE_URL + DELETE_STEP_SERIES, body)
}

export default new StepSeriesServices();