import React from "react";
import cn from "classnames";
import styles from "./checkbox.module.sass";

const Checkbox = ({className, size, toggle, classCheckboxTick, disable, content, value, onChange, reverse}) => {
  return (
    <label className={cn(styles.checkbox, className, { [styles.reverse]: reverse }, {[styles.disabled] : disable})} >
      <input disabled={disable} className={styles.input} type="checkbox" onChange={onChange} checked={value} />
      <span className={styles.inner}>
        <span className={cn(styles.tick, classCheckboxTick, {[styles.small] : size === 'small'})}></span>
        {content && (
          <>
            <span className={styles.text} dangerouslySetInnerHTML={{ __html: content }}></span>
          </>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
