import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import FileUploadModal from "../../../../../../components/Modals/Others";
import Icon from "../../../../../../components/Icon";

const ControlFileUpload = ({className, valueOne, setValueOne, error, onCSV, onValidation, validateOne, count, placeHolderOne, accept, multiple, labelOne, usedFor}) => {

  const [visibleModal, setVisibleModal] = useState(false);


  return (
    <>
      <div className={cn(styles.control, className)} onClick={() => setVisibleModal(true)}>
        <label className={styles.pdUpload}>
          <div className={styles.previewInput}> 
            <input type="file" className='file-input' accept=".xlsx, .xls, .csv" />
            <label className={styles.button}><Icon name='upload' size='20' /> CSV</label>
          </div>
        </label>
      </div>
      <Modal width={`small`} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FileUploadModal valueOne={valueOne} setValueOne={setValueOne} error={error} onCSV={onCSV} onValidation={onValidation} validateOne={validateOne} count={count} placeHolderOne={placeHolderOne} accept={accept} multiple={multiple} labelOne={labelOne} usedFor={usedFor} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default ControlFileUpload;