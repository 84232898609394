import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import EventTooltip from '../../../../CustomTooltips/EventTooltip'
import TooltipGlodal from "../../../../TooltipGlodal";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, access, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Link to={`/view-custom-events/${appid}/${item.id}`}>
                        <TooltipGlodal width={180} itemId={`customEvent${item.id}`} title={item.name}><div className={styles.itemName}><span id={`customEvent${item.id}`}>{item.name}</span></div></TooltipGlodal>
                    </Link>
                </div>
                <div className={styles.col}>{item.category != null ? item.category : '-'}</div>
                <div className={styles.col}>
                    {item.customParameterDetails && item.customParameterDetails.slice(0,1).map((e,i) =>{return(<span>{e.name}</span>)})}<span>{item.customParameterDetails.length > 1 ? <EventTooltip secondaryText='Custom Parameters' custom={item.customParameterDetails}><span className={styles.number}>&nbsp;&#43;{item.customParameterDetails.length-1}</span></EventTooltip>  : ''}</span>
                </div>
                <div className={styles.col}><Control access={access} className={styles.control} id={item.id} appid={appid} archiveStatus={item.archive} deleteStatus={item.active} markerName={item.name}/></div>
            </div>
        </>
    );
};

export default Row;
