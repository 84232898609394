import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import moment from "moment";
import TooltipGlodal from "../../../../TooltipGlodal";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <TooltipGlodal title={(item.matchSession != null && item.matchSession.match != null && item.matchSession.match.name != null) ? item.matchSession.match.name : '-'} width={150} itemId={`matchName${item.id}`}><div id={`matchName${item.id}`} className={styles.name}>{(item.matchSession != null && item.matchSession.match != null && item.matchSession.match.name != null) ? item.matchSession.match.name : '-'}</div></TooltipGlodal>
                    <div className={styles.id}>{item.id}</div>
                </div>
                <div className={styles.col}>
                    <TooltipGlodal title={(item.matchSession != null && item.matchSession.competition != null && item.matchSession.competition.name != null) ? item.matchSession.competition.name : '-'} width={150} itemId={`competition${item.id}`}><div id={`competition${item.id}`} className={styles.name}>{(item.matchSession != null && item.matchSession.competition != null && item.matchSession.competition.name != null) ? item.matchSession.competition.name : '-'}</div></TooltipGlodal>
                    <div className={styles.id}>{(item.matchSession != null && item.matchSession.competition != null && item.matchSession.competition.id != null) ? item.matchSession.competition.name : '-'}</div>
                </div>
                <div className={styles.col}>{(item.matchSession != null && item.matchSession.matchOutcomeType != null) ? item.matchSession.matchOutcomeType.matchOutcomeName : '-'}</div>
                <div className={styles.col}>{item.matchSessionOutcomeValue != null ? item.matchSessionOutcomeValue : '-'}</div>
                <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
            </div>
        </>
    );
};

export default Row;
