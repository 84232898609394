import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from './importModal.module.sass'
import Switch from "../../../Switch";
import FileUploadInformation from './FileUploadInformation'
import FileUploadSelection from './FileUploadSelection'
import FileValidation from './FileValidation'
import FileImport from './FileImport'
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addItemAction, editItemAction } from "../../../../redux/action/builds/economy/items/itemAction";
import { editCurrecyAction, getAllCurrencyAction } from "../../../../redux/action/builds/economy/currency/currencyAction";
import { editBundleAction } from "../../../../redux/action/builds/economy/bundles/bundleAction";
import { editStoreAction } from "../../../../redux/action/builds/economy/stores/storeAction";
import Icon from "../../../Icon";
import {ReactComponent as Check} from '../../../../media/images/icons/Check_Svg.svg'

const ImportModal = ({className, type, onFileChange, onValidation, data, setData, files, fileErrors, revalidate, action, onClose, setBulkUpload, setTotal, override}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()
    const [activeIndex, setActiveIndex] = useState(0)
    const [list, setList] = useState(data)
    const [isLoading, setIsLoading] = useState(false)

    const [actions, setActions] = useState([{id: 1, name: 'Upload File', status: false}, {id: 2, name: 'Validate File', status: false}, {id: 3, name: 'Repair', status: false}, {id: 4, name: 'Import', status: false}])

    const onNext = async(action) => {
        if(action === 'Append'){
            let newIndex = activeIndex + 1
            setActiveIndex(newIndex)
        }else if(action === 'Override'){
            if(type === 'Currency'){
                let isValid = false
                let currencies = await override()
                for(var i = 0; i < currencies?.ids?.length; i++){
                    try{
                        let data = {projectId: appid, id: currencies?.ids[i], archive: true}
                        const res = await dispatch(editCurrecyAction(data, null, appid, null))
                        if(i === (currencies?.ids?.length - 1)){
                            isValid = true
                        }
                    }catch(error){
                        console.error('Error fetching data:', error);
                    }
                }
                if(isValid){
                    let newIndex = activeIndex + 1
                    setActiveIndex(newIndex)
                }
            }else if(type === 'Items'){
                let isValid = false
                let items = await override()
                let data = {items: []}   
                for(var i = 0; i < items.data?.length ; i++){
                    data.items.push({projectId : appid, id : items.data[i].id, archive: true})
                }
                const res = await dispatch(editItemAction(data, null, appid, null))
                isValid = true
                if(isValid){
                    let newIndex = activeIndex + 1
                    setActiveIndex(newIndex)
                }
            }else if(type === 'Bundles'){
                let isValid = false
                let bundles = await override()
                let data = {bundles: []}   
                for(var i = 0; i < bundles.data?.length ; i++){
                    data.bundles.push({projectId : appid, id : bundles.data[i].id, archive: true})
                }
                const res = await dispatch(editBundleAction(data, null, appid, null))
                isValid = true
                if(isValid){
                    let newIndex = activeIndex + 1
                    setActiveIndex(newIndex)
                }
            }else{
                let isValid = false
                let stores = await override()
                for(var i = 0; i < stores?.ids?.length; i++){
                    try{
                        let data = {stores: [{projectId: appid, id: stores?.ids[i], archive: true}]}
                        const res = await dispatch(editStoreAction(data, null, appid, null))
                        if(i === (stores?.ids?.length - 1)){
                            isValid = true
                        }
                    }catch(error){
                        console.error('Error fetching data:', error);
                    }
                }
                if(isValid){
                    let newIndex = activeIndex + 1
                    setActiveIndex(newIndex)
                }
            }
        }else{
            let newIndex = activeIndex + 1
            setActiveIndex(newIndex)
        }
    }

    const onBack = () => {
        setActiveIndex(activeIndex => activeIndex - 1)
    }

    const validate = async() => {
        let x = await onValidation()
        if(x){
            let newIndex = activeIndex + 1
            setActiveIndex(newIndex)
        }
    }

    const hasNoErrors = (items, type) => {
        let pricingKey = type === 'Items' ? 'itemPrices' : type === 'Bundles' ? 'bundlePrices' : ''
        for (const item of items) {
            // Check errors in the main item
            if (Object.values(item.errors).some(errorArray => errorArray.length > 0)) {
              return false;
            } 
            // Check errors in prices
            for (const price of item[pricingKey]) {
              if (Object.values(price.errors).some(errorArray => errorArray.length > 0)) {
                return false;
              }
            }
            // Check errors in lock by
            for (const price of item.rewardUnlockCondition) {
                if (Object.values(price.errors).some(errorArray => errorArray.length > 0)) {
                  return false;
                }
            }
            if(type === 'Bundles'){
                for (const price of item.bundleContent) {
                    if (Object.values(price.errors).some(errorArray => errorArray.length > 0)) {
                      return false;
                    }
                }
            }
        } 
        return true;
    };

    const checkCurrencyError = (obj) => {
        for (const item of obj) {
            // Check errors in the main item
            if (Object.values(item.errors).some(errorArray => errorArray.length > 0)) {
              return false;
            } 
        }
        return true;
    }

    const checkStoreContent = (obj) => {
        if (obj.errors) {
            for (let key in obj.errors) {
              if (obj.errors[key].length !== 0) {
                return false;
              }
            }
        }
        
        // Iterate over all properties of the object
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
              // If the property is an array, check each element
              for (let item of obj[key]) {
                if (!checkStoreContent(item)) {
                  return false;
                }
              }
            }else if (typeof obj[key] === 'object' && obj[key] !== null) {
              // If the property is an object, recurse into it
              if (!checkStoreContent(obj[key])) {
                return false;
              }
            }
        }
        
        return true;
    }

    const reValidate = () => {
        if(type === 'Currency'){
            let errors = checkCurrencyError(data)
            if(errors){
                if(setTotal){
                    setTotal(data?.length)
                }
                setBulkUpload(true)
                onClose()
            }else{
                revalidate(data)
            }
        }else if(type === 'Stores'){
            let errors = checkStoreContent(data)
            if(errors){
                setBulkUpload(true)
                onClose()
            }else{
                revalidate(data)
            }
        }
        else{
            let errors = hasNoErrors(data, type)
            if(errors){
                setBulkUpload(true)
                onClose()
            }else{
                revalidate(data)
            }
        }
    }

    return(
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.modalHeader}>
                        <div className={styles.block}></div>
                        <div className={styles.modalHeading}>Upload {type}</div>
                    </div>
                    {activeIndex >= 2 &&
                        <div className={styles.progress}>
                            {actions && actions.map((e, i) => (
                                <>
                                    <div className={styles.progressContent}>
                                        {!e.status && <div className={styles.progressStatus}></div>}
                                        {e.status && <Check />}
                                        <div className={styles.content}>{e.name}</div>
                                        <div className={styles.icon}><Icon name='arrow-next' size='16' /></div>
                                    </div>
                                </>
                            ))}
                        </div>
                    }
                    <div className={styles.modalBody}>
                        <div className={styles.bodyWrapper}>
                            {activeIndex === 0 && <FileUploadInformation type={type} />}
                            {activeIndex === 1 && <FileUploadSelection onNext={onNext} type={type}/>}
                            {activeIndex === 2 && <FileValidation type={type} onFileChange={onFileChange} files={files} fileErrors={fileErrors} onValidation={onValidation}/>}
                            {activeIndex === 3 && <FileImport type={type} data={data} setData={setData}/>}
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        {activeIndex < 1 && <button className={cn(styles.button, "button-small")} onClick={() => {onNext()}}>Next</button>}
                        {(activeIndex > 1 && activeIndex < 3) && <button className={cn(styles.button, "button-small")} onClick={() => {validate()}}>Validate</button>}
                        {activeIndex >= 3 && <button className={cn(styles.button, "button-small")} onClick={() => {reValidate()}}>{((type === 'Currency' && checkCurrencyError(data)) || ((type === 'Items' || type === 'Bundles') && hasNoErrors(data, type)) || (type === 'Stores' && checkStoreContent(data, type))) ? `Import` : `Validate`}</button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImportModal