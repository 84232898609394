import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../Checkbox";
import Row from "./Row";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'

const Table = ({tableFor,fullList, items, setValue, setData,categoryIndex}) => {


  return (
    <div className={styles.wrapperScroll}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}></div>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Quantity</div>
        </div>
        {items && items.map((x, index) => (
          <Row tableFor={tableFor} item={x} categoryIndex={categoryIndex} fullList={fullList} items={items} key={index} index={index} setValue={setValue} setData={setData} />))
        }
        {items&&items?.length==0&&
          <div style={{textAlign:"center",margin: "220px",width:" max-content"}}>
            <span>No Results Found</span>
          </div>}
      </div>
    </div>
  );
};

export default Table;
