import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './introduction.module.sass'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {ReactComponent as WelcomeSvg} from '../../../media/images/icons/goodbye 1.svg'
import {ReactComponent as BasicSvg} from '../../../media/images/icons/shapes 1.svg'
import {ReactComponent as EventsSvg} from '../../../media/images/icons/click-here 1.svg'
import {ReactComponent as GamifySvg} from '../../../media/images/icons/game-plan 1.svg'
import {ReactComponent as LiveOpsSvg} from '../../../media/images/icons/calendar 1.svg'
import {ReactComponent as EnableCheckbox} from '../../../media/images/icons/Checkbox_Enable.svg'
import {ReactComponent as DisableCheckbox} from '../../../media/images/icons/Checkbox_Disable.svg'
import {ReactComponent as BackendSvg} from '../../../media/images/icons/backend 3.svg'
import {ReactComponent as PlaySvg} from '../../../media/images/icons/Frame 730.svg'
import {ReactComponent as EventSvg} from '../../../media/images/icons/Frame 735.svg'
import {ReactComponent as RankingSvg} from '../../../media/images/icons/Frame 732.svg'
import {ReactComponent as AnnounceSvg} from '../../../media/images/icons/Frame 733.svg'
import {ReactComponent as HalfCircle} from '../../../media/images/half_ellipse.svg'
import {ReactComponent as HalfCross} from '../../../media/images/icons/Right_Cross.svg'

const IntroductionModal = ({className, setShowAnimation, setShowLoader, setLoaderType, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [activeIndex, setActiveIndex] = useState(0)

  const navigation = [
    { title : 'Welcome', icon : <WelcomeSvg />, activeIndex : 0, active : true},
    { title : 'Basics', icon : <BasicSvg />, activeIndex : 1, active : false},
    { title : 'Events', icon : <EventsSvg />, activeIndex : 2, active : false},
    { title : 'Gamify', icon : <GamifySvg />, activeIndex : 3, active : false},
    { title : 'Live Ops', icon : <LiveOpsSvg />, activeIndex : 4, active : false}
  ]

  const [navPills, setNavPills] = useState(navigation)

  const IncreaseActiveIndex = (index) => {
    if(index < 4){
      let nav = [...navPills]
      nav[index+1].active = true
      setNavPills(nav)
      setActiveIndex(activeIndex + 1)
    }else{
      if(localStorage.getItem('showModal')){
        setShowAnimation(true)
        setTimeout(() => {
          setShowAnimation(false)
          localStorage.removeItem('showModal')
        }, 4000)
      }
      onClose()
    }
  }

  const [firstName, setFirstName] = useState('')

  const  getUserName = () => {
    let userName = JSON.parse(localStorage.getItem('userName'))
    setFirstName(userName)
  }

  useEffect(() => {
    getUserName()
  }, [])

  return (
    <div className={styles.introductionModal}>
      <div className={styles.introductionModalContainer}>
          <div className={styles.introductionModalInnerContainer}>
            <div className={styles.bgCircle}>
              <HalfCircle />
            </div>
            <div className={styles.bgCross}>
              <HalfCross />
            </div>
            <div className={styles.nav}>
              <div className={styles.navLinksTitle}>
                {navPills && navPills.map((e, index) => {
                  return(
                    <>
                      <div className={styles.navPillsContainer}>
                        <div className={styles.checkbox}>
                          {e.active && <EnableCheckbox />}
                          {!e.active && <DisableCheckbox />}
                        </div>
                        <div className={styles.navPills}>
                          <div className={styles.navBars}>
                            <div className={styles.navIcon}>
                              {e.icon}
                            </div>
                            <div className={styles.navTitle}>
                              {e.title}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
              {activeIndex == 0 &&
                <>
                  <div className={styles.navLinksContain}>
                    <div className={styles.module}>
                        <div className={styles.moduleHeading}>
                          Hi {firstName}<span className={styles.contentYellow}>,</span><br />Welcome to Specter<span className={styles.contentBlue}>!</span>
                        </div>
                        <div className={styles.moduleContent}>
                          <p className={styles.moduleContentParagraph}>You now have access to the most comprehensive backend solution for gaming and gamification! We provide you with all the tools needed to engage, retain and grow your users!</p>
                          <p className={styles.moduleContentParagraph}>Let's spend a few minutes walking you through what you can do with Specter!</p>
                        </div>
                        <div className={styles.moduleButton}>
                          <Link onClick={()=>{IncreaseActiveIndex(activeIndex)}} className={styles.button}>Get Started</Link>
                        </div>
                    </div>
                  </div>
                  <div className={styles.navImages}>
                    <BackendSvg />
                  </div>
                </>
              }
              {activeIndex == 1 &&
                <>
                  <div className={styles.navLinksContain}>
                    <div className={styles.module}>
                        <div className={styles.moduleHeading}>
                          First, set up the <br />foundation of your app<span className={styles.contentYellow}>.</span>
                        </div>
                        <div className={styles.moduleContent}>
                          <p className={styles.moduleContentParagraph}>We provide a suite of tools designed to help you structure your game universe.</p>
                          <p className={styles.moduleContentParagraph}><span className={styles.bold}>&#8226;Matches:</span> Tailor multiplayer experiences. Define match formats, setrules, and manage game dynamics to ensure engaging play</p>
                          <p className={styles.moduleContentParagraph}><span className={styles.bold}>&#8226;Economy:</span> Control your game's economy. From currencies to in-game stores, create a thriving market for players.</p>
                          <p className={styles.moduleContentParagraph}><span className={styles.bold}>&#8226;Progression:</span> Design player growth. Set levels, rewards, and milestones to map out an exciting player journey</p>
                        </div>
                        <div className={styles.moduleButton}>
                          <Link onClick={()=>{IncreaseActiveIndex(activeIndex)}} className={styles.button}>Next</Link>
                        </div>
                    </div>
                  </div>
                  <div className={styles.navImages}>
                    <PlaySvg />
                  </div>
                </>
              }
              {activeIndex == 2 &&
                <>
                  <div className={styles.navLinksContain}>
                    <div className={styles.module}>
                        <div className={styles.moduleHeading}>
                          Any action can <br /> be tracked as an event<span className={styles.contentYellow}>.</span>
                        </div>
                        <div className={styles.moduleContent}>
                          <p className={styles.moduleContentParagraph}>Let's say a player plays a match in your game, there are specific details that capture the moment. Actions like playing a match can be tracked as an event in Specter.</p>
                          <p className={styles.moduleContentParagraph}>All details describing this event, such as the time taken, the score achieved, and any power-ups used, are called event parameters.</p>
                          <p className={styles.moduleContentParagraph}>User Action = <span className={styles.bold}>Event</span> <br />Details = <span className={styles.bold}>Event Parameters</span></p>
                        </div>
                        <div className={styles.moduleButton}>
                          <Link onClick={()=>{IncreaseActiveIndex(activeIndex)}} className={styles.button}>Next</Link>
                        </div>
                    </div>
                  </div>
                  <div className={styles.navImages}>
                    <EventSvg />
                  </div>
                </>
              }
              {activeIndex == 3 &&
                <>
                  <div className={styles.navLinksContain}>
                    <div className={styles.module}>
                        <div className={styles.moduleHeading}>
                          Bring your game to life<span className={styles.contentYellow}>.</span> <br /> Engage<span className={styles.contentBlue}>,</span> Retain<span className={styles.contentBlue}>,</span> Repeat<span className={styles.contentYellow}>.</span>
                        </div>
                        <div className={styles.moduleContent}>
                          <p className={styles.moduleContentParagraph}>Remember the events we just configured! Build tasks and captivating battle passes backed by these real time events to keep players actively invested and to give them a sense of mastery.</p>
                          <p className={styles.moduleContentParagraph}>Configure dynamic leaderboards and a variety of competition types to add that crucial layer of competitiveness necessary for a successful game.</p>
                          <p className={styles.moduleContentParagraph}>It's all about making your game not just fun to play, but also fun to comeback to.</p>
                        </div>
                        <div className={styles.moduleButton}>
                          <Link onClick={()=>{IncreaseActiveIndex(activeIndex)}} className={styles.button}>Next</Link>
                        </div>
                    </div>
                  </div>
                  <div className={styles.navImages}>
                    <RankingSvg />
                  </div>
                </>
              }
              {activeIndex == 4 &&
                <>
                  <div className={styles.navLinksContain}>
                    <div className={styles.module}>
                        <div className={styles.moduleHeading}>
                          Keep your game fresh<span className={styles.contentYellow}>.</span> <br /> Always<span className={styles.contentYellow}>.</span>
                        </div>
                        <div className={styles.moduleContent}>
                          <p className={styles.moduleContentParagraph}>Imagine this: every element you've built, from seasonal themes to new challenges, can be scheduled just the way you want. Planning is easy here - set up your game's seasons, roll out marketing campaigns, and even launch competitions, all on your timeline.</p>
                          <p className={styles.moduleContentParagraph}>But here's where it gets really dynamic: you can use player actions to trigger these campaigns. So when a player does something significant in-game, it can set off a whole new set of content relevant to that action.</p>
                          <p className={styles.moduleContentParagraph}>It's about making your game not just interactive, but reactive.</p>
                        </div>
                        <div className={styles.moduleButton}>
                          <Link onClick={()=>{IncreaseActiveIndex(activeIndex)}} className={styles.button}>Go to Specter</Link>
                          <Link onClick={onClose} className={styles.buttonCancel}>Explore Demo</Link>
                        </div>
                    </div>
                  </div>
                  <div className={styles.navImages}>
                    <AnnounceSvg />
                  </div>
                </>
              }

            </div>
          </div>
      </div>
    </div>
  )
}

export default IntroductionModal