import react, { useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './resource.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import {ReactComponent as OpenInNewTab} from '../../../media/images/icons/tab_open.svg'
import Icon from '../../../components/Icon'

const Resource = ({className, data}) => {
    
    const [generalNav, setGeneralNav] = useState([
        {id : 1, title : 'Setting up your team', url: 'https://manual.specterapp.xyz/specter-user-manual/getting-started/onboarding'}, 
        {id : 2, title : 'Apps', url: 'https://manual.specterapp.xyz/specter-user-manual/getting-started/onboarding'}, 
        {id : 3, title : 'App Settings', url: 'https://manual.specterapp.xyz/specter-user-manual/app/app-settings'}, 
        {id : 4, title : 'Access Control', url: 'https://manual.specterapp.xyz/specter-user-manual/organisation/access-control'}, 
        {id : 5, title : 'Managing Environments', url: 'https://manual.specterapp.xyz/specter-user-manual/app/managing-your-environments'}
    ])

    const [dashboardNav, setDashboardNav] = useState([
        {id : 1, title : 'Games', url: 'https://manual.specterapp.xyz/specter-user-manual/build/games'}, 
        {id : 2, title : 'Matches', url: 'https://manual.specterapp.xyz/specter-user-manual/build/matches'}, 
        {id : 3, title : 'Economy', url: 'https://manual.specterapp.xyz/specter-user-manual/build/economy'}, 
        {id : 4, title : 'Progression', url: 'https://manual.specterapp.xyz/specter-user-manual/build/progression'}, 
        {id : 5, title : 'Events', url: 'https://manual.specterapp.xyz/specter-user-manual/build/events'}
    ])

    const [developmentNav, setDevelopmentNav] = useState([
        {id : 1, title : 'Players', url: 'https://manual.specterapp.xyz/specter-user-manual/engage/players'}, 
        {id : 2, title : 'Achievements', url: 'https://manual.specterapp.xyz/specter-user-manual/engage/achievements'}, 
        {id : 3, title : 'Competitions', url: 'https://manual.specterapp.xyz/specter-user-manual/engage/competitions'}, 
        {id : 4, title : 'Leaderboard', url: 'https://manual.specterapp.xyz/specter-user-manual/engage/leaderboards'}, 
        {id : 5, title : 'LiveOps', url: 'https://manual.specterapp.xyz/specter-user-manual/engage/live-ops'}])

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Featured Resources" classTitle="title-green">
                    <div className={styles.resourceContainer}>
                        <div className={styles.resource}>
                            <div className={styles.contentHeading}>
                                <span>Quick access to some key resources to get you started.</span>
                            </div>
                            <div className={styles.resourceLinks}>

                                <div className={styles.general}>
                                    <div className={styles.title}>General</div>
                                    <div className={styles.navLink}>
                                        {generalNav && generalNav.map((e, i) =>{
                                            return(
                                                <>
                                                    <div className={styles.link}>
                                                        <a href={e.url} target='_blank'>{e.title}<OpenInNewTab className={styles.tab} /></a>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className={styles.dashboard}>
                                    <div className={styles.title}>Build</div>
                                    <div className={styles.navLink}>
                                        {dashboardNav && dashboardNav.map((e, i) =>{
                                            return(
                                                <>
                                                    <div className={styles.link}>
                                                        <a href={e.url} target='_blank'>{e.title}<OpenInNewTab className={styles.tab} /></a>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className={styles.development}>
                                    <div className={styles.title}>Engage</div>
                                    <div className={styles.navLink}>
                                        {developmentNav && developmentNav.map((e, i) =>{
                                            return(
                                                <>
                                                    <div className={styles.link}>
                                                        <a href={e.url} target='_blank'>{e.title}<OpenInNewTab className={styles.tab} /></a>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Resource