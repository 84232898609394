import React, { useState } from "react";
import styles from './cardLayout.module.sass'
import cn from "classnames";
import Icon from "../../Icon";
import { useParams } from "react-router-dom";
import Mission from '../../../media/images/placeholders/Mission.png'
import Control from "../../../screens/AppInformation/AppInfo/Control";
import { deleteMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import { useDispatch } from "react-redux";
import Toast from "../../Toast";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "../../Modals/ModalComponent";
import DeleteMediaModal from "./DeleteMediaModal"
import {ReactComponent as BundleFolder} from "../../../media/images/icons/bundleFolder.svg"

const MediaCardLayout = ({className, access,onCancel, item,value, type,getMedia, onChange,folderName,released,withoutСheckbox,progress}) => {
    
    const [visible, setVisible] = useState(false);
    const {appid} = useParams()
    // const {folderName} = useParams()
    const dispatch = useDispatch()
    const [visibleModal, setVisibleModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const [cardHover, setCardHover] = useState(false);
    const handleClick = () => {
        onChange();
        setVisible(!visible);
    }

    const deleteMedia = (id) => {
        let deleteMediaData = {
            projectId : appid,
            ids : [id]
        }
        dispatch(deleteMediaAction(deleteMediaData, appid,folderName,false))
        setTimeout(() => {
            getMedia()
        }, 200);
    }
    const copyToClipboard = (value) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = (value != null || value == '') ? value : '-';
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
    }

    // const downloadMedia = (url, name) => {
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = name || 'download';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // const downloadMedia = (url, name) => {
    //     fetch(url)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = name || 'download';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         })
    //         .catch(console.error);
    // };
    
    const downloadMedia = async (url,name) => {
        await fetch(url, {
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
            'Cache-Control': 'no-cache'
          },
          cache: 'no-store'
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          name,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
      };

    return (
        <div className={cn(styles.product, className, {[styles.active]: visible,})}>
            <div className={styles.card}>
                <div className={styles.cardWrapper}>
                    <BundleFolder style={{margin:"12px 12px 12px 24px"}}/>
                    <div className={styles.cardBody}>
                        <div className={styles.content}>
                            <div className={styles.label}>{(item.name && item.name != null && item.name != '') ? item.name : '-'}</div>
                        </div>
                        {(!progress > 0) &&(<div className={styles.fileContent}>
                            {/* <div className={styles.preview}><Control usedFor='Media' type={type} src={item.url} /></div> */}
                            <div className={styles.fileSize}>{(item.size && item.size != null && item.size != '') ? <>{(item.size /1024).toFixed(2)} MB</> : ''}</div>
                            {/* <div className={styles.copy} style={{marginLeft:"auto"}}>{!access && <span style={{fontSize:"10px",display:"flex",minWidth:"64px"}} onClick={()=>{copyToClipboard(item.url != null ? item.url : '');setIsCopied(true);setTimeout(() => {setIsCopied(false)}, 2000);}}>{isCopied?"Copied!":"Copy URL"}<Icon name='copy' size='16' /></span>}</div> */}
                        </div>)}
                        {progress > 0 && (
                            <div className={styles.progressBar}>
                                <div className={styles.progress} style={{ width: `${progress}%` }}></div>
                            </div>
                        )}
                    </div>

                    {(!progress > 0) &&(
                    <>
                        <div onClick={()=>{setVisibleModal(true)}} style={{height: "24px", width: "24px",backgroundColor:"#ffffff", borderRadius: "24px",cursor:"pointer",margin:"auto 12px auto 4px"}}>
                            <Icon className={styles.controlIcon} fill="#6F767E" name='trash' size='14' />
                        </div>
                        <div onClick={() => { downloadMedia(item.url, item.name) }} style={{ height: "24px", width: "24px", backgroundColor: "#ffffff", borderRadius: "24px", cursor: "pointer",margin:"auto 24px auto 4px" }}>
                            <Icon className={styles.controlIcon} fill="#6F767E" name='download' size='14' />
                        </div>
                    </>
                    )}
                    {(progress > 0) &&(
                    <>
                        <div onClick={onCancel} style={{height: "24px", width: "24px",backgroundColor:"#ffffff", borderRadius: "24px",cursor:"pointer",margin:"auto"}}>
                            <Icon className={styles.controlIcon} fill="#6F767E" name='close' size='14' />
                        </div>
                    </>
                    )}
                </div>
            </div>
            <Modal visible={visibleModal} onClose={() => {setVisibleModal(false);setCardHover(false)}}>
                <DeleteMediaModal onProceed={()=>{deleteMedia(item.id)}} onClose={() => {setVisibleModal(false);setCardHover(false)}}/>
            </Modal>
        </div>
    );
};

export default MediaCardLayout;
