import React, { useState } from 'react'
import styles from './tooltip.module.sass'

const Tooltip = ({ type, text, data, isLast, children }) => {
    
    const [showTooltip, setShowTooltip] = useState(false)

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    
    return (
        <div className={styles.custom} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && 
                <div className={styles.tooltip} style={isLast?{top:"unset",bottom:"100%"}:{}}>
                    <div className={styles.groupHeading}>
                        <span className={styles.heading}>{text}</span>
                    </div>
                    {type == 'item' &&
                        (data && data.map((e, i) => {
                            if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                                 return(
                                     <>
                                         <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'vitual') ? styles.Virtual : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.RMG : ''}>
                                            <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'virtual') ? styles.currencyContent : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.itemContent : ''}>{e.currency.code}</span>
                                            <span>&nbsp;</span>
                                            <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'vitual') ? styles.virtualPrice : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.RMGPrice : ''}>{e.price}</span>
                                        </span>  <br />
                                     </>
                                 )
                            }else if(e.priceTypes == 'IAP'){
                             return(
                                 <>
                                    <span className={styles.IAP}>
                                        <span className={styles.IAPCode}>{e.realWorldCurrency.currency}</span>
                                        <span>&nbsp;</span>
                                        <span className={styles.IAPPrice}>{e.price}</span>
                                    </span>  
                                    <br />
                                 </>
                             )
                            }
                         }))
                    }
                    {type == 'bundle' &&
                        (data && data.map((e, i) => {
                            if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                                 return(
                                     <>
                                         <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'vitual') ? styles.Virtual : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.RMG : ''}>
                                            <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'virtual') ? styles.currencyContent : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.itemContent : ''}>{e.currency.code}</span>
                                            <span>&nbsp;</span>
                                            <span className={(e.priceTypes == 'virtual currency' || e.priceTypes == 'vitual') ? styles.virtualPrice : (e.priceTypes == 'RMG' || e.priceTypes == 'real money') ? styles.RMGPrice : ''}>{e.price}</span>
                                        </span>  <br />
                                     </>
                                 )
                            }else if(e.priceTypes == 'IAP'){
                             return(
                                 <>
                                     <span className={styles.IAP}>
                                        <span className={styles.IAPCode}>{e.realWorldCurrency.currency}</span>
                                        <span>&nbsp;</span>
                                        <span className={styles.IAPPrice}>{e.price}</span>
                                    </span>  <br />
                                 </>
                             )
                            }
                         }))
                    }
                    {type == 'Stores' &&
                        (data && data.map((e, i) => {
                             return(
                                 <>
                                     <span className={i%2==0 ? styles.Virtual : styles.IAP}>
                                        <span className={i%2==0 ? styles.currencyContent : styles.IAPCode}>{e.name}</span>
                                    </span>  <br/>
                                 </>
                             )
                         }))
                    }
                </div>
            }
        </div>
    )
}

export default Tooltip