import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './currencyFilter.module.sass'
import Icon from "../../../../Icon";
import axios from "axios";
import { BASE_URL } from "../../../../../resources/APIEndpoints";
import Form from "../../../../Form";
import { useDispatch, useSelector } from "react-redux";
import { getAllMetaDataAction } from "../../../../../redux/action/common/commonAction";
import { Range, getTrackBackground } from "react-range";
import Checkbox from "../../../../Checkbox";
import { getAllCurrencyAction } from "../../../../../redux/action/builds/economy/currency/currencyAction";
import { useParams } from "react-router-dom";

const STEP = 1;
const MIN = 0;
const MAX = 120;

const CurrencyFilterModal = ({className, action, filters, onClose}) => {

    const dispatch = useDispatch()
    const {appid} = useParams()

    let filterList = [{id: 1, name : 'Currency Type'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [currencyTypeOptions, setCurrencyTypeOptions] = useState([{id : 1, name : 'Virtual Currency', type: 'virtual'},{id: 2, name: 'Real Money', type: 'real'}])
    const [filterCurrencyType, setFilterCurrencyType] = useState('')
    const [activeIndex, setActiveIndex] = useState(0)

    const onCurrencyType = (e, name) => {
        if(e.target.checked){
            filters.setCurrencyType([...filters.currencyType, name])
        }else{
            filters.setCurrencyType(filters.currencyType.filter(item => item !== name))
        }
    }

    const saveFilter = () => {
        action()
        onClose()
    }

    const resetFilter = () => {
        filters.setCurrencyType([])
        const getCurrencyData = {
            projectId : appid,
            offset: 0,
            limit: 8
        }
        dispatch(getAllCurrencyAction(getCurrencyData))
        onClose()
    }

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index === activeIndex})} key={index} onClick={() => setActiveIndex(index)}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Currency Type' && filters.currencyType.length > 0 && <span className={styles.countPill}>{filters.currencyType.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='20'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                      <>
                                        <div className={styles.form}>
                                          <Form placeholder="Search Currency Type" type="text" name="search" icon="search" onChange={(e) => {setFilterCurrencyType(e.target.value)}}/>
                                        </div>
                                        <div className={styles.filterCheckbox}>
                                            {currencyTypeOptions && currencyTypeOptions.filter(option => option.name && option.name.toLowerCase().includes(filterCurrencyType.toLowerCase())).map((x, i) => {
                                                return(
                                                  <Checkbox className={styles.checkbox} key={i} content={x.name} toggle={true} reverse={true} value={filters.currencyType.includes(x.type)} onChange={(e)=>{onCurrencyType(e, x.type)}}/>
                                                )
                                            })}
                                        </div>
                                      </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn("button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CurrencyFilterModal