import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import AddContentModal from "../../../../../../components/Modals/Build/Economy/Store/AddContent";

const Control = ({pageItem, pageBundle, fullCurrencyList, fullBundleList, fullItemsList,setItemPage,itemLimit,itemPage,setBundlePage,bundleLimit,bundlePage, className, type, width, currency, categoryIndex, setCurrency, item, setItem, bundle, setBundle, setValue,fullCategories,customizeBundleData,customizeItemData}) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  const handleClose = () => {
    setVisibleModal(false);
    customizeBundleData(categoryIndex)
    customizeItemData(categoryIndex)
    let content = JSON.parse(sessionStorage.getItem('contents'))
    setValue(content)
  };


  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div>
              <span>Add Content</span><span onClick={x.action} className={styles.add}><AddMetaIcon /></span>
            </div>
        ))}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
      <AddContentModal onClose={() => handleClose()} pageItem={pageItem} pageBundle={pageBundle} type={type} itemLimit={itemLimit} itemPage={itemPage} setItemPage={setItemPage} bundleLimit={bundleLimit} bundlePage={bundlePage} setBundlePage={setBundlePage} currency={currency} setCurrency={setCurrency} categoryIndex={categoryIndex} fullCurrencyList={fullCurrencyList} fullBundleList={fullBundleList} fullItemsList={fullItemsList} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} setValue={setValue} fullCategories={fullCategories} customizeBundleData={customizeBundleData} customizeItemData={customizeItemData}/>
      </Modal>
    </>
  );
};

export default Control;
