import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../Checkbox";
import Row from "./Row";
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'

const Table = ({platformOption, realWorldCurrencyOptions, items, access, title, setValue, categoryIndex, allItems, setAllItems, allBundles, setAllBundle }) => {

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [page, setPage] = useState(1)
  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };
  const onPrevious = () => {
      setPage(prevPage => prevPage - 1)
  }

  const onNext = () => {
      setPage(nextPage => nextPage + 1)
  }
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Display Name</div>
          <div className={styles.col}>Quantity</div>
          <div className={styles.col}></div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
        </div>
        {items[categoryIndex].storeContent && items[categoryIndex].storeContent.map((x, index) => {
          if (index<page*10 && index>(page-1)*10-1) {
          return <Row platformOption={platformOption} realWorldCurrencyOptions={realWorldCurrencyOptions} access={access} total={items} setValue={setValue} item={x} key={x.id} index={index} categoryIndex={categoryIndex} allItems={allItems} setAllItems={setAllItems} allBundles={allBundles} setAllBundle={setAllBundle} />
          }
        })}
        </div>
        {items[categoryIndex]?.storeContent && items[categoryIndex]?.storeContent?.length>10 && <div className={styles.pagination}>
          <div className={styles.icons}>
              <button disabled={page <= 1 ? true : false}>
              <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
              </button>
              <span className={styles.pageno}>{page+"/"+ Math.ceil(items[categoryIndex]?.storeContent?.length/10)}</span>
              <button disabled={items[categoryIndex]?.storeContent?.length <= page*10 ? true : false}>
              <Next className={styles.next} onClick={onNext}/>
              </button>
          </div>
          </div>
        }
    </div>
  );
};

export default Table;
