import React from 'react'
import styles from './editEvent.module.sass'
import EventInformation from './EventInformation'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

const EditEvent = ({isChanged, setIsChanged}) => {
    return (
    <>
        <ToastContainer />
        <div className={styles.row}>
          <div className={styles.col}>
              <EventInformation  isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
          </div>
        </div>
    </>
    )
}

export default EditEvent