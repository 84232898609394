import React, { useState } from "react";
import {OperatorSelectorProps, defaultOperators} from "react-querybuilder";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./customOperator.module.sass"
import { useEffect } from "react";
// import Tooltip from "../Tooltip";

const Dropdown = (OperatorSelectorProps, className, classLabel, classDropdownHead, small, upBody, fields, value) => {

    const [visible, setVisible] = useState(false)

    const [operator, setOperator] = useState([{name : 'equal', label : 'equal'},{name : 'notEqual', label : 'notEqual'},{name : 'lessThan', label : 'lessThan'},{name : 'greaterThan', label : 'greaterThan'},{name : 'equal', label : 'equal'},{name : 'lessThanInclusive', label : 'lessThanInclusive'},{name : 'greaterThanInclusive', label : 'greaterThanInclusive'}])

    const handleClick = (event) => {
        OperatorSelectorProps.handleOnChange(event.name)
        setVisible(false)
    };

    const getOperators = () => {
        if(OperatorSelectorProps.field.dataType == 'string'){
            setOperator([{name : 'equal', label : 'equal'},{name : 'notEqual', label : 'notEqual'}])
        }else if(OperatorSelectorProps.field.dataType == 'integer'){
            setOperator([{name : 'lessThan', label : 'lessThan'},{name : 'greaterThan', label : 'greaterThan'},{name : 'equal', label : 'equal'},{name : 'lessThanInclusive', label : 'lessThanInclusive'},{name : 'greaterThanInclusive', label : 'greaterThanInclusive'}])
        }else if(OperatorSelectorProps.field.dataType == 'boolean'){
            setOperator([{name : 'equal', label : 'equal'}, {name : 'notEqual', label : 'notEqual'}])
        }else if(OperatorSelectorProps.field.dataType == 'float'){
            setOperator([{name : 'lessThan', label : 'lessThan'},{name : 'greaterThan', label : 'greaterThan'},{name : 'equal', label : 'equal'},{name : 'lessThanInclusive', label : 'lessThanInclusive'},{name : 'greaterThanInclusive', label : 'greaterThanInclusive'}])
        }
    }

    useEffect(()=>{
        getOperators()
    }, [])
      
    return (
        <div className={styles.dropdowns}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(classLabel, styles.label)}>
                <span>Select Operator</span>
            </div>
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.selection}>{(OperatorSelectorProps.value == null || OperatorSelectorProps.value == undefined || OperatorSelectorProps.value == '' ||  OperatorSelectorProps.value == '=') ? 'Select Operator' : OperatorSelectorProps.value}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {operator && operator.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value})} key={index}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown