import React from "react";
import styles from './forbidden.module.sass'
import ForbiddenSvg from '../../../media/images/Forbidden.jpg'
import { Link } from "react-router-dom";
import cn from "classnames";

const Forbidden = () => {



    return(
        <>
            <div className={styles.page}>
                <div className={styles.container}>
                    <div className={styles.forbiddenSvg}>
                        <img src={ForbiddenSvg} width={360} height={300} alt='gameHub_forbidden' />
                    </div>
                    <div className={styles.warning}>
                        <h2>OOPS....!</h2>
                        <h5>Looks like you don't have access to this app.</h5>
                    </div>
                    <div className={styles.button}>
                        <Link to={`/dashboard`} className={cn("button-small", styles.button)}>Go Back To Dashboard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forbidden