import {GET_MATCHES, GET_MATCHES_SUCCESS, GET_MATCHES_ERROR, ADD_MATCHES, ADD_MATCHES_SUCCESS, ADD_MATCHES_ERROR, EDIT_MATCHES, EDIT_MATCHES_SUCCESS, EDIT_MATCHES_ERROR, GET_MATCH_DETAIL, GET_MATCH_DETAIL_SUCCESS, GET_MATCH_DETAIL_ERROR, FILTER_MATCHES_SUCCESS, GET_LEADERBOARD_MATCHES_SUCCESS, GET_LEADERBOARD_MATCHES_ERROR, FILTER_MATCHES_ERROR} from '../../../actionTypes/actionTypes'

const initialState = {
    matches : [],
    leaderboardMatches : [],
    matchDetail : [],
    isLoading : false,
    isFiltered : false,
    error : null,
    totalCount:null,
};

export const matchReducer =( state=initialState, action )=>{
    switch(action.type){

        case GET_MATCHES : {
            return {
                ...state,
                isLoading : true,
                error : null
            }
        }

        case GET_MATCHES_SUCCESS : {
            return {
                ...state,
                isLoading : false,
                isFiltered : false,
                matches : action.payload,
                totalCount: action.totalCount,
                error : null
            }
        }

        case GET_MATCHES_ERROR : {
            return {
                ...state,
               isLoading : false,
               error : action.payload,
            }
        }

        case FILTER_MATCHES_SUCCESS : {
            return {
                ...state,
                matches : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered : true,
            }
        }

        case FILTER_MATCHES_ERROR : {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }

        case GET_LEADERBOARD_MATCHES_SUCCESS : {
            return {
                ...state,
                leaderboardMatches : action.payload
            }
        }

        case GET_LEADERBOARD_MATCHES_ERROR : {
            return {
                ...state,
               error : action.payload,
            }
        }

        case GET_MATCH_DETAIL : {
            return {
                ...state
            }
        }

        case GET_MATCH_DETAIL_SUCCESS : {
            return {
                ...state,
                matchDetail : action.payload,
            }
        }

        case GET_MATCH_DETAIL_ERROR : {
            return {
                ...state
            }
        }

        case ADD_MATCHES_SUCCESS : {
            return {
                ...state,
                matches : [...state.matches, action.payload],
            }
        }

        case ADD_MATCHES_ERROR : {
            return {
                ...state,
               error : action.payload,
            }
        }

        case EDIT_MATCHES_SUCCESS : {
            const updateMatches = state.matches && state.matches.map((matches) =>
                matches.id === action.payload.id ? action.payload : matches
            );
            return {
                ...state,
                matches : updateMatches
            }
        }

        case EDIT_MATCHES_ERROR : {
            return {
                ...state,
               error : action.payload,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   