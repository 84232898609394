import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Tooltip from "../Tooltip";

const FileUpload = ({ className, type, placeHolder, multiple, onChange, ...props }) => {
    
    const fileInputRef = useRef(null);

    const handleFiles = (selectedFiles) => {
        onFileUpload(selectedFiles)
    };

    const onFileUpload = async(e) => {
        let files = e?.target?.files ? e?.target?.files : e?.dataTransfer?.files ? e?.dataTransfer?.files : []
        console.log(files, "Files", files?.length)
        if(type === 'Items'){
            for(var i = 0; i < files.length; i++){
                if((files[i].name).toLowerCase().includes('items') && !(files[i].name).toLowerCase().includes('pricing') && !(files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVItem(files[i])
                }else if((files[i].name).toLowerCase().includes('pricing')){
                    onChange.onCSVPricing(files[i])
                }else if((files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVFile(files[i])
                }
            }
        }else if(type === 'Bundles'){
            for(var i = 0; i < files.length; i++){
                if((files[i].name).toLowerCase().includes('bundles') && !(files[i].name).toLowerCase().includes('pricing') && !(files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVItem(files[i])
                }else if((files[i].name).toLowerCase().includes('pricing')){
                    onChange.onCSVPricing(files[i])
                }else if((files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVFile(files[i])
                }
            }
        }else if(type === 'Stores'){
            for(var i = 0; i < files.length; i++){
                if((files[i].name).toLowerCase().includes('store') && !(files[i].name).toLowerCase().includes('pricing') && !(files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVItem(files[i])
                }else if((files[i].name).toLowerCase().includes('pricing')){
                    onChange.onCSVPricing(files[i])
                }else if((files[i].name).toLowerCase().includes('lock')){
                    onChange.onCSVFile(files[i])
                }
            }
        }else if(type === 'Currency'){
            onChange.onCSVCurrency(files[0])
        }
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
        const droppedFiles = e;
        handleFiles(droppedFiles)
    }

    return (
        <div className={cn(styles.file, className)} onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            <div className={styles.wrap} for='import'>
                <input ref={fileInputRef} id='import' className={styles.input} type="file" multiple={multiple} onChange={(e)=>{onFileUpload(e)}}/>
                <div className={styles.box}>
                    <label for='import'><Icon name="upload" size="24" />{placeHolder}</label>
                </div>
            </div>
        </div>
    );
};



export default FileUpload;
