import {
  ProgramItem,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
  ProgramResizeHandle,
} from "@nessprim/planby-pro";
import React from "react";
import cn from 'classnames'
import customStyle from "../app.module.sass"
import {ReactComponent as Recurring} from "../../../../../media/images/icons/repeat.svg"
import {ReactComponent as Layer} from "../../../../../media/images/icons/layer.svg"
import {ReactComponent as Ranking} from "../../../../../media/images/icons/ranking.svg"
import {ReactComponent as People} from "../../../../../media/images/icons/people.svg"
import {ReactComponent as Lock} from "../../../../../media/images/icons/lock.svg"
import {ReactComponent as TagIcon} from "../../../../../media/images/icons/tag.svg"
import {ReactComponent as Frame} from "../../../../../media/images/icons/Frame 146921.svg"
import {ReactComponent as Mask} from "../../../../../media/images/icons/Mask group.svg"
import {ReactComponent as Backward} from "../../../../../media/images/icons/backward-5-seconds.svg"
import {ReactComponent as Flag} from "../../../../../media/images/icons/flag.svg"
import {ReactComponent as TickSquare} from "../../../../../media/images/icons/tick-square.svg"
import {ReactComponent as Setting} from "../../../../../media/images/icons/setting-4.svg"
import {ReactComponent as MedalStar} from "../../../../../media/images/icons/medal-star.svg"
import Modal from "../../../../../components/Modals/ModalComponent";
import Bundle from '../../../../../media/images/placeholders/Bundles.png'


export const Program = ({ isVerticalMode, program,rightAnimationClass,calendarStartDate, calendarEndDate,leftAnimationClass, ...rest }) => {


const [showTooltip, setShowTooltip] = React.useState(false);
const [showProgramPopUp, setShowProgramPopUp] = React.useState(false);
const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
const timeoutRef = React.useRef(null);

const [color, setColor] = React.useState(["#FFBC99","#B5E4CA","#FFD88D","#CABDFF","#B1E5FC"]);
const [arr, setArr] = React.useState(["$64","SK64","SK64","SK64","SK64"]);

  const {
    isLive,
    isMinWidth,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    set12HoursTimeFormat,
    getMouseEventTempTime,
  } = useProgram({
    isVerticalMode,
    program,
    ...rest,
  });

  const debounce = (func, delay) => {
    return function(args) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => func(args), delay);
    }
};

  function getOrdinalSuffix(day) {
    const j = day % 10,
          k = day % 100;
    if (j === 1 && k !== 11) {
        return day + "st";
    }
    if (j === 2 && k !== 12) {
        return day + "nd";
    }
    if (j === 3 && k !== 13) {
        return day + "rd";
    }
    return day + "th";
}

function formatDate(dat) {
    const date = new Date(dat);
    const months = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];

    // Formatting hours for AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Adding leading zero to minutes if less than 10
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${getOrdinalSuffix(day)} ${monthName}, ${hours}:${formattedMinutes} ${ampm}`;
}

function isXGreaterThanY(x, y) {
  const dateX = new Date(x);
  const dateY = new Date(y);

  // Compare the two dates
  return dateX > dateY;
}


const handleMouseOver = React.useCallback((event) => {
  // event.stopPropagation();
  if (!showTooltip) {
      const { clientX, clientY, target } = event;
      const { top, left } = target.getBoundingClientRect();
      const calendarWidth = target.closest('#planby-wrapper').offsetWidth;
      const calendarHeight = target.closest('#planby-wrapper').offsetHeight;
      const calendarRect = target.closest('#planby-wrapper').getBoundingClientRect();
      let posX = clientX - left+28;
      let posY = clientY - top+8;
      if ((clientX-calendarRect.left + 200) > calendarWidth) {
        posX = clientX - left-200;
      }
      if ((clientY-calendarRect.top + 120) > calendarHeight) {
        posY = clientY - top-84;
      }
      setTooltipPosition( { x: posX, y: posY });
      setShowTooltip(true);
  }
}, [showTooltip]);

const handleMouseOut = () => {
  setShowTooltip(false);
};
const getUnitName= (intervalUnitId) => {

  switch (intervalUnitId) {
    case 1: // Days
    return "Daily";
    case 2: // Weeks
    return "Weekly";
    case 3: // Months
    return "Monthly";
    case 4: // Years
    return "Yearly";
    case 5: // Years
    return "Endless";
    case 6: // Years
    return "Custom";
    case 7: // Minutes
      return "Minutes";
    case 8: // Hours
      return "Hourly";
    default:
      return 'Invalid interval unit ID';
  }
};
const { data } = program;
const { image, title, since, till, recurrenceCount, scheduleType, nextInstanceDate,prizeDistributionOffset,description,CalendarType, leaderboardOutcomeDetails, competitionEntryPrices, prizeDistributionRule,maxPlayers,minPlayers,isEndDateNull, maxAttemptAllowed,maxEntryAllowed,matchName, rewardsUnlockCondition,titleID,currentInstance,rewardDetails, iconUrl, noOfTasks,intervalTimeName, props} = data;

const names = rewardsUnlockCondition&& rewardsUnlockCondition.map(item => 
  item.inventoryUnlockItems?.name || 
  item.inventoryUnlockBundles?.name || 
  item.levelSystem?.name
).filter(name => name);
const nameprops = rewardsUnlockCondition&& rewardsUnlockCondition.map(item =>
  {return `${item?.levelSystemName} [${item?.levelNo}]`}
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function extractRewardsDetails(rewardDetails) {
  const result = [];
  if (rewardDetails?.items) {
      rewardDetails.items.forEach(item => {
          if (item.quantity && item.name) {
              result.push(`${item.quantity} ${item.name}`);
          }
      });
  }
  if (rewardDetails?.bundles) {
      rewardDetails.bundles.forEach(bundle => {
          if (bundle.quantity && bundle.name) {
              result.push(`${bundle.quantity} ${bundle.name}`);
          }
      });
  }
  if (rewardDetails?.currencies) {
      rewardDetails.currencies.forEach(currency => {
          if (currency.quantity && currency.name) {
              result.push(`${currency.quantity} ${currency.name}`);
          }
      });
  }
  return result;
}

  return (
    <>
    <div
      {...getMouseEvents()}
        onMouseEnter={(event)=>handleMouseOver(event)}
        onMouseLeave={handleMouseOut}
        onClick={()=>setShowProgramPopUp(true)}
    >
    <ProgramBox
      width={styles.width}
      style={{
        ...styles.position,
        padding:"8px 0px",
        pointerEvents: "auto",
        overflow:"visible",
      }}
      className={`${leftAnimationClass} ${rightAnimationClass}`}
    >
      
      <ProgramContent
        style={{
          padding:"4px",
          background:"#ecf7fa",
          border:"2px solid #a0d7e7",
          borderLeft:isXGreaterThanY(calendarStartDate,since)?"none":"2px solid #a0d7e7",
          borderRight:isXGreaterThanY(till,calendarEndDate)?"none":"2px solid #a0d7e7",
          borderRadius: (isXGreaterThanY(calendarStartDate,since)&&isXGreaterThanY(till,calendarEndDate))?"0px":isXGreaterThanY(calendarStartDate,since)?"0px 8px 8px 0px":isXGreaterThanY(till,calendarEndDate)?"8px 0px 0px 8px":"8px",
          pointerEvents: "auto",
          overflow:"visible"
        }}
        className={customStyle.tooltip}
        isVerticalMode={isVerticalMode}
        width={styles.width}
        isLive={isLive}
        
      >
        {showTooltip && (
            <div className={customStyle.tooltiptext} style={{minWidth:"128px",scale:"1.3", left:`${tooltipPosition.x}px`,top:`${tooltipPosition.y}px`}}>
              <p style={{fontWeight:"600"}}>{title}{scheduleType=="recurring"&&<Recurring fill="#FFFFFF" style={{position:"absolute",right:"12px",width:"12px",height:"12px"}}/>}</p>
              <p style={{fontWeight:"400"}}><span style={{fontWeight:"600"}}>Start:</span>{formatDate(since)}</p>
              <p style={{fontWeight:"400"}}><span style={{fontWeight:"600"}}>End:</span>{formatDate(till)}</p>
              {scheduleType=="recurring"&&recurrenceCount>currentInstance&& 
              <p style={{fontWeight:"400",marginTop:"6px"}}><span style={{fontWeight:"600"}}>Next:</span>{formatDate(nextInstanceDate)}</p>}
            </div>
          )}
        <ProgramResizeHandle {...resizeEvents.eventsLeft} />
        <ProgramResizeHandle {...resizeEvents.eventsRight} />
        <ProgramFlex isVerticalMode={isVerticalMode}>
          <ProgramStack
          >
          
            <ProgramTitle 
            style={{
              fontSize: "12px",
              color:"#11142d"
            }}
            
            >
              {
                scheduleType=="recurring"&&
                <Recurring style={{marginRight:"4px",width:"18px",height:"18px"}}/>
              }
              {title}
              {
                scheduleType=="recurring"&&
                ` (${currentInstance}/${recurrenceCount})`
              }
            </ProgramTitle>
            
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
    </div>


    <Modal visible={showProgramPopUp} onClose={() => setShowProgramPopUp(false)}>
            <div 
              style={{
                display:"block",
                gap:"10px",
                margin:"0",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "32px",
                letterSpacing: "-0.02em",
                color: "#1A1D1F"
              }}
            >
              <div style={{display:"flex",height:"32px"}}>
                <div 
                  style={{
                    width : "16px", 
                    height : "32px",
                    background : "#B5E4CA",
                    borderRadius : "4px",
                    marginRight:"14px"
                  }}
                >
                </div>
                <span>Preview</span>
              </div>

              <div className={customStyle.preview}>   
                  <div className={customStyle.previewImage}>
                      <img src={iconUrl?iconUrl:Bundle} alt={"alt"} style={{width: "96px", height: "96px"}}></img>
                  </div>
                  <div style={{display:"flex",width:"100%"}}>
                    <div style={{display:"block",width:"52%",paddingLeft:"20px",textOverflow:"ellipsis"}}>
                      <p style={{fontSize:"22px",lineHeight:"32px",fontWeight:"600",overflow:"hidden",textOverflow:"ellipsis"}}>{title}</p>
                      <p style={{fontSize:"14px",lineHeight:"24px",fontWeight:"600",color:"#6F767E",overflow:"hidden",textOverflow:"ellipsis"}}>{titleID}</p>
                      <div style={{display:"flex",marginTop:"14px"}}>
                        {(CalendarType=="competition"||CalendarType=="leaderboard")?
                        <span style={{width:"50%",fontSize:"14px",lineHeight:"24px",fontWeight:"500",color:"#272B30"}}>
                            <Layer style={{marginRight:"4px"}}/>{matchName}
                        </span>
                        :
                        <span style={{width:"50%",fontSize:"14px",lineHeight:"24px",fontWeight:"500",color:"#272B30"}}>
                            <Flag style={{marginRight:"4px"}}/>{CalendarType=="tasks"?"Task":CalendarType=="missions"?"Mission":"Step Series"}
                        </span>
                        }
                        {(CalendarType=="competition"||CalendarType=="leaderboard")?
                        <span style={{width:"50%",fontSize:"14px",lineHeight:"24px",fontWeight:"500",color:"#272B30", marginLeft:"12px",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                            <Ranking style={{marginRight:"4px"}}/>{leaderboardOutcomeDetails=="high_score"?"High Score":leaderboardOutcomeDetails}
                        </span>
                        :
                        CalendarType=="tasks"?<span style={{width:"50%",fontSize:"14px",lineHeight:"24px",fontWeight:"500",color:"#272B30", marginLeft:"12px"}}>
                            <TickSquare style={{marginRight:"4px"}}/>{props.isRecurring?"Every Time":"One Time"}
                        </span>:""
                        }
                      </div>
                    </div>
                    <div style={{display:"block",width:"48%",gap:"8px",paddingLeft:"20px"}}>
                      <p style={{fontSize:"16px",lineHeight:"28px",fontWeight:"500"}}><span style={{fontWeight:"700"}}>{scheduleType=="recurring"?"Interval Start:":"Start:"}</span> {formatDate(since)}</p>
                      <p style={{fontSize:"16px",lineHeight:"28px",fontWeight:"500",marginTop:"4px"}}><span style={{fontWeight:"700"}}>{scheduleType=="recurring"?"Interval End:":"End:"}</span> {isEndDateNull?"---":formatDate(till)}</p>
                      {scheduleType=="recurring"?<p style={{fontSize:"16px",lineHeight:"24px",fontWeight:"500",marginTop:"12px"}}><Recurring style={{marginRight:"4px"}}/>{intervalTimeName ?capitalizeFirstLetter(intervalTimeName):getUnitName(props.intervalUnitId)} ({currentInstance}/{recurrenceCount})</p>:<p style={{fontSize:"16px",lineHeight:"24px",fontWeight:"500",marginTop:"12px"}}>Single</p>}
                    </div>
                  </div>
              </div>
              

              <p style={{fontSize:"14px",lineHeight:"24px",fontWeight:"600"}}>
                {(description!==null&&description!==undefined&&description!=="")?description:"No Description Provided"}
              </p>

              {CalendarType=="competition"&&
                <div style={{display:"flex",marginTop:"28px"}}>
                  <TagIcon style={{marginRight:"16px",width:"32px",height:"32px"}}/>
                  {competitionEntryPrices!==null&&competitionEntryPrices!==undefined&&competitionEntryPrices?.length>0? competitionEntryPrices.map((e,i)=>{
                    return <span style={{padding:"4px 8px",backgroundColor:`${e.priceType=="virtual currency"?"#EAFAE4":"B1E5FC"}`, height:"32px", borderRadius:"6px",lineHeight:"24px", marginRight:"20px",fontSize:"15px"}}>{e.currencies.name}{e.price}</span>
                  }):
                   <span style={{padding:"4px 8px",backgroundColor:`${color[Math.random()*color?.length]}`, height:"32px", borderRadius:"6px",lineHeight:"24px", marginRight:"20px",fontSize:"15px"}}>Free</span>
                  }
                  
                </div>
              }
            {CalendarType=="competition"&&
            <>
              <div style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",display:"flex",marginTop:"28px"}}>
                <p style={{marginRight:"24px",width:"45%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}><People style={{marginRight:"8px"}}/>{minPlayers} - {maxPlayers} Players</p> <p style={{whiteSpace:"nowrap",width: "40%",overflow: "hidden",textOverflow: "ellipsis"}}><Lock style={{marginRight:"8px"}}/>{names?.length > 0 ? names.join(', ') : <span>Unrestricted Access</span>}</p>
              </div>
              <div style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",display:"flex",marginTop:"20px"}}>
                <p style={{marginRight:"24px",width:"45%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}><Mask style={{marginRight:"8px"}}/>Max {maxEntryAllowed} Entries</p> <p><Backward style={{marginRight:"8px"}}/>Max {maxAttemptAllowed} Attempts</p>
              </div>
            </>
            }
            {(CalendarType!=="competition"&&CalendarType!=="leaderboard")&&
            <>
              <div style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",display:"flex",marginTop:"28px"}}>
                <p style={{marginRight:"16px",width:"40%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}><Lock style={{marginRight:"8px"}}/>{nameprops?.length > 0 ? nameprops.join(', ') : "Unrestricted Access"}</p>
              {(noOfTasks!==null&&noOfTasks!==undefined)&&
                <p style={{margin:"0 auto",width:"45%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>No. of Tasks: {noOfTasks}</p>
              }
              </div>
              <div style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",display:"flex",marginTop:"28px"}}>
                <p style={{marginRight:"24px",width:"45%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}><Setting style={{marginRight:"8px"}}/>{props.rewardClaim=="automatic"?"Server Side":"Client Side"}</p>
              </div>
              <div style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",display:"flex",marginTop:"28px"}}>
                <p style={{marginRight:"24px",width:"45%",whiteSpace:"nowrap",overflow: "hidden",textOverflow: "ellipsis"}}><MedalStar style={{marginRight:"8px"}}/>{extractRewardsDetails(rewardDetails)?.length > 0 ? extractRewardsDetails(rewardDetails).join(', '):"No Rewards"}</p>
              </div>
            </>
            }
            {(CalendarType=="competition"||CalendarType=="leaderboard")&&
            <>
              <p style={{fontSize:"14px",lineHeight:"28px",fontWeight:"500",marginTop:"28px"}}>
                  <Frame style={{marginRight:"8px"}}/>
                  {(prizeDistributionRule && prizeDistributionRule.length > 0 && prizeDistributionRule[0].rewardDetails) ? (
                      Object.keys(prizeDistributionRule[0].rewardDetails).map((rewardType, index) => (
                          Array.isArray(prizeDistributionRule[0].rewardDetails[rewardType]) &&
                          prizeDistributionRule[0].rewardDetails[rewardType].map((reward, idx) => (
                              <span key={`${rewardType}-${index}-${idx}`} style={{marginRight:"8px"}}>
                                  {reward.amount} {reward.id}{(idx < prizeDistributionRule[0].rewardDetails[rewardType].length - 1 || rewardType !== Object.keys(prizeDistributionRule[0].rewardDetails).pop()) && ','}
                              </span>
                          ))
                      ))
                  ) : (
                      "No Prize Allocated"
                  )}
              </p>
               <p style={{fontSize:"18px",lineHeight:"28px",fontWeight:"500",marginTop:"18px"}}><span style={{fontWeight:"700",marginRight:"8px"}}>Prize Distribution Date:</span>{(prizeDistributionOffset!==null&&prizeDistributionOffset!==undefined)?prizeDistributionOffset:"N/A"}</p>
            </>}
           
              </div> 
        </Modal>
    </>
  );
};
