import React from "react";
import style from './CreateCompetitionSchedule.module.sass';
import ScheduleCompetitionInformation from './ScheduleCompetitionInformation';
import { ToastContainer } from "react-toastify";

const CreateCompetitionSchedule = ({isChanged, setIsChanged}) => {
    return (
      <>
        <ToastContainer />
        <div className={style.row}>
            <div className={style.col}>
                <ScheduleCompetitionInformation isChanged={isChanged} setIsChanged={setIsChanged} className={style.card}/>
            </div>
        </div>
      </>
    )
  }
  
  export default CreateCompetitionSchedule