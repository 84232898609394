import { GET_APP_INVITED_MEMBERS, GET_APP_INVITED_MEMBERS_SUCCESS, GET_APP_INVITED_MEMBERS_ERROR, GET_APP_ACCEPTED_MEMBERS, GET_APP_ACCEPTED_MEMBERS_SUCCESS, GET_APP_ACCEPTED_MEMBERS_ERROR } from "../../../actionTypes/actionTypes";
import MemberServices from '../../../../services/appSettingsServices/MembersServices/memberServices'
import Toast from "../../../../components/Toast";
import { toast } from "react-toastify";

export const getAcceptedMembersAction = (body) => {
    return async (dispatch) => {
        dispatch(getAcceptedMembers(GET_APP_ACCEPTED_MEMBERS, null))
        await MemberServices.getAcceptedMembers(body)
        .then(res=>{
            console.log(res.data.data)
            dispatch(getAcceptedMembers(GET_APP_ACCEPTED_MEMBERS_SUCCESS, res.data.data.memberDetails))
        }).catch(err=>{
            dispatch(getAcceptedMembers(GET_APP_ACCEPTED_MEMBERS_ERROR, err.response))
        })
    }
}

export const getInvitedMembersAction = (body) => {
    return async (dispatch) => {
        dispatch(getInvitedMembers(GET_APP_INVITED_MEMBERS, null))
        await MemberServices.getInvitedMembers(body)
        .then(res=>{
            dispatch(getInvitedMembers(GET_APP_INVITED_MEMBERS_SUCCESS, res.data.data.inviteMemberDetails))
        }).catch(err=>{
            dispatch(getInvitedMembers(GET_APP_INVITED_MEMBERS_ERROR, err.response))
        })
    }
}

export const sendMemberInviteAction = (body, projectId) => {
    return async (dispatch) => {
        await MemberServices.sendInvite(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 1500, style : {background : '#DEF1E5'}})
            let inviteUser = {
                organisationId : localStorage.getItem('orgId'),
                status : 'invited',
                ...((projectId != null && projectId != undefined) && {projectId : projectId})
            }
            dispatch(getInvitedMembersAction(inviteUser))
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editMemberPermissionAction = (body, appid) => {
    return async (dispatch) => {
        await MemberServices.editMemberPermission(body)
        .then(res => {
            let members = {organisationId: localStorage.getItem('orgId'), ...(appid != null && {projectId: appid})}
            dispatch(getAcceptedMembersAction(members))
            toast.success(<Toast type='Success' messages='Member Permission Updated Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 1500, style : {background : '#DEF1E5'}})
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editInvitedMemberPermissionAction = (body, appid) => {
    return async (dispatch) => {
        await MemberServices.editInvitedMemberPermission(body)
        .then(res => {
            let members = {organisationId: localStorage.getItem('orgId'), status: "invited", ...(appid != null && {projectId: appid})}
            dispatch(getInvitedMembersAction(members))
            toast.success(<Toast type='Success' messages='Member Permission Updated Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 1500, style : {background : '#DEF1E5'}})
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const deleteMemberAction = (body) => {
    return async (dispatch) => {
        await MemberServices.deleteMember(body)
        .then(res => {
            toast.success(<Toast type='Success' messages='Member Deleted Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 1500, style : {background : '#DEF1E5'}})
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getAcceptedMembers = (type, data) => {
    return {
        type : type,
        payload : data
    }
}

export const getInvitedMembers = (type, data) => {
    return {
        type : type,
        payload : data
    }
}
