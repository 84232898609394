import axios from "../../../../utils/interceptor";
import { BASE_URL, GET_TASK, CREATE_TASK, EDIT_TASK, GET_PARAM_VALUES, DELETE_TASK } from "../../../../resources/APIEndpoints";

function TaskServices(){
    this.getParamValues = (body) => axios.post(BASE_URL + GET_PARAM_VALUES, body)
    this.getAllTask = (body) => axios.post(BASE_URL + GET_TASK, body)
    this.createTask = (body) => axios.post(BASE_URL + CREATE_TASK, body)
    this.editTask = (body) => axios.post(BASE_URL + EDIT_TASK, body)
    this.archiveTask = (body) => axios.post(BASE_URL + EDIT_TASK, body)
    this.deleteTask = (body) => axios.post(BASE_URL + DELETE_TASK, body)
}

export default new TaskServices();