

const newTimezones =[
    {"zoneName": "Africa/Abidjan", "abbreviation": "GMT"},
    {"zoneName": "Africa/Cairo", "abbreviation": "EET"},
    {"zoneName": "Africa/Casablanca", "abbreviation": "WET"},
    {"zoneName": "Africa/Johannesburg", "abbreviation": "SAST"},
    {"zoneName": "Africa/Lagos", "abbreviation": "WAT"},
    {"zoneName": "Africa/Nairobi", "abbreviation": "EAT"},
    {"zoneName": "America/Adak", "abbreviation": "HST"},
    {"zoneName": "America/Anchorage", "abbreviation": "AKST"},
    {"zoneName": "America/Chicago", "abbreviation": "CST"},
    {"zoneName": "America/Denver", "abbreviation": "MST"},
    {"zoneName": "America/Los_Angeles", "abbreviation": "PST"},
    {"zoneName": "America/New_York", "abbreviation": "EST"},
    {"zoneName": "America/Sao_Paulo", "abbreviation": "BRT"},
    {"zoneName": "America/Toronto", "abbreviation": "EST"},
    {"zoneName": "Asia/Dubai", "abbreviation": "GST"},
    {"zoneName": "Asia/Hong_Kong", "abbreviation": "HKT"},
    {"zoneName": "Asia/Jakarta", "abbreviation": "WIB"},
    {"zoneName": "Asia/Kolkata", "abbreviation": "IST"},
    {"zoneName": "Asia/Seoul", "abbreviation": "KST"},
    {"zoneName": "Asia/Shanghai", "abbreviation": "CST"},
    {"zoneName": "Asia/Singapore", "abbreviation": "SGT"},
    {"zoneName": "Asia/Tokyo", "abbreviation": "JST"},
    {"zoneName": "Europe/Amsterdam", "abbreviation": "CET"},
    {"zoneName": "Europe/Berlin", "abbreviation": "CET"},
    {"zoneName": "Europe/Lisbon", "abbreviation": "WET"},
    {"zoneName": "Europe/London", "abbreviation": "GMT"},
    {"zoneName": "Europe/Moscow", "abbreviation": "MSK"},
    {"zoneName": "Europe/Paris", "abbreviation": "CET"},
    {"zoneName": "Europe/Rome", "abbreviation": "CET"},
    {"zoneName": "Australia/Sydney", "abbreviation": "AEST"},
    {"zoneName": "Pacific/Auckland", "abbreviation": "NZST"},
    {"zoneName": "Pacific/Fiji", "abbreviation": "FJT"},
    {"zoneName": "Pacific/Honolulu", "abbreviation": "HST"}
]
  

export default  newTimezones