import React, { useState } from "react";
import cn from "classnames";
import styles from "./form.module.sass";
import Icon from "../../Icon";
import {ReactComponent as AddIcon} from '../../../media/images/icons/add_meta.svg'
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";

const Form = ({className, onSubmit, placeholder, options, value, setValue, focus, setFocus, reward, setReward, type, name, icon, size}) => {

  const [visible, setVisible] = useState(false)

  const onAdd = (e, index) => {
    if(reward.some(x => x.id === e.id)){
      toast.warning("This reward already existed.")
    }else{
      e.status = true
      setReward(prevState => [...prevState, e])
    }
  }

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <form className={size == 'sm' ? cn(className, styles.formSm) : cn(className, styles.form)} action="" onSubmit={onSubmit}>
        <input className={styles.input} type={type} value={value} onClick={() => setVisible(true)}  onChange={(e) => setValue(e.target.value)}
          name={name} placeholder={placeholder} required />
        <button className={styles.result}>
          <Icon name={icon} size="24" />
        </button>
      </form>
      {visible &&
        <>
          <div className={styles.options}>
            {options && options.map((e,i) => {
              return(
                <>
                  <div className={styles.rewards}>
                    <div className={styles.add}>
                        <span className={styles.icon} onClick={()=>{onAdd(e,i)}}><AddIcon /></span>
                    </div>
                    <div className={styles.rewardsIcon}>   
                        <img src={e.iconUrl} alt="icon" width={50}/>
                    </div>
                    <div className={styles.rewardsDetails}>
                        <span className={styles.rewardName}>{e.name}</span> <br  />
                        <span className={styles.rewardId}>{e.rewardId}</span>
                    </div>
                  </div>
                  {i != (options.length - 1) ?
                    <div className={styles.divider}>
                      <hr className={styles.border} />
                    </div>
                  : ''}
                </>
              )
            })}
          </div>
        </>
      }
      </OutsideClickHandler>
    </>
  );
};

export default Form;
