import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multiselect.module.sass";
import Tooltip from "../../../Tooltip";
import Icon from "../../../Icon";
import EventTooltip from '../../../../components/CustomTooltips/EventTooltip'

const Dropdown = ({className, disabled, classDropdownHead, classDropdownLabel, classCheckboxTick, value, setValue, placeholder, options, label, tooltip, small, upBody}) => {

  const [visible, setVisible] = useState(false);

  const handleClick = (selectedOption) => {
    if(value.some(e => e.name === selectedOption.name)){
      setValue(value.filter((values) => values.name !== selectedOption.name));
    }else{
      setValue([...value, selectedOption]);
    }
  };

  const removeOption = (selectedOption) => {
    if(value.some(e => e.name === selectedOption.name)){
      setValue(value.filter((values) => values !== selectedOption));
    }else{
      setValue([...value, selectedOption]);
    }
  }

  return (
    <div className={styles.main}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
        </div>
      )}
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className,{ [styles.small]: small },{ [styles.active]: visible}, { [styles.disabled]: disabled})}>
        <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(true)}>
          <div className={styles.selection}>
            {value.length > 0 ?
              <>
                {value && value.slice(0,7).map((e,i) => {
                  return(
                    <span className={(e.disable == true && e.params == 'default') ? styles.disablePills : (e.disable == true && e.params == 'optional') ? styles.pillsOptional : styles.pills}>{e.name} {e.disable == true ? '' : e.disable == false || e.disable == undefined ? <span className={styles.remove} onClick={()=>{removeOption(e)}}><Icon name='close' size='12' /></span> : 'C'}</span>
                  )
                })}
                <span>{value.length > 7 ? <EventTooltip primaryText='System Parameters' secondaryText='Custom Parameters' defaults={value.filter(obj => obj.params == 'default')} optional={value.filter(obj => obj.params == 'optional')} specter={value.filter(obj => obj.params == 'specter')} ><span className={styles.number}>&nbsp;&#43;{value.length-7}</span></EventTooltip>  : ''}</span>
                {/* <EventTooltip secondaryText='Custom Parameters' custom={values.customParameterDetails}><span>&nbsp;&#43;&nbsp;{value.length-6}</span></EventTooltip> */}
              </>
              
            : placeholder}
          </div>
        </div>
        {!disabled &&
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
            {options && options.map((x, index) => (
              <div className={cn(styles.option)} key={index}>
                  <label className={cn(styles.checkbox, className)} onChange={() => handleClick(x, index)} checked={value.some(e => e.name === x.name)}>
                      <input className={styles.input} type="checkbox" checked={value.some(e => e.name === x.name)}/>
                      <span className={styles.inner}>
                          <span className={cn(styles.tick, classCheckboxTick)}></span>
                          <span className={styles.optionLabel}>{x.name}</span>
                      </span>
                  </label>
              </div>
            ))}
          </div>
        }
      </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
