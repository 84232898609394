import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../Tooltip";

const PlayerDataDropdown = ({className, groupBy, type, classLabel, classDropdownHead, classDropdownLabel, as, value, setValue, data, setData, options, index, placeholder, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);

    const handleClick = (e) => {
        let datas = [...data]
        datas[index].permission = e.name
        setData(datas)
        setVisible(false)
    };
      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (<Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />)}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                    <div className={styles.selection}>{value == null || value == '' || value == undefined ? <span className={styles.placeholder}>{placeholder}</span> : value }</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {options && options.map((x, index) => (
                    <div onClick={() => handleClick(x)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={x.id}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default PlayerDataDropdown