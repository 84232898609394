import React, { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";
import Tooltips from "../../Tooltip";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Dropdown = ({
  className,
  disabled,
  setIsCalled,
  setEventPage,
  eventPage,
  eventLimit,
  limit,
  id,
  disable,
  currency,
  check,
  groupBy,
  error,
  customHandleFunction,
  customFunc,
  setSelectedZone,
  errorMessage,
  classLabel,
  classDropdownHead,
  classDropdownLabel,
  placeholder,
  value,
  setValue,
  setId,
  setType,
  setDisplayId,
  setField,
  options,
  label,
  small,
  upBody,
  tooltip,
  place
}) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const containerRef = useRef();
  const dispatch = useDispatch();
  const { appid } = useParams();

  const handleClick = (values) => {
    if (customHandleFunction || customFunc) {
      const abc = {
        target: {
          value: values.value
        }
      };
      customHandleFunction && customHandleFunction(abc);
      setSelectedZone(values.value);
    } else {
      setValue(values.name);
      setId(values.id);
      if (check) {
        check(values.id);
      }
      if (error) {
        error(false);
      }
      if (setDisplayId) {
        if (values.type === 'custom') {
          setDisplayId(values.eventId);
        } else {
          setDisplayId(values.id);
        }
      }
      if (setField) {
        if (values.type === 'custom') {
          let field = [...values.customParameterDetails, ...values.defaultParameterDetails];
          let fieldOptions = [];
          field && field.forEach((e, i) => {
            fieldOptions.push({ id: e.id, name: e.name, inputType: e.dataType.dataTypeName, type: e.type });
          });
          setField(field);
        } else {
          let field = [...values.customParameterDetails, ...values.defaultParameterDetails, ...values.specterParameterDetails, ...values.optionalParameterDetails];
          let fieldOptions = [];
          field && field.forEach((e, i) => {
            fieldOptions.push({ id: e.id, name: e.name, inputType: e.dataType.dataTypeName, type: e.type });
          });
          setField(field);
        }
      }
      if (setType) {
        setType(values.type);
      }
    }
    setVisible(false);
    setInputValue(""); // Clear the input value after selection
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div
      id={id}
      className={groupBy === 1 ? styles.groupDropdown : groupBy === 2 ? styles.groupByTwoDropdown : groupBy === 3 ? styles.groupByThreeDropdown : groupBy === 4 ? styles.groupByFourDropdown : 'dropdown'}
    >
      <OutsideClickHandler onOutsideClick={() => {setInputValue("");setVisible(false)}}>
        {label && (
          <div className={styles.groupLabel}>
            <div className={cn(styles.label, classDropdownLabel)}>
              {label}
              {tooltip && (
                <Tooltips
                  className={styles.tooltip}
                  title={tooltip}
                  icon="info"
                  place="right"
                />
              )}
            </div>
            <div className={styles.error}>
              {(value === null || value === undefined || value === '') && errorMessage}
            </div>
          </div>
        )}
        <div
          className={cn(
            styles.dropdown,
            className,
            { [styles.small]: small },
            { [styles.active]: visible },
            { [styles.disabled]: disabled }
          )}
        >
          <div
            className={(errorMessage && (value === null || value === undefined || value === '' || value == {}))
              ? cn(styles.head, styles.head_error, classDropdownHead)
              : cn(styles.head, classDropdownHead, { [styles.disabled]: disabled })}
            onClick={() => setVisible(!visible)}
            style={customHandleFunction && { height: "40px" }}
          >
            <div className={styles.placeholder} style={customHandleFunction && { height: "40px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {currency && <div className={styles.currency}>{currency}</div>}
              {(value === null || value === undefined || value === '') ? 
              <input
                type="text"
                disabled={disabled}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={currency ? `${placeholder}` : placeholder}
                className={currency ? styles.locInput : styles.normalInput}
                autoFocus
                style={{ height: "40px",color:"#6F767E",fontSize:"inherit",fontWeight:"inherit"}}
              /> 
              : value}
            
            {!(value === null || value === undefined || value === '') && (
              <input
                disabled={disabled}
                type="text"
                value={inputValue}
                placeholder={value}
                onChange={handleInputChange}
                className={currency ? styles.locInput : styles.normalInput}
                autoFocus
                style={{ height: "40px",color:"#6F767E",fontSize:"inherit",fontWeight:"inherit"}}
              />
            )}
            </div>
          </div>
          {!disabled && visible && (
            <div ref={containerRef} className={cn(styles.body, { [styles.bodyUp]: upBody })}>
              {filteredOptions.map && filteredOptions.map((x, index) => (
                <div
                  onClick={() => handleClick(x, index)}
                  className={cn(styles.option, { [styles.selectioned]: x.name === value })}
                  key={index}
                >
                  {x.name != null ? x.name : x.levelName}
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
