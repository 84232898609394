import {
    GridCell as IGridCell,
    GridItem,
    GridDivider,
  } from "@nessprim/planby-pro";
  
  export function GridCell(props) {
    const {
      isDayMode,
      isHoverHighlight,
      item,
      timelineDividerArray,
      gridDividerProps,
      gridItemClickProps,
    } = props;
  
    const { onItemClick, ...dividerProps } = gridDividerProps.props;
    const { left, ...styles } = gridDividerProps.styles;
    const height = (item?.position?.height&&props?.channelNo) ? item.position.height*props?.channelNo + 24 : 24;
    // const height = item.position.height ? item.position.height + 24 : 24;
    console.log("props",props.containerHeight)
    console.log("height",item?.position?.height)
    return (
      <GridItem
        isDayMode={isDayMode}
        isHoverHighlight={isHoverHighlight}
        {...item.position}
        {...gridItemClickProps}
        style={{
          // height:height,
          height:props?.containerHeight,
          ...(item?.channel?.uuid!=='0'&&{display:"none"}),
          // minHeight:props.isFullscreen?"90vh":"316px",
          borderBottom:"none",borderTop:"none",borderLeft:"none",pointerEvents:"none"}}
      >
        {/* {isDayMode &&
          timelineDividerArray.map((_, index) => (
            <GridDivider
              key={index}
              {...styles}
              {...dividerProps}
              left={left(index)}
              onClick={onItemClick(item, index)}
              
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#a0aec0",
                  opacity: 0.2,
                  fontSize: 10,
                }}
              >
                {index + 1}
              </div>
            </GridDivider>
          ))} */}
      </GridItem>
    );
  }
  