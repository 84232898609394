import React, {useState} from 'react'
import cn from 'classnames'
import styles from './previewModal.module.sass'

const PreviewModal = ({className, onClose, src, type}) => {

    return (
        <div className={styles.previewModal}>
        <div className={styles.previewContent}>
            {type == 'Images' ?
                <img src={src} alt='Preview_Screenshot' className={styles.images}/>
            : type == 'Videos' ?
                <video className={styles.videos} src={src} controls></video>
            : ''}
            
        </div>
        </div>
    )
}

export default PreviewModal