import React, {useEffect, useState, useRef, useContext} from 'react'
import cn from 'classnames'
import Toast from '../../../../../components/Toast';
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../../components/Card'
import Radio from '../../../../../components/Radio';
import TextInput from '../../../../../components/TextInput';
import TextArea from '../../../../../components/TextArea';
import Tooltip from '../../../../../components/Tooltip';
import { BASE_URL, GET_APPS } from '../../../../../resources/APIEndpoints';
import Icon from '../../../../../components/Icon';
import styles from './match.module.sass'
import Match from '../../../../../media/images/placeholders/Mission.png'
import FileImages from '../../../../../components/File/Images'
import FileVideos from '../../../../../components/File/Videos';
import { ReactComponent as AddButtonIcon } from '../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import Dropdown from '../../../../../components/Dropdown';
import GameDropdown from '../../../../../components/Dropdown/CreatableDropdown';
import { getListOfAllGamesAction } from '../../../../../redux/action/builds/games/gamesAction';
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { createMatchAction, editMatchAction, getAllMatchesAction, getMatcheDetailAction } from '../../../../../redux/action/builds/matches/matchesAction';
import moment from 'moment';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../components/MetaInput';
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext';

const MatchInformation = ({ className ,isChanged, setIsChanged, onClose}) => {

    const { appid } = useParams();
    const { id } = useParams();
    const globalStates = useContext(GlobalStateContext)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoRef = useRef()
    const screenshotRef = useRef()
    const videoRef = useRef()
    const tagRef = useRef()

    const placeholder = Match
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [matchIcon, setMatchIcon] = useState(null)
    const [matchId, setMatchId] = useState("")
    const [matchName, setMatchName] = useState("");
    const [matchDescription, setMatchDescription] = useState()
    const [tags, setTags] = useState([]);

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
          setEditAccess(globalStates.access)
          setUserAccess(false)
      }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
          let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
          if(appPermission?.length > 0){
              setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
              setEditAccess(globalStates.access)
          }
      }else{

      }
  }

  useEffect(() => {
      getUserAccess()
  }, [globalStates.access])

    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //           setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //           globalStates.setAccess(true)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     getUserAccess()
    // }, [])

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //       setEditAccess(!editAccess)
    //       globalStates.setAccess(false)
    //     }
    // }

    // Match Information
    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setMatchIcon(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setMatchIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    
    const onMatchName = (e) => {
      if(e.target.value == ''){
        setMatchName('')
      }else{
        errors.nameError = ''
        setMatchName(e.target.value)
      }
    }

    const onMatchId = (e) => {
      if(e.target.value == ''){
        setMatchId('')
      }else{
        errors.idError = ''
        setMatchId(e.target.value)
      }
    }

    const onMatchDescription = (e) => {
        setMatchDescription(e.target.value)        
    }


    // Match and Leaderboard Detail
    const [minPlayer, setMinPlayer] = useState(null)
    const [maxPlayer, setMaxPlayer] = useState(null)
    const [leaderboardName, setLeaderboardName] = useState('')
    const [leaderboardId, setLeaderboardId] = useState('')
    const [leaderboardDescription, setLeaderbordDescription] = useState('')
    const [leaderboardMethod, setLeaderboardMethod] = useState('')
    const [leaderboardRequired, setLeaderboardRequired] = useState(false)
    const [frequency, setFrequency] = useState(true)

    const onMinPlayer = (e) => {
      setMinPlayer(parseInt(e.target.value))
    }

    const onMaxPlayer = (e) => {
      setMaxPlayer(parseInt(e.target.value))
    }

    const onLeaderboardName = (e) => {
      if(e.target.value == ''){
        setLeaderboardName('')
      }else{
        errors.leaderboardNameErr = ''
        setLeaderboardName(e.target.value)
      }
    }

    const onLeaderboardId = (e) => {
      if(e.target.value == ''){
        setLeaderboardId('')
      }else{
        errors.leaderboardIdErr = ''
        setLeaderboardId(e.target.value)
      }
    
    }

    const onLeaderboardDescription = (e) => {
      setLeaderbordDescription(e.target.value)
    }

    const [games, setGames] = useState()
    const [gameName, setGameName] = useState()
    const [gameId, setGameId] = useState()
    const [position, setPosition] = useState(null)
    const [matchFormatOption, setMatchFormatOption] = useState()
    const [matchFormat, setMatchFormat] = useState('')
    const [matchFormatId, setMatchFormatId] = useState()
    const [matchOutcomeOption, setMatchOutcomeOption] = useState()
    const [matchOutcome, setMatchOutcome] = useState('')
    const [matchOutcomeId, setmatchOutcomeId] = useState()
    const [leaderboardSourceOption, setLeaderboardSourceOption] = useState()
    const [leaderboardSource, setLeaderboardSource] = useState('Match')
    const [leaderboardSourceId, setLeaderboardSourceId] = useState(1)
    const [leaderboardOutcomeOption, setLeaderboardOutcomeOption] = useState()
    const [leaderboardOutcome, setLeaderboardOutcome] = useState('')
    const [leaderboardOutcomeId, setLeaderboardOutcomeId] = useState(null)
    const [recurringOptions, setRecurringOption] = useState()
    const [recurring, setRecurring] = useState('')
    const [recurringId, setRecurringId] = useState()


    
    const onPosition = (e) => {
      setPosition(parseInt(e.target.value))
    }

    const getMetaData = () => {
      dispatch(getAllMetaDataAction())
    }

    let isMetaDataLoading = useSelector((state) => {
      return state.common.isLoading
    })

    let allMetaData = useSelector((state) => {
      return state.common.metaData
    })

    useEffect(()=>{ 
      configureMetaData()
    }, [allMetaData])

    const configureMetaData = () => {
      setMatchFormatOption(allMetaData.matchFormatIds && allMetaData.matchFormatIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.matchFormatName == 'single_player' ? 'Single Player' : obj.matchFormatName == 'multi_player' ? 'Multiplayer' : obj.matchFormatName == 'multi_player_team' ? 'Multiplayer Team' : ''),
        };
      }))
      setMatchWinningConditionOptions(allMetaData.matchWinningConditionIds && allMetaData.matchWinningConditionIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.name == 'higher' ? 'Higher Wins' : obj.name == 'lower' ? 'Lower Wins' : ''),
        };
      }))
      setMatchOutcomeOption(allMetaData.matchOutcomeIds && allMetaData.matchOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.matchOutcomeName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      setLeaderboardSourceOption(allMetaData.leaderboardSourceTypeMasterIds && allMetaData.leaderboardSourceTypeMasterIds.map((obj) => {
        return {
          id: obj.id,
          name: (obj.name == 'match' ? 'Match' : obj.name == 'statistics' ? 'Statistics' : ''),
        };
      }))
      setLeaderboardOutcomeOption(allMetaData.leaderboardOutcomeIds && allMetaData.leaderboardOutcomeIds.map((obj) => {
        return {
          id: obj.id,
          name: obj.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        };
      }))
      setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.filter(obj => obj.intervalName !== 'monthly' && obj.intervalName !== 'yearly' && obj.intervalName !== 'all_time' && obj.intervalName !== 'custom').map((obj) => {
        return {
          id: obj.id,
          name: (obj.intervalName.charAt(0).toUpperCase() + obj.intervalName.slice(1))?.replace(/_/g, ' '),
        };
      }))
    }

    let getAllGames = async() => {
      let games = {
        projectId : appid
      }
      await dispatch(getListOfAllGamesAction(games))
    }

    useEffect(()=>{
      if(!isGameListFetched){
        getAllGames()
      }
      if(!isMetaDataLoading){
        getMetaData()
      }
    }, [])

    let allGames = useSelector((state) => {
      return state.games.games
    })

    let isGameListFetched = useSelector((state) => {
      return state.games.isGameListFetched
    })

    const configureGames = () => {
      setGames(allGames)
    }

    useEffect(()=>{
      configureGames()
    }, [allGames])

    // Custom Data
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = (e) => {

    }

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', gameErr : '', matchFormatErr : '', winningConditionErr: '', matchResultErr : '', leaderboardErr : '', leaderboardNameErr : '', leaderboardIdErr : '', leaderboardSourceErr : '', leaderboardRankingErr : '', frequencyErr : ''})

    const validation = (data, leadeboardData) => {

      const error = {}
      let isValid = true

      if (data.name == '') {
          error.nameError = 'Display Name is required';
          isValid = false;
          const element = document.getElementById('matchName');
          const offset = 140;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
      }
    
      if (data.matchId == '') {
          error.idError = 'Match ID is required';
          isValid = false;
          if(data.name != ''){
            const element = document.getElementById('matchId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if (data.gameId == '') {
          error.gameErr = 'Game is required';
          isValid = false;
          if(data.name != '' && data.matchId != ''){
            const element = document.getElementById('gameName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if(data.matchFormatTypeMasterId == null){
          error.matchFormatErr = 'Match Format is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != ''){
            const element = document.getElementById('matchFormat');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if(data.matchOutcomeTypeMasterId == null){
          error.matchResultErr = 'Match Result Criteria is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null){
            const element = document.getElementById('matchResult');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if(data.matchWinningConditionTypeMasterId == null){
        error.winningConditionErr = 'Winning Condition is required'
        isValid = false;
        if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null && data.matchOutcomeTypeMasterId != null){
          const element = document.getElementById('winningCondition');
          const offset = 100;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      }

      if((data.isLeaderboard == true && leadeboardData.name == '')){
          error.leaderboardNameErr = 'Display Name is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchFormatTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null){
            const element = document.getElementById('leaderboardName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if((data.isLeaderboard == true && leadeboardData.leaderboardId == '')){
          error.leaderboardIdErr = 'Leaderboard ID is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchOutcomeTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null && (data.isLeaderboard == true && leadeboardData.name != '')){
            const element = document.getElementById('leaderboardId');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      if((data.isLeaderboard == true && leadeboardData.leaderboardOutcomeTypeMasterId == null)){
          error.leaderboardRankingErr = 'Ranking Method is required'
          isValid = false;
          if(data.name != '' && data.matchId != '' && data.gameId != '' && data.matchOutcomeTypeMasterId != null && data.matchOutcomeTypeMasterId != null && data.matchWinningConditionTypeMasterId != null && (data.isLeaderboard == true && leadeboardData.name != '') && (data.isLeaderboard == true && leadeboardData.leaderboardId != '')){
            const element = document.getElementById('leaderboardRanking');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
      }

      setErrors(error);

      if(isValid){
        return true
      }
  }

  const [disable, setDisable] = useState(false)

    const saveMatch = () => {
      const metaDataList = [...metaData];
      const finalMetaData = {};
      for(var i = 0; i < metaDataList.length ; i++){
        finalMetaData[metaDataList[i].key] = metaDataList[i].value;
      }

      let matchTags = []
      tags && tags.forEach((e)=>{
        matchTags.push(e.text)
      })

      let match = {
        id : matchDBID,
        projectId : appid,
        name : matchName,
        matchId : matchId,
        minPlayers : (matchFormatId == 1 ? 1 : minPlayer),
        maxPlayers : (matchFormatId == 1 ? 1 : maxPlayer),
        description : matchDescription,
        logo : matchIcon,
        matchFormatTypeMasterId : matchFormatId,
        matchOutcomeTypeMasterId : matchOutcomeId,
        numberOfPostion : (matchOutcomeId == 4 ? position : null),
        gameId : gameId,
        isLeaderboard : leaderboardRequired,
        meta : finalMetaData,
        tags : matchTags,
        matchWinningConditionTypeMasterId : (matchFormatId == 1 ? 1 : matchWinningConditionId)
      }

      // const podiumRanksDetail = []
      // podiumRanks && podiumRanks.forEach((e, i) => {
      //   var key_one = e.ranks
      //   var key_two = e.score
      //   podiumRanksDetail.push({[key_one]:key_two})
      // })
     
      // let leaderboard = {
      //   ...((leaderboardDBID != null && leaderboardDBID != undefined) && {id : leaderboardDBID}),
      //   leaderboardId : leaderboardId,
      //   name : leaderboardName,
      //   description : leaderboardDescription,
      //   leaderboardOutcomeDetails : (leaderboardOutcomeId == 4 ? podiumRanksDetail : []),
      //   isRecurring : (recurringId == 6 ? false : true),
      //   gameId : gameId,
      //   active : true,
      //   archive : false,
      //   projectId : appid,
      //   matchId : matchDBID,
      //   leaderboardOutcomeTypeMasterId : leaderboardOutcomeId,
      //   sourceTypeId : leaderboardSourceId,
      //   intervalId : recurringId,
      //   startDate : (recurringId == 6 ? moment.utc(moment(startDateTime)).format("YYYY-MM-DD HH:mm") : null),
      //   endDate : (recurringId == 6 ? moment.utc(moment(endDateTime)).format("YYYY-MM-DD HH:mm") : null)
      // }
      if(validation(match)){
        dispatch(editMatchAction(match, navigate, appid, setDisable))
        setIsChanged(false)
      }
    }

    const [podiumRanks, setPodiumRanks] = useState([])

    const addRank = () => {
      setPodiumRanks(prevState => [...prevState, {ranks : null, score : null}])
    }

    const onRankChange = (e) => {
      let initialRanks = []
      for(var i = 0; i < parseInt(e) ; i++){
        initialRanks.push({ranks : i+1, score : null})
      }
      setPodiumRanks(initialRanks)
    }

    const onScoreChange = (e, index) => {
      let totalPodiumRanks = [...podiumRanks]
      totalPodiumRanks[index].score = e
      setPodiumRanks(totalPodiumRanks)
    }

    const removePodiumRanks = (index) => {
      let removeField = [...podiumRanks]
      let podiumFields = removeField.filter((e,i,c)=>{
          return i != index
      })
      setPodiumRanks(podiumFields)
    }

    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')

    const onChangeStartDateTime = (e) => {
      setStartDateTime(e.target.value)
    }

    const onChangeEndDateTime = (e) => {
      setEndDateTime(e.target.value)
    }


    const getMatchById = async() => {
      let getMatchById = {
        projectId : appid,
        ids : [id]
      }
      dispatch(getMatcheDetailAction(getMatchById))
    }

    let matchDetail = useSelector((state) => {
      return state.matches.matchDetail
    })

    const[disableLeaderboard,setDisableLeaderboard]=useState()
    const [matchDBID, setMatchDBID] = useState()
    const [leaderboardDBID, setLeaderboardDBID] = useState()
    const [checkObject, setCheckObject] = useState()

    const configureMatchDetail = () => {
      if(matchDetail.length > 0){
        let match = matchDetail[0]
        setCheckObject(match)
        setMatchDBID(match.id)
        setMatchName(match.name)
        setMatchId(match.matchId)
        setLogo({src: (match.logo != null ? match.logo : Match), alt: 'Upload an Image'}); 
        setMatchIcon(match.logo)
        setMatchDescription(match.description != null ? match.description : '')

        setGameName(match.game.name)
        setGameId(match.game.id)
        setMatchFormat(match.matchFormatTypeMasterId == 1 ? 'Single Player' : match.matchFormatTypeMasterId == 2 ? 'Multiplayer' : 'Multiplayer Team')
        setMatchFormatId(match.matchFormatTypeMasterId)
        setMinPlayer(match.minPlayers)
        setMaxPlayer(match.maxPlayers)
        setMatchOutcome(match.matchOutcomeTypeMasterId == 1 ? 'Score' : match.matchOutcomeTypeMasterId == 2 ? 'Completion Time' : match.matchOutcomeTypeMasterId == 3 ? 'Win Loss Draw' : match.matchOutcomeTypeMasterId == 4 ? 'Finish Position' : match.matchOutcomeTypeMasterId == 5 ? 'Winnings Collected' : '')
        setmatchOutcomeId(match.matchOutcomeTypeMasterId)
        setPosition(match.numberOfPostion)
        setMatchWinningCondition(match.matchWinningConditionTypeMasterId != null && match.matchWinningConditionTypeMasterId == 1 ? 'Lower Wins' : match.matchWinningConditionTypeMasterId != null && match.matchWinningConditionTypeMasterId == 2 ? 'Higher Wins' : '')
        setMatchWinningConditionId(match.matchWinningConditionTypeMasterId != null ? match.matchWinningConditionTypeMasterId  : null)
        // if(match.leaderboards != undefined || match.leaderboards != null){
        //   setLeaderboardDBID(match.leaderboards.length > 0 ? match.leaderboards[0].id : null)
        //   setLeaderboardRequired(match.isLeaderboard)
        //   setDisableLeaderboard(match.isLeaderboard)
        //   setLeaderboardName(match.leaderboards.length > 0 ? match.leaderboards[0].name : '')
        //   setLeaderboardId(match.leaderboards.length > 0 ? match.leaderboards[0].leaderboardId : '')
        //   setLeaderbordDescription(match.leaderboards.length > 0 ? match.leaderboards[0].description : '')
        //   setLeaderboardOutcome((match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster.id == 1) ? 'High Score' : (match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster.id == 2) ? 'Time Trail' : (match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster.id == 3) ? 'Win Loss Draw Points' : (match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster.id == 4) ? 'Position Weighting' : (match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster.id == 5) ? 'Cumulative Score' : '')
        //   setLeaderboardOutcomeId(match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeMaster != null && match.leaderboards[0].leaderboardOutcomeMaster.length > 0 ? match.leaderboards[0].leaderboardOutcomeMaster.id : '')
        //   if(match.leaderboards.length > 0 && match.leaderboards[0].leaderboardOutcomeDetails.length > 0){
        //     const podiumRanksDetail = match.leaderboards[0].leaderboardOutcomeDetails && match.leaderboards[0].leaderboardOutcomeDetails.map((e, i) => {
        //       const [ranks, score] = Object.entries(e)[0];
        //       return {ranks: parseInt(ranks), score: score}
        //     })
        //     setPodiumRanks(podiumRanksDetail)
        //   }else{
        //     setPodiumRanks([])
        //   }
        //   setFrequency(match.leaderboards.length > 0 ? match.leaderboards[0].isRecurring : '')
        //   setRecurringId(match.leaderboards.length > 0 ? match.leaderboards[0].intervalId : '')
        //   setRecurring((match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 1) ? 'Daily' : (match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 2) ? 'Weekly' : (match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 3) ? 'Monthly' : (match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 4) ? 'Yearly' : (match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 5) ? 'All Time' : (match.leaderboards.length > 0 && match.leaderboards[0].intervalId == 6) ? 'Custom' : '')
        //   setStartDateTime(match.leaderboards.length > 0 ? moment.utc(match.leaderboards[0].startDate).local().format('YYYY-MM-DD HH:mm') : null)
        //   setEndDateTime(match.leaderboards.length > 0 ? moment.utc(match.leaderboards[0].endDate).local().format('YYYY-MM-DD HH:mm') : null)
        // }

        let matchTags = []
        match.tagsMapping && match.tagsMapping.forEach(e => {
          e.tag && matchTags.push({id : e.tag.id, text : e.tag.name})
        })
        setTags(matchTags)
        let meta = []
        if(match.meta == null || JSON.stringify(match.meta) == '{}'){
            setMetaData([{key : '', value : null}])
        }else{
          match.meta && Object.keys(match.meta).forEach(e => {
            meta.push({key : e, value : match.meta[e]})
          })
          setMetaData(meta)
        }

      }
    }

    useEffect(()=>{
      configureMatchDetail()
    }, [matchDetail])

    useEffect(()=>{
      getMatchById()
    }, [])


    function compareLeaderboardOutcomeDetailsArrays(arr1, arr2) {
      let array1=arr1&& arr1.filter(item => !item?.archive)
      let array2=arr2&& arr2.filter(item => !item?.archive)
      if (array1?.length==0 &&(array2==null || array2==undefined)) {
          return true
      }
      if (array1?.length !== array2?.length) {
          return false
      }
      for (let i = 0; i < array1.length; i++) {
          let [ranks, score] = Object.entries(array2[i])[0];
          if (
              (String(array1[i]?.ranks)==ranks|| ((ranks==undefined||ranks==null)&&array1.length==0))&&
              (String(array1[i]?.score)==score|| ((score==undefined|| score==null)&&array1.length==0))
          ) {
          }
          else{
              return false;
          }
      }

      return true;
    }


    const setchange = () => {
      let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })

        let leaderboardOutcomeDetailsCheck = checkObject&& (checkObject.leaderboards.length > 0)&&(( compareLeaderboardOutcomeDetailsArrays(podiumRanks, checkObject.leaderboards[0].leaderboardOutcomeDetails)))

      

      if (checkObject&&
          (matchIcon==checkObject.logo)&&
          (matchName==checkObject?.name)&&
          (matchId==checkObject.matchId)&&
          (matchDescription == checkObject.description||((matchDescription===undefined||matchDescription==''||matchDescription=="")&&((checkObject.description===undefined||checkObject.description==''||checkObject.description==""||checkObject.description==null))))&&
          (gameId==checkObject.gameId)&&
          (matchFormatId==checkObject.matchFormatTypeMasterId)&&
          (minPlayer==checkObject.minPlayers)&&
          (maxPlayer==checkObject.maxPlayers)&&
          (position==checkObject.numberOfPostions)&&
          // (matchOutcomeId==checkObject.matchOutcomeTypeMasterId)&&
          // (checkObject.leaderboards.length > 0?leaderboardOutcomeDetailsCheck:(podiumRanks.length==0))&&
          // (leaderboardRequired==checkObject.isLeaderboard)&&
          // (leaderboardName==(checkObject.leaderboards.length > 0 ? checkObject.leaderboards[0].name : ''))&&
          // (leaderboardId==(checkObject.leaderboards.length > 0 ? checkObject.leaderboards[0].leaderboardId : ''))&&
          // (leaderboardDescription==(checkObject.leaderboards.length > 0 ?checkObject.leaderboards[0].description: ''))&&
          // (leaderboardOutcomeId==(checkObject.leaderboards.length > 0 && checkObject.leaderboards[0].leaderboardOutcomeMaster != null && checkObject.leaderboards[0].leaderboardOutcomeMaster.length > 0 ? checkObject.leaderboards[0].leaderboardOutcomeMaster.id:''))&&
          // (recurringId==(checkObject.leaderboards.length > 0 ?checkObject.leaderboards[0].intervalId:''))&&
          // ((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
          (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
      )
      {
          setIsChanged(false)
      }
      else 
      {
          setIsChanged(true)
      }
  }

  const cancel = () => {
    setEditAccess(true)
    globalStates.setAccess(true)
    // navigate(`/matches/${appid}`)
    setIsChanged(false)
  }

  const [matchWinningCondition, setMatchWinningCondition] = useState('')
  const [matchWinningConditionId, setMatchWinningConditionId] = useState(null)
  const [matchWinningConditionOptions, setMatchWinningConditionOptions] = useState() 

  useEffect(()=>{
    setchange()   
  },[position,maxPlayer,minPlayer,matchIcon,matchId,matchName,matchDescription,gameId,matchFormatId,matchOutcomeId,tags,metaData])

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
              <div className={styles.description}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                          <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                          <input disabled={editAccess} type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                          {!editAccess && <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {matchIcon != null && !editAccess ?
                            <div className={styles.previewCanecl}>
                              <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={matchIcon} setIcon={setMatchIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.group}>
                        <TextInput disabled={editAccess} id='matchName' className={styles.field} tooltip={TooltipTitle.MatchName} errorMessage={errors.nameError}  label="Match Name*" name="matchName" type="text" required value={matchName} onChange={(e)=>{onMatchName(e)}}/>
                        <TextInput disabled={editAccess} id='matchId' className={styles.field} tooltip={TooltipTitle.MatchId} errorMessage={errors.idError} label="Match ID*" name="matchID" type="text" required value={matchId} onChange={(e)=>{onMatchId(e)}}/>
                    </div>
                    <div className={styles.textGroup}>
                      <TextArea disabled={editAccess} tooltip={TooltipTitle.MatchDescription} rows={5} label="Match Description" value={matchDescription} onChange={(e)=>{onMatchDescription(e)}}/>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Information" classTitle="title-red">
              <div className={styles.description}>
                <div className={styles.group}>
                  <div className={styles.field}>
                    <GameDropdown disabled={editAccess} tooltip={TooltipTitle.MatchGame} id='gameName' label='Select Game*' errorMessage={errors.gameErr} placeholder='Choose Game' type="game" options={games} value={gameName} setValue={setGameName} setId={setGameId}/>
                  </div>
                  <div className={styles.field}>
                    <Dropdown disabled={editAccess} tooltip={TooltipTitle.MatchFormat} id='matchFormat' label='Select Match Format*' errorMessage={errors.matchFormatErr} placeholder='Choose Match Format' value={matchFormat} setValue={setMatchFormat} setId={setMatchFormatId} options={matchFormatOption}/>
                  </div>
                </div>
                {(matchFormatId == 2 || matchFormatId == 3) &&
                  <div className={styles.group}>
                    <TextInput disabled={editAccess} className={styles.field} tooltip={TooltipTitle.MatchPlayer} label="Minimum Player" name="minPlayer" type='number' onWheelCapture={e => {e.currentTarget.blur()}}  required value={minPlayer} onChange={(e)=>{onMinPlayer(e)}}/>
                    <TextInput disabled={editAccess} className={styles.field} tooltip={TooltipTitle.MatchPlayer} label="Maximum Player" name="maxPlater" type='number' onWheelCapture={e => {e.currentTarget.blur()}}  required value={maxPlayer} onChange={(e)=>{onMaxPlayer(e)}}/>
                  </div>
                }
                <div className={styles.group}>
                  <div className={styles.field}>
                    <Dropdown disabled={editAccess} tooltip={TooltipTitle.MatchResult} id='matchResult' label='Match Result Criteria*' errorMessage={errors.matchResultErr} placeholder='Choose Match Criteria' value={matchOutcome} setValue={setMatchOutcome} setId={setmatchOutcomeId} options={matchOutcomeOption}/>
                  </div>
                  <div className={styles.field}>
                    <Dropdown disabled={editAccess} id='winningCondition' errorMessage={errors.winningConditionErr} label='Select Winning Condition Type*' placeholder='Select Winning Condition Type' value={matchWinningCondition} setValue={setMatchWinningCondition} setId={setMatchWinningConditionId} options={matchWinningConditionOptions}/>
                  </div>
                </div>
                <div className={styles.group}>
                  {matchOutcomeId == 4 &&
                    <TextInput disabled={editAccess} className={styles.field} tooltip={TooltipTitle.MatchPosition}  label="No of Position" name="noOfPosition" type='number' onWheelCapture={e => {e.currentTarget.blur()}}  required value={position} onChange={(e)=>{onPosition(e)}}/>
                  }
                </div>
              </div>
            </Card>
            
            {/* <Card className={cn(styles.card, className)} title="Leaderboard Creation" classTitle="title-purple">
              <div className={styles.description}>
                <div className={styles.group}>
                  <div className={styles.fieldRadio}>
                    <div className={styles.radioLabel}>
                        <span>Do you want to create Leaderboards for this match?*</span>
                    </div>
                    <div className={styles.variants}>
                        <Radio className={styles.radio} name="leaderboard" value={leaderboardRequired} onChange={() => setLeaderboardRequired(true)} content="Yes" />
                        <Radio status={disableLeaderboard} className={styles.radio} name="leaderboard" value={!leaderboardRequired} onChange={() => setLeaderboardRequired(false)} content="No" />
                    </div>
                  </div>
                </div>
                {leaderboardRequired &&
                <>
                  <div className={styles.group}>
                    <TextInput id='leaderboardName' className={styles.field} tooltip={TooltipTitle.MatchLeaderboard} errorMessage={errors.leaderboardNameErr}  label="Leaderboard Name*" name="leaderboardName" type="text" required value={leaderboardName} onChange={(e)=>{onLeaderboardName(e)}}/>
                    <TextInput id='leaderboardId' className={styles.field} tooltip={TooltipTitle.MatchLeaderboardId} errorMessage={errors.leaderboardIdErr}  label="Leaderboard ID*" name="leaderboardId" type="text" required value={leaderboardId} onChange={(e)=>{onLeaderboardId(e)}}/>
                  </div>

                  <div className={styles.textGroup}>
                    <TextArea tooltip={TooltipTitle.MatchLeaderboardDescription} rows={5} label="Leaderboard Description" value={leaderboardDescription} onChange={(e)=>{onLeaderboardDescription(e)}}/>
                  </div>

                  <div className={styles.group}>
                    <TextInput tooltip={TooltipTitle.MatchLeaderboardSource} disabled={true} label='Leaderboard Source*' errorMessage={errors.leaderboardSourceErr} value={leaderboardSource} className={styles.field}/>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.MatchLeaderboardRanking} id='leaderboardRanking' label='Leaderboard Ranking Method*' errorMessage={errors.leaderboardRankingErr} placeholder='Choose Leaderboard Ranking Method' value={leaderboardOutcome} setValue={setLeaderboardOutcome} setId={setLeaderboardOutcomeId} options={leaderboardOutcomeOption}/>
                    </div>
                  </div>

                  <div className={styles.group}>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.MatchFrequency} label='Recurring' placeholder='Choose Leaderboard Recurring' value={recurring} setValue={setRecurring} setId={setRecurringId} options={recurringOptions}/>
                    </div>
                  </div>

                  {recurringId == 6 &&
                    <div className={styles.group}>
                      <TextInput tooltip={TooltipTitle.MatchStartDate}  label='Start Date/Time' name='startDateTime' type='datetime-local' className={styles.field} value={startDateTime} onChange={(e)=>{onChangeStartDateTime(e)}}/>
                      <TextInput tooltip={TooltipTitle.MatchEndDate}  label='End Date/Time' name='endDateTime' type='datetime-local' className={styles.field} value={endDateTime} onChange={(e)=>{onChangeEndDateTime(e)}}/>
                    </div>
                  }
                  
                  {leaderboardOutcomeId == 4 &&
                    <>
                      <div className={styles.group}>
                        <div className={styles.podiumSystem}>
                              <div className={styles.podiumGroup}>
                                  <div className={styles.rankLabel}>
                                      <span className={styles.podiumLabelTxt}>No of Podium Ranks</span><span onClick={addRank} className={styles.podiumLabelImg}><AddIcon /></span>
                                  </div>
                                  <div>
                                      <input type='number' onWheelCapture={e => {e.currentTarget.blur()}}  className={styles.inputField} value={podiumRanks.length > 0 ? podiumRanks.length : ''} onChange={(e)=>{onRankChange(e.target.value)}}/>
                                  </div>
                              </div>
                        </div>
                      </div>
                      {podiumRanks && podiumRanks.length > 0 && (
                        <>
                          <div className={styles.groupRanking}>
                            <div className={styles.fieldBlank}></div>
                            <div className={styles.fieldRank}>Starting Rank</div>
                            <div className={styles.field}>Score</div>
                          </div>

                          {podiumRanks && podiumRanks.map((e,i) => (
                            <>
                              <div className={styles.groupRanking} key={i}>
                                <div className={styles.fieldBlank}></div>
                                <div className={styles.fieldRank}>{i+1}</div>
                                <div className={styles.field}><TextInput key={i} type='number' onWheelCapture={e => {e.currentTarget.blur()}}  value={e.score} onChange={(e)=>{onScoreChange(parseInt(e.target.value), i)}} /></div>  
                                <div className={styles.fieldRemove}><span onClick={()=>{removePodiumRanks(i)}}><Remove /></span></div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                        
                    </>
                  }
                </>
              }
              </div>
            </Card> */}

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                    <TagInputWithDropdown disabled={editAccess} tooltip={TooltipTitle.MatchTags}  tags={tags} setTags={setTags}/>

                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.MatchMetaData} icon="info"/></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                              return(
                                  <>
                                      <TextInput disabled={editAccess} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                      <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                      {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon} /></span>}
                                  </>
                              )
                            })}
                        </div>

                    </div>
                </div>
            </Card>
          {!userAccess && !editAccess &&
            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} className={styles.Save} onClick={saveMatch}>Edit</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={styles.Cancel} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
          }
        </>
    )
}

export default MatchInformation