import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../Tooltip";

const Dropdown = ({className, groupBy , classLabel, classDropdownHead, placeholder, classDropdownLabel, value, setValue, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value.name)
        setVisible(false);
    };

      
    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (
                    <Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />
                )}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                {value&&value!==""?
                <div className={styles.selection}>{value}</div>
                :
                <div style={{color:"#6F767E"}} className={styles.selection}>{placeholder}</div>
                }
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {options && options.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown