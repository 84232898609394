import React, { useContext, useEffect, useState } from "react";
import styles from './accessWarningHead.module.sass'
import cn from "classnames";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
import Icon from "../Icon";
import { useParams } from "react-router-dom";

const AccessWarningHeader = ({className}) => {

    const globalStates = useContext(GlobalStateContext)
    const {appid} = useParams()
    const [show, setShow] = useState(false)

    const onChangeAccessRead = () => {
        globalStates.setAccess(false)
    }

    const onChangeAccessView = () => {
        globalStates.setAccess(true)
    }

    useEffect(() => {
        getUserAccess()
    }, [])

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setShow(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setShow(appPermission[0].permissions.id === 1 ? true : false)
            }
        }else{

        }
    }

    return(
        <>
            <header className={styles.warningHeader}>
                <div className={globalStates.access ? styles.view : styles.edit}>
                    {globalStates.access ?
                        <>
                            <div className={cn(styles.warning, {[styles.active] : show})}>Currently you're in view-only mode.</div>
                            {!show && <div className={styles.icon} onClick={onChangeAccessRead}><Icon name='edit' size='18'/></div>}
                        </>
                    :
                        <>
                            <div className={cn(styles.warning, {[styles.active] : show})}>Currently you're in edit mode.</div>
                            {!show && <div className={styles.icon} onClick={onChangeAccessView}><Icon name='close' size='18'/></div>}
                        </>
                    }
                </div>
            </header>
        </>
    )
}

export default AccessWarningHeader