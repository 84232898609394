import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../../Checkbox";
import Icon from "../../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import TextInput from "../../../../../TextInput";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import currencyPlaceHolder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../media/images/placeholders/Items.png'
import progressionMarkerPlaceholder from '../../../../../../media/images/placeholders/Progression_Marker.png'
import BundlePlaceholder from '../../../../../../media/images/placeholders/Bundles.png'

/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({usedFor, disable, item, access, value, onChange, index, total, setValue, allCurrency, setAllCurrency, allItems, setAllItems, allBundles, setAllBundle, allPM, setAllPM}) => {
    
    const {appid} = useParams()

    const onInputChange = (e, index) => {
        const content = (usedFor === 'Link Rewards' ? JSON.parse(sessionStorage.getItem('link-rewards')) : JSON.parse(sessionStorage.getItem('contents')))
        content[index].quantity = (e.target.value == '' ? null : parseInt(e.target.value))
        if(usedFor === 'Link Rewards'){
            content[index].isUpdated = true
            setValue(content)
            sessionStorage.setItem('link-rewards', JSON.stringify(content))
        }else{
            setValue(content)
            sessionStorage.setItem('contents', JSON.stringify(content))
        }
    }

    const onRemove = (item, index) => {
        const content = (usedFor === 'Link Rewards' ? JSON.parse(sessionStorage.getItem('link-rewards')) : JSON.parse(sessionStorage.getItem('contents')))
        content[index].archive = true
        setValue(content)
        if(item && item.currencyId != null){
            let currencyList = [...allCurrency]
            currencyList = currencyList.map(obj => {
                if (obj.id === item.currencyId ) {
                    return { ...obj, status: false, quantity: null, archive: true };
                }
                return obj;
            })
            setAllCurrency(currencyList)
        }
        else if(item && item.itemId != null){
            let itemList = [...allItems]
            itemList = itemList.map(obj => {
                if (obj.id === item.itemId ) {
                    return { ...obj, status: false, quantity: null, archive: true };
                }
                return obj;
            })
            setAllItems(itemList)
        }
        else if(item && item.bundleId != null){
            let bundleList = [...allBundles]
            bundleList = bundleList.map(obj => {
                if (obj.id === item.bundleId ) {
                    return { ...obj, status: false, quantity: null, archive: true };
                }
                return obj;
            })
            setAllBundle(bundleList)
        }
        else if(item && item.progressionMarkerId != null){
            let pmList = [...allPM]
            pmList = pmList.map(obj => {
                if (obj.id === item.progressionMarkerId ) {
                    return { ...obj, status: false, quantity: null, archive: true };
                }
                return obj;
            })
            setAllPM(pmList)
        }
        if(usedFor === 'Link Rewards'){
            sessionStorage.setItem('link-rewards', JSON.stringify(content))
        }else{
            sessionStorage.setItem('contents', JSON.stringify(content))
        }
    }
    
    useEffect(()=> {
        
    }, [value])

    return (
        <>
            {(item.archive == false || item.archive == undefined) &&
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.preview}>
                            <img src={
                                item.iconUrl == null && item.currencyId != null && (item.itemId == null && (item.bundleId == undefined || item.bundleId == null) && (item.progressionMarkerId == undefined || item.progressionMarkerId == null)) ? currencyPlaceHolder 
                                : item.iconUrl == null && item.itemId != null && (item.currencyId == null && (item.bundleId == undefined || item.bundleId == null) && (item.progressionMarkerId == undefined || item.progressionMarkerId == null)) ? itemPlaceHolder  
                                : item.iconUrl == null && item.bundleId != null && (item.currencyId == null && item.itemId == null && (item.progressionMarkerId == undefined || item.progressionMarkerId == null)) ? BundlePlaceholder
                                : item.iconUrl == null && item.progressionMarkerId != null && (item.currencyId == null && item.itemId == null && (item.bundleId == undefined || item.bundleId == null)) ? progressionMarkerPlaceholder
                                : item.iconUrl } alt="Item" />
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.displayId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.itemName}>{item.name}</div><div className={styles.itemId}>{item.displayId}</div></div>
                <div className={styles.col}><input disabled={access || disable} type='text' key={index} className={styles.inputWidth} value={item.quantity} onChange={(e)=>{onInputChange(e, index)}} /></div>
                <div className={styles.col}>{item.type != null ? item.type : '-'}</div>
                <div className={styles.col}>{(!access && !disable) && <img className={styles.removeIcon} src={removeIcon} onClick={()=>{onRemove(item, index)}}/>}</div>
            </div>
            }
        </>
    );
};

export default Row;
