import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import customAxios from '../../../../../../utils/interceptor';
import { Link, json, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './progressionSystem.module.sass'
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import TextArea from '../../../../../../components/TextArea';
import Radio from '../../../../../../components/Radio';
import Dropdown from '../../../../../../components/Dropdown/CreatableDropdown';
import Icon from '../../../../../../components/Icon';
import addIcon from '../../../../../../media/images/icons/add.png'
import editIcon from '../../../../../../media/images/icons/edit.png'
import Rewards from './Rewards'
import { createProgressionSystemAction, editProgressionSystemAction } from '../../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import Control from './Control';
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from '../../../../../../components/Tooltip';
import { BASE_URL, GET_PROGRESSION_SYSTEM } from '../../../../../../resources/APIEndpoints';
import ProgressionSystem from '../../../../../../media/images/placeholders/Progression_System.png'
import { useRef } from 'react';
import Toast from '../../../../../../components/Toast';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import currencyPlaceholder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceholder from '../../../../../../media/images/placeholders/Items.png'
import {ReactComponent as Previous} from '../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../media/images/icons/next.svg'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext';

const eventOption = ['Select Events','option 1','option 2', 'option 3']

const ProgressionSystemInfo = ({className, onClose, generateData,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef()
    const globalStates = useContext(GlobalStateContext)
    const [visibleHelp, setVisibleHelp] = useState(false);
    const placeholder = ProgressionSystem
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [type, setType] = useState()
    const [progressionIcon, setProgressionIcon] = useState()
    const [changedIcon, setChangedIcon] = useState(null)
    const [displayName, setDisplayName] = useState()
    const [progressionId, setProgressionId] = useState()
    const [progressionDescription, setProgressionDescription] = useState()
    const [parameter, setParameter] = useState(true)
    const [system, setSystem] = useState(true)
    const [progression, setProgression] = useState(null)
    const [events, setEvents] = useState(eventOption[0])
    const [progressionType, setProgressionType] = useState(false)
    const [progressionSchedule, setProgressionSchdule] = useState(false)
    const [progressionTime, setProgressionTime] = useState()
    const [indexLevel, setIndexLevel] = useState()
    const [tags, setTags] = useState([])
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [levels, setLevels] = useState([])
    const [psId, setPSID] = useState()
    const [page, setPage] = useState(1)

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setEditAccess(globalStates.access)
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
                setEditAccess(globalStates.access)
            }
        }else{

        }
    }

    useEffect(() => {
        getUserAccess()
    }, [globalStates.access])

    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //             setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //             globalStates.setAccess(true)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     getUserAccess()
    // }, [])

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //         setEditAccess(!editAccess)
    //         globalStates.setAccess(false)
    //     }
    // }
    
    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setProgressionIcon(res.data.getUrl)
        //setChangedIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
 toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    const onPrevious = () => {
        setPage(prevPage => prevPage - 1)
    }

    const onNext = () => {
        setPage(nextPage => nextPage + 1)
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setLogo({src: placeholder , alt: 'Upload an Image'})
        setProgressionIcon(null)
        ref.current.value = ''
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };
    const [checkObject, setCheckObject] = useState()

    const getProgressionSystemDetail = async() => {
        let getPSBYID = {
            ids: [id],
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, getPSBYID, headers)
        if(res){
            let PSID = res.data.data.levelDetails
            setCheckObject(PSID)
            setDisplayName(PSID.name)
            setPSID(PSID.levelSystemId)
            setProgressionDescription(PSID.description)
            setProgressionIcon(PSID.iconUrl)
            setLogo({src: (PSID.iconUrl != null ? PSID.iconUrl : ProgressionSystem), alt: 'Upload an Image'})
            setParameter(PSID.appEventId == null ? true : false)
            setProgressionId(PSID.progressionMarker.id)
            setProgression(PSID.progressionMarker.name)
            setProgressionSchdule(PSID.rewardGrantScheduleType == 'on-completion' ? false : true)
            let levelSystemReward = []
            if(PSID.levelDetails.length == 0){
                let initialLevels = [{no : 1, name : '', incrementalParameterValue : 0, cumulativeParameterValue : 0, rewardDetails : []}]
                setLevels(initialLevels)
                sessionStorage.setItem("Level", JSON.stringify(initialLevels))
            }else{
                for(var i = 0 ; i < PSID.levelDetails.length ; i++){
                    let currentReward = PSID.levelDetails[i].rewardDetails.map(i => ({id: (i.currencyDetail ? i.currencyDetail.id : i.itemDetail ?  i.itemDetail.id : ''), rewardId: (i.currencyDetail ? i.currencyDetail.currencyId : i.itemDetail ?  i.itemDetail.itemId : ''), iconUrl : (i.currencyDetail && i.currencyDetail.iconUrl != null ? i.currencyDetail.iconUrl : i.currencyDetail && i.currencyDetail.iconUrl == null ? currencyPlaceholder  : i.itemDetail && i.itemDetail.iconUrl != null ? i.itemDetail.iconUrl : i.itemDetail && i.itemDetail.iconUrl == null ? itemPlaceholder : '') , name : (i.currencyDetail ? i.currencyDetail.name : i.itemDetail ? i.itemDetail.name : ''), quantity :i.quantity , status : true, type: (i.currencyDetail ? 'Currency' : i.itemDetail ? 'items' : '')}))
                    levelSystemReward.push({no : i+1, name : PSID.levelDetails[i].levelName, incrementalParameterValue : PSID.levelDetails[i].incrementalParameterValue, cumulativeParameterValue : PSID.levelDetails[i].cumulativeParameterValue, rewardDetails : currentReward})
                }
                sessionStorage.setItem("Level", JSON.stringify(levelSystemReward))
                setLevels(levelSystemReward)
            }
            PSID.tagsMapping && PSID.tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(PSID.meta == null || JSON.stringify(PSID.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                PSID.meta && Object.keys(PSID.meta).forEach(e => {
                    meta.push({key : e, value : PSID.meta[e]})
                });
                setMetaData(meta)
            }
        }
    }

    const getProgressionMarker = () => {
        let PM = {
            projectId : appid,
            ids : [],
            offset : 0,
            limit : 500
        }
        dispatch(getAllProgressionMarkerAction(PM))
    }

    useEffect(()=>{
        getProgressionSystemDetail()
        getProgressionMarker()
    }, [])

    let progressionMarker = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', pmError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('psName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.levelSystemId == '') {
            error.idError = 'PS ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('psId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.progressionMarkerId == null) {
            error.pmError = 'PM is required';
            isValid = false;
            if(data.name != '' && data.levelSystemId != ''){
                const element = document.getElementById('pmName');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const edit = () => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let PSTags = []
        tags && tags.forEach((e)=>{
            PSTags.push(e.text)
        })

        let levelDetails = JSON.parse(sessionStorage.getItem('Level'))
        let levels = []
        for(var i = 0 ; i < levelDetails.length ; i++){
            levels.push({no: i+1, name : levelDetails[i].name.toString() , incrementalParameterValue : levelDetails[i].incrementalParameterValue, cumulativeParameterValue : levelDetails[i].cumulativeParameterValue, rewardDetails : {currencies : [], items: [],bundles: [],rewardSets: [], progressionMarkers: []}})
            for(var j = 0 ; j < levelDetails[i].rewardDetails.length ; j++){
                if(levelDetails[i].rewardDetails[j].type == 'Currency'){
                    levels[i].rewardDetails.currencies.push({id : levelDetails[i].rewardDetails[j].id, quantity : levelDetails[i].rewardDetails[j].quantity})
                }else{
                    levels[i].rewardDetails.items.push({id : levelDetails[i].rewardDetails[j].id, quantity : levelDetails[i].rewardDetails[j].quantity})
                }
            }
        }

        let editProgressionSystem = {
            projectId : appid,
            id : id,
            name : displayName,
            levelSystemId : psId,
            iconUrl : progressionIcon,
            description : progressionDescription,
            progressionMarkerId : progressionId,
            appEventId : null,
            levelSystemTypeId: 1,
            levelDetails : levels,
            rewardGrantScheduleType : (progressionSchedule ? "custom" : "on-completion"),
            tags: PSTags,
            meta: finalMetaData
        }
        if(validation(editProgressionSystem)){
            dispatch(editProgressionSystemAction(editProgressionSystem, navigate, appid, setDisable))
            setIsChanged(false)
        }
        
    }
    
    // const data = (data) => {
    //     const levelFields = []
    //     let ab = data.baseValue
    //     let parameter = [ab]
    //     for(var i = 0 ; i < data.levels ; i++){
    //         let abc = parameter[i] * data.multiplyer
    //         parameter.push(abc)
    //         levelFields.push({no: i+1, name : `Level ${(i+1).toString()}`, value : Math.round(parameter[i]), rewardDetails :[]})
    //     }
    //     setLevels(levelFields)
    //     localStorage.setItem('Level', JSON.stringify(levelFields))
    // }

    // const addLevels = () => {
    //     const levelFields = [...levels]
    //     levelFields.push({no: levels.length + 1, name : '', value : '', rewardDetails : []})
    //     setLevels(levelFields)
    //     localStorage.setItem('Level', JSON.stringify(levelFields))
    // }

    // const onChangeLevel = (index, value) => {
    //     if(/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
    //         const updatedInputData = [...levels];
    //         updatedInputData[index].name = value;
    //         setLevels(updatedInputData);
    //         localStorage.setItem('Level', JSON.stringify(levels))
    //     }
        
    // }

    // const onChangeLevelParameter = (index, value) => {
    //     const updatedInputData = [...levels];
    //     updatedInputData[index].value = parseInt(value);
    //     setLevels(updatedInputData);
    //     localStorage.setItem('Level', JSON.stringify(levels))
    // }

    // const handleKeyDown = (event) => {
    //     if (event.key === '.') {
    //       event.preventDefault();
    //     }
    // };

    // const removeLevel = (index) => {
    //     let removeField = [...levels]
    //     let levelFields = removeField.filter((e,i,c)=>{
    //         return i != index
    //     })
    //     setLevels(levelFields)
    //     localStorage.setItem('Level', JSON.stringify(levelFields))
    // }

    const addLevels = () => {
        const levelFields = [...levels]
        levelFields.push({no: levels.length + 1, name : '', incrementalParameterValue : null, cumulativeParameterValue : null, rewardDetails : []})
        setLevels(levelFields)
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }

    const onChangeLevel = (index, value) => {
        if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
            const updatedInputData = [...levels];
            updatedInputData[index].name = value;
            setLevels(updatedInputData);
            sessionStorage.setItem('Level', JSON.stringify(levels))
        }
    }

    const onChangeIncrementalParameter = (index, value) => {
        const updatedInputData = [...levels];
        updatedInputData[index].incrementalParameterValue = parseInt(value);
        setLevels(updatedInputData);
        let testValue = 0
        for(var i = 0 ; i < updatedInputData.length ; i++){
            testValue = testValue + updatedInputData[i].incrementalParameterValue
            updatedInputData[i].cumulativeParameterValue = parseInt(testValue);
            setLevels(updatedInputData);
        }
        setLevels(updatedInputData);
        sessionStorage.setItem('Level', JSON.stringify(updatedInputData))
    }


    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
    };

    const removeLevel = (index) => {
        let removeField = [...levels]
        let levelFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        let testValue = 0
        for(var i = 0 ; i < levelFields.length ; i++){
            testValue = testValue + levelFields[i].incrementalParameterValue
            levelFields[i].cumulativeParameterValue = parseInt(testValue);
            setLevels(levelFields);
        }
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }

    const data = (data) => {
        const levelFields = [{no: 1, name : `Level 1`, incrementalParameterValue : 0, cumulativeParameterValue : 0, rewardDetails :[]}]
        let baseValueParam = data.baseValue
        let incrementalParameter = [1, baseValueParam]
        let cumulativeParameter = [1, baseValueParam]
        for(var i = 1 ; i < data.levels ; i++){
            let incrementalValueParameter = incrementalParameter[i] * data.multiplyer
            let cumulativeValueParameter = cumulativeParameter[i] + incrementalValueParameter
            cumulativeParameter.push(cumulativeValueParameter)
            incrementalParameter.push(incrementalValueParameter)
            if(incrementalParameter[i] > Number.MAX_SAFE_INTEGER || incrementalParameter[i] > Number.MAX_SAFE_INTEGER){
                toast.error(<Toast type='Error' messages='Value Exceeds largest Integer value'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
                break;
            }else{
                levelFields.push({no: i+1, name : `Level ${(i+1).toString()}`, incrementalParameterValue : Math.round(incrementalParameter[i]), cumulativeParameterValue : parseInt(cumulativeParameter[i]), rewardDetails :[]})
            }
            //levelFields.push({no: i+1, name : `Level ${(i+1).toString()}`, incrementalParameterValue : Math.round(incrementalParameter[i]), cumulativeParameterValue : parseInt(cumulativeParameter[i]), rewardDetails :[]})
        }
        setLevels(levelFields)
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }


    const onTagInput = () => {
        if(document.getElementById('PSTagInput')){
            document.getElementById('PSTagInput').focus()
        }
    }

    const cancel = () => {
        setEditAccess(true)
        globalStates.setAccess(true)
        // navigate(`/progression-system/${appid}`)
        setIsChanged(false)
    }

    const [disable, setDisable] =  useState(false)
    const [visibleFile, setVisibleFile] = useState(false)

    function compareContentArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
    
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.id===array2[i]?.item?.itemId|| array2[i]?.item?.itemId==undefined|| array2[i]?.item?.itemId==null)&&
                (array1[i]?.id===array2[i]?.currency?.currencyId || array2[i]?.currency?.currencyId==undefined|| array2[i]?.currency?.currencyId==null)&&
                parseInt(array1[i].quantity)===array2[i].quantity
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }


    function compareLevelsArray(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            let contentCheck = compareContentArrays(array1[i].rewardDetails, array2[i].rewardDetails)
            if (
                (array1[i].name==array2[i].levelName)&&
                (array1[i].incrementalParameterValue==array2[i].incrementalParameterValue)&&
                (array1[i].no==array2[i].levelNo)&&
                (contentCheck)&&
                (array1[i].cumulativeParameterValue==array2[i].cumulativeParameterValue)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }


    const setchange = () => {

        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
        let levelsCheck = checkObject&& ((checkObject.levelDetails&& compareLevelsArray(levels, checkObject.levelDetails))||!checkObject?.levelDetails)
        
        if (checkObject&&
            (displayName==checkObject.name)&&
            (psId==checkObject.levelSystemId)&&
            (progressionIcon==checkObject.iconUrl)&&
            (((progressionDescription==undefined||progressionDescription==''||progressionDescription==null)&& checkObject.description==null)||progressionDescription==checkObject.description)&&
            (progressionId==checkObject.progressionMarkerId)&&
            (levelsCheck)&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }


    useEffect(()=>{
        setchange()
    },[type,progressionIcon,displayName,psId,progressionId,progressionDescription,parameter,system,progression,progressionSchedule,metaData,levels,tags])
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                    <div className={styles.description}>
                    
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <img src={(progressionIcon != "" && src == "") ? progressionIcon : (progressionIcon != "" && src != "") ? src : progressionIcon} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                            </div>
                            <div className={styles.previewInput}> 
                                <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                {!editAccess && <label className={styles.button} onClick={() => setVisibleFile(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                            </div>
                            {progressionIcon != null && !editAccess &&
                            <div className={styles.previewCanecl}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                            }
                        </div>
                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={progressionIcon} setIcon={setProgressionIcon} setValue={setLogo} onClose={onClose} />
                        <div className={styles.group}>
                            <TextInput disabled={editAccess} id='psName' className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.PSName} label="Display Name*" name="DisplayName" type="text" required value={displayName} onChange={(e)=>{e.target.value == '' ? setDisplayName('') : setDisplayName(e.target.value); errors.nameError = ''}} />
                            <TextInput disabled={editAccess} id='psId' className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.PSID} label="Progression System ID*" name="progressionId" type="text" required value={psId} onChange={(e)=>{e.target.value == '' ? setPSID('') : setPSID(e.target.value); errors.idError = ''}}/>
                        </div>
                        <TextArea disabled={editAccess} className={styles.field} rows={5} tooltip={TooltipTitle.PSDescription} label="Progression System Description" value={progressionDescription} onChange={(e)=>{setProgressionDescription(e.target.value)}}/>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Select Parameter<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSParameter} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={editAccess} className={styles.radio} name="parameter" value={parameter} onChange={() => setParameter(true)} content="Progression Marker" />
                                    <Radio disabled={editAccess} className={styles.radio} name="parameter" value={!parameter} onChange={() => setParameter(false)} content="Statistics" />
                                </div>
                            </div>
                            {parameter ?
                                <>
                                    <Dropdown disabled={editAccess} id='pmName' errorMessage={errors.pmError} placeholder='Select Progression Marker*'  label="Choose Progression Marker" tooltip={TooltipTitle.PSPM} type={"progression marker"} options={progressionMarker} value={progression} setValue={setProgression} setId={setProgressionId} groupBy={2} />
                                </>
                            : ''}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Type" classTitle="title-blue">
                    <div className={styles.cardDescription}>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.group}>
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Progression System Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSType} icon="info"/></span></span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio disabled={editAccess} className={styles.radio} name="PSType" value={system} onChange={() => setSystem(true)} content="Linear" />
                                        <Radio disabled={true} className={styles.radio} name="PSType" value={!system} onChange={() => setSystem(false)} content="Non-Linear" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.levelSystem}>
                                    <div className={styles.levelSystemGroup}>
                                        <div className={cn(styles.levelLabel, {[styles.disabled] : editAccess})}>
                                            <span className={styles.levelLabelTxt}>Level</span><span className={styles.levelLabelImg} onClick={editAccess ? null : addLevels}><AddIcon /></span>
                                        </div>
                                        <div className={styles.configure}>
                                            <Control disabled={editAccess} width="small" levelsDetail={levels} setter={data} className={styles.control} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {levels && levels.length > 0 ?
                                <div className={styles.groupByThree}>
                                    <p></p>
                                    <p className={styles.fieldTitle}>Level Name</p>
                                    <p className={styles.fieldTitle}>Incremental Parameter Value</p>
                                    <p className={styles.fieldTitle}>Cumulative Parameter Value</p>
                                    <p className={styles.fieldTitle}>Rewards</p>
                                </div>
                            : ''} 
                            
                            {/* {levels && levels.map((e,i)=>{
                                return(
                                    <>
                                        <div key={i} className={styles.groupByThree}>
                                            <p className={styles.index}>{i+1}</p>
                                            <TextInput className={styles.field} defaultValue={levels[i].name} name="level" type="text" required onChange={(e)=>{onChangeLevel(e,i)}}/>
                                            <TextInput className={styles.field} defaultValue={levels[i].value} name="parameter" type="text" required onChange={(e)=>{onChangeLevelParameter(e,i)}}/>
                                            <p className={styles.field}>{JSON.parse(localStorage.getItem('Level')) && JSON.parse(localStorage.getItem('Level'))[i].rewardDetails.length == 0 ? <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('add')}}><img src={addIcon} className={styles.icon} /></span> : <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('edit')}}><img src={editIcon} className={styles.icon} width={30}/></span>}</p>
                                        </div>
                                    </>
                                )
                            })} */}

                            {levels && levels.map((e,i)=>{
                                    if (i<page*10 && i>(page-1)*10-1) {
                                return(
                                    <>
                                        <div key={i} className={styles.groupByThree}>
                                        <p className={styles.index}>{i+1}</p>
                                            <TextInput disabled={editAccess} className={styles.field} type="text" key={i} value={e.name} onChange={(e)=> onChangeLevel(i, e.target.value) }/>
                                            <TextInput disabled={(i == 0 || editAccess) ? true : false} className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={i== 0 ? 0 : e.incrementalParameterValue} onChange={(e) => onChangeIncrementalParameter(i, e.target.value)} onKeyDown={handleKeyDown}/>
                                            <TextInput className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={e.cumulativeParameterValue} disabled onKeyDown={handleKeyDown}/>
                                            {/* <TextInput className={styles.field} type="text" key={i} value={e.name} onChange={(e)=> onChangeLevel(i, e.target.value) }/>
                                            <TextInput className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={e.value} onChange={(e) => onChangeLevelParameter(i, e.target.value)}/> */}
                                            <p className={styles.field}>
                                                {(levels && levels[i].rewardDetails.length == 0 && i != 0) ? 
                                                    (!editAccess &&
                                                        <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('add')}}>
                                                            <AddIcon className={styles.icon}/>
                                                        </span> 
                                                    )
                                                : (levels && levels[i].rewardDetails.length > 0 && i != 0) ?
                                                    <div className={styles.levelRewards}>
                                                        <div className={styles.reward}>
                                                            {levels && levels[i].rewardDetails.length > 0 ? 
                                                                (levels[i].rewardDetails && levels[i].rewardDetails.slice(0,2).map((x,index) => 
                                                                    <span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>
                                                                )) 
                                                            : ''}
                                                            <span>{levels[i].rewardDetails.length > 2 ? <span>&nbsp;&#43;&nbsp;{levels[i].rewardDetails.length-2}</span>  : ''}</span>
                                                        </div>
                                                        {!editAccess &&
                                                            <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(i) ; setType('edit')}}>
                                                                <EditIcon className={styles.icon}/>
                                                            </div>
                                                        }
                                                    </div>
                                                : ''}
                                            </p>  
                                            <p className={styles.remove}>
                                            {i != 0 ?
                                                (!editAccess &&
                                                    <span className={styles.removeIcon} onClick={()=>{removeLevel(i)}}>
                                                        <RemoveIcon />
                                                    </span>
                                                )
                                            : ''}
                                            </p>
                                        </div>
                                    </>
                                )}
                            })}

                            <div>
                                <Rewards type={type} level={indexLevel} visible={visibleHelp} setVisible={setVisibleHelp} onClose={onClose} setPSLevels={setLevels}/>
                            </div>
                            {levels.length>10 && <div className={styles.pagination}>
                            <div className={styles.icons}>
                                <button disabled={page <= 1 ? true : false}>
                                <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
                                </button>
                                <span className={styles.pageno}>{page+"/"+ Math.ceil(levels.length/10)}</span>
                                <button disabled={levels.length <= page*10 ? true : false}>
                                <Next className={styles.next} onClick={onNext}/>
                                </button>
                            </div>
                            </div>}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                        <TagInputWithDropdown disabled={editAccess} tooltip={TooltipTitle.PSTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.PSMetaData} icon="info"/></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddIcon /></span>}
                            </div>

                            {metaData && metaData.length > 0 &&
                                <div className={styles.groupMeta}>
                                    <div className={styles.field}>Key</div>
                                    <div className={styles.field}>Value</div>
                                </div>
                            }

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput disabled={editAccess} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                            <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon}/></span>}
                                        </>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button disabled={disable} className={styles.Save} onClick={edit}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={styles.Cancel} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default ProgressionSystemInfo