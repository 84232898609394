import React from "react";
import style from '../../Bundles/CreateBundles/createBundle.module.sass';
import StoreInformation from './StoreInformation';
import { ToastContainer } from "react-toastify";

const CreateStore = ({isChanged, setIsChanged}) => {
    return (
      <>
        <ToastContainer />
        <div className={style.row}>
            <div className={style.col}>
                <StoreInformation isChanged={isChanged} setIsChanged={setIsChanged} className={style.card}/>
            </div>
        </div>
      </>
    )
  }
  
  export default CreateStore