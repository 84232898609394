import {
  ChannelItem as IChannelItem,
  ChannelBox,
  ChannelLogo,
} from "@nessprim/planby-pro";

export const ChannelItem = ({ isVerticalMode, channel }) => {
  const { position, logo } = channel;

  return (
    <ChannelBox
      data-testid="sidebar-item"
      isVerticalMode={isVerticalMode}
      style={{
        border: "1px solid transparent",
        borderBottomColor: "#7180961a",
        borderRightColor: "#7180961a",
      }}
      {...position}
    >
      {/* <ChannelLogo src={logo} alt="Logo" /> */}
    </ChannelBox>
  );
};
