import React from "react";
import cn from "classnames";
import styles from "./toolTip.module.sass";
import Icon from "../Icon";

const Tooltips = ({ className, title, icon, place }) => {
  return (
    <>
      {/* <Tooltip title="Delete"><Icon name={icon} /></Tooltip> */}
      <div className={cn(styles.tooltip, className)}>
        <span data-tip={title} title={title} data-place={place}><Icon name={icon} /></span>
      </div>
    </>
  );
};

export default Tooltips;
