import React from "react";
import styles from './editBundle.module.sass'
import BundleInformation from './BundleInformation'
import { ToastContainer } from "react-toastify";

const CreateBundle = ({isChanged, setIsChanged}) => {
  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
            <BundleInformation isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default CreateBundle