import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './matches.module.sass'
import MatchesTableLayout from '../../../../../components/EngageComponents/PlayersComponent/matchHistory'
import { useParams } from 'react-router-dom';
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction';
import Dropdown from '../../../../../components/Dropdown'
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

const Profile = ({activeIndex}) => {

  const {appid} = useParams()
  const {id} = useParams()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const getPlayerDetails = async() => {
      let playerDetail = {
          entities : {
              value : 'matches',
              offset: ((page-1)*limit),
              limit: limit
          },
          projectId : appid,
          ids : [id]
      }
      await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
  }

  const onPrevious = () => {
    setPage(prevPage => prevPage - 1)
  }

  const onNext = () => {
    setPage(nextPage => nextPage + 1)
  }

  useEffect(()=>{
    getPlayerDetails()
  }, [activeIndex, page])

  const playerMatches = useSelector((state) => {
    return state.players.matches.userMatchHistory
  })

  const matchOverview = useSelector((state) => {
    return state.players.matches.matchOverview
  })

  let totalCount = useSelector((state) => {
    return state.players.totalMatchCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
  }

  return (
    <>
        <Card title="Overview" classTitle="title-purple">
            <div className={styles.overview}>
                <div className={styles.group}>
                    <TextInput className={styles.field} value={matchOverview && matchOverview.totalMatchPlayed != null ? matchOverview.totalMatchPlayed : 0} disabled label='Total Matches' name='matches' type='text'/>
                    <TextInput className={styles.field} value={matchOverview && matchOverview.totalCompetition != null ? matchOverview.totalCompetition : 0} disabled label='Total Competition' name='competition' type='text' />
                </div>    
            </div>
        </Card>
        <Card title="Match History" classTitle="title-purple" head={
          <>
            <div className={styles.dropDown}>
                <Dropdown placeholder='Default' />
            </div>
          </>
        }>
          <div className={styles.overview}>
            <div className={styles.groups}>
              {playerMatches && playerMatches.length > 0 ?
                <>
                  <MatchesTableLayout items={playerMatches} />
                  <div className={styles.pagination}>
                      <Stack spacing={2}>
                      <Pagination renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                              '&.Mui-selected': {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              },
                              '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              }
                            }}
                        />
                      )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
                    </Stack>
                  </div>
                </>  
              : <div className={styles.warning}>No Data Available</div>}
            </div>
          </div>
        </Card>
    </>
  )
}

export default Profile