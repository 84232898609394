import { GET_APP_INVITED_MEMBERS, GET_APP_INVITED_MEMBERS_SUCCESS, GET_APP_INVITED_MEMBERS_ERROR, GET_APP_ACCEPTED_MEMBERS, GET_APP_ACCEPTED_MEMBERS_SUCCESS, GET_APP_ACCEPTED_MEMBERS_ERROR } from "../../../actionTypes/actionTypes";

const initialState = {
    acceptedMembers : [],
    invitedMembers : [],
    isLoading : false
};

export const memberReducer = (state=initialState, action) => {
    switch(action.type){

        case GET_APP_ACCEPTED_MEMBERS : {
            return {
                ...state,
                acceptedMembers: [],
                isLoading : true,
            }
        }

        case GET_APP_ACCEPTED_MEMBERS_SUCCESS : {
            return {
                ...state,
                acceptedMembers: action.payload,
                isLoading : false
            }
        }
        
        case GET_APP_ACCEPTED_MEMBERS_ERROR : {
            return {
                ...state,
                // acceptedMembers: action.payload,
                isLoading : false
            }
        }

        case GET_APP_INVITED_MEMBERS : {
            return {
                ...state,
                invitedMembers: [],
                isLoading : true,
            }
        }

        case GET_APP_INVITED_MEMBERS_SUCCESS : {
            return {
                ...state,
                invitedMembers: action.payload,
                isLoading : false
            }
        }
        
        case GET_APP_INVITED_MEMBERS_ERROR : {
            return {
                ...state,
                // invitedMembers: action.payload,
                isLoading : false
            }
        }
        
        default: {
            return {
                ... state
            }
        }
    }    
}    