import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../Checkbox";
import Row from "./Row";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import { Suspense } from "react";

const Table = ({usedFor, tableFor, items,setValue,setData}) => {


  return (
    <div className={styles.wrapperScroll}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}></div>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Quantity</div>
        </div>
        {items.map((x, index) => (
          <Row usedFor={usedFor} tableFor={tableFor} item={x} items={items} key={index} index={index} setValue={setValue} setData={setData} />
        ))}
      </div>
      {items&&items?.length==0&&
        <div style={{textAlign:"center",margin: "auto",width:"max-content",height:"inherit"}}>
          <p style={{marginTop: "160px"}}>No Results Found</p>
        </div>
      }
    </div>
  );
};

export default Table;
