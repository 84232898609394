import React, { useState } from "react";
import {CombinatorSelectorProps} from "react-querybuilder";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./customCombinator.module.sass"
import { useEffect } from "react";
// import Tooltip from "../Tooltip";

const Dropdown = (CombinatorSelectorProps, className, classLabel, classDropdownHead, small, upBody, fields, value) => {

    const [visible, setVisible] = useState(false)

    const handleClick = (event) => {
        CombinatorSelectorProps.handleOnChange(event.name)
        setVisible(false)
    };
      
    return (
        <div className={styles.dropdowns}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.selection}>{(CombinatorSelectorProps.value == null || CombinatorSelectorProps.value == undefined || CombinatorSelectorProps.value == '') ? 'Select Parameter' : CombinatorSelectorProps.value.toUpperCase()}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {CombinatorSelectorProps.options && CombinatorSelectorProps.options.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        {x.name.toUpperCase()}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown