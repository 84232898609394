import React from 'react'
import TaskInformation from './TaskInformation'
import styles from './editTask.module.sass'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const CreateTask = ({modalFor, as,onClose, item, index, setValue, currency, setCurrency, currencyCount, currencyPage, items, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setStepSeries}) => {

    return(
        <>
            <ToastContainer />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TaskInformation modalFor={modalFor} as={as} onClose={onClose} index={index} task={item} setValue={setValue} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={items} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setStepSeries={setStepSeries} className={styles.card}/>
                </div>
            </div>
        </>
    )

}

export default CreateTask