import React from 'react'
import TaskInformation from './TaskInformation'
import styles from './createTask.module.sass'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const CreateTask = ({isChanged, setIsChanged}) => {

    return(
        <>
            <ToastContainer />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TaskInformation isChanged={isChanged} setIsChanged={setIsChanged}  className={styles.card}/>
                </div>
            </div>
        </>
    )

}

export default CreateTask