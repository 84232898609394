import React, { useState } from "react";
import cn from "classnames";
import styles from "./sendInviteInput.module.sass";
import Icon from "../../../Icon";
import Tooltip from "../../../Tooltip";
import MemberPermissionDropdown from "../MemberPermissionDropdown";

const SendInviteInput = ({className, usedFor, fieldName, value, permission, options, setValue, setPermission, classLabel, classInput, label, icon, copy, currency, style, tooltip, place, icons, error, groupBy, ...props}) => {


  return (
    <div className={cn(styles.field, { [styles.fieldCurrency]: currency }, className )}>
      <div className={styles.wrap}>
        <input className={cn(classInput, styles.input)} {...props} ></input>
        {currency && <div className={styles.currency}>{currency}</div>}
         {usedFor == 'app' && <MemberPermissionDropdown options={options} permission={permission} setPermission={setPermission} value={value} setValue={setValue}/>}
      </div>
    </div>
  );

};

export default SendInviteInput;