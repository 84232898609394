import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import {ReactComponent as Circle} from '../../media/images/smaller_circle.svg'
import {ReactComponent as OutlineCircle} from '../../media/images/full_hollow_circle.svg'
import Cross from '../../media/images/fill_cross.png'
import {ReactComponent as OutlineCross} from '../../media/images/hollow_crossed.svg'
import Lottie from "lottie-react";
import Loader from '../../media/lottieAnimation/Specter_Animation.json'
import Modal from "../Modals/ModalComponent";
import IntroductionModal from "../Modals/IntroductionModal";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";

const Page = ({type, background, wide, children, title, showLoader, setShowLoader, loaderType, setLoaderType}) => {

    const [visible, setVisible] = useState(false);
    const [isChanged,setIsChanged] = useState(false);
    const [appName, setAppName] = useState("")

    const getLocalStorageData = () => {
        const appName = localStorage.getItem('App')
        setAppName(appName)
    }

    useEffect(()=>{
        getLocalStorageData()
    }, [])

    const globalStates = useContext(GlobalStateContext)

    const style = {
        display :'block',
        margin : '0px auto',
        width : '160px',
        height : '160px'
    }

    return (
        <>
        {showLoader ?
            <div className={styles.loaderContainer}>
                <div className={styles.loaderWrapper}>
                    <div className={styles.loader}>
                        <Lottie animationData={Loader} loop={true} autoPlay={true} style={style} />
                    </div>
                    <div className={styles.loaderTitle}>
                        {loaderType === 0 && <div className={styles.title}>Crafting your Specter experience...<br />Welcome aboard!</div>}
                        {loaderType === 1 && <div className={styles.title}>Welcoming you back to where the magic happens!</div>}
                        {loaderType === 2 && <div className={styles.title}>Shifting realms to better suit your needs...<br />Hang tight!</div>}
                        {loaderType === 3 && <div className={styles.title}>Laying the foundation for your next big hit...<br />Stay tuned!</div>}
                        {loaderType === 4 && <div className={styles.title}>Navigating to new possibilities...<br />Where to next?</div>}
                        {loaderType === 5 && <div className={styles.title}>Returning you to the big picture...<br /> Let's see what's next!</div>}
                    </div>
                </div>
            </div>
        :
            <>
            <Modal width='large' visible={globalStates.showQuickGuideModal} onClose={() => globalStates.setShowQuickGuideModal(false)}>
              <IntroductionModal setLoaderType={setLoaderType} setShowLoader={setShowLoader} setShowAnimation={globalStates.setShowAnimation} onClose={()=> globalStates.setShowQuickGuideModal(false)}/>
            </Modal>
            <div className={styles.page}>
                <Sidebar loaderType={loaderType} setLoaderType={setLoaderType} appName={appName} setAppName={setAppName} showLoader={showLoader} setShowLoader={setShowLoader} isChanged={isChanged} setIsChanged={setIsChanged} type={type} className={cn(styles.sidebar, {[styles.visible]: visible})} onClose={() => setVisible(false)} />
                <Header isChanged={isChanged} setIsChanged={setIsChanged} onOpen={() => setVisible(true)} />
                <div className={styles.inner}>
                    {background &&
                        <>
                            <Circle className={styles.circle} />
                            <img src={Cross} width={22} height={22} className={styles.cross}/>
                            <OutlineCircle className={styles.outlineCircle} />
                            <OutlineCross className={styles.outLineCross}/>
                            <Circle className={styles.bottomCircle} />
                            <div className={styles.intercept}>
                                <Circle className={styles.overlapCircle} />
                                <OutlineCircle className={styles.overlapOutlineCircle} />
                            </div>
                            <img src={Cross} width={22} height={22} className={styles.bottomcross}/>
                            <OutlineCross className={styles.bottomOutLineCross}/>
                            <Circle className={styles.circleRight} />
                        </>
                    }
                        <div className={cn(styles.container, {[styles.wide]: wide}, {[styles.view] : (title ? title.includes('View ') : false)})}>
                            {title && (
                                <div className={cn("h5", styles.title)}>{title}</div>
                            )}
                            {React.Children.map(children, child => 
                                React.cloneElement(child, {loaderType, setLoaderType, isChanged, setIsChanged, showLoader, setShowLoader, appName, setAppName })
                            )}
                        </div>
                </div>
            </div>
            </>
        }
        </>
    );
};

export default Page;
