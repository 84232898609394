import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({ items, title, usedFor }) => {

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Rank</div>
          <div className={styles.col}>Player Name</div>
          <div className={styles.col}>Score</div>
          {usedFor === 'instant battle' && <div className={styles.col}>Prize</div>}
        </div>
        {items && items.map((x, index) => (
          <Row usedFor={usedFor} item={x} key={index} index={index} />))}
      </div>
    </div>
  );
};

export default Table;
