import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../../components/Icon";
import {ReactComponent as Arrow} from '../../../../../../media/images/icons/Union.svg'
import Sort from '../../../../../../components/Sort'

const Control = ({className, setPage, value, setValue, data, sort, setSort, sortIndex, setSortIndex, selected, setSelected, filters, action, type, width}) => {


  return (
    <>
      <div className={cn(styles.control, className)}>
        <Sort setPage={setPage} value={value} setValue={setValue} data={data} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selected} setSelected={setSelected} sort={sort} setSort={setSort}>
          <div className={cn(styles.buttons, className, { [styles.active]: (value?.name !== null)})}>
            <span className={styles.sortIcon}>
              <Arrow className={cn(styles.up, { [styles.active]: (value?.value !== null && value?.value === 0)})}/>
              <Arrow className={cn(styles.down, { [styles.active]: (value?.value !== null && value?.value === 1)})}/>
            </span>
            <span className={styles.buttonText}>
              {value?.name ? (value?.name === 'updated_at' ? 'Updated At' : value?.name === 'created_at' ? 'Created At' : value?.name == 'name' ? 'Document Name' : '') : 'Sort'}
            </span>
          </div>
        </Sort>
      </div>
    </>
  );
};

export default Control;
