import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './event.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import Loader from "../../../../components/Loader";
import EmptyState from '../../../../components/EmptyStates'
import { ToastContainer } from 'react-toastify';
import CustomEventTable from '../../../../components/BuildComponents/EventComponent/CustomEventTable'
import SpectreEventTable from '../../../../components/BuildComponents/EventComponent/SpectreEventTable'
import { getDefaultEventAction, getCustomEventAction, filterEventsAction, filterCustomEventsAction, filterDefaultEventsAction } from '../../../../redux/action/builds/events/eventActions'
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const Tabs = ["Specter", "Custom"];

const Events = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");

  const [defaultPage, setDefaultPage] = useState(1)
  const [defaultLimit, setDefaultLimit] = useState(8)
  const [defaultCount, setDefaultCount] = useState()

  const [customPage, setCustomPage] = useState(1)
  const [customLimit, setCustomLimit] = useState(8)
  const [customCount, setCustomCount] = useState()

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])


  let getCustomEvent = () => {
    let getCustomEvent = {
      projectId : appid,
      offset: ((customPage-1)*customLimit),
      limit: customLimit
    }
    dispatch(getCustomEventAction(getCustomEvent))
  }

  let getDefaultEvent = () => {
    let getDefaultEvent = {
      projectId : appid,
      offset: ((defaultPage-1)*defaultLimit),
      limit: defaultLimit
    }
    dispatch(getDefaultEventAction(getDefaultEvent))
  }

  let customeEvents = useSelector((state) => {
    return state.event.customEvents
  })

  let totalCustomEvents = useSelector((state)=>{
    return state.event.totalCustomEventCount
  })

  let totalDefaultvents = useSelector((state)=>{
    return state.event.totalDefaultEventCount
  })

  let defaultEvents = useSelector((state) => {
    return state.event.defaultEvents
  })

  let isLoading = useSelector((state)=>{
    return state.event.isLoading
  })

  let filterEvents = useSelector((state) => {
    return state.event.filteredEvent
  })

  let isSearched = useSelector((state) => {
    return state.event.isSearched
  })

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const handleDefaultPageChange = (event, value)  => {
    setDefaultPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleCustomPageChange = (event, value)  => {
    setCustomPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(()=>{
    getDefaultEvent()
    configureSkeleton()
  }, [defaultPage])

  useEffect(()=>{
    getCustomEvent()
    configureSkeleton()
  }, [customPage])

  useEffect(() => {
    configureSkeleton()
  }, [activeIndex])

  const [searchFlag, setSearchFlag] = useState(false)

  const onSearch = (search) => {
    if(search == ''){
      setSearchFlag(false)
      if(activeIndex == 0){
        getDefaultEvent()
      }else{
        getCustomEvent()
      }
    }else{
      setSearchFlag(true)
      if(activeIndex == 0){
        let searchEvent = {search : search, projectId : appid,offset: ((defaultPage-1)*defaultLimit),limit: defaultLimit}
        dispatch(filterDefaultEventsAction(searchEvent))
      }else{
        let searchEvent = {search : search, projectId : appid,offset: ((customPage-1)*customLimit),limit: customLimit}
        dispatch(filterCustomEventsAction(searchEvent))
      }
    }
  }


  return(
    <>
        <Card layOut={`Get`}  className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Events'} classTitle={cn("title-purple", styles.title)}
          head={
            (
              <>
                <div className={styles.add}>
                  {showSkeleton ?
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                  : !showSkeleton && ((activeIndex === 0 && defaultEvents.length > 0)||(activeIndex === 1 && customeEvents.length > 0)) ?
                    <>
                    {!userAccess &&
                      <div className={styles.createButton}>
                        <Link to={`/create-events/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                      </div>
                    }
                    </>
                  : ''}
                </div>
              </>
            )
          }
        >

        <div className={styles.wrapper}>
          {showSkeleton ?
          <>
            <div className={styles.toggle}>
              <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" height={40}/>
            </div>
            <div className={styles.seprator}></div>
            <div className={styles.options}>
              <div className={styles.filters}>
                <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={74} height={34}/>
                <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={130} height={34}/>
              </div>
              <div className={styles.searchForm}>
                <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={220} height={40}/>
              </div>
            </div>
            <SkeletonTable rows={activeIndex == 0 ? defaultEvents.length : customeEvents.length} column={4} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[200, 100, 100, 40]} colHeight={[25, 25, 25, 40]} />
          </>
          : !showSkeleton ?
          <>
            <div className={styles.toggle}>
              {Tabs && Tabs.map((x, index) => (
                <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                  <p>{x}</p>
                </button>
              ))}
            </div>
            {(activeIndex === 0 && defaultEvents.length == 0)||(activeIndex === 1 && customeEvents.length == 0) ?
              <EmptyState access={userAccess} route={`/create-events/${appid}`} type="Event" />
            : (activeIndex === 0 && defaultEvents.length > 0)||(activeIndex === 1 && customeEvents.length > 0) ?
              <>
                <div className={styles.seprator}></div>
                <div className={styles.options}>
                  <div className={styles.filters}>
                    {/* <div className={styles.buttons}>
                      <Icon name='filter' size='20' className={styles.icon} />
                      <span className={styles.buttonText}>Filter</span>
                    </div> */}
                    <div className={styles.buttons}>
                      <ArchiveSvg className={styles.icon} />
                      <span className={styles.buttonText}>Show Archived</span>
                    </div>
                  </div>
                  <div className={styles.searchForm}>
                    <Form className={styles.form} placeholder="Search Event" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                  </div>
                </div>
                {activeIndex === 0 && 
                  <>
                    {searchFlag ?
                      <>
                      {isSearched && <div className={styles.textCenter}><Loader /></div>}
                      {!isSearched && filterEvents.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
                      {!isSearched && filterEvents.length > 0 && <SpectreEventTable access={userAccess} items={filterEvents} title="Last edited" />}
                      </>
                    : <SpectreEventTable access={userAccess} items={defaultEvents} title="Last edited" />}
                    
                  </>
                }
                {activeIndex === 1 && 
                  <>
                    {searchFlag ?
                      <>
                      {isSearched && <div className={styles.textCenter}><Loader /></div>}
                      {!isSearched && filterEvents.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
                      {!isSearched && filterEvents.length > 0 && <CustomEventTable access={userAccess} items={filterEvents} title="Last edited" />}
                      </>
                    :
                    <CustomEventTable access={userAccess} items={customeEvents} title="Last edited" />
                    }
                  </>
                }
              </>
            : ''}
          </>
          : ''}

          {activeIndex == 0 && 
            <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                      '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(totalDefaultvents/defaultLimit)} color="primary" onChange={handleDefaultPageChange} />
                </Stack>   
              </div>
          }

          {activeIndex == 1 &&
          <div className={styles.pagination}>
            <Stack>
              <Pagination renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                  '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalCustomEvents/customLimit)} color="primary" onChange={handleCustomPageChange} />
            </Stack>  
          </div>
          }
        </div>

        </Card>
        <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about Events <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/events" target="_blank">Click Here</a></p>
        </div>
    </>
  )

}

export default Events