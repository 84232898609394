import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './createPlayer.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createCustomSignupAction, editPlayerProfileAction } from '../../../../../redux/action/engage/Players/PlayersAction'
 
const AddPlayer = ({className, type, onClose, action}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const Navigate = useNavigate()

    const [customId, setCustomId] = useState('')

    const onChangeCustomId= (e) => {
        setCustomId(e.target.value)
    }

    const linkAccount = () => {
        if(action == 'Create Player'){
            let accountDetails = {
                projectId : appid,
                customId : customId
            }
            dispatch(createCustomSignupAction(accountDetails, Navigate, appid))
        }else{
            let userDetail = {
                projectId : appid,
                userId : id,
                customId : customId
            }
            dispatch(editPlayerProfileAction(userDetail, appid, id, null, 0))
        }
        onClose()
    }

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Link Account</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.contentBody}>
                                <TextInput isId={true} value={customId} label='Custom ID' type='text' onChange={(e)=>{onChangeCustomId(e)}} />
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={linkAccount}>Save</button>
                                <button className={styles.cancel} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPlayer