
import { endOfDay, format, startOfDay } from "date-fns";
import { epgChannels } from "./epg-channels";
import { v4 as uuidv4 } from "uuid";
import { faker } from "@faker-js/faker";
import demoData from "../../demoLiveOps.json"

export const getToday = ({
  date = "2022-04-19",
  formatType = "yyyy-MM-dd",
} = {}) => format(new Date(date), formatType);

// function generateData(channelUuid) {
//   const data = [];
//   const currentDate = format(new Date(), "yyyy-MM-dd");
//   const startDate = new Date(`${currentDate}T08:00:00Z`);
//   const endDate = new Date(`${currentDate}T18:00:00Z`);
//   const image = `https://api.dicebear.com/7.x/pixel-art/svg?seed=${faker.internet.displayName()}`;
//   let current = startDate;

//   while (current < endDate) {
//     const since = new Date(current); // Copy the start time
//     since.setSeconds(500, 0); // Set seconds to 0 for exact minute

//     // Random duration from 30 minutes to 2 hours
//     const durationInMinutes = Math.floor(Math.random() * (120 - 30 + 1)) + 30;

//     // Calculate till time by adding the random duration to the start time
//     const till = new Date(since.getTime() + durationInMinutes * 60000);
//     till.setSeconds(0, 0); // Set seconds to 0 for exact minute

//     data.push({
//       channelUuid,
//       id: uuidv4(),
//       title: faker.company.catchPhrase(),
//       description: "",
//       since: since.toISOString(),
//       till: till.toISOString(),
//       image,
//     });

//     current = new Date(till); // Move to the end of the current show
//   }

//   return data;
// }

// export const getResources = () => {
//   const channels = epgChannels;

//   const epg = generateData(channels[0].uuid);
//   const data = channels.flatMap((channel) => generateData(channel.uuid));
//   return { channels, epg: data };
// };
//////////////////////




const TIME_FORMAT = {
  DEFAULT: "yyyy-MM-dd HH:mm:ss",
};

function generateMonthModeData(
  startDate,
  endDate,
  channelId
) {
  const objects = [];

  const start = new Date(startDate);
  const end = new Date(endDate);

  while (start < end) {
    const numMonths = faker.datatype.number({ min: 1, max: 5 });
    const since = startOfDay(
      new Date(start.getFullYear(), start.getMonth(), 1, 0, 0, 0)
    );
    const till = endOfDay(
      new Date(start.getFullYear(), start.getMonth() + numMonths, 0, 23, 59, 59)
    );
    const obj = {
      id: uuidv4(),
      description: faker.hacker.phrase(),
      title: faker.name.jobArea(),
      since: format(since, TIME_FORMAT.DEFAULT).replace(" ", "T"),
      till: format(till, TIME_FORMAT.DEFAULT).replace(" ", "T"),
      channelUuid: channelId,
      image: faker.image.technics(),
    };
    objects.push(obj);

    start.setMonth(start.getMonth() + numMonths);
  }

  return objects;
}

// Month
export const getMonthResources = (startDate, endDate) => {
  const channels = epgChannels;

  const monthData = channels.flatMap(({ uuid }) =>
    generateMonthModeData(startDate, endDate, uuid)
  );

  return { channels: channels, epg: monthData };
};

function generateWeekModeData(
  startDate,
  endDate,
  channelId
) {
  const objects = [];

  const start = new Date(startDate);
  const end = new Date(endDate);

  while (start < end) {
    const numDays = faker.datatype.number({ min: 1, max: 10 });
    const since = startOfDay(
      new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0)
    );
    const till = endOfDay(
      new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + numDays - 1,
        23,
        59,
        59
      )
    );

    const obj = {
      id: uuidv4(),
      description: faker.hacker.phrase(),
      title: faker.name.jobArea(),
      since: format(since, TIME_FORMAT.DEFAULT).replace(" ", "T"),
      till: format(till, TIME_FORMAT.DEFAULT).replace(" ", "T"),
      channelUuid: channelId,
      image: faker.image.technics(),
    };
    objects.push(obj);

    start.setDate(start.getDate() + numDays);
  }

  return objects;
}

// Week
export const getWeekResources = (startDate, endDate) => {
  const channels = epgChannels;

  const weekData = channels.flatMap(({ uuid }) =>
    generateWeekModeData(startDate, endDate, uuid)
  );

  return { channels: channels, epg: weekData };
};

export const getResources2 = (
  startDate,
  endDate,
  type
) => {
  if (type === "month") return getMonthResources(startDate, endDate);

  const data = getWeekResources(startDate, endDate);
  return data;
};


//Demo Data try





function generateData(channelUuid) {
  const data = [];
  const dat =demoData.data.liveOps;
  for (let index = 0; index < dat.length; index++) {
    data.push({
      channelUuid,
      id: dat[index].id,
      title: dat[index].competition.name,
      since: dat[index].startDate,
      till:  dat[index].endDate,
    });
  }

  return data;
}

export const getResources = () => {
  const channels = epgChannels;

  const epg = {uuid: "368358e0-25ca-4ec9-90c6-db659968f8a2",}
  const data =  generateData(epg.uuid);
  return { channels:[epg], epg: data };
};

