import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './addStepsModal.module.sass'
import Dropdown from '../../../../../Dropdown'
import TaskDropdown from '../../../../../Dropdown/TaskDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTaskAction } from '../../../../../../redux/action/engage/Achievement/Task/TaskAction'
import { useParams } from 'react-router-dom'
// import CreateTask from '../../../../../../screens/Engage/Achievement/Tasks/CreateTask'
import CreateTask from './CreateTaskInfo'
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle'
 
const AddStepsModal = ({taskType, modalFor, onClose,className, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setValue, setStepSeries}) => {

    const options = [{id : 1, name : 'Choose Exsisting Task'}, {id : 2, name : 'Create New Task'}]
    const [taskOptions, setTaskOptions] = useState([])
    const [task, setTask] = useState(false)
    const [taskId, setTaskId] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [taskName, setTaskName] = useState(null)

    const dispatch = useDispatch()
    const {appid} = useParams()


    useEffect(() => {
        getAllTask()
    }, [])

    let tasks = useSelector((state) => {
        return state.task.tasks
    })

    const getAllTask = async() => {
        let getTask = {
            projectId : appid,
            ids : [],
            offset : 0,
            limit : 100
         }

        await dispatch(getAllTaskAction(getTask))
    }

    const configTask = () => {
        setTaskOptions(tasks)
    }

    useEffect(()=>{
        configTask()
    }, [tasks])

    useEffect(()=>{
        if (task==true) {
            setTaskName(null)
            setSelectedTask(null)
        }
    }, [task])

    return (
        <>
            <div className={styles.addStepsModal}>
                <div className={styles.addStepsContainer}>
                    <div className={styles.addStepsHeader}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>{modalFor == 'Mission' ? 'Configure Task' : 'Configure Step'}</span>
                        </div>
                    </div>
                    <div className={styles.addStepsBody}>
                        <div className={styles.addStepsBodyContainer}>
                                    {/* {task==false&&  */}
                                    <div className={styles.group}>
                                        <div className={styles.field}>
                                            <TaskDropdown tooltip={TooltipTitle.SSAddTask} label='Choose Existing Task' setTask={setTask} type={"task"} placeholder='Select Existing Task' options={taskOptions} value={taskName} setValue={setTaskName} details={selectedTask} setDetail={setSelectedTask}/>
                                        </div>
                                    </div>
                                    {/* } */}
                                    {task==false&& selectedTask != null &&
                                        <div className={styles.group}>
                                            <CreateTask taskType={taskType} modalFor={modalFor} onClose={onClose} task={selectedTask} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries}/>
                                        </div>
                                    }
                            {task &&
                                <CreateTask taskType={taskType} modalFor={modalFor} onClose={onClose} task={null} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries}/>
                            }
                        </div>
                    </div>
                    <div className={styles.addStepsFooter}></div>
                </div>
            </div>
        </>
    )
}

export default AddStepsModal