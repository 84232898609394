import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../../../Checkbox";
import Icon from "../../../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../../media/images/icons/remove.svg'
import Bundles from '../../../../../../../media/images/placeholders/Bundles.png'
import { useEffect } from "react";
import CurrencyTooltip from '../../../../../../CustomTooltips/CurrencyTooltip'
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({usedFor, tableFor, item, items, index, setValue, setData }) => {
    
    const {appid} = useParams()
    const addContent = (item, index) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, bundleId : item.id, quantity : null, displayId : item.bundleId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, bundleId : item.id, quantity : null, displayId : item.bundleId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('task-rewards', JSON.stringify(content))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, bundleId : item.id, quantity : null, displayId : item.bundleId, priceList: item.inventoryBundlesPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('link-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, bundleId : item.id, quantity : null, displayId : item.bundleId, priceList: item.inventoryBundlesPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('contents', JSON.stringify(content))
            }
        }
    }

    const RemoveContent = (item, id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let removedReward = [...content]
                // let remainingRewards = removedReward.filter((e,i,c)=> {
                //     return e.bundleId != id
                // })
                let remainingRewards = removedReward && removedReward.map(e => e.bundleId === id ? {...e, archive: true} : e)
                // setValue(remainingRewards)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let removedReward = [...content]
                // let remainingRewards = removedReward.filter((e,i,c)=> {
                //     return e.bundleId != id
                // })
                let remainingRewards = removedReward && removedReward.map(e => e.bundleId === id ? {...e, archive: true} : e)
                // setValue(remainingRewards)
                sessionStorage.setItem('task-rewards', JSON.stringify(remainingRewards))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let removedReward = [...content]
                // let remainingRewards = removedReward.filter((e,i,c)=> {
                //     return e.bundleId != id
                // })
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.bundleId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('link-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let removedReward = [...content]
                // let remainingRewards = removedReward.filter((e,i,c)=> {
                //     return e.bundleId != id
                // })
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.bundleId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('contents', JSON.stringify(remainingRewards))
            }
        }
    }

    const onInputChange = (e,id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.bundleId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(updatedArray))
            }else{
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.bundleId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('task-rewards', JSON.stringify(updatedArray))
            }
        }else{
           if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-rewards'))
                const updatedArray = content.map(item => {
                    if (item.bundleId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                // setValue(updatedArray)
                sessionStorage.setItem('link-rewards', JSON.stringify(updatedArray))
           }else{
            let itemList = [...items]
            itemList[index].quantity = parseInt(e.target.value)
            setData(itemList)
            const content = JSON.parse(sessionStorage.getItem('contents'))
            const updatedArray = content.map(item => {
                if (item.bundleId === id) {
                return { ...item, quantity: parseInt(e.target.value) };
                }
                return item;
            });
            // setValue(updatedArray)
            sessionStorage.setItem('contents', JSON.stringify(updatedArray))
           }
        }
    }

    useEffect(()=>{

    }, [item])

    return (
        <>
            <div className={styles.row}>
                {item.status == false ?
                    <div className={styles.col}><Add className={styles.addIcon} onClick={()=>{addContent(item, index)}}/></div>
                :item.status == true ?
                    <div className={styles.col}><Remove className={styles.addIcon} onClick={()=>{RemoveContent(item, item.id)}}/></div>
                : ''}
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.modalPreview}>
                            <img src={item.iconUrl != null ? item.iconUrl : Bundles} alt="Bundle" />
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.bundleId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.modalItemName}>{item.name}</div><div className={styles.modalItemId}>{item.bundleId}</div></div>
                <div className={styles.col}>
                    {item.inventoryBundlesPrices && item.inventoryBundlesPrices.slice(0,1).map((e,i)=>{
                        if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                            return (
                                <>
                                    <div className={styles.prices}>
                                        <span className={e.priceTypes == 'virtual currency' ? styles.virtual : styles.real}>{e.currency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryBundlesPrices && item.inventoryBundlesPrices.length > 1 && i === 0) ?
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryBundlesPrices}><span className={styles.count}>+ {item.inventoryBundlesPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }else if(e.priceTypes == 'IAP'){
                            return(
                                <>
                                    <div className={styles.prices}>
                                        <span className={styles.IAP}>{e.realWorldCurrency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 1 && i === 0) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }
                    })} 
                </div>
                <div className={styles.col}><div className={styles.rewardsInput}><input disabled={item.status == false ? true : false} key={item.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={items[index].status == false ? '' : items[index].quantity} onChange={(e)=>{onInputChange(e, item.id)}}/></div></div>
            </div>
        </>
    );
};

export default Row;
