export const routes = {
    AppOverview : {path : '/app-overview/:appid'},
    AppMembers : {path : '/app-members/:appid'},
    API : {path : '/app-api/:appid'},
    MemberInviteSetPassword : {path : '/members/set-password'},
    Media : {path : '/app-media/:appid'},
    Documents : {path : '/app-documents/:appid'},
    CreateDocuments : {path : '/create-document/:appid'},
    EditDocuments : {path : '/edit-document/:appid/:id'},
    MediaFolders : {path : '/media/folders/:folderName/:appid'},
    
    AppSettingsInfo : {path : '/app-information/:appid'},
    AppInfo : {path : '/env-information/:appid'},
    Terminal : {path : '/terminal/:appid'},

    Games : {path : '/games/:appid'},
    ViewGame : {path : '/view-game/:appid/:id'},
    CreateGame : {path : '/create-game/:appid'},
    EditGames : {path : '/edit-game/:appid/:id'},

    Matches : {path : '/matches/:appid'},
    ViewMatch : {path : '/view-match/:appid/:id'},
    CreateMatch : {path : '/create-match/:appid'},
    EditMatch : {path : '/edit-match/:appid/:id'},

    Currency : {path : '/currency/:appid'},
    ViewCurrency : {path : '/view-currency/:appid/:id'},
    CreateCurrency : {path : '/create-currency/:appid'},
    EditCurrency : {path : '/edit-currency/:appid/:id'},

    Items : {path : '/item/:appid'},
    ViewItem : {path : '/view-item/:appid/:id'},
    CreateItems : {path : '/create-item/:appid'},
    EditItems : {path : '/edit-item/:appid/:id'},

    Bundles : {path : '/bundle/:appid'},
    ViewBundle : {path : '/view-bundle/:appid/:id'},
    CreateBundles : {path : '/create-bundle/:appid'},
    EditBundles : {path : '/edit-bundle/:appid/:id'},

    Stores : {path : '/store/:appid'},
    ViewStore : {path : '/view-store/:appid/:id'},
    CreateStore : {path :'create-store/:appid'},
    ViewStores: {path : '/view-store/:appid/:id'},
    EditStores: {path : '/edit-store/:appid/:id'},

    GetProgressionMarker : {path : '/progression-marker/:appid'},
    ViewProgressionMarker : {path : '/view-progression-marker/:appid/:id'},
    CreateProgressionMarker : {path : '/create-progression-marker/:appid'},
    EditProgressionMarker : {path : '/edit-progression-marker/:appid/:id'},

    GetProgressionSystem : {path : '/progression-system/:appid'},
    ViewProgressionSystem : {path : '/view-progression-system/:appid/:id'},
    CreateProgressionSystem : {path : '/create-progression-system/:appid'},
    EditProgressionSystem : {path : '/edit-progression-system/:appid/:id'},

    Events : {path : '/events/:appid'},
    CreateEvents : {path : '/create-events/:appid'},
    ViewCustomEvents : {path: '/view-custom-events/:appid/:id'},
    EditCustomEvents : {path: '/edit-custom-events/:appid/:id'},
    ViewDefaultEvents : {path : '/view-default-events/:appid/:id'},
    EditDefaultEvents : {path : '/edit-default-events/:appid/:id'},

    Task : {path : '/task/:appid'},
    ViewTask : {path : '/view-task/:appid/:id'},
    CreateTask : {path : '/create-task/:appid'},
    EditTask : {path : '/edit-task/:appid/:id'},

    StepSeries : {path : '/step-series/:appid'},
    ViewStepSeries : {path : '/view-step-series/:appid/:id'},
    CreateStepSeries : {path : '/create-step-series/:appid'},
    EditStepSeries : {path : '/edit-step-series/:appid/:id'},

    Missions : {path : '/mission/:appid'},
    ViewMission : {path : '/view-mission/:appid/:id'},
    CreateMission : {path : '/create-mission/:appid'},
    EditMission : {path : '/edit-mission/:appid/:id'},

    GetPlayers : {path : '/players/:appid'},
    PlayerDetails : {path : '/player-details/:appid/:id'},

    Leaderboard : {path : '/leaderboard/:appid'},
    ViewLeaderboard : {path : '/view-leaderboard/:appid/:id'},
    CreateLeaderboard : {path : '/create-leaderboard/:appid'},
    EditLeaderboard : {path : '/edit-leaderboard/:appid/:id'},
    LeaderboardResult : {path : '/leaderboard-details/:appid/:id'},
    CompetitionResult : {path : '/competition-result/:appid/:id'},
    CompetitionInstantBattleResult : {path : '/instant-battle-result/:appid/:id'},
    Calendar : {path : '/calendar/:appid'},
    CompetitionSchedule : {path : '/competition-schedule/:appid'},
    ViewCompetitionSchedule : {path : '/view-competition-schedule/:appid/:id'},
    CreateCompetitionSchedule : {path : '/create-competition-schedule/:appid'},
    EditCompetitionSchedule : {path : '/edit-competition-schedule/:appid/:id'},

    CompetitionTournament : {path : '/competition-tournament/:appid'},
    ViewCompetitionTournament : {path: '/view-competition-tournament/:appid/:id'},
    CreateCompetitionTournament : {path: '/create-competition-tournament/:appid'},
    EditCompetitionTournament : {path: '/edit-competition-tournament/:appid/:id'},

    CompetitionInstantBattle : {path : '/competition-instant-battle/:appid'},
    ViewCompetitionInstantBattle : {path: '/view-competition-instant-battle/:appid/:id'},
    CreateCompetitionInstantBattle : {path: '/create-competition-instant-battle/:appid'},
    EditCompetitionInstantBattle : {path: '/edit-competition-instant-battle/:appid/:id'},

    Community : {path : 'community/:appid'},
    // AppMembers : {path : '/app-members/:appid'},
    // API : {path : '/API/:appid'},

    Dashboard : {path : '/dashboard'},
    Apps : {path : '/apps'},
    OrganisationProfile : {path : '/settings-Organization-profile'},
    UserProfile : {path : '/settings-member-profile'},
    OrganisationMembers : {path : '/access-manager'},
    
    Login : {path : '/'},
    SignUp : {path : '/sign-up'},
    EmailValidate : {path : '/email-verification'},
    ResetPassword : {path : '/reset-password'},
    ForgotPassword : {path : '/forgot-password'},
    MemberInviteSetPassword : {path : '/members/set-password'},
}