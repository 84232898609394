import React from 'react'
import TaskInformation from './TaskInformation'
import styles from './createTask.module.sass'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const CreateTask = ({taskType, modalFor, onClose, task, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setValue, setStepSeries}) => {

    return(
        <>
            <ToastContainer />
            <div className={styles.row}>
                <div className={styles.col}>
                    <TaskInformation taskType={taskType} modalFor={modalFor} onClose={onClose} task={task} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} className={styles.card}/>
                </div>
            </div>
        </>
    )

}

export default CreateTask