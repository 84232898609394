import { GET_MEDIA, GET_MEDIA_SUCESS, GET_MEDIA_ERROR, ADD_MEDIA, ADD_MEDIA_SUCESS, ADD_MEDIA_ERROR, DELETE_MEDIA, DELETE_MEDIA_SUCESS, DELETE_MEDIA_ERROR } from "../../../actionTypes/actionTypes";

const initialState = {
    media : [],
    isLoading : false,
    error : null,
    totalCount : null
};

export const mediaReducer = (state=initialState, action) => {
    switch(action.type){

        case GET_MEDIA : {
            return {
                ...state,
                media: [],
                isLoading : true,
                error : null
            }
        }

        case GET_MEDIA_SUCESS : {
            return {
                ...state,
                media: action.payload,
                isLoading : false,
                error : null
            }
        }

        case GET_MEDIA_ERROR : {
            return {
                ...state,
                media: [],
                isLoading : false,
                error : action.payload,
            }
        }

        case ADD_MEDIA_SUCESS : {
            return {
                ...state,
                media: [...state.media, action.payload],
                isLoading : false,
                error : null
            }
        }

        case ADD_MEDIA_ERROR : {
            return {
                ...state,
                media: [],
                isLoading : false,
                error : action.payload,
            }
        }

        case DELETE_MEDIA_SUCESS : {
            return {
                ...state,
                media: action.payload,
                isLoading : false,
                error : null
            }
        }

        case DELETE_MEDIA_ERROR : {
            return {
                ...state,
                media: [],
                isLoading : false,
                error : action.payload,
            }
        }
        
        default: {
            return {
                ... state
            }
        }
    }    
}    