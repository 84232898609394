import React from "react";
import cn from "classnames";
import styles from "./form.module.sass";
import Icon from "../Icon";

const Form = ({className, onChange, as, onSubmit, placeholder, value, type, name, icon, size}) => {
  
  return (
    <form className={size == 'sm' ? cn(className, styles.formSm) : cn(className, styles.form)} onSubmit={onSubmit}>
      <input className={(as && as == 'players') ? styles.inputWhite : styles.input} type={type} onChange={onChange} name={name} value={value} placeholder={placeholder} required />
      <button className={styles.result}>
        <Icon name={icon} size="24" />
      </button>
    </form>
  );
};

export default Form;
