import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducer/rootreducer/rootReducer'

const initialState = {};
const middleWare = [thunk];

const persistConfig = {
    key: 'persist-key',
    version : 1,
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleWare)))
const persistor = persistStore(store)

export default store
export {persistor}