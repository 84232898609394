import { GET_TASK, CREATE_TASK, EDIT_TASK, FILTER_TASK_SUCCESS, GET_PARAM_VALUES, ARCHIVE_TASK, DELETE_TASK, GET_TASK_SUCCESS, GET_TASK_ERROR, FILTER_TASK_ERROR} from "../../../../actionTypes/actionTypes";

const initialState = {
    tasks : [],
    paramValues:[],
    isLoading : false,
    isFiltered : false,
    totalCount : null
};

export const taskReducer =(state=initialState, action)=>{
    switch(action.type){

        case GET_TASK_SUCCESS : {
            return {
                ...state,
                tasks: action.payload,
                isLoading : false,
                isFiltered: false,
                totalCount : action.totalCount,
                errors: null
            }
        }

        case GET_TASK_ERROR : {
            return {
                ...state,
                isLoading : false,
                error : action.payload,
            }
        }

        case FILTER_TASK_SUCCESS : {
            return {
                ...state,
                tasks : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_TASK_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case CREATE_TASK : {
            return {
                ...state,
                tasks: action.payload,
            }
        }

        case EDIT_TASK : {
            return {
                ...state,
                tasks: action.payload,
            }
        }

        case GET_PARAM_VALUES : {
            return {
                ...state,
                paramValues: action.payload,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    