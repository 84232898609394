import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import GrantPM from "../../../../../../components/EngageComponents/PlayersComponent/Modals/CreatePM";

const Control = ({className, access, type, width}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div className={styles.controlDiv}>
              <span>Grant Progression Marker</span>{!access && <span onClick={x.action} className={styles.addIcon}><AddMetaIcon /></span>}
            </div>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <GrantPM onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
