import React, { useState } from "react";
import { FieldSelectorProps, OperatorSelectorProps } from "react-querybuilder";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./CustomFieldSelector.module.sass";
import TooltipTitle from "../../../../../../../Tooltip/TooltipTitle";
import Tooltips from "../../../../../../../components/Tooltip";

const Dropdown = (FieldSelectorProps, className, classLabel, classDropdownHead, small, upBody ) => {

    const [visible, setVisible] = useState(false);
    const fieldValue = FieldSelectorProps?.value?.name || '';
    const [filterText, setFilterText] = useState(fieldValue);

    const handleClick = (event) => {
        let field = { 
            name: event.name, 
            id: event.id, 
            // dataType: event.dataType ? event.dataType.dataTypeName : undefined, 
            dataType: event.inputType ? event.inputType :event.dataType.dataTypeName?event.dataType.dataTypeName: undefined,
            type: event.type 
        };
        FieldSelectorProps.handleOnChange(field);
        setVisible(false);
        setFilterText(fieldValue);
    };

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };


    return (
        <div className={styles.dropdowns}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(fieldValue); }}>
                <div className={cn(classLabel, styles.label)}>
                    <span>Select Parameter<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskParameter} icon="info" /></span></span>
                </div>
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible })}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder="Select Parameter"
                                value={filterText }
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                            />
                        </div>
                    </div>
                    {visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {FieldSelectorProps?.options?.map((x, index) => (
                                x.name.toLowerCase().includes(filterText.toLowerCase()) && (
                                    <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === FieldSelectorProps?.value?.name })} key={index}>
                                        {x.name}
                                    </div>
                                )
                            ))}
                            {FieldSelectorProps?.options?.filter(x => x.name.toLowerCase().includes(filterText.toLowerCase())).length === 0 && (
                                <div className={styles.option} style={{ textAlign: "center" }}>
                                    No matching parameters
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
}

export default Dropdown;
