import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../Checkbox";
import Row from "./Row";
import {ReactComponent as AddMetaIcon} from '../../media/images/icons/add_meta.svg'
import { Suspense } from "react";

const Table = ({items, access, title, type, setValue }) => {

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  
  const desiredLength = 8;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Icon</div>
          <div className={styles.col}>Display Name</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Currency Code</div>
          <div className={styles.col}>Currency Type</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => (
          <Row access={access} setValue={setValue} type={type} item={x} key={index} index={index} value={selectedFilters.includes(x.id)} onChange={() => handleChange(x.id)} />
        ))}
      </div>
    </div>
  );
};

export default Table;
