import React, { useState, useEffect } from 'react'
import styles from './tooltip.module.sass'
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

const Tooltip = ({className, errors, children, position}) => {

    const [visible, setVisible] = useState(false);

    return (
       <>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>{children}</div>
                    {errors?.length > 0 &&
                        <div className={cn(styles.tooltipContainer, className, {[styles.invert] : position === 'bottom-left'},  {[styles.normal] : position === 'normal'}, {[styles.left] : position === 'left'})}>
                            {errors && errors.map((e, i) => {
                                return(
                                    <>
                                        <div className={styles.text}>
                                            {e}  
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    }
                </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip