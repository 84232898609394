import {BASE_URL, GET_MATCHES, ADD_MATCHES, EDIT_MATCHES, GET_LEADERBOARD_MATCHES} from '../../../resources/APIEndpoints'
import axios from '../../../utils/interceptor'

function matchServices() {
    this.getAllMatches = (body) => axios.post(BASE_URL + GET_MATCHES, body)
    this.getAllLeaderboardMatches = (body) => axios.post(BASE_URL + GET_LEADERBOARD_MATCHES, body)
    this.addMatch = (body) => axios.post(BASE_URL + ADD_MATCHES, body)
    this.editMatch = (body) => axios.post(BASE_URL + EDIT_MATCHES, body)
    this.archiveMatch = (body) => axios.post(BASE_URL + EDIT_MATCHES, body)
}

export default new matchServices()