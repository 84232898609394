import React, { useContext, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ConfirmNavigationModal from "../ConfirmNavigationModal";
import Modal from "../Modals/ModalComponent";
import Icon from "../Icon";
import User from "./User";
import { useEffect } from "react";
import Theme from "../Theme";
import { ReactComponent as LeftArrow } from "../../media/images/leftArrow.svg";
import AccessWarningHeader from "../AccessWarningHeader";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";

const Header = ({className, onOpen, isChanged, setIsChanged}) => {

  const {appid} = useParams()

  const [visible, setVisible] = useState(false);
  const [mobile, setMobile] = useState()
  const [visibleModal, setVisibleModal] = useState(false);
  const [lnk, setLnk] = useState()

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  useEffect(()=>{
    setMobile(isMobile)
  }, [])

  const navigation = [
    {
      title: "Currencies",
      url: `/currency/${appid}`,
    },
    {
      title: "Items",
      url: `/item/${appid}`,
    },
    {
      title: "Bundles",
      url: `/bundle/${appid}`,
    },
    {
      title: "Stores",
      url: `/store/${appid}`,
    }
  ]

  const progressionNavigation = [
    {
      title: "Progression Markers",
      url: `/progression-marker/${appid}`,
    },
    {
      title: "Progression Systems",
      url: `/progression-system/${appid}`,
    },
  ]

  const engageNavigation = [
    {
      title: "Tasks",
      url: `/task/${appid}`,
    },
    {
      title: "Missions",
      url: `/mission/${appid}`,
    },
    {
      title: "Step Series",
      url: `/step-series/${appid}`,
    }
  ]

  const competitionNavigation = [
    {
      title: 'Tournaments',
      url : `/competition-tournament/${appid}`
    },
    {
      title : 'Instant-Battles',
      url : `/competition-instant-battle/${appid}`
    }
  ]
  
  const appSettingsNavigation = [
    {
      title : 'App Information',
      url : `/app-information/${appid}`
    },
    {
      title: "Media",
      url: `/app-media/${appid}`,
    },
    {
      title : 'Members',
      url : `/app-members/${appid}`
    },
    {
      title : 'API',
      url : `/app-api/${appid}`
    }
  ]

  const LiveOpsNavigation = [
    {
      title : 'Competitions',
      url : `/competition-schedule/${appid}`
    },
    {
      title : 'Calendar',
      url : `/calendar/${appid}`
    }
  ]

  const ProfileSettingsNavigation = [
    {
      title : 'Organization',
      url : `/settings-organization-profile`
    },
    {
      title : 'Member',
      url : `/settings-member-profile`
    }
  ]

  const Navigate = useNavigate();
  const { pathname } = useLocation();

  const NavigateTo = (e) => {
      if(e.target.value == 1){
        Navigate('/currency')
      }else if(e.target.value == 2){
        Navigate('/item')
      }else if(e.target.value == 3){
        Navigate('/bundle')
      }else if(e.target.value == 4){
        Navigate('/reward-set')
      }else{
        Navigate('/')
      }
  }

  const NavTo = (x) => {
    if (isChanged==true) {
      setLnk(x)
      setVisibleModal(true);
    } else {
      Navigate(x)
    }
  }

  const onCls = () => {
    setVisibleModal(false)
    setLnk()
  }

  const onProceed = () => {
    setVisibleModal(false)
    Navigate(lnk)
    setIsChanged(false)
  }
  
  const [progress, setProgress] = useState(true)

  const globalStates = useContext(GlobalStateContext)

  const configureProgress = () => {
    setProgress(globalStates.bulkUploadProcess)
  }

  useEffect(() => {
    configureProgress()
  }, [globalStates.bulkUploadProcess])

  return (
    <>
      <header className={cn(styles.header, className, {[styles.progress]: progress})}>
        <div className={styles.mainHeader}>
            <button className={styles.burger} onClick={() => handleClick()}></button>
            <div className={styles.mobile}>
            {mobile == true && (window.location.pathname == '/economy' || window.location.pathname == '/currency' || window.location.pathname == '/item' || window.location.pathname == '/bundle' || window.location.pathname == '/reward-set') ?
              <div className={styles.NavDropdown}>
                  <select className={styles.mobileNavDropdow} onChange={(e)=>{NavigateTo(e)}}>
                    <option value={1}>Currency</option>
                    <option value={2}>Items</option>
                    <option value={3}>Bundles</option>
                    <option value={4}>Reward Set</option>
                  </select>
              </div>
            : ''}
            </div>
            {(window.location.pathname.includes('/app-information/')|| window.location.pathname.includes('/app-media/')|| window.location.pathname.includes('/app-members/')|| window.location.pathname.includes('/app-api/'))?
              <>
                <button className={styles.headHelp} style={{marginRight:"24px"}} onClick={()=>NavTo(`/app-overview/${appid}`)}>
                  <Icon name={"arrow-left"} size={18}/>
                </button>
              </>
            :(window.location.pathname.includes(`/${appid}`)&&!(window.location.pathname.includes('/app-information/')|| window.location.pathname.includes('/app-media/')|| window.location.pathname.includes('/app-members/')|| window.location.pathname.includes('/app-api/')))?
              <>
                <button className={styles.headHelp} style={{marginRight:"24px"}} onClick={()=>NavTo(`/app-information/${appid}`)}>
                  <Icon name={"setting"} size={16}/>
                </button>
              </>
            :
            ''
            }
            
            <div className={styles.headContent}>
              <div className={styles.control} onClick={() => setVisible(false)}>
                <User className={styles.user} />
              </div>
            </div>
        </div>
        <Modal visible={visibleModal} onClose={() => onCls()}>
          <ConfirmNavigationModal onProceed={()=>onProceed()} onClose={() => onCls()}/>
        </Modal>
        {(window.location.pathname.includes('/currency') || window.location.pathname.includes('/create-currency') || window.location.pathname.includes('/edit-currency') || window.location.pathname.includes('/item') || window.location.pathname.includes('/create-item') || window.location.pathname.includes('/edit-item') || window.location.pathname.includes('/bundle/') || window.location.pathname.includes('/create-bundle') || window.location.pathname.includes('/edit-bundle') || window.location.pathname == '/reward-set' || window.location.pathname == '/create-reward-set' || window.location.pathname.includes('/store') || window.location.pathname.includes('/create-store') || window.location.pathname.includes('/edit-store')) ?
                <div className={styles.subdropdown}>
                  {navigation && navigation.map((x,index)=>{
                    return(
                      <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                      onClick={()=>NavTo(x.url)} key={index}>
                          {x.title}
                      </div>
                    )
                  })}
              </div>
        : ''}

        {(window.location.pathname.includes('/progression-marker/') ||  window.location.pathname.includes('/create-progression-marker') || window.location.pathname.includes('/edit-progression-marker') ||  window.location.pathname.includes('/progression-system') ||  window.location.pathname.includes('/create-progression-system')) || window.location.pathname.includes('/edit-progression-system') ?
                <div className={styles.subdropdown}>
                  {progressionNavigation && progressionNavigation.map((x,index)=>{
                    return(
                      <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                      onClick={()=>NavTo(x.url)} key={index}>
                          {x.title}
                      </div>
                    )
                  })}
              </div>
        : ''}
        
        {(window.location.pathname.includes('/task') || window.location.pathname.includes('/create-task') || window.location.pathname.includes('/edit-task') || window.location.pathname.includes('/step-series') || window.location.pathname.includes('/create-step-series') || window.location.pathname.includes('/edit-step-series') || window.location.pathname.includes('/mission') || window.location.pathname.includes('/create-mission') || window.location.pathname.includes('/edit-mission')) ?
                <div className={styles.subdropdown}>
                  {engageNavigation && engageNavigation.map((x,index)=>{
                    return(
                      <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                      onClick={()=>NavTo(x.url)} key={index}>
                          {x.title}
                      </div>
                    )
                  })}
              </div>
        : ''}

      {(window.location.pathname.includes('/competition-tournament') || window.location.pathname.includes('/competition-instant-battle') ||  window.location.pathname.includes('/create-competition-tournament') ||  window.location.pathname.includes('/create-competition-instant-battle') || window.location.pathname.includes('/edit-competition-tournament') ||  window.location.pathname.includes('/edit-competition-instant-battle')) ?
              <div className={styles.subdropdown}>
                {competitionNavigation && competitionNavigation.map((x,index)=>{
                  return(
                    <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                    onClick={()=>NavTo(x.url)} key={index}>
                        {x.title}
                    </div>
                  )
                })}
            </div>
      : ''}
      {(window.location.pathname.includes('/competition-schedule')||window.location.pathname.includes('/edit-competition-schedule')||window.location.pathname.includes('/create-competition-schedule')||window.location.pathname.includes('/calendar') ) ?
              <div className={styles.subdropdown}>
                {LiveOpsNavigation && LiveOpsNavigation.map((x,index)=>{
                  return(
                    <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                    onClick={()=>NavTo(x.url)} key={index}>
                        {x.title}
                    </div>
                  )
                })}
            </div>
      : ''}
  
      {/* {(window.location.pathname.includes('/env-information') || window.location.pathname.includes('/media') || window.location.pathname.includes('/app-members') || window.location.pathname.includes('/api') ) ?
              <div className={styles.subdropdown}>
                {appSettingsNavigation && appSettingsNavigation.map((x,index)=>{
                  return(
                    <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
                    onClick={()=>NavTo(x.url)} key={index}>
                        {x.title}
                    </div>
                  )
                })}
            </div>
      : ''} */}

      {(window.location.pathname.includes('/settings-organization-profile') || window.location.pathname.includes('/settings-member-profile') ) ?
        <div className={styles.subdropdown}>
          {ProfileSettingsNavigation && ProfileSettingsNavigation.map((x,index)=>{
            return(
              <div className={cn(styles.item, {[styles.active]: pathname.slice(1).includes(x.url.slice(1))})}
              onClick={()=>NavTo(x.url)} key={index}>
                {x.title}
              </div>
            )
          })}
        </div>
        : ''}
      </header>
      
      {window.location.pathname.includes('/view') &&
        <AccessWarningHeader />
      }

    </>
  );
};

export default Header;
