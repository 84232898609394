import react, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './appDetails.module.sass'
import AppIconPlaceholder from '../../../media/images/placeholders/App.png'
import {ReactComponent as Category} from '../../../media/images/icons/category.svg'
import {ReactComponent as Genre} from '../../../media/images/icons/Genre_Icon.svg'
import {ReactComponent as Platform} from '../../../media/images/icons/platform.svg'
import Icon from '../../../components/Icon'
import { toast, ToastContainer } from 'react-toastify'
import Toast from '../../../components/Toast'
import { Link, useParams } from 'react-router-dom'
import CustomTooltip from '../../../components/CustomTooltips/ReusableTooltip'
import { ReactComponent as  ServerIcon} from "../../../media/images/icons/server_icon.svg";

const AppDetails = ({className, data}) => {

    const {appid} = useParams()

    const copyUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = (data.id != null || data.id == '') ? data.id : '-';
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
    };

    const [environment, setEnvironment] = useState()
    const getLocalStorageData = () => {
        const env = localStorage.getItem('environment')
        setEnvironment(env == 'Staging' ? 'Quality Assurance' : env)
    }

    useEffect(()=>{
        getLocalStorageData()
    }, [])

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)}>
                    <div className={styles.quickAppOverview}>
                        <div className={styles.wrapper}>
                            <div className={styles.appIcon}>
                                <img src={(data.iconUrl != null || data.iconUrl == '') ? data.iconUrl : AppIconPlaceholder} />
                            </div>
                            <div className={styles.appInformation}>
                                <div className={styles.appName}>
                                    <div className={styles.name}>{(data.name != null || data.name == '') ? data.name : '-'} <Link to={`/env-information/${appid}`}><Icon name='setting' size='16' /></Link></div>
                                    <div className={styles.env}>
                                        <div className={environment == 'Production' ? styles.production : environment == 'Quality Assurance' ? styles.staging : styles.develop}><ServerIcon className={styles.logo} /></div>
                                        <div className={styles.server}>{environment}</div>
                                    </div>
                                </div>
                                <div className={styles.id}><span className={styles.title}>App ID :</span>{(data.id != null || data.id == '') ? data.id : '-'} <span className={styles.copyToClipboard} onClick={copyUIDClipboard}><Icon name='copy' size='16' /></span></div>
                                <div className={styles.appInfo}>
                                    <div className={styles.category}>
                                        <div className={styles.categoryIcon}><Category /></div>
                                        <div className={styles.categoryName}><span className={styles.textBold}>Category: </span>{(data.projectCategoryMaster && data.projectCategoryMaster != null && data.projectCategoryMaster != '' && data.projectCategoryMaster.categoryName != '') ? data.projectCategoryMaster.categoryName : '-' }</div>
                                    </div>
                                    <div className={styles.genre}>
                                        <div className={styles.genreIcon}><Genre /></div>
                                        <div className={styles.genreNames}>
                                            <div className={styles.textBold}>Genre:&nbsp;</div>
                                            {data.genres && data.genres != null && data.genres.length > 0 ?
                                                <>
                                                    <div className={styles.genreContainer}>
                                                        {data.genres && data.genres.slice(0,2).map((e,i) => {
                                                            return(
                                                                <>
                                                                    <span className={styles.genreName}>{e.genreName.charAt(0).toUpperCase() + e.genreName.slice(1)}</span>
                                                                    <span>{data.genres.length == 1 ? '' : (data.genres.length > 1 && i < 1) ? ',' : ''}</span>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                    <CustomTooltip text="Genre" data={data.genres && data.genres.map((e,i) => ({id : e.id, name : e.genreName}))}><span className={styles.tooltipValues}>{data.genres.length > 2 ? <span>&nbsp;&#43;{data.genres.length-2}</span>  : ''}</span></CustomTooltip>
                                                </>
                                            : 'No Genre Available'}
                                        </div>
                                        {/* <div className={styles.genreName}><span className={styles.textBold}>Genre: </span>{(data.genres && data.genres != null && data.genres.length > 0) ? <>{data.genres && data.genres.map((e, index) => {return(<><span className={styles.genreName}>{e.genreName.charAt(0).toUpperCase() + e.genreName.slice(1)}</span><span>{index !== data.genres.length - 1 && ','}</spn></>)})}</> : 'No Genre Present'}</div> */}
                                    </div>
                                    <div className={styles.platform}>
                                        <div className={styles.platformIcon}><Platform /></div>
                                        <div className={styles.platformName}><span className={styles.textBold}>Platform:&nbsp;</span>{(data.projectPlatformDetails && data.projectPlatformDetails != null && data.projectPlatformDetails.length > 0) ? <>{data.projectPlatformDetails && data.projectPlatformDetails.map((e, index) => {return(<><span>{e.platformId == 1 ? 'Android' : 'iOS'}</span><span>{index !== data.projectPlatformDetails.length - 1 && ','}</span></>)})}</> : 'No Platform Present'}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={styles.env}>
                                <div className={environment == 'Production' ? styles.production : environment == 'Quality Assurance' ? styles.staging : styles.develop}><ServerIcon className={styles.logo} /></div>
                                <div className={styles.server}>{environment}</div>
                            </div> */}
                        </div>
                    </div>
                </Card>
            </>
        )
    }
}

export default AppDetails