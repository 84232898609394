import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom'
import styles from './addMembers.module.sass'
import Icon from '../../../Icon'
import SendInviteInput from '../SendInviteInput'
import MemberTableLayout from '../MemberTableComponent'
import {acceptedUser} from '../../../../screens/AppSettings/Members/mockData/acceptedUser'
import MemberPermissionDropdown from '../MemberPermissionDropdown'
import axios from 'axios'
import { BASE_URL } from '../../../../resources/APIEndpoints'
import { toast } from 'react-toastify'
import Toast from '../../../Toast'
import { sendMemberInviteAction } from '../../../../redux/action/AppSettings/Members/MembersAction'

const AddMemberModal = ({className, id, usedFor, onClose, members}) => {

  const {appid} = useParams()
  const dispatch = useDispatch()

  const [permissionOption, setPermissionOption] = useState([{id : 1, name : 'View Only'},{id : 2, name : 'Can Edit'}])
  const [permission, setPermission] = useState(permissionOption[0].name)
  const [userPermission, setUserPermission] = useState(1)

  const [email, setEmail] = useState()
  const [organisationId, setOrganisationId] = useState()

  const onChangeEmail = (e) =>{
    setEmail(e)
  }

  const sendInvite = async() => {
    let inviteUser = {
      email : email,
      organisationId : localStorage.getItem('orgId'),
      permissions : [{ projectId : (appid == undefined || appid == null ? id : appid), permissionId : userPermission }]
    }
    
    dispatch(sendMemberInviteAction(inviteUser, appid))
    setTimeout(()=>{
      onClose()
    }, 1000)
  }

  return(
    <>
      <div className={styles.addMembersModal}>
        <div className={styles.addMemberContainer}>
          <div className={styles.memberHeader}>

            <div className={styles.headingContainer}>
              <div className={styles.headingBlock}></div>
              <div className={styles.headingContent}>Add Members</div>
            </div>

          </div>

          <div className={styles.memberBody}>
            <div className={styles.inviteBody}>

              <div className={styles.inviteForm}>
                <SendInviteInput usedFor={usedFor} onChange={(e)=>{onChangeEmail(e.target.value)}} options={permissionOption}  value={permission} setValue={setPermission} permission={userPermission} setPermission={setUserPermission} className={styles.field} placeholder='Type Email' currency={<Icon name="profile-circle" size="20" />} />
                <Link className={styles.invitedUser} onClick={sendInvite}><span>Send Invite</span></Link>
              </div>

              <div className={styles.memberLists}>
                {members && members.map((item, index) => {
                  return(
                    <>
                      <div className={styles.membersList}>
                        <div className={styles.player}>
                          <div className={styles.memberIcon}>
                              <img src={(item.memberDetails != null && item.memberDetails.profile_url != null) ? item.memberDetails.profile_url : 'https://playstarz-staging.s3.ap-south-1.amazonaws.com/2f067589-12c6-40a7-910d-fd90c72fb0bfimage.jpg'} alt={item.name}/>
                          </div>
                          <div className={styles.playerDetails}>
                              <div className={styles.userName}>{(item.memberDetails != null && item.memberDetails.name != null) ? item.memberDetails.name : '-'}</div>
                              <div className={styles.playerId}>{(item.memberDetails != null && item.memberDetails.email) ? item.memberDetails.email : '-'}</div>
                          </div>
                        </div>
                        <div className={styles.memberPermission}>
                          <MemberPermissionDropdown type='modal' options={permissionOption} value={(item.permissions && item.permissions.length > 0 && item.permissions[0].permissionId == 1) ? 'View Only' : 'Can Edit'} />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className={styles.memberFooter}></div>

        </div>
      </div>
    </>
  )

}

export default AddMemberModal