import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './addContent.module.sass'
import Icon from '../../../../../Icon'
import Form from '../../../../../Form'
import TextInput from '../../../../../TextInput'
import Dropdown from '../../../../../Dropdown'
import ItemsTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalItemTable'
import CurrencyTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalCurrencyTable'
import BundleTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalBundleTable'
import PMTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/TableLayoutForModal/ModalPMTable'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllItemAction } from '../../../../../../redux/action/builds/economy/items/itemAction'
import { getAllCurrencyAction } from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import {ReactComponent as Previous} from '../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Button } from 'bootstrap'
import axios from 'axios';
import bundlePlaceHolder from '../../../../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../../../../media/images/placeholders/Progression_Marker.png'
import currencyPlaceHolder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../media/images/placeholders/Items.png'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'

const typesOption = ['Types']
const subTypesOption = ['Sub Types']
const tagsOption = ['Tags']
const sorting = ["Items", "Currency", "Bundles", "Progression Marker"];
 
const AddContentModal = ({usedFor, pagePM, pageItem, pageBundle, pageCurrency, className, type, onClose,value, setValue, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundles, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage,setCalled}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    const [tabIndex, setTabIndex] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState([])

    const  Sorting = () => {
        if(type == 'Bundle'){
            setSort(["Items", "Currency", "Bundles"])
        }else{
            setSort(["Items", "Currency", "Bundles", "Progression Marker"])
        }  
    }

    useEffect(()=>{
        Sorting()
    }, [type])

    const [searchPage, setSearchPage] = useState(1)
    const [itemsSearchCount,setItemsSearchCount]=useState(null)
    const [bundleSearchCount,setBundleSearchCount]=useState(null)
    const [currencySearchCount,setCurrencySearchCount]=useState(null)
    const [pmSearchCount,setPmSearchCount]=useState(null)

    const [searchValue,setSearchValue]=useState('')

    const searchReward = () => {
        {
            let search = {
                projectId : appid,
                search : searchValue,
                offset: ((searchPage-1)*itemCount),
                limit: itemCount

            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                if(res?.data?.data?.currencies && res?.data?.data?.currencies.length > 0){
                    const updatedArray = res?.data?.data?.currencies && res?.data?.data?.currencies.map((ele) => {
                        const matchObj = value.find(element => element.currencyId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setCurrency&&setCurrency(updatedArray)
                    setCurrencySearchCount(res?.data?.data?.currenciesSearchCount)
                }
                else{
                    setCurrency&&setCurrency([])
                    setCurrencySearchCount(res?.data?.data?.currenciesSearchCount)
                }
                if(res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.length > 0){
                    const updatedArray = res?.data?.data?.inventoryItems && res?.data?.data?.inventoryItems.map(ele => {
                        const matchObj = value.find(element => element.itemId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setItem&&setItem(updatedArray)
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)
                }
                else{
                    setItem&&setItem([])
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)
                }
                if(res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.length > 0){
                    const updatedArray = res?.data?.data?.inventoryBundles && res?.data?.data?.inventoryBundles.map(ele => {
                        const matchObj = value.find(element => element.bundleId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setBundle&&setBundle(updatedArray)
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)

                }
                else{
                    setBundle&&setBundle([])
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)
                }
                if(res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.length > 0){
                    const updatedArray = res?.data?.data?.progressionMarkers && res?.data?.data?.progressionMarkers.map(ele => {
                        const matchObj = value.find(element => element.progressionMarkerId == ele.id && (!element.archive || element.archive === false))
                        if(matchObj){
                            return {
                                ...ele,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...ele,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setPM&&setPM(updatedArray)
                    setPmSearchCount(res?.data?.data?.progressionMarkersSearchCount)
                }
                else{
                    setPM&&setPM([])
                    setPmSearchCount(res?.data?.data?.progressionMarkersSearchCount)
                }
            })
        }
    }

    let totalCurrency = useSelector((state)=>{
        return state.currencies.totalCount
    })

    const handleCurrencyChange = (event, value)  => {
        setSearchPage(value)
    }

    let totalItems = useSelector((state)=>{
        return state.item.totalCount
    })

    const handleItemChange = (event, value)  => {
        setSearchPage(value)
    }

    let totalBundles = useSelector((state)=>{
        return state.bundles.totalCount
    })

    const handleBundleChange = (event, value)  => {
        setSearchPage(value)
    }

    let totalPM = useSelector((state)=>{
        return state.progressionMarkers.totalCount
    })

    const handlePMChange = (event, value)  => {
        setSearchPage(value)
    }

    const save = () => {
        if(usedFor === 'Link Rewards'){
            let content = JSON.parse(sessionStorage.getItem('link-rewards'))
            setValue(content)
            onClose()
        }else{
            let content = JSON.parse(sessionStorage.getItem('contents'))
            setValue(content)
            onClose()
        }
    }

    useEffect(()=>{
        setSearchPage(1)
    },[activeIndex,searchValue])


    useEffect(()=>{
        searchReward()
    },[searchValue,searchPage])

    const closeModal = () => {
        onClose();
    }

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>{type == 'Bundle' ? 'Add Content' : 'Add Rewards'}</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalSearch}>
                                    <Form className={styles.form} placeholder="Search" type="text" name="search" icon="search" onChange={(e)=>{setSearchValue(e?.target?.value)}}/>
                                </div>
                                <div className={styles.contentBody}>
                                    <div className={styles.toggle}>
                                        {sort && sort.map((x, index) => (
                                            <button className={type == 'Bundle'? cn(styles.link, { [styles.active]: index === activeIndex}) : cn(styles.links, { [styles.active]: index === activeIndex})} onClick={() => setActiveIndex(index)} key={index}>
                                                <p>{x}</p>
                                            </button>
                                        ))}
                                    </div>
                                    {(type == 'Bundle' || type == 'Task') &&
                                        <>
                                            <div className={styles.wrap}>
                                                {activeIndex === 0 && 
                                                    <>
                                                        <ItemsTableLayout usedFor={usedFor} type='Item' items={item} setData={setItem} setValue={setValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                    '&.Mui-selected': {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    },
                                                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    }
                                                                    }}
                                                                />
                                                                )} siblingCount={1} page={searchPage} count={Math.ceil((searchValue !== '')?(itemsSearchCount/itemCount):(totalItems/itemCount))} color="primary" onChange={handleItemChange} />

                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className={styles.wrap}>
                                                {activeIndex === 1 && 
                                                    <>
                                                        <CurrencyTableLayout usedFor={usedFor} type='Currency' items={currency} setData={setCurrency} setValue={setValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                    '&.Mui-selected': {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    },
                                                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    }
                                                                    }}
                                                                />
                                                                )} siblingCount={1} page={searchPage} count={Math.ceil((searchValue !== '')?(currencySearchCount/currencyCount):(totalCurrency/currencyCount))} color="primary" onChange={handleCurrencyChange} />

                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                    
                                            <div className={styles.wrap}>
                                                {activeIndex === 2 &&
                                                    <>
                                                        <BundleTableLayout usedFor={usedFor} type='Bundles' items={bundles} setData={setBundle} setValue={setValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                    '&.Mui-selected': {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    },
                                                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    }
                                                                    }}
                                                                />
                                                                )} siblingCount={1} page={searchPage} count={Math.ceil((searchValue !== '')?(bundleSearchCount/bundleCount):(totalBundles/bundleCount))} color="primary" onChange={handleBundleChange} />

                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                    {type == 'Task' &&
                                        <>
                                            <div className={styles.wrap}>
                                                {activeIndex === 3 &&
                                                    <>
                                                        <PMTableLayout usedFor={usedFor} type='Progression Marker' items={PM} setData={setPM} setValue={setValue} title="Last edited" />
                                                        <div className={styles.pagination}>
                                                            <Stack spacing={2}>
                                                                <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                    '&.Mui-selected': {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    },
                                                                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                        color: 'rgb(255, 255, 255)',
                                                                        fontWeight : 'bold',
                                                                        border : '1px solid rgb(42, 133, 255)',
                                                                        background : 'rgba(42, 133, 255)'
                                                                    }
                                                                    }}
                                                                />
                                                                )} siblingCount={1} page={searchPage} count={Math.ceil((searchValue !== '')?(pmSearchCount/PMCount):(totalPM/PMCount))} color="primary" onChange={handlePMChange} />

                                                            </Stack>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.button}>
                    <button onClick={save} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Save</button>
                    <button onClick={closeModal} className={cn("button-white-small")}>Cancel</button>
                </div>
            </div>
        </>
    )
}

export default AddContentModal