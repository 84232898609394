import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import EventTooltip from '../../../../CustomTooltips/EventTooltip'
import TooltipGlodal from "../../../../TooltipGlodal";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, access, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Link to={`/view-default-events/${appid}/${item.id}`}>
                        <TooltipGlodal width={200} itemId={`defaultEvent${item.id}`} title={item.name}><div className={styles.itemName}><span id={`defaultEvent${item.id}`}>{item.name}</span></div></TooltipGlodal>
                    </Link>
                </div>
                <div className={styles.col}>{item.category != null ? item.category : '-'}</div>
                <div className={styles.col}>
                    {[...item.defaultParameterDetails,...item.optionalParameterDetails, ...item.specterParameterDetails, ...item.customParameterDetails] && [...item.defaultParameterDetails,...item.optionalParameterDetails,...item.specterParameterDetails,...item.customParameterDetails].slice(0,1).map((e,i) =>{
                        return(
                        <span>{e.name}</span>
                        )
                    })}
                    <span>{(item.defaultParameterDetails.length + item.customParameterDetails.length + item.optionalParameterDetails.length + item.specterParameterDetails.length) > 1 ? <EventTooltip primaryText='System Parameters' secondaryText='Custom Parameters' defaults={item.defaultParameterDetails} optional={item.optionalParameterDetails} specter={item.specterParameterDetails} custom={item.customParameterDetails}><span className={styles.number}>&nbsp;&#43;{item.defaultParameterDetails.length + item.customParameterDetails.length + item.optionalParameterDetails.length + item.specterParameterDetails.length-1}</span></EventTooltip>  : ''}</span>
                </div>
                <div className={styles.col}><Control access={access} className={styles.control} id={item.id} appid={appid} archiveStatus={item.archive} deleteStatus={item.active} markerName={item.progressionMarkerName}/></div>
            </div>
        </>
    );
};

export default Row;
