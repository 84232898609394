import React, { useState } from "react";
import cn from "classnames";
import Icon from "../../../../../Icon";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";

const Control = ({className, id, appid, status, name}) => {

  const actions = [
    {
      icon: "edit",
      pathName : `/edit-document/${appid}/${id}`,
      action: () => console.log("edit"),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions && actions.map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, status : status, name : name}} className={styles.button} key={index} onClick={x.action}>
                <Icon name='edit' size='20' />
              </Link>
            )
          }
        )}
      </div>
    </>
  );
};

export default Control;
