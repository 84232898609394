import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../../components/Modals/ModalComponent";
import ConfigModal from "../../../../../../../components/Modals/Build/Progression/Config";
import {ReactComponent as Formula} from '../../../../../../../media/images/icons/fx_Formula.svg'

const Control = ({ className, disabled, width, levelsDetail, setter}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  const data = (data) => {
    setter(data)
  }

  return (
    <>
      <div className={cn(styles.control, className, {[styles.disabled] : disabled})}>
        {actions.map((x, index) => (
          <Link key={index} onClick={disabled ? null : x.action}>
            <Formula />
          </Link>
        ))}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ConfigModal levelsDetail={levelsDetail} getSetter={data} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
