import {
  CurrentTime,
  Timeline,
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDivider,
  TimelineDividers,
  useTimeline,
  TimelineWeekMonthBox,
  TimelineWeekMonthDate,
} from "@nessprim/planby-pro";
export function CustomTimeline(props) {
  //////////////////////////

  const { isWeekMonthMode, isMonthMode, ...rest } = useTimeline(props);
  const {  weekDayWidth, monthsWidth } = rest;
  const {
    formatWeekMonthDate,
    getDayMonthName,
  } = rest;
  const { mode,isRTL } = props;


  function convertTo12HourFormat(time12h) {
    // Split the time string into hours and AM/PM indicator
    const [hour, amPm] = time12h.split(":");

    // Capitalize the AM/PM indicator
    const capitalizedAmPm = amPm.slice(-2).toUpperCase();
  
    // Format the time
    const formattedTime = `${hour} ${capitalizedAmPm}`;
  
    return formattedTime;
  }
  

  const renderWeekMonth = (item, index) => {
    const { isNewDay, time } = getTime(item);
    const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
    const left = isMonthMode ? monthsWidth[index].left : width * index;
    const position = {
      left,
      width,
    };
    const isVisible = isTimelineVisible(position);
    if (!isVisible) return null;
    const isModernStyle = mode.style === "modern";
  
    return (
      <TimelineWeekMonthBox
        className="planby-timeline-box"
        data-testid="timeline-item"
        key={index}
        isToday={isToday}
        isWeekMonthMode={isWeekMonthMode}
        isCurrentTime={isCurrentTime}
        isVerticalMode={isVerticalMode}
        timelineHeight={timelineHeight}
        styleType={mode.style}
        {...position}
      >
        <TimelineWeekMonthDate
          className="planby-timeline-week-month-date"
          isRTL={isRTL}
          isVerticalMode={isVerticalMode}
          styleType={mode.style}
        >
          {isModernStyle && <span style={props.calendarMode=="30Days"?{fontSize:"10px",color:"#272B30"}:{fontSize:"15px",color:"#272B30"}}>{getDayMonthName(item)}</span>}
          <span style={props.calendarMode=="30Days"?{fontSize:"8px", color:"#6F767E"}:{fontSize:"12px", color:"#6F767E"}}>{formatWeekMonthDate(item)}</span>
        </TimelineWeekMonthDate>
        {/* <TimelineDividers isVerticalMode={isVerticalMode}>
          {renderDividers(isNewDay)}
        </TimelineDividers> */}
      </TimelineWeekMonthBox>
    );
  };

  /////////////////////////
  const {
    time,
    dividers,
    timelineHeight,
    timelineDividers,
    getTime,
    getTimelineProps,
    getCurrentTimeProps,
  } = useTimeline(props);

  

  const {
    isToday,
    isBaseTimeFormat,
    isCurrentTime,
    isTimelineVisible,
    isVerticalMode,
  } = props;

  const { hourWidth } = props;

  const renderTime = (item, index) => {
    const { isNewDay, time } = getTime(item);
    const position = { left: hourWidth * index, width: hourWidth };
  
    const isVisible = isTimelineVisible(position);
    if (!isVisible) return null;
    return (
      <TimelineBox
      width={hourWidth}
        key={index}
        isToday={isToday}
        isCurrentTime={isCurrentTime}
        isVerticalMode={isVerticalMode}
        timelineHeight={timelineHeight}
        {...position}
        style={{display:"sticky"}}
      >
        <TimelineTime
          isVerticalMode={isVerticalMode}
          isNewDay={isNewDay}
          isBaseTimeFormat={isBaseTimeFormat}
          style={{fontSize:"8px",left:"12px",top:"32px"}}
        >
          {
          // index!==0?
          // convertTo12HourFormat(time)
            time
          // :""
          }
        </TimelineTime>
        {/* <TimelineDividers isVerticalMode={isVerticalMode}>
          {renderDividers(isNewDay)}
        </TimelineDividers> */}
      </TimelineBox>
    );
  };

  const renderDividers = (isNewDay) =>
    dividers.map((_, index) => (
      <TimelineDivider
        key={index}
        isVerticalMode={isVerticalMode}
        isNewDay={isNewDay}
        width={hourWidth / timelineDividers} 
        left={index * (hourWidth / timelineDividers)}
      />
    ));

  return (
    <TimelineWrapper {...getTimelineProps()} style={{height:"80px",borderBottom: "1px solid #efefef",marginBottom:"22px"}}>
      {isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
      {mode.type=="day"?time.map((item, index) => renderTime(item, index))
      :time.map((item, index) => renderWeekMonth(item , index))}
    </TimelineWrapper>
  );
}