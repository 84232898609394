import React, {useState} from 'react'
import cn from 'classnames'
import styles from './archiveModal.module.sass'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { archiveGameAction } from '../../../../redux/action/builds/games/gamesAction'
import { archiveMatchAction } from '../../../../redux/action/builds/matches/matchesAction'
import { archiveCurrencyAction } from '../../../../redux/action/builds/economy/currency/currencyAction'
import { archiveItemAction } from '../../../../redux/action/builds/economy/items/itemAction'
import { archiveBundleAction } from '../../../../redux/action/builds/economy/bundles/bundleAction'
import { archiveStoreAction } from '../../../../redux/action/builds/economy/stores/storeAction'
import { archivePMAction } from '../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction'
import { archivePSAction } from '../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction'


const ArchiveModal = ({className, id, name, type, status, onClose}) => {
  
  const dispatch = useDispatch()
  const {appid} = useParams()

  const archive = {
    games : archiveGameAction,
    matches : archiveMatchAction,
    currency : archiveCurrencyAction,
    items: archiveItemAction,
    bundles: archiveBundleAction,
    stores: archiveStoreAction,
    pm: archivePMAction,
    ps: archivePSAction
  }

  const onArchive = async() => {
    const archiveAction = archive[type]
    if(archiveAction){
        if(type === 'games' || type === 'matches' || type === 'currency' || type === 'pm' || type === 'ps'){
          let data = {
            projectId: appid,
            id: id,
            archive : (status ? false : true)
          }
          dispatch(archiveAction(data, appid, onClose, status))
        }else if(type === 'items'){
          let data = {
            items: [
              {projectId: appid, id: id, archive : (status ? false : true)}
            ]
          }
          dispatch(archiveAction(data, appid, onClose, status))
        }
        else if(type === 'bundles'){
          let data = {
            bundles: [
              {projectId: appid, id: id, archive : (status ? false : true)}
            ]
          }
          dispatch(archiveAction(data, appid, onClose, status))
        }else if(type === 'stores'){
          let data = {
            stores: [
              {projectId: appid, id: id, archive : (status ? false : true)}
            ]
          }
          dispatch(archiveAction(data, appid, onClose, status))
        }
    }else{
      console.warn('Unknown type')
    }
  }

  return (
    <div className={styles.archiveModal}>
        <div className={styles.archiveBody}>
            <div className={styles.warning}>
                <h5>Warning!</h5>
            </div>
            <div className={styles.warningContent}>
                <p>Are you sure you want to {status == true ? 'Unarchive' : 'Archive'} <span className={styles.title}>{name}</span>?</p>
            </div>
        </div>
        <div className={styles.btns}>
            <button className={styles.archive} onClick={onArchive}>Yes</button>
            <button className={styles.cancel} onClick={onClose}>Cancel</button>
        </div>
    </div>
  )
}

export default ArchiveModal