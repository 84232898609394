import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import Game from '../../../../../media/images/placeholders/Game.png'
import TooltipGlodal from "../../../../TooltipGlodal";
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, access, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                <Link to={`/view-competition-schedule/${appid}/${item.id}`}>
                    <TooltipGlodal width={120} itemId={`competition${item.id}`} title={item.competition != null ? item.competition.name : '-'}><div className={styles.itemName}><span id={`competition${item.id}`}>{item.competition != null ? item.competition.name : '-'}</span></div></TooltipGlodal>
                </Link>
                </div>
                <div className={styles.col}><div className={styles.name}>{item.competition != null ? item.competition.competitionId : '-'}</div></div>
                <div className={styles.col}>{item.competition != null ? item.competition.minPlayers : '-'}</div>
                <div className={styles.col}>{item.competition != null ? item.competition.maxPlayers : '-'}</div>
                <div className={styles.col}>{item.competition != null && item.startDate != null ? moment.utc(item.startDate).local().format('YYYY-MM-DD HH:mm') : '-'}</div>
                <div className={styles.col}>{item.competition != null && item.endDate != null ? moment.utc(item.endDate).local().format('YYYY-MM-DD HH:mm'): '-'}</div>
                <div className={styles.col}><Control access={access} className={styles.control} id={item.id} appid={appid} name={(item.competition && item.competition != null) ? item.competition.name : '-'}/></div>
            </div>
        </>
    );
};

export default Row;
