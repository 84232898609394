import React, { useState, useEffect} from 'react'
import cn from 'classnames'
import styles from './customValueEditor.module.sass'
import Card from '../../../../../../../components/Card'
import {ReactComponent as AddMetaIcon} from '../../../../../../../media/images/icons/add_meta.svg'
import { Rule, ValueEditor, ValueEditorProps } from 'react-querybuilder'
import TextInput from '../../../../../../../components/TextInput'
import Dropdown from '../../../../../../../components/Dropdown/CurrencyDropdown'
import Radio from '../../../../../../../components/Radio'
import TooltipTitle from '../../../../../../../Tooltip/TooltipTitle'
import Tooltips from '../../../../../../../components/Tooltip'

const CustomValueEditor = (ValueEditorProps, query) => {

    const [constructedValue, setCunstructedValue] = useState(ValueEditorProps.value)

    const onInputChange = (e) => {
        const name = e.target.name
        if(name == 'parameter'){
            let values = {...constructedValue, value : e.target.value}
            setCunstructedValue(values)
            ValueEditorProps.handleOnChange(values)
        }
        else if(name.includes("type") == true){
            if(e.target.value == 'oneshot'){
                let values = {...constructedValue, type : false}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }else{
                let values = {...constructedValue, type : true}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }
        }
        else if(name == 'noOfRecords'){
            let values = {...constructedValue, noOfRecords : ((e.target.value != '' && e.target.value != null) ? parseInt(e.target.value) : null)}
            setCunstructedValue(values)
            ValueEditorProps.handleOnChange(values)
        }
        else if(name.includes('allTime') == true){
            if(e.target.value == 'yes'){
                let values = {...constructedValue, allTime : true}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }else{
                let values = {...constructedValue, allTime : false}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }
        }
    }


    return (
        <>
                {ValueEditorProps.value != null  &&
                    <TextInput tooltip={TooltipTitle.TaskParameterValue} groupBy='value' label='Parameter Value' name='parameter' value={(ValueEditorProps.rule.value == '' || ValueEditorProps.rule.value == undefined || ValueEditorProps.rule.value == null) ? '' : ValueEditorProps.value.value} onChange={(e)=> onInputChange(e)}/>
                }
                {ValueEditorProps.field.type == 'statistic' &&
                <div className={styles.groupByThree}>
                    {ValueEditorProps.value != null  &&
                        <div className={styles.fieldRadio}>
                            <div className={styles.radioLabel}>
                                <span>Incremental Type<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskIncrementalType} icon="info"/></span></span>
                            </div>
                            <div className={styles.variants}>
                                <Radio className={styles.radio} value={!ValueEditorProps.value.type} name={ValueEditorProps.value.id != undefined || ValueEditorProps.value.id != null ? `type${ValueEditorProps.value.id}` : `type${ValueEditorProps.rule.id}`} id={`oneshot`} content="One Shot" onChange={(e)=>onInputChange(e)}/>
                                <Radio className={styles.radio} value={ValueEditorProps.value.type} name={ValueEditorProps.value.id != undefined || ValueEditorProps.value.id != null ? `type${ValueEditorProps.value.id}` : `type${ValueEditorProps.rule.id}`} id={`cumulative`} content="Cumulative" onChange={(e)=>onInputChange(e)}/>
                            </div>
                        </div>
                    }
                    {(ValueEditorProps.value != null && ValueEditorProps.value != '' && ValueEditorProps.value.type == true) &&
                    <div className={styles.fieldRadio}>
                        <div className={styles.radioLabel}>
                            <span>Include All Records<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskTimePeriod} icon="info"/></span></span>
                        </div>
                        <div className={styles.variants}>
                            <Radio className={styles.radio} value={ValueEditorProps.value.allTime} name={ValueEditorProps.value.id != undefined || ValueEditorProps.value.id != null ? `allTime${ValueEditorProps.value.id}` : `allTime${ValueEditorProps.rule.id}`} id={`yes`} content="Yes" onChange={(e)=>onInputChange(e)}/>
                            <Radio className={styles.radio} value={!ValueEditorProps.value.allTime} name={ValueEditorProps.value.id != undefined || ValueEditorProps.value.id != null ? `allTime${ValueEditorProps.value.id}` : `allTime${ValueEditorProps.rule.id}`} id={`no`} content="No" onChange={(e)=>onInputChange(e)}/>
                        </div>
                    </div>}
                    {(ValueEditorProps.value != null && ValueEditorProps.value != '' && (ValueEditorProps.value.type == true && ValueEditorProps.value.allTime == false)) &&
                        <TextInput tooltip={TooltipTitle.TaskRecords} groupBy='noOfRecords' className={styles.field} label='Number of records to be included (Optional)' name='noOfRecords' value={ValueEditorProps.value.noOfRecords} onChange={(e)=>{onInputChange(e)}}/>
                    }
                </div>
                }
        </>
    )
}

export default CustomValueEditor