import { GET_PROGRESSION_SYSTEM, ADD_PROGRESSION_SYSTEM, EDIT_PROGRESSION_SYSTEM, FILTER_PROGRESSION_SYSTEM_SUCCESS, GET_PROGRESSION_SYSTEM_SUCCESS, GET_PROGRESSION_SYSTEM_ERROR, FILTER_PROGRESSION_SYSTEM_ERROR } from "../../../../actionTypes/actionTypes"

const initialState = {
    progressionSystem : [],
    isLoading : false,
    isFiltered: false,
    totalCount : null,
    error : null
}

export const progressionSystemReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_PROGRESSION_SYSTEM : {
            return {
               ...state,
               isLoading : true
            }
        }

        case GET_PROGRESSION_SYSTEM_SUCCESS : {
            return {
               ...state,
               isLoading : false,
               isFiltered: false,
               progressionSystem: action.payload,
               totalCount : action.totalCount,
               error : null
            }
        }

        case GET_PROGRESSION_SYSTEM_ERROR : {
            return {
               ...state,
               isLoading : false,
               error: action.payload
            }
        }

        case FILTER_PROGRESSION_SYSTEM_SUCCESS : {
            return {
               ...state,
               progressionSystem: action.payload,
               totalCount: action.totalCount,
               isLoading: false,
               isFiltered: true
            }
        }

        case FILTER_PROGRESSION_SYSTEM_ERROR : {
            return {
               ...state,
               isLoading: false,
               errors: action.payload
            }
        }

        case ADD_PROGRESSION_SYSTEM : {
            return {
                ...state,
                progressionSystem: action.payload,
                isLoading : false
             }
        }

        case EDIT_PROGRESSION_SYSTEM : {
            return {
                ...state,
                progressionSystem: action.payload,
                isLoading : false
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}
