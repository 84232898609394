import React from 'react'
import MatchInformation from './MatchInformation'
import styles from './createMatches.module.sass'
import { ToastContainer } from 'react-toastify'


const CreateMacth = ({isChanged, setIsChanged}) => {
  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
          <div className={styles.col}>
              <MatchInformation isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
          </div>
      </div>
    </>
  )
}

export default CreateMacth