import React, { useState } from 'react'
import styles from './tooltip.module.sass'
import moment from "moment";
import 'moment-timezone';
import {ReactComponent as Internet} from '../../../media/images/icons/internetBlack.svg'

const Tooltip = ({ text, userTimeZone, isLast, children, startDate, endDate, timeZone}) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    
    return (
        <div className={styles.custom} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <div className={styles.tooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {showTooltip && 
                    <>
                        <div className={styles.dateContainer} style={isLast?{top:"unset",bottom:"100%"}:{}}>
                            {timeZone &&
                                <div className={styles.timeZone}>
                                    <div className={styles.internetIcon}><Internet fill="#FFFFFF" size="16"/></div>
                                    <div className={styles.timeZoneTitle}>Set in {timeZone.split('/')[0]} ({timeZone.split('/')[1]})</div>
                                </div>
                            }
                            <div className={styles.date}>
                                <div className={styles.dateTitle}>Start Date/Time</div>
                                <div className={styles.dateValue}>{startDate != null ? <>{moment(startDate).tz(userTimeZone).format('MMM DD, YYYY')} {moment(startDate).tz(userTimeZone).format('hh:mm A')}</> : '-'}</div>
                            </div>
                            <div className={styles.date}>
                                <div className={styles.dateTitle}>End Date/Time</div>
                                <div className={styles.dateValue}>{endDate != null ? <>{moment(endDate).tz(userTimeZone).format('MMM DD, YYYY')} {moment(endDate).tz(userTimeZone).format('hh:mm A')}</> : <span className={styles.icon}>&#8734;</span>}</div>
                            </div>   
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Tooltip