import React from 'react'
import styles from './textArea.module.sass'
import cn from "classnames";
import Tooltip from '../Tooltip';
import { red } from '@mui/material/colors';

const TextArea = ({className, classLabel, classInput, label, tooltip, ...props}) => {

    return (
        <div className={styles.wrapContainer}>
            {label && (
                <div className={cn(classLabel, styles.label)} style={{display:"flex", justifyContent:"space-between"}}>
                <span>
                {label}{" "}
                {tooltip && (
                    <Tooltip
                    className={styles.tooltip}
                    title={tooltip}
                    icon="info"
                    place="right"
                    />
                )}
                </span>
                <span className={styles.tagsWarning}><span className={styles.tagBold} style={{ color: props?.value !== null && props?.value?.length === 3000 ? "red" : "" }}>{(props?.value!==null&&props?.value!==undefined&&props.value!=='')?props?.value?.length:"0"}</span>/3000</span>
                </div>
            )}
            
            <div className={styles.wrap}>
                <textarea style={{resize : 'none'}} maxLength="3000" className={cn(classInput, styles.input)} {...props} />
            </div>
        </div>
    )
}

export default TextArea