import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../../resources/APIEndpoints";
// import Tooltip from "../Tooltip";

const FileImages = ({ className, label, value, setValue, data, tooltip, title, success, error, accept, a, ...props }) => {

    let screenshotHolder = []
    const screenshotRef = useRef()

    const [total, setTotal] =  useState()
    const [loaded, setLoaded] = useState()
    const [percentage, setPercentage] = useState()
    const [files , setFiles] = useState(null)

    const onUploadProgress = (progressEvent) => {
        for(var i = 1 ; i <= document.getElementsByClassName('progress-bar-images').length; i++){
            const {loaded, total} = progressEvent;
            let percentage = Math.floor((loaded*100)/total)
            if(document.getElementById(`successfull${i}`)){
                document.getElementById(`successfull${i}`).style.width = `${percentage}%`
                if(percentage == 100){
                    document.getElementById(`percentage${i}`).style.display = 'none'
                    document.getElementById(`view${i}`).style.display = 'block'
                }
            }
        }
    }
    
    const onScreenShotUpload = async(e) => {
        for(var i = 0; i< e.target.files.length; i++){
            screenshotHolder.push(e.target.files[i])
        }        
        screenshotHolder.forEach(async (element, index) => {
            const file_binary = await convertbase64(element)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${element.type}`)
            let imageUrl = res.data.getUrl
            setValue(prevState => [...prevState, imageUrl])
            var config = {headers : {'Content-Type' : `${element.type}`}, method: 'put', url: res.data.postUrl,data :convertDataURIToBinary(file_binary), onUploadProgress}
              axios(config)
              .then(function (response) {
                e.target.value = ''
                //toast.success(`Screenshot ${index+1} uploaded successfully`)
              })
              .catch(function (error) {
                toast.error("Please upload the file again")
              });
            });
    }
    
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    return (
        <div className={cn(styles.file, className)}>
            <div className={styles.wrap}>
                <input ref={data} multiple accept="image/*" className={styles.input} type="file" onChange={(e)=>{onScreenShotUpload(e)}}/>
                <div className={styles.box}>
                    <label><Icon name="upload" size="24" />Click or drop Image</label>
                </div>
            </div>
        </div>
    );
};

export default FileImages;
