import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../Toast";
import { addMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../resources/APIEndpoints";

const FileUpload = ({ className, activeIndex, access, disabled, id, usedFor, setSidebar,folderName, label, value, setValue, icon, setIcon, setFile, data, tooltip, title, success, error, accept, a,type,catIndex,customUploadFunction, ...props }) => {

    let filePlaceHolder = []
    const {appid} = useParams()
    const dispatch = useDispatch()

    const [total, setTotal] =  useState()
    const [loaded, setLoaded] = useState()
    const [percentage, setPercentage] = useState()
    const [files , setFiles] = useState(null)

    const onUploadProgress = (progressEvent) => {
        for(var i = 1 ; i <= document.getElementsByClassName('progress-bar-images').length; i++){
            const {loaded, total} = progressEvent;
            let percentage = Math.floor((loaded*100)/total)
            if(document.getElementById(`successfull${i}`)){
                document.getElementById(`successfull${i}`).style.width = `${percentage}%`
                if(percentage == 100){
                    document.getElementById(`percentage${i}`).style.display = 'none'
                    document.getElementById(`view${i}`).style.display = 'block'
                }
            }
        }
    }
    const onFileUpload = async(e) => {
        if (type=="store") {
            
            customUploadFunction(e,catIndex)
        }
        else if(usedFor == 'media'){
            let fileContent = []
            for(var i = 0; i< e.target.files.length; i++){
                let fileData = e.target.files[i]
                let fileName = fileData.name
                let fileType = fileData.type
                let fileSize = fileData.size/1024
                let file_binary = await convertbase64(fileData)
                fileContent.push({contentType : `${fileType}`, fileSize : fileSize,category : folderName, fileName : fileName, url : null, data : convertDataURIToBinary(file_binary)})
            }
            const forEachPromise = new Promise((resolve) => {
                const token = localStorage.getItem('token')
                const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
                fileContent && fileContent.forEach(async(e, i) => {
                    const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${e.contentType}&projectId=${appid}&category=${folderName}&fileName=${e.fileName}`,headers)
                    let fileUrl = res.data.getUrl
                    fileContent[i].url = fileUrl
                    var config = {headers : {'Content-Type' : `${e.contentType}`}, method: 'put', url: res.data.postUrl,data : e.data}
                    axios(config)
                    .then(function (response) {
                        e.target.value = ''
                    })
                    .catch(function (error) {

                    })
                    // If it's the last iteration, resolve the Promise
                    if(i === fileContent.length - 1) {
                        resolve();
                    }
                })   
            });
            
            await forEachPromise;

            let files = {
                projectId : appid,
                contents : fileContent && fileContent.map(obj => {const { data, ...rest } = obj; return rest;})
            }
            dispatch(addMediaAction(files, setValue, appid,folderName))
        }else if(usedFor == 'multiUpload'){
            let screenshotHolder = []
            for(var i = 0; i< e.target.files.length; i++){
                screenshotHolder.push(e.target.files[i])
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}        
            screenshotHolder && screenshotHolder.forEach(async (element, index) => {
                const file_binary = await convertbase64(element)
                const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${element.type}&projectId=${appid}&category=${folderName}&fileName=${element.name}`,headers)
                let imageUrl = res.data.getUrl
                //setValue(prevState => [...prevState, imageUrl])
                var config = {headers : {'Content-Type' : `${element.type}`}, method: 'put',url: res.data.postUrl,data :convertDataURIToBinary(file_binary), onUploadProgress}
                axios(config)
                .then(function (response) {
                    if(setSidebar){
                        let uploadLogo = {
                            projectId : appid,
                            contents : [{
                                contentType : element.type,
                                fileName : element.name,
                                category : folderName,
                                url : res.data.getUrl,
                                fileSize : element.size/1024
                            }]
                        }
                        dispatch(addMediaAction(uploadLogo, null, appid,folderName))
                    }
                    toast.success(<Toast type='Success' messages={`Screenshot ${index+1} Uploaded Successfully`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                })
                .catch(function (error) {
                    
                });
            });
        }
        else{
            if(e.target.files[0]) {
                setFile({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
            } 
            var file = e.target.files[0];   
            const file_binary = await convertbase64(file)
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&projectId=${appid}&category=${folderName}&fileName=${file.name}`,headers)
            setIcon(res.data.getUrl)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            axios(config)
            .then(function (response) {
                if(setSidebar){
                    let uploadLogo = {
                        projectId : appid,
                        contents : [{
                            contentType : file.type,
                            fileName : file.name,
                            category : folderName,
                            url : res.data.getUrl,
                            fileSize : file.size/1024
                        }]
                    }
                    dispatch(addMediaAction(uploadLogo, null, appid,folderName))
                    setSidebar(false)
                }
                toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            })
            .catch(function (error) {
                toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            });
        }
    }
    
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    return (
        <label for={id} className={cn(styles.file, className)}>
            <div className={usedFor == 'media' ? styles.wrap : styles.wrapLogo}>
                <input disabled={disabled} ref={data} multiple id={id} accept={folderName=='bundles'?'*':"image/*, video/*"} className={cn(styles.input, {[styles.disabled] : disabled})} type="file" onChange={(e)=>{onFileUpload(e);setSidebar(false)}}/>
                <div className={styles.box}>
                    <label for={id}><Icon name="upload" size="24" />{title}</label>
                </div>
            </div>
        </label>
    );
};

export default FileUpload;
