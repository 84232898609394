import React, {useEffect, useRef, useState} from 'react'
import cn from "classnames";
import Card from "../../../../../components/Card";
import addIcon from '../../../../../media/images/icons/light.png'
import styles from './IBResult.module.sass'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import EmptyStates from '../../../../../components/EmptyStates';
import moment from 'moment';
import { getCompetitionResultAction } from '../../../../../redux/action/engage/Competitions/competitionAction';
import Calendar from './Calendar';
import LiveOpsResultTableLayout from '../../../../../components/EngageComponents/LederboardComponent/LeaderboardResult';
import LiveOpsResultPDTableLayout from '../../../../../components/EngageComponents/LederboardComponent/LeaderboardResultPD';
import { getLeadboardResultPDAction } from '../../../../../redux/action/engage/LiveOps/LiveOpsAction';

const IBResult = ({props}) => {

    const {id} = useParams()
    const {appid} = useParams()
    const location = useLocation()

    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(1000)

    const [competitionInstanceId, setCompetitionInstanceId] = useState(location.state.instanceId)
    const [current, setCurrent] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        getCompetitionResult()
    }, [competitionInstanceId, page]);

    const getCompetitionResult = () => {
        let competitionResult = {
            competitionId : id,
            instanceId : competitionInstanceId,
            pagination : {
                offset : ((page-1)*limit),
                limit : limit
            }
        }
        dispatch(getCompetitionResultAction(competitionResult))
    }

    let competitionResults = useSelector((state) =>{
        return state.competition.competitionResults
    })

    let totalResult = useSelector((state) =>{
        return state.competition.resultCount
    })

    const handlePageChange = (event, value)  => {
        setPage(value)
    }

    const [results, setResults] = useState([])

    const configureCurrentInstace = () => {
        if(competitionResults && current){
            competitionResults = competitionResults && competitionResults.find(e => e.slotId === current.id)
            setResults([competitionResults])
        }
    }

    useEffect(() => {
        configureCurrentInstace()
    }, [competitionResults, current])

    return(
        <>
        <div className={styles.leaderboardHeading}>
            <div className={cn("h5", styles.title)}>{location.state.name}</div>
        </div>
        <Card className={styles.card} classCardHead={styles.head} title="Competition Result" classTitle={cn("title-purple", styles.title)}
            head={
            <>
                {/* <div className={competitionStatus == 'yet to start' ? styles.purple : competitionStatus == 'in progress' ? styles.green : competitionStatus == 'completed' ? styles.grey : competitionStatus == 'stopped' ? styles.orange : competitionStatus == 'in review' ? styles.yellow : competitionStatus == 'failed' ? styles.red : ''}>{competitionStatus == 'yet to start' ? 'Yet-to-Start' : competitionStatus == 'in progress' ? 'Live' : competitionStatus == 'completed' ? 'Completed' : competitionStatus == 'in review' ? 'In-Review' : competitionStatus == 'stopped' ? 'Stopped' : competitionStatus == 'failed' ? 'Failed' : ''}</div> */}
                <Calendar instances={competitionResults} value={current} setValue={setCurrent} />
            </>
            }>
            <div>
                <div className={styles.wrapper}>
                    {results.length == 0 && 
                        <EmptyStates type="Competition Result" />
                    }
                    {results.length > 0 &&
                        <>
                            <LiveOpsResultTableLayout items={results} title="Last edited" usedFor={location.state.usedFor}/>
                            <div className={styles.pagination}>
                                <Stack>
                                    <Pagination renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                            sx={{
                                                '&.Mui-selected': {
                                                    color: 'rgb(255, 255, 255)',
                                                    fontWeight : 'bold',
                                                    border : '1px solid rgb(42, 133, 255)',
                                                    background : 'rgba(42, 133, 255)'
                                                },
                                                '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                    color: 'rgb(255, 255, 255)',
                                                    fontWeight : 'bold',
                                                    border : '1px solid rgb(42, 133, 255)',
                                                    background : 'rgba(42, 133, 255)'
                                                }
                                            }}
                                        />
                                    )} siblingCount={1} count={Math.ceil(totalResult/limit)} color="primary" onChange={handlePageChange} />
                                </Stack>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Card>
    </>
  )
}

export default IBResult