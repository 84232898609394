import React, {useState} from 'react'
import cn from 'classnames'
import styles from './deleteModal.module.sass'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deleteTaskAction } from '../../../../redux/action/engage/Achievement/Task/TaskAction'
import { deleteTaskGroupAction } from '../../../../redux/action/engage/Achievement/StepSeries/StepSeriesAction'
import { deleteCompetitionAction } from '../../../../redux/action/engage/Competitions/competitionAction'
import { deleteLeaderboardAction } from '../../../../redux/action/engage/Leaderboard/leaderboardAction'

const DeleteModal = ({className, id, name, type, onClose}) => {
  
  const dispatch = useDispatch()
  const {appid} = useParams()

  const remove = {
    task : deleteTaskAction,
    mission: deleteTaskGroupAction,
    'step-series' : deleteTaskGroupAction,
    tournament : deleteCompetitionAction,
    ib: deleteCompetitionAction,
    leaderboard: deleteLeaderboardAction,
  }

  const onDelete = () => {
    const deleteAction = remove[type]
    if(deleteAction){
        let data = {
            projectId: appid,
            ids: [id],
            ...((type === 'mission' || type === 'step-series') && {typeId: (type === 'mission' ? 1 : 2)}),
            ...((type === 'tournament' || type === 'ib') && {competitionFormatTypeMasterId : (type === 'tournament' ? 2 : 3)})
        }
        dispatch(deleteAction(data, appid, onClose))
    }else{
        console.warn('Unknown type')
    }
  }

  return (
    <div className={styles.deleteModal}>
        <div className={styles.deleteBody}>
            <div className={styles.warning}>
                <h5>Warning!</h5>
            </div>
            <div className={styles.warningContent}>
                <p>Are you sure you want to delete <span className={styles.title}>{name}</span>?</p>
            </div>
        </div>
        <div className={styles.btns}>
            <button className={styles.delete} onClick={onDelete}>Yes</button>
            <button className={styles.cancel} onClick={onClose}>Cancel</button>
        </div>
    </div>
  )
}

export default DeleteModal