import {GET_META_DATA, GET_TAGS, UPDATE_TAGS, GET_PLATFORMS, GET_GOALS} from '../../actionTypes/actionTypes'

const initialState = {
    metaData : [],
    tags:[],
    goals : [],
    platforms : [],
    isLoading : false
};

export const commonReducer =( state=initialState, action )=>{
    switch(action.type){
        case GET_META_DATA : {
            return {
                ...state,
                metaData: action.payload,
            }
        }
        case GET_TAGS : {
            return {
                ...state,
                tags: action.payload,
            }
        }
        case GET_GOALS : {
            return {
                ...state,
                goals: action.payload,
            }
        }
        case GET_PLATFORMS : {
            return {
                ...state,
                platforms: action.payload,
            }
        }
        case UPDATE_TAGS : {
            return {
                ...state,
                tags: action.payload,
            }
        }
        default: {
            return {
                ... state
            }
        }
    }    
}   