import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './confirm.module.sass'
import { addAPIKeyAction } from '../../../../redux/action/apps/appsAction'

const ConfirmModal = ({className, onClose, appid, envId}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSave = () => {
    let newApiKey = {
      appId : appid,
      envId : envId
    }
    dispatch(addAPIKeyAction(newApiKey, appid)) 
    onClose()   
  }
  
  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>
              <h5>Warning!</h5>
          </div>
          <div className={styles.warningContent}>
              <p>Are you sure you want to generate new api key for <span className={styles.currencyName}>{envId == 1 ? 'Development' : envId == 2 ? 'Quality Assurance' : envId == 3 ? 'Production' : ''}</span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.deleteBtn} onClick={onSave}>Generate</button>
        <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}

export default ConfirmModal