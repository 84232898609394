import { GET_BUNDLES, GET_BUNDLES_BY_ID, ADD_BUNDLES, EDIT_BUNDLES, FILTER_BUNDELS_SUCCESS, GET_BUNDLES_SUCCESS, GET_BUNDLES_ERROR, FILTER_BUNDELS_ERROR } from "../../../../actionTypes/actionTypes";

const initialState = {
    bundles : [],
    bundleDetails : [],
    isLoading : false,
    isFiltered : false,
    totalCount : null,
    error: null
}

export const bundleReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_BUNDLES : {
            return {
               ...state,
               isLoading: true
            }
        }

        case GET_BUNDLES_SUCCESS : {
            return {
               ...state,
               bundles: action.payload,
               isLoading : false,
               isFiltered: false,
               totalCount : action.totalCount,
               errors: null
            }
        }

        case GET_BUNDLES_ERROR : {
            return {
               ...state,
               isLoading : false,
               errors: action.payload
            }
        }

        case FILTER_BUNDELS_SUCCESS : {
            return {
                ...state,
                bundles : action.payload,
                totalCount: action.totalCount,
                isLoading: false,
                isFiltered: true
            }
        }

        case FILTER_BUNDELS_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case GET_BUNDLES_BY_ID : {
            return {
                ...state,
                bundleDetails: action.payload,
                isLoading : false
             }
        }

        case ADD_BUNDLES : {
            return {
                ...state,
                bundles: action.payload,
                messages : action.payload
            }
        }

        case EDIT_BUNDLES : {
            return {
                ...state,
                bundles: action.payload,
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}