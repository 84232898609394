import axios from "../../utils/interceptor";
import { BASE_URL, GET_APPS, GET_APP_OVERVIEW, ADD_APPS, EDIT_APPS, ADD_API_KEY } from "../../resources/APIEndpoints";

function AppServices(){

    this.getAllApps = (body) => axios.post(BASE_URL + GET_APPS, body)
    this.getAppOverview = (body) => axios.post(BASE_URL + GET_APP_OVERVIEW, body)
    this.createApps = (body) => axios.post(BASE_URL + ADD_APPS, body)
    this.editApps = (body) => axios.post(BASE_URL + EDIT_APPS, body)
    this.addAPIKeys = (body) => axios.post(BASE_URL + ADD_API_KEY, body)

}

export default new AppServices();