import React, { useState } from "react"
import cn from "classnames"
import { Link } from "react-router-dom"
import styles from "./control.module.sass"
import Icon from "../../../../../Icon"
import {ReactComponent as Remove} from '../../../../../../media/images/icons/remove.svg'
import {ReactComponent as Key} from '../../../../../../media/images/icons/light.svg'
import Modal from "../../../../../../components/Modals/ModalComponent";
import ChangePasswordModal from '../../../../../../components/EngageComponents/PlayersComponent/Modals/ChangePassword'

const Control = ({className, authProvider}) => {

  const [visibleModal, setVisibleModal] = useState(false)

  const actions = [
    {
      icon: "key",
      action:  () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => {
            return(
              <Link to={x.pathName}  className={styles.button} key={index} onClick={x.action}>
                <Key className={styles.icon} />
              </Link>
            )
        })}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ChangePasswordModal authProvider={authProvider} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
