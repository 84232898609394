import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./inventoryFilter.module.sass"

const CompetitionToggle = ({className, setPage, active, setActive, tabs, classLabel, classDropdownHead, classDropdownLabel, value, setValue, setId, options, label, small, upBody, tooltip}) => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setPage(1)
        setValue(value.name)
        if(setId){
            setId(value.id)
        }
        setVisible(false);
    };

    return(
        <>
            <div className={styles.wrapper}>
                <div className={styles.inventory}>
                    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                    {label && (
                        <div className={cn(classLabel, styles.label)}>
                        {label}
                        </div>
                    )}
                    <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                        <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                            <div className={styles.selection}>{value}</div>
                        </div>
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options && options.map((x, index) => (
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                                {x.name}
                            </div>
                        ))}
                        </div>
                    </div>
                    </OutsideClickHandler>
                </div>
                <div className={styles.toggle}>
                    {tabs && tabs.map((x, index) => (
                        <button className={cn(styles.link, { [styles.active]: index === active})} onClick={() => {setActive(index);setPage(1)}} key={index}>
                            <p>{x}</p>
                        </button>
                    ))}
                </div>
            </div>
        </>
    )
}

export default CompetitionToggle