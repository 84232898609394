import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import {ReactComponent as Trophy} from '../../../../../../media/images/icons/trophy.svg'
import EyeIcon from '../../../../../../media/images/icons/eye.png'
import Vertical3Dots from "../../../../../../media/images/icons/vertical3Dots.svg"
import VerticalOptionsTooltip from "../../../../../VertcalOptionsTooltip"
import Modal from "../../../../../Modals/ModalComponent";
import DeleteModal from "../../../../../Modals/Others/DeleteModal";
import ArchiveModal from "../../../../../Modals/Others/ArchieveModal";

const Control = ({className, isLast, PD, status, scheduleInstance, instanceId, access, customDate, id, name, appid, type, archive, interval, archiveStatus, deleteStatus, markerName}) => {

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)

  const actions = [
    {
      pathName : `/view-leaderboard/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      name:"Edit",
      icon: "edit",
      pathName : `/edit-leaderboard/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      name:"Result",
      icon : 'trophy',
      pathName : `/leaderboard-details/${appid}/${id}`,
    },
    {
      name: "Delete",
      icon: "trash",
      action:  () => setVisibleDeleteModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{PD:PD, interval : interval, name : name, instanceId: instanceId, scheduleInstance:scheduleInstance, status:status}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : 
          <VerticalOptionsTooltip isLast={isLast} data={actions} PD={PD} status={status} scheduleInstance={scheduleInstance} instanceId={instanceId} name={name} interval={interval}>
            <div className={styles.button}>
              <img src={Vertical3Dots} width={20} height={20} className={styles.icon} />
            </div>
          </VerticalOptionsTooltip>
        }  
      </div>
      <Modal visible={visibleDeleteModal} onClose={() => setVisibleDeleteModal(false)}>
        <DeleteModal id={id} name={name} type={type} onClose={() => setVisibleDeleteModal(false)}/>
      </Modal>
      {/* <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveModal id={id} name={name} status={archive} type={type} onClose={() => setVisibleArchiveModal(false)}/>
      </Modal> */}
    </>
  );
};

export default Control;
