import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";

const Dropdown = ({ className, disabled, groupBy, classLabel, classDropdownHead, classDropdownLabel, value, setValue, data, setData, options, index, label, small, upBody, tooltip }) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState('');

    const handleClick = (e) => {
        let datas = [...data];
        datas[index].gamePlatformMasterId = e?.id ? e.id : e.platformId;
        datas[index].gamePlatformName = e.name;
        setData(datas);
        setVisible(false);
        setFilterText('');
    };

    const filteredOptions = options&&options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(''); }}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                        {label}{" "}
                        {tooltip && (<Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />)}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder="Select Platform"
                                value={filterText !== '' ? filterText : value}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions.map && filteredOptions.map((x) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={x.id}>
                                    {x.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
}

export default Dropdown;
