import {GET_PROGRESSION_MARKER, ADD_PROGRESSION_MARKER, EDIT_PROGRESSION_MARKER, FILTER_PROGRESSION_MARKER_SUCCESS, GET_PROGRESSION_MARKER_SUCCESS, GET_PROGRESSION_MARKER_ERROR, FILTER_PROGRESSION_MARKER_ERROR} from '../../../../actionTypes/actionTypes'

const initialState = {
    progressionMarker: [],
    isLoading: false,
    isFiltered: false,
    totalCount: null
}

export const progressionMarkerReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_PROGRESSION_MARKER : {
            return {
               ...state,
               isLoading: true
            }
        }

        case GET_PROGRESSION_MARKER_SUCCESS : {
            return {
               ...state,
               progressionMarker: action.payload,
               totalCount: action.totalCount,
               isLoading: false,
               isFiltered: false,
               error: null
            }
        }

        case GET_PROGRESSION_MARKER_ERROR : {
            return {
                ...state,
                isLoading : false,
                errors: action.payload
            }
        }

        case FILTER_PROGRESSION_MARKER_SUCCESS : {
            return {
               ...state,
               progressionMarker: action.payload,
               totalCount: action.totalCount,
               isLoading: false,
               isFiltered: true
            }
        }

        case FILTER_PROGRESSION_MARKER_ERROR : {
            return {
               ...state,
               isLoading: false,
               errors: action.payload
            }
        }

        case ADD_PROGRESSION_MARKER : {
            return {
                ...state,
                progressionMarker: action.payload
            }
        }

        case EDIT_PROGRESSION_MARKER : {
            return {
                ...state,
                progressionMarker: action.payload,
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}