import React from "react";
import styles from './viewStore.module.sass'
import StoreInformation from './StoreInformation';
import { ToastContainer } from "react-toastify";

const ViewStore = ({isChanged, setIsChanged}) => {
    return (
      <>
        <ToastContainer />
        <div className={styles.row}>
            <div className={styles.col}>
                <StoreInformation isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
            </div>
        </div>
      </>
    )
  }
  
  export default ViewStore