import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"

const MemberPermissionDropdown = ({className, type, classLabel, classDropdownHead, classDropdownLabel, list, value, setValue, index, permission, setPermission, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);

    const handleClick = (e) => {
        let data = [...list]
        data[index].permission = e
        setValue(data)
        setVisible(false)
    }

      
    return (
        <div className={type == 'modal' ? styles.dropdownModal : type == 'table' ? styles.tableDropdown : styles.groupDropdown}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                    <div className={styles.selection}>{value}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {options && options.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default MemberPermissionDropdown