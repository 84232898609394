import React, { useEffect } from 'react'
import CurrencyInfo from './CurrentInformation'
import CurrencyAction from './CurrentAction'
import styles from './editCurrency.module.sass'
import { ToastContainer } from 'react-toastify'

const EditCurrency = ({isChanged, setIsChanged}) => {
  
  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
          <CurrencyInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default EditCurrency