import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import AddStepsModal from "../../../../../../components/EngageComponents/AchievementComponent/StepSeriesComponent/Modal/AddStepsModal";
import Tooltips from "../../../../../../components/Tooltip";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";

const Control = ({className, width, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, setValue, PMCount, PMPage, setStepSeries}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions && actions.map((x, index) => (
            <div>
              {/* <span>Add Step<span><Tooltips className={styles.tooltip} title={TooltipTitle.SSAddTask} icon="info"/></span></span> */}
              <span onClick={x.action} className={styles.add}><AddMetaIcon /></span>
            </div>
        ))}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddStepsModal currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
