import { GET_COMPETITION_TOURNAMENTS, GET_COMPETITION_INSTANT_BATTLE, CREATE_COMPETITION_TOURNAMENT, EDIT_COMPETITION_TOURNAMENT, EDIT_COMPETITION_INSTANT_BATTLE, CREATE_COMPETITION_INSTANT_BATTLE, GET_COMPETITION_TOURNAMENTS_SUCCESS, GET_COMPETITION_TOURNAMENTS_FAILURE, GET_COMPETITION_INSTANT_BATTLE_SUCCESS, GET_COMPETITION_INSTANT_BATTLE_FAILURE, CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS, CREATE_COMPETITION_TOURNAMENT_SUCCESS, GET_COMPETITION_TOURNAMENTS_DETAILS, GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS, GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE, GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE, GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS, GET_COMPETITION_INSTANT_BATTLE_DETAILS, FILTER_COMPETITION_SUCCESS, GET_COMPETITION_RESULT_ERROR, GET_COMPETITION_RESULT_SUCCESS } from "../../../actionTypes/actionTypes";

const initialState = {
    tournaments : [],
    instantBattle : [],
    tournamentDetail : [],
    instantBattleDetail : [],
    competitionResults : [],
    competitionResultDates : [],
    tournamentError : [],
    instantBattleError : [],
    instantBattleCount : null,
    tournamentCount : null,
    resultCount : null,
    isLoading: false,
    isFiltered: false
};

export const competitionReducer = (state = initialState, action) => {
    switch(action.type){

        case GET_COMPETITION_TOURNAMENTS_SUCCESS : {
            return {
                ...state,
                tournaments: action.payload,
                tournamentError : [],
                tournamentCount : action.totalCount,
                tournamentDetail : [],
                instantBattleDetail : [],
                isLoading: false,
                isFiltered: false
            }
        }

        case GET_COMPETITION_TOURNAMENTS_FAILURE : {
            return {
                ...state,
                instantBattle: [],
                tournamentError : action.payload,
                tournamentDetail : [],
                instantBattleDetail : [],
                isLoading: false,
                isFiltered: false
            }
        }

        case GET_COMPETITION_INSTANT_BATTLE_SUCCESS : {
            return {
                ...state,
                instantBattle: action.payload,
                instantBattleError : [],
                instantBattleCount : action.totalCount,
                tournamentDetail : [],
                instantBattleDetail : [],
                isLoading: false,
                isFiltered: false
            }
        }

        case GET_COMPETITION_INSTANT_BATTLE_FAILURE : {
            return {
                ...state,
                instantBattle: [],
                instantBattleError : action.payload,
                tournamentDetail : [],
                instantBattleDetail : [],
                isLoading: false,
                isFiltered: false
            }
        }

        case FILTER_COMPETITION_SUCCESS : {
            return {
                ...state,
                ...(action.competition === 2 && {tournaments : action.payload, instantBattle: []}),
                ...(action.competition === 3 && {tournaments : [], instantBattle : action.payload}),
                tournamentDetail : [],
                instantBattleDetail : [],
                isFiltered: true,
                isLoading: false
            }
        }

        case GET_COMPETITION_TOURNAMENTS_DETAILS : {
            return {
                ...state,
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS : {
            return {
                ...state,
                tournamentDetail: action.payload,
                instantBattleDetail : []
            }
        }
        
        case GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE : {
            return {
                ...state,
                tournamentDetail: [],
                instantBattleDetail : []
            }
        }

        case GET_COMPETITION_INSTANT_BATTLE_DETAILS : {
            return {
                ...state,
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS : {
            return {
                ...state,
                instantBattleDetail: action.payload,
                tournamentDetail : [],
            }
        }

        case GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE : {
            return {
                ...state,
                instantBattleDetail: [],
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case CREATE_COMPETITION_TOURNAMENT_SUCCESS : {
            return {
                ...state,
                tournaments: [...state.tournaments, action.payload],
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS : {
            return {
                ...state,
                instantBattle: [...state.instantBattle, action.payload],
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case EDIT_COMPETITION_TOURNAMENT : {
            return {
                ...state,
                tournaments: action.payload,
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case EDIT_COMPETITION_INSTANT_BATTLE : {
            return {
                ...state,
                instantBattle: action.payload,
                tournamentDetail : [],
                instantBattleDetail : []
            }
        }

        case GET_COMPETITION_RESULT_SUCCESS : {
            return {
                ...state,
                competitionResults : action.payload,
                competitionResultDates : action.dates,
                resultCount : action.totalCount,
            }
        }

        case GET_COMPETITION_RESULT_ERROR : {
            return {
                ...state,
                competitionResults : [],
                competitionResultDates : [],
                resultCount : null
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    