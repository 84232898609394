import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from './taskFilter.module.sass'
import Icon from "../../../../Icon";
import axios from "axios";
import { BASE_URL } from "../../../../../resources/APIEndpoints";
import Checkbox from "../../../../Checkbox";
import Form from "../../../../Form";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Radio from "../../../../Radio";
import { getCustomEventAction, getDefaultEventAction } from "../../../../../redux/action/builds/events/eventActions";
import moment from 'moment'
import DatePicker from "react-datepicker";
import Item from '../../../../Schedule/Item'
import { format } from "date-fns";
import TextInput from "../../../../TextInput";
import Dropdown from '../../../../../components/Dropdown';
import { getAllTaskAction } from "../../../../../redux/action/engage/Achievement/Task/TaskAction";

const TaskFilterModal = ({className, action, filters, onClose}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    let filterList = [{id: 1, name: 'Task Frequency'}, {id: 2, name: 'Event Name'}, {id: 3, name: 'Date'}, {id: 4, name: 'Frequency'}, {id: 5, name: 'Status'}, {id: 6, name: 'Instance Status'}]
    const [listFilters, setListFilters] = useState(filterList)
    const [activeIndex, setActiveIndex] = useState(0)
    // Event Toggle
    const [isEventToggle, setIsEventToggle] = useState(true)
    // Filter Options
    const [recurringOptions, setRecurringOption] = useState([{id: 7, name: 'Minutes'}, {id: 8, name: 'Hours'}, {id: 1, name: 'Days'}, {id: 2, name: 'Week'}])
    const [eventsOption, setEventOption] = useState([])
    const intervalOption = [{id: 1, name: 'Minutes'}, {id: 2, name: 'Hours'}, {id: 3, name: 'Days'}, {id: 4, name: 'Weeks'}]
    const statusOption  = [{id: 1, name: 'Yet To Start'}, {id: 2, name: 'In Progress'}, {id: 3, name: 'Stopped'}, {id: 4, name: 'Completed'}]
    const instanceStatusOption  = [{id: 1, name: 'Yet To Start'}, {id: 2, name: 'In Progress'}, {id: 3, name: 'Stopped'}, {id: 4, name: 'Completed'}]

    // Start Date and End Date
    const [visibleStartDate, setVisibleStartDate] = useState(false)
    const [visibleEndDate, setVisibleEndDate] = useState(false)

    const getAllEvents = async() => {
        if(!isEventToggle){
            let events = {
                projectId : appid,
                offset: 0,
                limit: 1000
            }
            dispatch(getDefaultEventAction(events))
            dispatch(getCustomEventAction(events))
        }
    }

    const toggleEvent = (index) => {
        if(index === 1){
            setIsEventToggle(false)
        }
    }

    useEffect(() => {
        getAllEvents()
    }, [isEventToggle])

    let customeEvents = useSelector((state) => {
        return state.event.customEvents
    })

    let defaultEvents = useSelector((state) => {
        return state.event.defaultEvents
    })

    const configureEvents = () => {
        // Combine both arrays into a single array
        const updatedCustomEvents = customeEvents && customeEvents.map(event => ({...event, eventType: 'custom'}))
        const updatedDefaultEvents = defaultEvents && defaultEvents.map(event => ({...event, eventType: 'default'})) 
        const events = [...updatedCustomEvents, ...updatedDefaultEvents];
        // Update the state with the combined data
        setEventOption(events);
    }

    useEffect(() => {
        configureEvents()
    }, [customeEvents, defaultEvents])

    // Filter
    const [filterEvent, setFilterEvent] = useState('')
    const [filterStatus, setFilterStatus] = useState('')
    const [filterInstance, setFilterInstance] = useState('')

    const onEventChange = (e, id, type) => {
        if(type === 'default'){
            if(e.target.checked){
                filters.setDefaultEvent([...filters.defaultEvent, id])
              }else{
                filters.setDefaultEvent(filters.defaultEvent.filter(item => item !== id))
            }
        }else{
            if(e.target.checked){
                filters.setCustomEvent([...filters.customEvent, id])
              }else{
                filters.setCustomEvent(filters.customEvent.filter(item => item !== id))
            }
        }
    }

    const onChangeRecurrance = (e) => {
        if(e.target.value === ''){
            filters.setRecurrance('')
        }else{
            filters.setRecurrance(parseInt(e.target.value))
        }
    }

    const onTaskStatus = (e, name) => {
        if(e.target.checked){
            filters.setStatus([...filters.status, (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')])
        }else{
            filters.setStatus(filters.status.filter(item => item !== (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')))
        }
    }

    const onTaskInstanceStatus = (e, name) => {
        if(e.target.checked){
            filters.setInstanceStatus([...filters.instanceStatus, (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')])
        }else{
            filters.setInstanceStatus(filters.instanceStatus.filter(item => item !== (name === 'Yet To Start' ? 'yet to start' : name === 'In Progress' ? 'in progress' : name === 'Completed' ? 'completed' : name === 'Stopped' ? 'stopped'  : '')))
        }
    }


    const saveFilter = () => {
        action()
        onClose()
    }

    const resetFilter = () => {
        filters.setRecurrance(null)
        filters.setRecurranceInterval(null)
        filters.setRecurranceIntervalId(null)
        filters.setFrequency(null)
        filters.setDefaultEvent([])
        filters.setCustomEvent([])
        filters.setStartDate('')
        filters.setEndDate('')
        filters.setStatus([])
        filters.setInstanceStatus([])  
        let getAllTask = {
            projectId : appid,
            ids : [],
            //Sort
            sortOrder : 'asc',
            sortField : 'updated_at',
            // Pagination
            offset: 0,
            limit: 8
        }
        dispatch(getAllTaskAction(getAllTask))
        onClose()
    }

    return(
        <>
            <div className={styles.filterModal}>
                <div className={styles.filterContainer}>

                    <div className={styles.filterHead}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Filters</span>
                        </div>
                    </div>

                    <div className={styles.filterBody}>
                        <div className={styles.seperator}></div>
                        <div className={styles.bodyContainer}>
                            <div className={styles.filterList}>
                                {listFilters && listFilters.map((e, index) => {
                                    return(
                                        <>
                                            
                                            <div className={cn(styles.filterItem, className,{ [styles.active]: index===activeIndex})} key={index} onClick={() => {setActiveIndex(index);toggleEvent(index)}}>
                                                <div className={styles.filterText}>
                                                    {e.name}
                                                    {e.name == 'Task Frequency' && filters.frequency !== null && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Event Name' && (filters.defaultEvent?.length + filters.customEvent?.length) > 0 && <span className={styles.countPill}>{filters.defaultEvent?.length + filters.customEvent?.length}</span>}
                                                    {e.name == 'Date' && (filters.startDate !== '' || filters.endDate !== '') && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Frequency' && (filters.recurrance !== null || filters.recurranceIntervalId !== null) && <span className={styles.countPill}>{1}</span>}
                                                    {e.name == 'Status' && filters.status?.length > 0 && <span className={styles.countPill}>{filters.status?.length}</span>}
                                                    {e.name == 'Instance Status' && filters.instanceStatus?.length > 0 && <span className={styles.countPill}>{filters.instanceStatus?.length}</span>}
                                                </div>
                                                {activeIndex == index && <div className={styles.filterIcon}><Icon name="arrow-down" size='20'/></div>}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={styles.filterElement}>
                                <div className={styles.filterContent}>
                                    {activeIndex == 0 && 
                                        <>
                                            <div className={styles.fieldRadio}>
                                                <div className={styles.radioLabel}>
                                                    <span>Is Recurring?</span>
                                                </div>
                                                <div className={styles.variants}>
                                                    <Radio className={styles.radio} name="frequency" content="Yes" value={filters.frequency != null ? filters.frequency : null} onChange={() => {filters.setFrequency(true)}}/>
                                                    <Radio className={styles.radio} name="frequency" content="No" value={filters.frequency != null ? !filters.frequency : null} onChange={() => {filters.setFrequency(false)}}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 1 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Event Name" type="text" name="search" icon="search" onChange={(e) => {setFilterEvent(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {eventsOption && eventsOption.filter(option => option.name && option.name.toLowerCase().includes(filterEvent.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.defaultEvent.includes(x.id) || filters.customEvent.includes(x.id)} onChange={(e)=>{onEventChange(e, x.id, x.eventType)}}/>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 2 &&
                                        <>
                                            <div className={styles.group}>
                                                <div className={styles.field}>
                                                    <span className={styles.label}>Start Date</span>
                                                </div>
                                            </div>
                                            <div className={styles.group}>
                                                <Item className={styles.field} category="Start Date" icon="calendar" value={filters.startDate && format(filters.startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                                                    <div className={styles.date}>
                                                        <DatePicker selected={filters.startDate} onChange={(date) => {filters.setStartDate(date); setVisibleStartDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline/>
                                                    </div>
                                                </Item>                                                
                                            </div>

                                            <div className={styles.group}>
                                                <div className={styles.field}>
                                                    <span className={styles.label}>End Date</span>
                                                </div>
                                            </div>
                                            <div className={styles.group}>
                                                <Item className={styles.field} category="End Date" icon="calendar" value={filters.endDate && format(filters.endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                                                    <div className={styles.date}>
                                                        <DatePicker selected={filters.endDate} onChange={(date) => {filters.setEndDate(date); setVisibleEndDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline/>
                                                    </div>
                                                </Item>                                                
                                            </div>
                                        </>
                                    }
                                    {activeIndex === 3 &&
                                        <>
                                            <div className={styles.group}>
                                                <TextInput label='Interval Length' className={styles.textInput} value={filters.recurrance} onChange={(e) => {onChangeRecurrance(e)}}/>
                                            </div>
                                            <Dropdown label='Interval Unit' placeholder='Select Interval Unit' value={filters.recurranceInterval} options={recurringOptions} setValue={filters.setRecurranceInterval} setId={filters.setRecurranceIntervalId}/>
                                        </>
                                    }
                                    {activeIndex == 4 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Status" type="text" name="search" icon="search" onChange={(e) => {setFilterStatus(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {statusOption && statusOption.filter(option => option.name && option.name.toLowerCase().includes(filterStatus.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.status.includes(x.name === 'Yet To Start' ? 'yet to start' : x.name === 'In Progress' ? 'in progress' : x.name === 'Completed' ? 'completed' : x.name === 'Stopped' ? 'stopped'  : '')} onChange={(e)=>{onTaskStatus(e, x.name)}} />
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {activeIndex == 5 && 
                                        <>
                                            <div className={styles.form}>
                                                <Form placeholder="Search Instance Status" type="text" name="search" icon="search" onChange={(e) => {setFilterInstance(e.target.value)}}/>
                                            </div>
                                            <div className={styles.filterCheckbox}>
                                                {instanceStatusOption && instanceStatusOption.filter(option => option.name && option.name.toLowerCase().includes(filterInstance.toLowerCase())).map((x, i) => {
                                                    return(
                                                        <Checkbox key={i} className={styles.checkbox} toggle={true} reverse={true} content={x.name} value={filters.instanceStatus.includes((x.name === 'Yet To Start' ? 'yet to start' : x.name === 'In Progress' ? 'in progress' : x.name === 'Completed' ? 'completed' : x.name === 'Stopped' ? 'stopped'  : ''))} onChange={(e)=>{onTaskInstanceStatus(e, x.name)}} />
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.filterFooter}>
                        <div className={styles.button}>
                            <button onClick={saveFilter} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Apply</button>
                            <button onClick={resetFilter} className={cn("button-white-small")}>Reset</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TaskFilterModal