import { GET_DEFAULT_EVENTS, GET_CUSTOM_EVENTS, ADD_CUSTOM_EVENTS, EDIT_DEFAULT_EVENTS, EDIT_CUSTOM_EVENTS, FILTER_DEFAULT_SUCCESS, FILTER_CUSTOM_SUCCESS, FILTER_EVENT } from "../../../actionTypes/actionTypes";
import eventServices from "../../../../services/buildServices/eventServices/eventServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getDefaultEventAction = (body) => {
    return async (dispatch) => {
        await eventServices.getDefaultEvent(body)
        .then(res => {
            dispatch(getDefaultEvent(res.data.data.appEventDetails, res.data.data.totalCount))
        })
    }
}

export const getCustomEventAction = (body) => {
    return async (dispatch) => {
        await eventServices.getCustomEvent(body)
        .then(res=>{
            dispatch(getCustomEvent(res.data.data.appEventDetails, res.data.data.totalCount))
        })
    }
}

export const filterDefaultEventsAction = (body) => {
    return async (dispatch) => {
        await eventServices.getDefaultEvent(body)
        .then(res => {
            dispatch(fileterEvent(res.data.data.appEventDetails, null, FILTER_EVENT))
        }).catch(err => {
            console.log(err)
        })
    }
}

export const filterCustomEventsAction = (body) => {
    return async (dispatch) => {
        await eventServices.getCustomEvent(body)
        .then(res => {
            dispatch(fileterEvent(res.data.data.appEventDetails, null, FILTER_EVENT))
        }).catch(err => {
            console.log(err)
        })
    }
}

export const createCustomEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await eventServices.createCustomEvent(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/events/${appid}`)
            }, 2000)
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const editDefaultEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await eventServices.editDefaultEvent(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/events/${appid}`)
            }, 2000)
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const editCustomEventAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await eventServices.editCustomEvent(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/events/${appid}`)
            }, 1500)
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const getDefaultEvent = (data, count) => {
    return {
        type : GET_DEFAULT_EVENTS,
        payload : data,
        totalCount : count
    }
}

export const getCustomEvent = (data, count) => {
    return {
        type : GET_CUSTOM_EVENTS,
        payload : data,
        totalCount : count
    }
}

export const createCustomEvent = (data) => {
    return {
        type : ADD_CUSTOM_EVENTS,
        payload : data
    }
}

export const editDefaultEvent = (data) => {
    return {
        type : EDIT_DEFAULT_EVENTS,
        payload : data
    }
}

export const editCustomEvent = (data) => {
    return {
        type : EDIT_CUSTOM_EVENTS,
        payload : data
    }
}

export const fileterEvent = (data, count, type) => {
    return {
        type : type,
        payload : data
    }
}