import axios from "../../../utils/interceptor";
import { BASE_URL, GET_LIVE_OPS_RESULT, STOP_TASK, STOP_TASK_GROUP, PAUSE_PLAY_LIVE_OPS, CREATE_LIVE_OPS_ACHIEVEMENT, GET_LIVE_OPS_CATEGORY, SCHEDULE_LIVE_OPS, RESCHEDULE_LIVE_OPS, GET_SCHEDULE_COMPETITION,CREATE_LIVE_OPS_CATEGORY,EDIT_SCHEDULE_COMPETITION, STOP_LIVE_OPS, CREATE_LIVE_OPS_TASK, CREATE_LIVE_OPS_TASK_GROUP,GET_CALENDAR} from "../../../resources/APIEndpoints";

function LiveOpsServices(){
    this.getLiveOpsCategory = (body) => axios.post(BASE_URL + GET_LIVE_OPS_CATEGORY,body)
    this.getLiveOpsResult = (body) => axios.post(BASE_URL + GET_LIVE_OPS_RESULT, body)
    this.createLiveOpsCategory = (body) => axios.post(BASE_URL + CREATE_LIVE_OPS_CATEGORY,body)
    this.scheduleTask = (body) => axios.post(BASE_URL + CREATE_LIVE_OPS_TASK, body)
    this.scheduleTaskGroup = (body) => axios.post(BASE_URL + CREATE_LIVE_OPS_TASK_GROUP, body)
    this.scheduleLiveOps = (body) => axios.post(BASE_URL + SCHEDULE_LIVE_OPS,body)
    this.rescheduleLiveOps = (body) => axios.post(BASE_URL + RESCHEDULE_LIVE_OPS,body)
    this.stopLiveOps = (body) => axios.post(BASE_URL + STOP_LIVE_OPS,body)
    this.stopTask = (body) => axios.post(BASE_URL + STOP_TASK, body)
    this.stopTaskGroup = (body) => axios.post(BASE_URL + STOP_TASK_GROUP, body)
    this.pausenplayLiveOps = (body) => axios.post(BASE_URL + PAUSE_PLAY_LIVE_OPS, body)
    this.getAllScheduleCompetition = (body) => axios.post(BASE_URL + GET_SCHEDULE_COMPETITION,body)
    this.editscheduleCompetition = (body) => axios.post(BASE_URL + EDIT_SCHEDULE_COMPETITION,body)
    this.getCalendar = (body) => axios.post(BASE_URL + GET_CALENDAR,body)   
}

export default new LiveOpsServices();