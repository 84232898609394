import {FILTER_SCHEDULE_COMPETITION_SUCCESS, GET_CALENDAR, GET_LIVE_OPS_CATEGORY,GET_SCHEDULE_COMPETITION, LIVE_OPS_RESULT, LIVE_OPS_RESULT_ERROR, LIVE_OPS_RESULT_SUCCESS} from "../../../actionTypes/actionTypes";

const initialState = {
    liveOpsCategory : [],
    filteredScheduleCompetition : [],
    liveOpsCalendar:[],
    liveOpsPD : [],
    isSearched : true,
    CompetitionSchedule :[],
    totalCount : null,
    resultCount: null
};


export const liveOpsReducer = (state=initialState, action) => {

    switch(action.type){
        case GET_LIVE_OPS_CATEGORY :{
            return {
                ...state,
                liveOpsCategory: action.payload,
            }
        }

        case GET_CALENDAR :{
            return {
                ...state,
                liveOpsCalendar: action.payload,
            }
        }

        case GET_SCHEDULE_COMPETITION :{
            return {
                ...state,
                CompetitionSchedule: action.payload,
                isLoading : false,
                totalCount : action.totalCount,
                filteredScheduleCompetition : [],
                isSearched : true
             }
        }

        case FILTER_SCHEDULE_COMPETITION_SUCCESS :{
            return {
                ...state,
                filteredScheduleCompetition : action.payload,
                isSearched : false
             }
        }

        case LIVE_OPS_RESULT : {
            return {
                ...state,
                liveOpsPD : [],
                resultCount : 0
            }
        }

        case LIVE_OPS_RESULT_SUCCESS : {
            return {
                ...state,
                liveOpsPD : action.payload,
                resultCount : action.totalCount
            }
        }
        
        case LIVE_OPS_RESULT_ERROR : {
            return {
                ...state,
                liveOpsPD : [],
                resultCount : 0
            }
        }

        default: {
            return {
                ... state
            }
        }
    }
}