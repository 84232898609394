import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, type, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    if(type === 'Progression Marker'){
        return (
            <>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.item}>
                            <div className={styles.preview}>
                                <img src={item.progressionMarker.iconUrl != null ? item.progressionMarker.iconUrl : ProgressionMarker} alt={`Players_${item.id}`} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>{item.progressionMarker.name}</div>
                    <div className={styles.col}>{item.progressionMarker.progressionMarkerId}</div>
                    <div className={styles.col}>{parseInt(item.amount)}</div>
                    <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.row}>
                    <div className={styles.col}></div>
                    <div className={styles.col}>{item.name}</div>
                    <div className={styles.col}>{item.progressionMarker}</div>
                    <div className={styles.col}>{item.currentLevel}</div>
                    <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>
            </>
        )
    }
};

export default Row;
