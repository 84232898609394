import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./textInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const TextInput = ({className, index, value, setValue, fieldName, classLabel, classInput, label, icon, copy, currency, style, tooltip, place, icons, error, groupBy, ...props}) => {

  useEffect(()=>{

  }, [value])

  return (
    <div className={groupBy == 'value' ? cn(styles.fieldRule, { [styles.fieldIcon]: icon }, { [styles.fieldCopy]: copy }, { [styles.fieldCurrency]: currency }, className ) : cn(styles.field, { [styles.fieldIcon]: icon }, { [styles.fieldCopy]: copy }, { [styles.fieldCurrency]: currency }, className )}>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip className={styles.tooltip} title={tooltip} icon="info" place={place ? place : "right"} />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <div className={cn(classInput, styles.input)}><div className={styles.values}>{JSON.stringify(value)}</div></div>
        {currency && <div className={styles.currency}><div className={styles.code}>&#123; &#125;</div></div>}
      </div>
    </div>
  );
};

export default TextInput;
