import React, {useState, useRef, useEffect} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import styles from './stopModal.module.sass'
import { useNavigate,useParams } from 'react-router-dom'
import { getAllMetaDataAction } from '../../../redux/action/common/commonAction'
import { stopLiveOpsAction, stopTaskAction, stopTaskGroupAction } from '../../../redux/action/engage/LiveOps/LiveOpsAction'

const StopScheduleModal = ({className, name, type, as, id, scheduleId, instanceId, liveOps, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const Stop = () => {
    if(type === 'Leaderboard' || type === 'Instant Battle' || type === 'Tournament'){
      let stopLiveOps = {
        instanceId : instanceId,
        leaderboardId : id
      }
      dispatch(stopLiveOpsAction(stopLiveOps,type, as, appid, onClose, navigate,id))
    }else if(type === 'Task'){
      let stopLiveOps = {
        taskId : id
      }
      dispatch(stopTaskAction(stopLiveOps, onClose, type, appid))
    }else if(type === 'StepSeries' || type === 'Mission'){
      let stopLiveOps = {
        taskGroupId : id
      }
      dispatch(stopTaskGroupAction(stopLiveOps, onClose, type, appid))
    }
  }

  return (
        <div className={styles.scheduleModal}>
          <div className={styles.scheduleContainer}>
            <div className={styles.modalHeader}>
              <div className={styles.modalHeading}>
                  <span>Stop {type}</span>
                </div>
            </div>

            <div className={styles.modalBody}>
              {type === 'Leaderboard' &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the leaderboard <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and no new scores will be accepted. This termination applies to all instances of the current and recurring leaderboards.</span>
                </>
              }
              {(type === 'Tournament' || type === 'Instant Battle') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the competition <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and no new scores will be accepted. This termination applies to all instances of the current and recurring competitions.</span>
                </>
              }
              {(type === 'Task') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the task <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the task will no longer be available for completion. This termination applies to all instances of the current and recurring tasks.</span>
                </>
              }
              {(type === 'Mission') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the mission <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the mission will no longer be available for completion. This termination applies to all instances of the current and recurring missions.</span>
                </>
              }
              {(type === 'StepSeries') &&
                <>
                  <span className={styles.warningContent}>Are you sure you want to permanently stop the step series <span className={styles.name}>{name}</span>?</span>
                  <span className={styles.warningContent}>&nbsp;This action cannot be undone, and the step series will no longer be available for completion. This termination applies to all instances of the current and recurring step series.</span>
                </>
              }
            </div>

            <div className={styles.modalFooter}>
              <button className={styles.buttonDanger} onClick={Stop}>Confirm</button>
              <button className={styles.buttonSecondary} onClick={onClose}>Cancel</button>
            </div>

          </div>
        </div>
  )
}

export default StopScheduleModal