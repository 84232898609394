// import React, { useState } from "react";
// import {FieldSelectorProps} from "react-querybuilder";
// import OutsideClickHandler from "react-outside-click-handler";
// import cn from 'classnames'
// import styles from "./CustomFieldSelector.module.sass"
// import { useEffect } from "react";
// // import Tooltip from "../Tooltip";

// const Dropdown = (FieldSelectorProps, className, classLabel, classDropdownHead, small, upBody, fields, value, options) => {

//     const [visible, setVisible] = useState(false)

//     const handleClick = (event) => {
//         let field = {name : event.name, id : event.id}
//         FieldSelectorProps.handleOnChange(field)
//         setVisible(false)
//     };
      
//     return (
//         <div className={styles.dropdowns}>
//             <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
//             <div className={cn(classLabel, styles.label)}>
//                 <span>Select Parameter</span>
//             </div>
//             <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
//                 <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
//                 <div className={styles.selection}>{(FieldSelectorProps.value == null || FieldSelectorProps.value == undefined || FieldSelectorProps.value == '') ? 'Select Parameter' : FieldSelectorProps.value}</div>
//                 </div>
//                 <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
//                 {FieldSelectorProps.options && FieldSelectorProps.options.map((x, index) => (
//                     <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
//                         {x.name}
//                     </div>
//                 ))}
//                 </div>
//             </div>
//             </OutsideClickHandler>
//         </div>
//     )
// }

// export default Dropdown

import React, { useState } from "react";
import {FieldSelectorProps, OperatorSelectorProps} from "react-querybuilder";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./CustomFieldSelector.module.sass"
import { useEffect } from "react";
import TooltipTitle from "../../../../../../../Tooltip/TooltipTitle";
import Tooltips from "../../../../../../../components/Tooltip";
// import Tooltip from "../Tooltip";

const Dropdown = (FieldSelectorProps, OperatorSelectorProps, className, classLabel, classDropdownHead, small, upBody, fields, value, options) => {

    const [visible, setVisible] = useState(false)


    const handleClick = (event) => {
        let field = {
            name : event.name, 
            id : event.id,
            // dataType: event.dataType ? event.dataType.dataTypeName : undefined, 
            dataType: event.inputType ? event.inputType :event.dataType.dataTypeName?event.dataType.dataTypeName: null,
            type : event.type
        }
        FieldSelectorProps.handleOnChange(field)
        setVisible(false)
    };
      
    return (
        <div className={styles.dropdowns}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(classLabel, styles.label)}>
                <span>Select Parameter<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskParameter} icon="info"/></span></span>
            </div>
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                <div className={styles.selection}>{(FieldSelectorProps.value == null || FieldSelectorProps.value == undefined || FieldSelectorProps.value == '') ? 'Select Parameter' : FieldSelectorProps.value.name}</div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {FieldSelectorProps.options && FieldSelectorProps.options.map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        {x.name}
                    </div>
                ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown