import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./DataTypes.module.sass"
import Tooltip from "../../../Tooltip";

const Dropdown = ({className, disabled, classLabel, classDropdownHead, classDropdownLabel, value, setValue, index, data, setData, options, label, small, upBody, tooltip}) => {

    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        let datas = [...data]
        datas[index].dataType = value.name
        datas[index].dataTypeId = value.id
        setData(datas)
        setVisible(false);
    };
      
    return (
        <div className={styles.Dropdown}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                {label}{" "}
                {tooltip && (
                    <Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />
                )}
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small] : small },{[styles.active] : visible}, {[styles.disabled] : disabled})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                    <div className={styles.selection}>{value == null || value == '' || value == undefined ? <span className={styles.placeholder}>Select Type</span> : value }</div>
                </div>
                {!disabled &&
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options && options.map((x, index) => (
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                                {x.name}
                            </div>
                        ))}
                    </div>
                }
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown