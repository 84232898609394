import React, {useState, useRef} from 'react'
import cn from 'classnames'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import styles from './addCategoryModal.module.sass'
import TextInput from '../../../TextInput'
import {createScheduleLiveOpsAction,createLiveOpsCategoryAction} from '../../../../redux/action/engage/LiveOps/LiveOpsAction'
import ColorRadio from '../../../../components/ColorRadio'
import { useNavigate,useParams } from 'react-router-dom'


const AddAppModal = ({className, onClose}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams();

  const [appName, setAppName] = useState()

  const [radioColor, setRadioColor] = useState()

  const colorOption=["#EFEFEF","#FFBC99","#CABDFF","#B1E5FC","#B5E4CA","#FFD88D","#FFB7F5","#FF8D8D","#8DA0FF"]

  const onChangeAppName = (e) => {
    setAppName(e.target.value)
  }

  const save = () => {
      let createLiveOpsCategory = {
        name: appName,
        colorCode:radioColor,
        projectId:appid,
      }
      dispatch(createLiveOpsCategoryAction(createLiveOpsCategory, navigate, appid))
      onClose()
  }

  return (
    <div className={styles.addAppModal}>
      <div className={styles.addAppModalBody}>
        <div className={styles.modalHeading}>
            <div className={styles.block}></div><span>Create Category</span>
        </div>
        <div>
              <TextInput type='text' label='Name' placeholder='Enter Category Name' onChange={(e)=>{onChangeAppName(e)}}/>
          </div>
      </div>
      <div className={styles.group}>
      {colorOption.map((color, index) => {
          return (
              <div key={index} className={styles.variants}>
                  <ColorRadio colorCode={color} id={index} name="color" value={radioColor==color} onChange={()=>{setRadioColor(color)}}/>
              </div>
          );
      })}
          
      </div>

      <div className={styles.btns}>
        <button className={styles.save} onClick={save}>Save</button>
      </div>
    </div>
  )
}

export default AddAppModal