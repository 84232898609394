import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './apps.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import AppTableLayout from '../../../../components/Organisation/AppSetting/AppTableLayout'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import { filterAppsAction, getListOfAppsAction, getallAppAction } from '../../../../redux/action/apps/appsAction'
import Control from '../../../Organization/Apps/GetApps/Control'
import Loader from '../../../../components/Loader'
import EmptyStates from '../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton'
import {ReactComponent as EmptyState} from '../../../../media/images/Apps_Initial_State.svg'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const Apps = ({className, setAppName, setShowLoader, setLoaderType}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const globalStates = useContext(GlobalStateContext)

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(8)

  const getAllApps = async() => {
    let allApps = {
      offset: ((page-1)*limit),
      limit: limit
    }
    await dispatch(getListOfAppsAction(allApps))
  }

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(() =>{
    configureSkeleton()
    getAllApps()
  }, [page])

  let apps = useSelector((state) => {
    return state.apps.appList
  })

  let isLoading = useSelector((state) => {
    return state.apps.isFetched
  })

  let totalApps = useSelector((state) => {
    return state.apps.totalAppCount
  })

  let isSearched = useSelector((state) => {
    return state.apps.isSearched
  })

  let filterApp = useSelector((state) => {
    return state.apps.filterApp
  })
  
  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [searchFlag, setSearchFlag] = useState(false)

  const onSearch = (search) => {
    if(search == ''){
      setSearchFlag(false)
      getAllApps()
    }else{
      setSearchFlag(true)
      let searchApps = {search : search, offset: ((page-1)*limit), limit: limit}
      dispatch(filterAppsAction(searchApps))
    }
  }

  return(
    <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={70} height={32}/>: "Apps"} classTitle={cn("title-purple", styles.title)}
          head={
            (
              <>
                <div className={styles.add}>
                  {showSkeleton && apps.length  > 0 && globalStates?.user?.type === 'admin' ?
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                  : !showSkeleton && apps.length  > 0 && globalStates?.user?.type === 'admin'?
                  <>
                    <div className={styles.createButton}>
                      <Control type='small' setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType}/>
                    </div>
                  </>
                  : ''}
                </div>
              </>
            )

          }
        >
        
          <div className={styles.wrapper}>
            {apps.length > 0 && <div className={styles.seprator}></div>}
              <div className={styles.options}>
                <div className={styles.filters}>
                  {(showSkeleton && apps.length > 0) ?
                    <>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={74} height={34}/>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={130} height={34}/>
                    </>
                  : !showSkeleton && apps.length > 0 ?
                    <>
                      <div className={styles.buttons}>
                          <Icon name='filter' size='20' className={styles.icon} />
                          <span className={styles.buttonText}>Filter</span>
                      </div>
                      <div className={styles.buttons}>
                          <ArchiveSvg className={styles.icon} />
                          <span className={styles.buttonText}>Show Archived</span>
                      </div>
                    </>
                  : ''}
                  
                </div>
                <div className={styles.searchForm}>
                {showSkeleton && apps.length > 0 ?
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={220} height={40}/>
                : !showSkeleton && apps.length > 0 ?
                  <Form className={styles.form} placeholder="Search Apps" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                : ''}
                </div>
            </div>
            {showSkeleton && 
              (apps.length == 0 ?
                <>
                
                </>
              : 
                <SkeletonTable rows={apps.length} column={6} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[44, 150, 80, 80, 80, 40]} colHeight={[44, 25, 25, 25, 25, 40]} />
              )
            }

            {!showSkeleton && 
              (apps.length == 0 ?
                  <div className={styles.emptyState}>
                    <div className={styles.emptyStateSVG}>
                      <EmptyState />
                    </div>
                    <div className={styles.emptyStateContent}>
                      <span>Feels a little empty over here!</span>
                    </div>
                    <div className={styles.emptyStateButton}>    
                      <Control setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType}/>
                    </div>
                  </div>
              : searchFlag ?
                <>
                  {isSearched && <div className={styles.textCenter}><Loader /></div>}
                  {!isSearched && filterApp.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
                  {!isSearched && filterApp.length > 0 && <AppTableLayout to='accepted' setAppName={setAppName} setShowLoader={setShowLoader} items={filterApp} userType={globalStates?.user?.type} title="Last edited" />}
                </>
              : <AppTableLayout to='accepted' items={apps} setAppName={setAppName} setShowLoader={setShowLoader} setLoaderType={setLoaderType} userType={globalStates?.user?.type} title="Last edited" />
              )
            }
            
            {!isLoading && apps.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(totalApps/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
          </div>
        
        {/* {isLoading ?
          <>
            <div className={styles.wrapper}>
              <div className={styles.loading}>
                <Loader className={styles.loader}/>
              </div>
            </div>
          </>
        : isLoading == false && apps.length == 0 ?
        <>
          <div className={styles.wrapper}>
            <EmptyStates type="Apps" setAppName={setAppName} setShowLoader={setShowLoader} />
          </div>
        </>
        : isLoading == false && apps.length > 0 ?
        <>
                <div className={styles.wrapper}>
                    <div className={styles.seprator}></div>
                    <div className={styles.options}>
                      <div className={styles.filters}>
                        <div className={styles.buttons}>
                          <Icon name='filter' size='20' className={styles.icon} />
                          <span className={styles.buttonText}>Filter</span>
                        </div>
                        <div className={styles.buttons}>
                          <ArchiveSvg className={styles.icon} />
                          <span className={styles.buttonText}>Show Archived</span>
                        </div>
                      </div>
                      <div className={styles.searchForm}>
                        <Form className={styles.form} placeholder="Search Apps" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                      </div>
                  </div>
                  <AppTableLayout to='accepted' items={apps} setAppName={setAppName} setShowLoader={setShowLoader} title="Last edited" />
                </div>
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        sx={{
                          '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          },
                          '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                          }
                        }}
                      />
                    )} siblingCount={1} count={Math.ceil(totalApps/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            </>       
        : ''} */}

        </Card>        
    </>
  )

}

export default Apps