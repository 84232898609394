import React, { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./customDropdown.module.sass";
import Tooltip from "../../Tooltip";
import Tooltips from "../../Tooltip";
import { getCustomEventAction, getDefaultEventAction } from "../../../redux/action/builds/events/eventActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Dropdown = ({ className, disabled, id, position, disable, currency, check, groupBy, error, customHandleFunction, errorMessage, classDropdownHead, classDropdownLabel, placeholder, value,  options, label, small, upBody, tooltip }) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState('');
    const containerRef = useRef();
    const dispatch = useDispatch();
    const { appid } = useParams();

    const filteredOptions = options&&options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        margin:"4px",
        background: "#FFFFFF",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div id={id} className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setFilterText('');setVisible(false);  }}>
                {label && (
                    <div className={styles.groupLabel}>
                        <div className={cn(styles.label, classDropdownLabel)}>
                            {label}
                            {tooltip && (
                                <Tooltips
                                    className={styles.tooltip}
                                    title={tooltip}
                                    icon="info"
                                    place="right"
                                />
                            )}
                        </div>
                        <div className={styles.error}>
                            {(value == null || value == undefined || value == '' || (Array.isArray(value) && value.length === 0)) && errorMessage}
                        </div>
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={(errorMessage && (value == null || value == undefined || value == '' || (Array.isArray(value) && value.length === 0))) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection} style={{display:"flex"}}>
                        {currency && <div className={styles.icon} style={{marginRight:"8px"}}>{currency}</div>}
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder={placeholder}
                                value={filterText !== '' ? filterText : (Array.isArray(value) ? '' : value)}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div ref={containerRef} className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {(filteredOptions&&filteredOptions.length>0)?
                            filteredOptions.map && filteredOptions.map((x, index) => (
                                <div onClick={() => {setVisible(false);setFilterText('');customHandleFunction(x)}} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={index}>
                                    {x.name != null ? x.name : x.value}
                                </div>
                            ))
                            :
                            <p className={styles.option} style={{textAlign:"center"}}>No Results Found</p>
                        }
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
            {position === 'bottom' &&
                <div className={styles.error}>
                    {errorMessage}
                </div>
            }
        </div>
    );
}

export default Dropdown;
