import React from 'react'
import GameInfo from './GameInformation'
import styles from './editGame.module.sass'
import { ToastContainer } from 'react-toastify'

const CreateGame = ({isChanged, setIsChanged}) => {

  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
            <GameInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default CreateGame