import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./LeaderboardCalendarDropdown.module.sass"
import Icon from "../../../../../../components/Icon";
import moment from "moment";

const LeaderboardCalendarDropdown = ({className, setCompetitionInstanceId, setCompetitionStatus, disabled, setPlaceHolderDate,getSelectedYearInOrder, getSelectedWeekInOrder, getSelectedMonthInOrder, interval, show, setShow, range, setRange, classLabel, type, classDropdownHead, classDropdownLabel, value, setValue, options, label, small, upBody, tooltip}) => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value.name)
        setCompetitionInstanceId(value.id)
        setCompetitionStatus(value.status)
        setVisible(false)
        // if(interval === 1){
        //     setValue(value.name)
        //     setPlaceHolderDate(true)
        //     setRange([{...range[0], startDate : moment(value.name.split(':')[1]).toDate(), endDate : moment(value.name.split(':')[1]).toDate()}])
        // }else if(interval === 2){
        //     setValue(value.name.split(':')[1])
        //     setPlaceHolderDate(true)
        //     let newRange = getSelectedWeekInOrder(range, moment(value.name.split(' - ')[0].split(' : ')[1]).toDate())
        //     setRange(newRange)
        // }else if(interval === 3){
        //     setValue(value.name.split(':')[1])
        //     setPlaceHolderDate(true)
        //     let newRange = getSelectedMonthInOrder(range, moment(value.name.split(' - ')[0].split(' : ')[1]).toDate())
        //     setRange(newRange)
        // }else if(interval === 4){
        //     setValue(value.name.split(':')[1])
        //     setPlaceHolderDate(true)
        //     let newRange = getSelectedYearInOrder(range, moment(value.name.split(' - ')[0].split(' : ')[1]).toDate())
        //     setRange(newRange)
        // }
        // setVisible(false)
    };

    const abc = (e) => {
        e.stopPropagation(); 
        setVisible(false)
        setShow(!show)
    }

    return(
        <>
            <div className={ styles.calendarDropdown}>
                <OutsideClickHandler onOutsideClick={() => {setVisible(false)}}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                    {label}{" "}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible},{[styles.disabled] : disabled})}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => {setVisible(!visible)}}>
                        <div className={styles.valueContainer}>
                            <div className={styles.selection}>{value}</div>
                        </div>
                    </div>
                    {!disabled &&
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options && options.map((x, index) => (
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                                {x.name}
                            </div>
                        ))}
                        </div>
                    }
                </div>
                </OutsideClickHandler>
            </div>
        </>
    )
}

export default LeaderboardCalendarDropdown