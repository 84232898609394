import { GET_STORES, ADD_STORES, EDIT_STORES, GET_STORES_BY_ID, FILTER_STORES_SUCCESS, GET_STORES_SUCCESS, GET_STORES_ERROR, FILTER_STORES_ERROR} from "../../../../actionTypes/actionTypes";

const initialState = {
    stores : [],
    storeDetails : [],
    isLoading : false,
    isFiltered : false,
    totalCount : null,
    error: null
}

export const storeReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_STORES : {
            return {
               ...state,
               isLoading: true
            }
        }

        case GET_STORES_SUCCESS : {
            return {
               ...state,
               stores: action.payload,
               totalCount : action.totalCount,
               isLoading : false,
               isFiltered : false,
               error: null
            }
        }

        case GET_STORES_ERROR : {
            return {
                ...state,
                isLoading : false,
                error: action.payload
            }
        }

        case FILTER_STORES_SUCCESS : {
            return {
               ...state,
               stores : action.payload,
               totalCount: action.totalCount,
               isLoading: false,
               isFiltered: true
            }
        }

        case FILTER_STORES_ERROR : {
            return {
                ...state,
                isLoading: false,
                errors: action.payload
            }
        }

        case ADD_STORES : {
            return {
                ...state,
                stores: action.payload,
                messages : action.payload
            }
        }

        case EDIT_STORES : {
            return {
                ...state,
                stores: action.payload,
            }
        }

        case GET_STORES_BY_ID : {
            return {
                ...state,
                storeDetails: action.payload,
                isLoading : false
             }
        }

        default : {
            return {
                ...state
            }
        }

    }

}