import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Channel, GridItemProps, Program, useEpg } from "@nessprim/planby-pro";
// Helpers
import { fetchResources, fetchResources2, areasWeek, areasMonth} from "./helpers";

import liveOpsData from "./demoLiveOps.json"
import { Link, useParams } from 'react-router-dom'
import {getCalendarAction} from '../../../../redux/action/engage/LiveOps/LiveOpsAction'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";

// Import theme
import { theme } from "./helpers/theme";
import { format,startOfDay,endOfDay,addDays,addYears,getDaysInMonth, startOfWeek, endOfWeek,startOfMonth, endOfMonth,startOfYear, endOfYear,isLeapYear,addMinutes, addHours, addWeeks, addMonths, parseISO, formatISO} from "date-fns";
import { string } from "prop-types";

import createWorker from './dataWorkerLoader';
// Example of globalStyles
const globalStyles = `
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
 .planby {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  /* Layout */
  .planby-layout {}
  .planby-corner-box {}

  /* Line */
  .planby-line {
  }

  /* Current time */
  .planby-current-time {}
  .planby-current-content {}

  /* Channels */
  .planby-channels {}

  /* Channel */
  .planby-channel {}

  .planby-content {border-top:2px;height:auto}

  planby-grid-item-divider {
    display: none;
    border:none;
  }

  /* Program */
  .planby-program {
    padding:8px 0px;
    pointer-events: auto
  }
  .planby-program-content {
    padding:4px;
    background:#ecf7fa;
    border:2px solid #a0d7e7;
    border-radius: 8px;
    pointer-events: auto
  }
  .planby-program-content:hover {
    opacity: 0.4;
    background:#ffffff
  }

  .planby-program-flex {}
  .planby-program-stack {}
  .planby-program-title {
    font-size: 12px;
    color:#11142d
  }
  .planby-program-text {
    display:none
  }

  /* Timeline */
  .planby-timeline-box::after{
    display:none;
  }
  .planby-timeline-wrapper {}
  .planby-timeline-box {}
  .planby-timeline-time {
    font-size:8px
  }
  .planby-timeline-dividers {
    display:none;
  }
  .planby-timeline-wrapper {}
}
  
`;

export function useApp({CalendarType,isFullscreen,lazyLoadInterval,currentInterval,setCurrentInterval}) {
  const currentDate = format(new Date(), "yyyy-MM-dd");
  const dateCurrent=new Date();
  const startDate = `${currentDate}T00:00:00`;
  const endDate = `${format(addDays(dateCurrent,1), 'yyyy-MM-dd')}T00:00:00`;
  const startWeekDate = format(startOfWeek(dateCurrent), 'yyyy-MM-dd');
  const endWeekDate = format(addDays(endOfWeek(dateCurrent),1), 'yyyy-MM-dd');
  const start30Days = format(startOfMonth(dateCurrent), 'yyyy-MM-dd');
  const end30Days = format(addDays(endOfMonth(dateCurrent),1), 'yyyy-MM-dd');
  const startMonthDate = format(startOfYear(dateCurrent), 'yyyy-MM-dd');
  const endMonthDate = format(addDays(endOfYear(dateCurrent),1), 'yyyy-MM-dd');
  const isMobileMax = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [timezone, setTimezone] = React.useState({"zoneName": "Asia/Kolkata", "abbreviation": "IST"});
  const [dayWidth, setDayWidth] = React.useState(1100);
  const [displayWidth, setDisplayWidth] = React.useState(window.innerWidth-48)
  const [calendarMode, setCalendarMode] = React.useState(
    "day"
  );
  const [dates, setDates] = React.useState({
    start: startDate,
    end: endDate,
  });

  let limitOffset=20


  const [dayLoadDates, setDayLoadDates] = React.useState({
    start: format(addDays(new Date(startDate),-lazyLoadInterval.day), 'yyyy-MM-dd'),
    end: format(addDays(new Date(endDate),lazyLoadInterval.day), 'yyyy-MM-dd'),
  });
  const [weekLoadDates, setWeekLoadDates] = React.useState({
    start: format(addWeeks(new Date(startWeekDate),-lazyLoadInterval.week), 'yyyy-MM-dd'),
    end: format(addWeeks(new Date(endWeekDate),lazyLoadInterval.week), 'yyyy-MM-dd'),
  });
  const [monthLoadDates, setMonthLoadDates] = React.useState({
    start: format(addMonths(new Date(start30Days),-lazyLoadInterval.month), 'yyyy-MM-dd'),
    end: format(addMonths(new Date(end30Days),lazyLoadInterval.month), 'yyyy-MM-dd'),
  });
  const [yearLoadDates, setYearLoadDates] = React.useState({
    start: format(addYears(new Date(startMonthDate),-lazyLoadInterval.year), 'yyyy-MM-dd'),
    end: format(addYears(new Date(endMonthDate),lazyLoadInterval.year), 'yyyy-MM-dd'),
  });

  const [filterStartDate,setFilterStartDate]=useState(new Date(dayLoadDates.start));
  const [filterEndDate,setFilterEndDate]=useState(new Date(dayLoadDates.end));
  
  const [loadedDaysData,setLoadedDaysData]=React.useState([]);
  const [loadedWeeksData,setLoadedWeeksData]=React.useState([]);
  const [loadedMonthsData,setLoadedMonthsData]=React.useState([]);
  const [loadedYearsData,setLoadedYearsData]=React.useState([]);

  const dataa =liveOpsData.data.liveOps;

  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);
  const areas = calendarMode !== "month" ? areasWeek : areasMonth;

  function getDaysInYear(date) {
    const year = date.getFullYear();
    return isLeapYear(year) ? 366 : 365;
  }

  const getDayWidth =()=>{
    let tempwidth=isFullscreen?displayWidth:dayWidth;
    if (calendarMode==="30Days") {
      const additionalDays = 30-getDaysInMonth(dates.start)
      return (tempwidth-((tempwidth/30)*additionalDays))
    }
    else{
      return (tempwidth<1480?1560:tempwidth)
    }
  }

  const { getEpgProps, getLayoutProps,scrollY } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: getDayWidth(),
    startDate:dates.start,
    endDate:dates.end,
    isVerticalMode: false,
    itemHeight: 48,
    isBaseTimeFormat:false,
    isTimeline: true,
    isSidebar: false,
    sidebarWidth:0,
    isCurrentTime:true,
    isLine: true,
    isResize: false,
    isInitialScrollToNow:false,
    dnd: { enabled: false, mode: "row" },
    overlap: {
      enabled: true,
      mode: "stack"
    },
    grid: {
      enabled: true,
      hoverHighlight: true,
    },
    timezone: {
      enabled: true,
      zone: timezone.zoneName,
      mode: "utc",
    },
    ...(calendarMode !== "day" && { mode: { type: (calendarMode=="30Days"?"week":calendarMode), style: "modern" } }),
    ...(calendarMode !== "day"&& areas),
    theme,
    globalStyles
  });
  const [currenScroll,SetCurrentScroll]=useState(0)
  
  const handleChangeCalendarMode = React.useCallback((e) => {
    setEpg([]);
    setChannels([]);
    const newMode = e.target.value;
    setCalendarMode(newMode);
    if (newMode === "week") {
      setFilterStartDate(new Date(weekLoadDates.start));
      setFilterEndDate(new Date(weekLoadDates.end));
      setDates({ start: startWeekDate, end: endWeekDate });
      (loadedWeeksData?.data?.liveOpsDetails?.length)?Calendar=loadedWeeksData:getAllScheduleCompetition()
    } else if (newMode === "30Days") {
      setFilterStartDate(new Date(monthLoadDates.start));
      setFilterEndDate(new Date(monthLoadDates.end));
      setDates({ start: start30Days, end: end30Days });
      (loadedMonthsData?.data?.liveOpsDetails?.length)?Calendar=loadedMonthsData:getAllScheduleCompetition()
    } else if (newMode === "month") {
      setFilterStartDate(new Date(yearLoadDates.start));
      setFilterEndDate(new Date(yearLoadDates.end));
      setDates({ start: startMonthDate, end: endMonthDate });
      (loadedYearsData?.data?.liveOpsDetails?.length)?Calendar=loadedYearsData:getAllScheduleCompetition()
    } else {
      setFilterStartDate(new Date(dayLoadDates.start));
      setFilterEndDate(new Date(dayLoadDates.end));
      setDates({ start: startDate, end: endDate });
      (loadedDaysData?.data?.liveOpsDetails?.length)?Calendar=loadedDaysData:getAllScheduleCompetition()
    }
    handleFetchResources(newMode);
  }, [weekLoadDates, monthLoadDates, yearLoadDates, dayLoadDates, startWeekDate, endWeekDate, start30Days, end30Days, startMonthDate, endMonthDate, startDate, endDate]);

  React.useEffect(() => {
    handleFetchResources(calendarMode);
  }, [calendarMode]);


  const handleChangeTimezone = React.useCallback(
    (e) => {
      setTimezone(e.target.value);
    },
    []
  );
  const dayPrevChange = React.useCallback(
    async()=>{
    if (calendarMode=="day") {
      setCurrentInterval(prev => ({
        ...prev,
        day: prev.day - 1
      }));
      setDates({start:`${format(addDays(dates.start,-1),'yyyy-MM-dd')}T00:00:00`,end:`${format(addDays(dates.end,-1),'yyyy-MM-dd')}T00:00:00`})
    }
    else if (calendarMode=="week") {
      setCurrentInterval(prev => ({
        ...prev,
        week: prev.week - 1
      }));
      setDates({start:format(addDays(dates.start,-7),'yyyy-MM-dd'),end:format(addDays(dates.end,-7),'yyyy-MM-dd')})
    }
    else if (calendarMode=="30Days") {
      setCurrentInterval(prev => ({
        ...prev,
        month: prev.month - 1
      }));
      const daysInPrevMonth = getDaysInMonth(addDays(dates.start,-1));
      const daysInThisMonth = getDaysInMonth(dates.start);
      setDates({start:format(addDays(dates.start,-daysInPrevMonth),'yyyy-MM-dd'),end:format(addDays(dates.end,-daysInThisMonth),'yyyy-MM-dd')})
    }
    else if(calendarMode=="month"){
      setCurrentInterval(prev => ({
        ...prev,
        year: prev.year - 1
      }));

    const dateInstance = new Date(dates.start);
    const startOfPreviousYear = startOfYear(addYears(dateInstance, -1));
    const endOfPreviousYear = endOfYear(startOfPreviousYear);

    setDates( {
        start: format(startOfPreviousYear, 'yyyy-MM-dd'),
        end: format(addDays(endOfPreviousYear,1), 'yyyy-MM-dd')
    });
      
    }
  });

  const dayNextChange = React.useCallback(
    async()=>{
    if (calendarMode=="day") {
      setCurrentInterval(prev => ({
        ...prev,
        day: prev.day + 1
      }));
      
      setDates({start:`${format(addDays(dates.start,1),'yyyy-MM-dd')}T00:00:00`,end:`${format(addDays(dates.end,1),'yyyy-MM-dd')}T00:00:00`})
    }
    else if (calendarMode=="week") {
      setCurrentInterval(prev => ({
        ...prev,
        week: prev.week + 1
      }));
      
      setDates({start:format(addDays(dates.start,7),'yyyy-MM-dd'),end:format(addDays(dates.end,7),'yyyy-MM-dd')})
    }
    else if (calendarMode=="30Days") {
      setCurrentInterval(prev => ({
        ...prev,
        month: prev.month + 1
      }));
      const daysInThisMonth = getDaysInMonth(dates.start);
      const daysInNextMonth = getDaysInMonth(addDays(dates.end,1));
      setDates({start:format(addDays(dates.start,daysInThisMonth),'yyyy-MM-dd'),end:format(addDays(dates.end,daysInNextMonth),'yyyy-MM-dd')})
    }
    else if(calendarMode=="month"){
      setCurrentInterval(prev => ({
        ...prev,
        year: prev.year + 1
      }));
    const startDateInstance = new Date(dates.start);
    const startOfNextYear = startOfYear(addYears(startDateInstance, 1));
    const endOfNextYear = endOfYear(addYears(startDateInstance, 1));
    const formattedStart = format(startOfNextYear, 'yyyy-MM-dd');
    const formattedEnd = format(addDays(endOfNextYear,1), 'yyyy-MM-dd');
    setDates({start: formattedStart,end: formattedEnd});
    }
  });


  function formatDateToString(date) {
    // Create a Date object from the input date
    const d = new Date(date);

    // Get the ISO string and split it to get date and time parts
    const isoString = d.toISOString();
    const [datePart, timePart] = isoString.split('T');
    const time = timePart.split('.')[0]; // Remove milliseconds

    // Format the date string with +00:00 timezone
    const formattedDate = `${datePart} ${time}+00:00`;

    return formattedDate;
}

    /////////////////////////////Get Data API /////////////////////////////////////////////////
    const {appid} = useParams()
    const dispatch = useDispatch()

    const calculateNextInstance = (latestInstanceStart, recurrenceFrequency, intervalUnitId) => {
      const date = latestInstanceStart&& parseISO(latestInstanceStart);
      switch (intervalUnitId) {
        case 7: // Minutes
          return formatISO(addMinutes(date, recurrenceFrequency));
        case 8: // Hours
          return formatISO(addHours(date, recurrenceFrequency));
        case 1: // Days
          return formatISO(addDays(date, recurrenceFrequency));
        case 2: // Weeks
          return formatISO(addWeeks(date, recurrenceFrequency));
        case 3: // Months
          return formatISO(addMonths(date, recurrenceFrequency));
        case 4: // Years
          return formatISO(addYears(date, recurrenceFrequency));
        default:
          return 'Invalid interval unit ID';
      }
    };

    const getLatestInstance = (data) => {
      return data.reduce((latest, current) => {
          const latestStartDate = new Date(latest.startDate);
          const currentStartDate = new Date(current.startDate);
          return currentStartDate > latestStartDate ? current : latest;
      });
  }

    const getAllScheduleCompetition = (addDates=null) => {
      if(addDates==null){
        if(calendarMode=="day"){
          setFilterStartDate(new Date(dayLoadDates.start))
          setFilterEndDate(new Date(dayLoadDates.end))
        }
        else if (calendarMode=="week") {
          setFilterStartDate(new Date(weekLoadDates.start))
          setFilterEndDate(new Date(weekLoadDates.end))
        }
        else if(calendarMode=="30Days"){
          setFilterStartDate(new Date(monthLoadDates.start))
          setFilterEndDate(new Date(monthLoadDates.end))
        }
        else if(calendarMode=="month"){
          setFilterStartDate(new Date(yearLoadDates.start))
          setFilterEndDate(new Date(yearLoadDates.end))
        }
        // alert(`Start${moment.utc(filterStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[000]Z')} End${moment.utc(filterEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[000]Z')}`)
      }
      else{
        if(calendarMode=="day"){
          setFilterStartDate(addDays(new Date(filterStartDate),addDates))
          setFilterEndDate(addDays(new Date(filterEndDate),addDates))
        }
        else if (calendarMode=="week") {
          setFilterStartDate(addWeeks(new Date(filterStartDate),addDates))
          setFilterEndDate(addWeeks(new Date(filterEndDate),addDates))
        }
        else if(calendarMode=="30Days"){
          setFilterStartDate(addMonths(new Date(filterStartDate),addDates))
          setFilterEndDate(addMonths(new Date(filterEndDate),addDates))
        }
        else if(calendarMode=="month"){
          setFilterStartDate(addYears(new Date(filterStartDate),addDates))
          setFilterEndDate(addYears(new Date(filterEndDate),addDates))
        }
        // alert(`Start${moment.utc(filterStartDate).format('YYYY-MM-DDTHH:mm:ss.SSS[000]Z')} End${moment.utc(filterEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS[000]Z')}`)
      }
      /*
        var scrollBefore = 0;

        window.addEventListener('scroll',function(e){
            const scrolled = window.scrollY;

            if(scrollBefore > scrolled){
                console.log("ScrollUP");
                scrollBefore = scrolled;
                //Desired action
            }else{
                scrollBefore = scrolled;
                console.log("ScrollDOWN");
                //Desired action
            }

        }) 
      */
    const CompetitionType = window.location.pathname.includes('tournament')?2:3
    setEpg([])
    setChannels([])
      Calendar=[]
      let payloadCalendar = 
      CalendarType=="leaderboard"?
        {
          entities: {
            "value": "leaderboard",
            "offset": 0,
            "filters":{
              "startDate" :  formatDateToString(filterStartDate),
              "endDate" : formatDateToString(filterEndDate)
            },
            "limit": limitOffset
          },
          projectId : appid
        }:CalendarType=="competition"?
        {
          entities: {
            "value": "competition",
            "offset": 0,
            "filters":{
              "startDate" :  formatDateToString(filterStartDate),
              "endDate" : formatDateToString(filterEndDate)
            },
            "limit": limitOffset
          },
          "competitionFormatTypeMasterId":CompetitionType,
          projectId : appid
        }:CalendarType=="tasks"?
        {
          entities: {
            "value": "tasks",
            "filters":{
              "startDate" :  formatDateToString(filterStartDate),
              "endDate" : formatDateToString(filterEndDate)
            },
            "offset": 0,
            "limit": limitOffset
          },
          projectId : appid
        }:CalendarType=="missions"?
        {
          entities: {
            "value": "missions",
            "filters":{
              "startDate" :  formatDateToString(filterStartDate),
              "endDate" : formatDateToString(filterEndDate)
            },
            "offset": 0,
            "limit": limitOffset
          },
          projectId : appid
        }:CalendarType=="step-series"?
        {
          entities: {
            "value": "step-series",
            "filters":{
              "startDate" :  formatDateToString(filterStartDate),
              "endDate" : formatDateToString(filterEndDate)
            },
            "offset": 0,
            "limit": limitOffset
          },
          projectId : appid
        }:""
      dispatch(getCalendarAction(payloadCalendar))
    }
  let Calendar = useSelector((state) => {
    return state.liveOps.liveOpsCalendar
  })
  function calculateDurationOffset(CurrentStartTime, CurrentEndTime) {
    if(CurrentStartTime&&CurrentEndTime){
    const startDate = new Date(CurrentStartTime);
    const endDate = new Date(CurrentEndTime);
  
    const durationInMilliseconds = endDate.getTime() - startDate.getTime();
  
    const durationInMinutes = Math.round(durationInMilliseconds / 60000);
  
    return durationInMinutes;}
  }
  


  const generateAllInstances = (startDate, recurrenceFrequency, intervalUnitId, recurrenceCount,tmpOffset,TotalStartDate,TotalEndDate) => {
    let instances = [];
    let previousInstances=0;
    let currentDate = new Date(startDate);
  
    if (intervalUnitId == 5 || intervalUnitId == 6) {
      instances.push(new Date(currentDate));
      return {instances:instances,previousInstances:previousInstances};
    } else {
      instances.push(new Date(currentDate));
    }
  //////for Previous instances
    while (true) {
      switch (intervalUnitId) {
        case 7: // Minutes
          currentDate = addMinutes(currentDate, -recurrenceFrequency);
          break;
        case 8: // Hours
          currentDate = addHours(currentDate, -recurrenceFrequency);
          break;
        case 1: // Days
          currentDate = addDays(currentDate, -recurrenceFrequency);
          break;
        case 2: // Weeks
          currentDate = addWeeks(currentDate, -recurrenceFrequency);
          break;
        case 3: // Months
          currentDate = addMonths(currentDate, -recurrenceFrequency);
          break;
        case 4: // Years
          currentDate = addYears(currentDate, -recurrenceFrequency);
          break;
        default:
          return  {instances:instances,previousInstances:previousInstances};
      }

      const endDate = new Date(currentDate);
      endDate.setMinutes(endDate.getMinutes() + tmpOffset);

  
      const instanceEndDate = new Date(endDate);
      const startDateWithinRange = ((currentDate < new Date(filterEndDate))&&(currentDate < new Date(TotalEndDate)));
      const endDateWithinRange = ((instanceEndDate >= new Date(filterStartDate))&&(instanceEndDate >= new Date(TotalStartDate)));
  
      if ((startDateWithinRange || endDateWithinRange)&&(currentDate >= new Date(TotalStartDate))) {
        previousInstances+=1
        instances.push(new Date(currentDate)); 
      }
      if (!(endDateWithinRange&&startDateWithinRange)) {
        break; 
      }

    }
    instances.reverse()
    currentDate = new Date(startDate);
    //////for further instances
    while (true) {
      switch (intervalUnitId) {
        case 7: // Minutes
          currentDate = addMinutes(currentDate, recurrenceFrequency);
          break;
        case 8: // Hours
          currentDate = addHours(currentDate, recurrenceFrequency);
          break;
        case 1: // Days
          currentDate = addDays(currentDate, recurrenceFrequency);
          break;
        case 2: // Weeks
          currentDate = addWeeks(currentDate, recurrenceFrequency);
          break;
        case 3: // Months
          currentDate = addMonths(currentDate, recurrenceFrequency);
          break;
        case 4: // Years
          currentDate = addYears(currentDate, recurrenceFrequency);
          break;
        default:
          return  {instances:instances,previousInstances:previousInstances};
      }

      const endDate = new Date(currentDate);
      endDate.setMinutes(endDate.getMinutes() + tmpOffset);

  
      const instanceEndDate = new Date(endDate);
      const startDateWithinRange = ((currentDate < new Date(filterEndDate))&&(currentDate < new Date(TotalEndDate)));
      const endDateWithinRange = ((instanceEndDate >= new Date(filterStartDate))&&(instanceEndDate >= new Date(TotalStartDate)));
  
      if ((startDateWithinRange || endDateWithinRange)&&(instanceEndDate <= new Date(TotalEndDate))) {
        instances.push(new Date(currentDate)); 
      }
      if (!(startDateWithinRange&&endDateWithinRange)) {
        break; 
      }
    }
    return  {instances:instances,previousInstances:previousInstances};
  };

  const checkOverlap = (start1, end1, start2, end2) => {
    return (start1 < end2 && end1 > start2);
  };

  function generateData(calendarMode) {
    const data = [];
    let currentChannelIndex=0
    const channel = [{ uuid: String(currentChannelIndex), dates: [] }];
    const dat= Calendar.data.liveOpsDetails;
    console.log("sortedDat",dat)
    if (CalendarType == "leaderboard") {
        
        if (calendarMode=="day") {
          dat.forEach((program,pid) => {
            const tmpOffset =calculateDurationOffset(program.scheduleInstances?.[0]?.startDate, program.scheduleInstances?.[0]?.endDate);

          const {instances,previousInstances} = generateAllInstances(currentInterval.day==0?program.scheduleInstances?.[0]?.startDate:filterStartDate, program.recurrenceFrequency, program.intervalUnitId, program.recurrenceCount,tmpOffset,program.startDate, program.endDate);
          while (true) {
              const currentChannelDates = channel[currentChannelIndex]?.dates || [];
              const overlapExists = currentChannelDates.some(dateRange =>
                checkOverlap(new Date(dateRange.start), new Date(dateRange.end), new Date(program.startDate), new Date(program.endDate))
              );
      
              if (!overlapExists) {
                break;
              }
      
              currentChannelIndex += 1;
              if (!channel[currentChannelIndex]) {
                channel.push({ uuid: String(currentChannelIndex), dates: [] });
              }
            }
            instances&&instances.forEach((instanceDate, instanceIndex) => {
            const startDate = new Date(instanceDate);
            const endDate = program.intervalUnitId==5?null:program.intervalUnitId==6? (new Date(program.endDate)):(new Date(instanceDate));
            (program.intervalUnitId!==5&&endDate&&program.scheduleInstances?.[0]?.startDate!==undefined&&program.scheduleInstances?.[0]?.endDate!==undefined&&program.intervalUnitId!==6)&&endDate.setMinutes(endDate.getMinutes() + calculateDurationOffset(program.scheduleInstances?.[0]?.startDate, program.scheduleInstances?.[0]?.endDate));
            
            const isEndDateNull = program?.scheduleInstances?.[0]?.endDate === null || program?.scheduleInstances?.[0]?.endDate === "null"||program?.endDate==null||endDate==null;
            const tillDate = isEndDateNull ? addYears(new Date(startDate), 100).toISOString() : new Date(endDate).toISOString();
            
            channel[currentChannelIndex].dates.push({ start: program.startDate, end: program.endDate });
            data.push({
              channelUuid:channel[currentChannelIndex].uuid,
              id: `${program.id}++${instanceIndex}`,
              title: program.leaderboard?.name || "",
              titleID: program.scheduledId,
              since: startDate.toISOString(),
              till: tillDate,
              isEndDateNull: isEndDateNull,
              recurrenceCount: program.recurrenceCount,
              scheduleType: program.scheduleType,
              currentInstance: program?.scheduleInstances?.length+instanceIndex-previousInstances,
              description: program.leaderboard?.description || "",
              matchName: program?.leaderboard?.match ? program?.leaderboard?.match?.name : "Custom",
              nextInstanceDate: calculateNextInstance(startDate.toISOString(), program.recurrenceFrequency, program.intervalUnitId),
              CalendarType: CalendarType,
              leaderboardOutcomeDetails: program?.leaderboard?.leaderboardOutcomeMaster ? program?.leaderboard?.leaderboardOutcomeMaster?.name : undefined,
              props: {...program}
            });
          });
          currentChannelIndex=0;
          });
        }
        else{
        dat.forEach((program,pid) => {
          const tmpOffset =calculateDurationOffset(program.scheduleInstances?.[0]?.startDate, program.scheduleInstances?.[0]?.endDate);
          const startDate = new Date(program.startDate);
          let endDate = null;
          if (program.intervalUnitId !== 5 && program.intervalUnitId !== 6) {
            endDate = new Date(program.endDate);
          } else if (program.intervalUnitId === 6) {
            endDate = program.endDate ? new Date(program.endDate) : null;
          }

          const isEndDateNull = program?.endDate === null || endDate === null;
          const tillDate = isEndDateNull ? addYears(startDate, 100).toISOString() : endDate.toISOString();

          data.push({
            channelUuid:channel[currentChannelIndex].uuid,
            id: `${program.id}--${pid}`,
            title: program.leaderboard?.name || "",
            titleID: program.scheduledId,
            since: startDate.toISOString(),
            till: tillDate,
            isEndDateNull: isEndDateNull,
            recurrenceCount: program.recurrenceCount,
            scheduleType: program.scheduleType,
            currentInstance: program?.scheduleInstances?.length,
            description: program.leaderboard?.description || "",
            matchName: program?.leaderboard?.match ? program?.leaderboard?.match?.name : "Custom",
            nextInstanceDate: calculateNextInstance(startDate.toISOString(), program.recurrenceFrequency, program.intervalUnitId),
            CalendarType: CalendarType,
            leaderboardOutcomeDetails: program?.leaderboard?.leaderboardOutcomeMaster ? program?.leaderboard?.leaderboardOutcomeMaster?.name : undefined,
            props: { ...program }
          });
        });
        }
    } else if (CalendarType === "competition") {
        if (calendarMode=="day") {
          for (let index = 0; index < dat.length; index++) {
          const latestInstance = dat[index].scheduleInstances && dat[index].scheduleInstances.length > 0 && getLatestInstance(dat[index].scheduleInstances);
          const tmpOffset =calculateDurationOffset(dat[index]?.scheduleInstances?.[0]?.startDate, dat[index]?.scheduleInstances?.[0]?.endDate);
          const {instances,previousInstances} = generateAllInstances(currentInterval.day==0?dat[index]?.scheduleInstances?.[0]?.startDate:filterStartDate, dat[index].recurrenceFrequency, dat[index].intervalUnitId, dat[index].recurrenceCount,tmpOffset,dat[index]?.startDate, dat[index]?.endDate);
          while (true) {
              const currentChannelDates = channel[currentChannelIndex]?.dates || [];
              const overlapExists = currentChannelDates.some(dateRange =>
                checkOverlap(new Date(dateRange.start), new Date(dateRange.end), new Date(dat[index].startDate), new Date(dat[index].endDate))
              );
      
              if (!overlapExists) {
                break;
              }         
      
              currentChannelIndex += 1;
              if (!channel[currentChannelIndex]) {
                channel.push({ uuid: String(currentChannelIndex), dates: [] });
              }
            }
            instances&&instances.forEach((instanceDate, instanceIndex) => {
              const startDate = new Date(instanceDate);
              let endDate;
              if (dat[index].intervalUnitId == 5) {
                  endDate = null;
              } else if (dat[index].intervalUnitId == 6) {
                  endDate = dat[index].endDate ? new Date(dat[index].endDate) : null;
              } else {
                  endDate = new Date(instanceDate);
                  const durationOffset = calculateDurationOffset(dat[index]?.scheduleInstances?.[0]?.startDate, dat[index]?.scheduleInstances?.[0]?.endDate);
                  durationOffset&& endDate.setMinutes(endDate.getMinutes() + parseInt(durationOffset));
              }
              
              const isEndDateNull = endDate === null || dat[index]?.scheduleInstances?.[0]?.endDate === "null" || dat[index]?.endDate === null||endDate==null;
              const tillDate = isEndDateNull ? addYears(new Date(startDate), 100).toISOString() : endDate.toISOString();
  
              channel[currentChannelIndex].dates.push({ start: dat[index].startDate, end: dat[index].endDate });
              data.push({
                  channelUuid:channel[currentChannelIndex].uuid,
                  id: `${dat[index].id}+${instanceIndex}`,
                  title: dat[index]?.competition?.name,
                  titleID: dat[index].scheduledId,
                  since: startDate.toISOString(),
                  till: tillDate,
                  isEndDateNull: isEndDateNull,
                  recurrenceCount: dat[index].recurrenceCount,
                  scheduleType: dat[index].scheduleType,
                  description: dat[index]?.competition?.description,
                  currentInstance: dat[index]?.scheduleInstances?.length+instanceIndex -previousInstances,
                  nextInstanceDate: calculateNextInstance(startDate.toISOString(), dat[index].recurrenceFrequency, dat[index].intervalUnitId),
                  CalendarType: CalendarType,
                  prizeDistributionOffset: dat[index]?.prizeDistributionOffset,
                  prizeDistributionRule: dat[index]?.competition?.prizeDistributionRule,
                  rewardsUnlockCondition: dat[index]?.competition?.rewardsUnlockCondition,
                  matchName: (dat[index]?.competition?.match?.name !== undefined) ? dat[index]?.competition?.match?.name : "Custom",
                  competitionEntryPrices: dat[index]?.competition?.competitionEntryPrices,
                  maxPlayers: dat[index]?.competition?.maxPlayers,
                  minPlayers: dat[index]?.competition?.minPlayers,
                  maxAttemptAllowed: dat[index]?.competition?.maxAttemptAllowed,
                  maxEntryAllowed: dat[index]?.competition?.maxEntryAllowed,
                  leaderboardOutcomeDetails: dat[index]?.competition?.leaderboard?.[0]?.leaderboardOutcomeMaster?.name,
                  props: { ...dat[index] }
              });

          });
          currentChannelIndex=0;
        }
        }
        else{
          for (let index = 0; index < dat.length; index++) {
          const startDate = new Date(dat[index].startDate);
          let endDate = null;
          if (dat[index].intervalUnitId !== 5 && dat[index].intervalUnitId !== 6) {
            endDate = new Date(dat[index].endDate);
          } else if (dat[index].intervalUnitId === 6) {
            endDate = dat[index].endDate ? new Date(dat[index].endDate) : null;
          }

          const isEndDateNull = dat[index].endDate === null || endDate === null;
          const tillDate = isEndDateNull ? addYears(startDate, 100).toISOString() : endDate.toISOString();


          data.push({
            channelUuid:channel[currentChannelIndex].uuid,
            id: `${dat[index].id}--${index}`,
            title: dat[index]?.competition?.name,
            titleID: dat[index.scheduledId],
            since: startDate.toISOString(),
            till: tillDate,
            isEndDateNull: isEndDateNull,
            recurrenceCount: dat[index].recurrenceCount,
            scheduleType: dat[index].scheduleType,
            description: dat[index]?.competition?.description,
            currentInstance: dat[index]?.scheduleInstances?.length,
            nextInstanceDate: calculateNextInstance(startDate.toISOString(), dat[index].recurrenceFrequency, dat[index].intervalUnitId),
            CalendarType: CalendarType,
            prizeDistributionOffset: dat[index]?.prizeDistributionOffset,
            prizeDistributionRule: dat[index]?.competition?.prizeDistributionRule,
            rewardsUnlockCondition: dat[index]?.competition?.rewardsUnlockCondition,
            matchName: dat[index]?.competition?.match ? dat[index]?.competition?.match?.name : "Custom",
            competitionEntryPrices: dat[index]?.competition?.competitionEntryPrices,
            maxPlayers: dat[index]?.competition?.maxPlayers,
            minPlayers: dat[index]?.competition?.minPlayers,
            maxAttemptAllowed: dat[index]?.competition?.maxAttemptAllowed,
            maxEntryAllowed: dat[index]?.competition?.maxEntryAllowed,
            leaderboardOutcomeDetails: dat[index]?.competition?.leaderboard?.[0]?.leaderboardOutcomeMaster?.name,
            props: { ...dat[index] }
          });
        }
        }
  } else if (CalendarType === "tasks" || CalendarType === "step-series" || CalendarType === "missions") {
    if (calendarMode=="day") {
      dat.forEach((program,pid) => {

      const tmpOffset =calculateDurationOffset(program.currentStartDate, program.currentEndDate);
      const {instances,previousInstances} = generateAllInstances(currentInterval.day==0?program.currentStartDate:filterStartDate, program.recurrenceFrequency, program.intervalUnitId, program.recurrenceCount,tmpOffset,program.startDate, program.endDate);


      while (true) {
        const currentChannelDates = channel[currentChannelIndex]?.dates || [];
        const overlapExists = currentChannelDates.some(dateRange =>
          checkOverlap(new Date(dateRange.start), new Date(dateRange.end), new Date(program.startDate), new Date(program.endDate))
        );

        if (!overlapExists) {
          break;
        }

        currentChannelIndex += 1;
        if (!channel[currentChannelIndex]) {
          channel.push({ uuid: String(currentChannelIndex), dates: [] });
        }
      }
      
      instances&&instances.forEach((instanceDate, instanceIndex) => {
        const startDate = new Date(instanceDate);
        const endDate = program.intervalUnitId==5?null:program.intervalUnitId==6? (new Date(program.endDate)):(new Date(instanceDate));
        (program.intervalUnitId!==5&&program.intervalUnitId!==6)&&endDate.setMinutes(endDate.getMinutes() + calculateDurationOffset(program.currentStartDate, program.currentEndDate));
        
        const isEndDateNull = program.currentEndDate === null || program.currentEndDate === "null"||program.endDate==null||program.endDate==undefined||program.currentEndDate==undefined||endDate==null;
        const tillDate = isEndDateNull ? addYears(new Date(startDate), 100) : endDate;

        channel[currentChannelIndex].dates.push({ start: program.startDate, end: program.endDate });
        data.push({
          channelUuid:channel[currentChannelIndex].uuid,
          id: `${program.id}-${instanceIndex}-${pid}`,
          title: program.name,
          titleID: CalendarType === "tasks" ? program.taskId : program.taskGroupId,
          since: startDate.toISOString(),
          till: tillDate.toISOString(),
          isEndDateNull: isEndDateNull,
          intervalTimeName:program?.intervalUnit?.intervalName,
          recurrenceCount: program.recurrenceCount,
          scheduleType: program.scheduleType,
          currentInstance: program.recurrenceCount-program.recurrenceCountLeft+instanceIndex -previousInstances,
          description: program.description,
          iconUrl: program.iconUrl,
          nextInstanceDate: instanceIndex < instances?.length - 1 ? instances[instanceIndex + 1] : null,
          rewardDetails: program.rewardDetails,
          noOfTasks: program.noOfTasks,
          CalendarType: CalendarType,
          rewardsUnlockCondition: program.levelDetails,
          props: {...program}
        });
      });
      currentChannelIndex=0;
    });
    }
    else{
      dat.forEach((program,pid) => {
        const startDate = new Date(program.startDate);
        let endDate = null;
        if (program.intervalUnitId !== 5 && program.intervalUnitId !== 6) {
          endDate = new Date(program.endDate);
        } else if (program.intervalUnitId === 6) {
          endDate = program.endDate ? new Date(program.endDate) : null;
        }

        const isEndDateNull = program.endDate === null || endDate === null;
        const tillDate = isEndDateNull ? addYears(new Date(startDate), 100) : endDate;

        data.push({
          channelUuid:channel[currentChannelIndex].uuid,
          id: `${program.id}--${pid}`,
          title: program.name,
          titleID: CalendarType === "tasks" ? program.taskId : program.taskGroupId,
          since: startDate.toISOString(),
          till: tillDate.toISOString(),
          isEndDateNull: isEndDateNull,
          intervalTimeName: program?.intervalUnit?.intervalName,
          recurrenceCount: program.recurrenceCount,
          scheduleType: program.scheduleType,
          currentInstance: program.recurrenceCount-program.recurrenceCountLeft, 
          description: program.description,
          iconUrl: program.iconUrl,
          nextInstanceDate: calculateNextInstance(startDate.toISOString(), program.recurrenceFrequency, program.intervalUnitId),
          rewardDetails: program.rewardDetails,
          noOfTasks: program.noOfTasks,
          CalendarType: CalendarType,
          rewardsUnlockCondition: program.levelDetails,
          props: { ...program }
        });
      });
      }
    setEpg(data);
    setChannels(channel);
  }

    return {data,channel};
  }

  const getResources = async(calendarMode) => {
    const {data,channel}=  generateData(calendarMode);
    return { channels:channel, epg: data };
  };


  const handleFetchResources = React.useCallback(async (calendarMode) => {
    setIsLoading(true);
    if (Calendar && Calendar?.data?.liveOpsDetails ) {
    const { channels,epg } = (await getResources(calendarMode))

    setEpg(epg);
    setChannels(channels);
    setIsLoading(false);
   
    }

  }, [Calendar]);
  
  //// worker implementation handleFetchFunction     ////


//   const handleFetchResources = React.useCallback((calendarMode) => {
//     // setIsLoading(true);

//     if (Calendar && Calendar?.data?.liveOpsDetails) {
//       const worker = createWorker();

//         worker.postMessage({
//             calendarMode: calendarMode,
//             CalendarType: CalendarType,
//             data: Calendar.data.liveOpsDetails,
//             filterEndDate:filterEndDate,
//             filterStartDate:filterStartDate
//         });

//         const updatedData = [];
//         const updatedChannels = [];

//         worker.onmessage = (event) => {
//             const { programData, channel, done} = event.data;

//             if (programData) {
//                 updatedData.push(programData);
//                 setEpg([...updatedData]); // Update EPG state incrementally
//             }

//             if (channel) {
//                 const channelIndex = updatedChannels.findIndex(c => c.uuid === channel.uuid);
//                 if (channelIndex === -1) {
//                     updatedChannels.push(channel);
//                 } else {
//                     updatedChannels[channelIndex] = channel;
//                 }
//                 setChannels([...updatedChannels]); // Update Channels state incrementally
//             }

//             if (done) {
//                 setIsLoading(false);
//             }
//         };

//         worker.onerror = (error) => {
//             console.error('Worker error: ', error);
//             setIsLoading(false);
//         };

//         return () => {
//             worker.terminate();
//         };
//     }

// }, [Calendar]);

  React.useEffect(()=>{
    if(calendarMode=="day"&&(currentInterval.day>=lazyLoadInterval.day||currentInterval.day<=-lazyLoadInterval.day)&&currentInterval.day!==0){
      getAllScheduleCompetition(currentInterval.day)
      handleFetchResources(calendarMode)
      setCurrentInterval(prev => ({
        ...prev,
        day: 0
      }));
    }
    else if (calendarMode=="week"&&(currentInterval.week>=lazyLoadInterval.week||currentInterval.week<=-lazyLoadInterval.week)&&currentInterval.week!==0) {
      getAllScheduleCompetition(currentInterval.week)
      handleFetchResources(calendarMode)
      setCurrentInterval(prev => ({
        ...prev,
        week: 0
      }));
    }
    else if(calendarMode=="30Days"&&(currentInterval.month>=lazyLoadInterval.month||currentInterval.month<=-lazyLoadInterval.month)&&currentInterval.month!==0){
      getAllScheduleCompetition(currentInterval.month)
      handleFetchResources(calendarMode)
      setCurrentInterval(prev => ({
        ...prev,
        month: 0
      }));
    }
    else if(calendarMode=="month"&&(currentInterval.year>=lazyLoadInterval.year||currentInterval.year<=-lazyLoadInterval.year)&&currentInterval.year!==0){
      getAllScheduleCompetition(currentInterval.year)
      handleFetchResources(calendarMode)
      setCurrentInterval(prev => ({
        ...prev,
        year: 0
      }));
    }
    
  },[currentInterval])

  React.useEffect(()=>{

    if ((scrollY-currenScroll)>300) {
      SetCurrentScroll(scrollY)
      limitOffset+=10
      getAllScheduleCompetition()
    }
  },[scrollY])

  React.useEffect(() => {
    if (Calendar?.data?.liveOpsDetails) {
      if(calendarMode=="day"){
        setLoadedDaysData(Calendar)
      }
      else if (calendarMode=="week") {
        setLoadedWeeksData(Calendar)
      }
      else if(calendarMode=="30Days"){
        setLoadedMonthsData(Calendar)
      }
      else if(calendarMode=="month"){
        setLoadedYearsData(Calendar)
      }
      else{
        
      }
    }
    
    handleFetchResources(calendarMode);
  }, [Calendar]);

  return {
    isLoading,
    setIsLoading,
    loadedDaysData,
    loadedWeeksData,
    loadedMonthsData,
    loadedYearsData,
    channels,
    epg,
    getEpgProps,
    getLayoutProps,
    onChangeTimezone: handleChangeTimezone,
    onChangeCalendarMode: handleChangeCalendarMode,
    dayPrevChange,
    dayNextChange,
    handleFetchResources:handleFetchResources,
    getAllScheduleCompetition,
    startDate:dates.start,
    endDate:dates.end,
  };
}
