import React from 'react'
import cn from 'classnames'
import styles from './ColorRadio.module.sass'

const Radio = ({ className, id, content, name, value, onChange, status, colorCode }) => {
  return (
    <div>
        <label className={status ? cn(styles.radioDisabled, className) : cn(styles.radio, className)}>
          <input disabled={status} className={styles.input} type="radio" name={name} onChange={onChange} checked={value} value={id} />
          <span className={styles.inner}>
          {/* <span className={status ? styles.disabledTick : styles.tick}></span> */}
          <span className={styles.tick} style={{backgroundColor:colorCode}}></span>
            <span className={status ? styles.disabled : styles.text} dangerouslySetInnerHTML={{ __html: content }}></span>
          </span>
        </label>
    </div>
  )
}

export default Radio