import React, {useState} from 'react'
import cn from 'classnames'
import styles from './archiveModal.module.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { editProgressionMarkerAction } from '../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction'

const ArchiveMarker = ({className, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const appId = location.state.appid
  const markerId = location.state.id
  const archiveStatus = location.state.archiveStatus
  const markerName = location.state.markerName

  const onArchive = () => {
    let markerData = {
      ids : [],
      offset : 0,
      projectId : appId,
      id : markerId,
      archive : (archiveStatus == true ? false : true)
    }
    //dispatch(editProgressionMarkerAction(markerData, null , appId))
    onClose()
  }

  return (
    <div className={styles.archiveBody}>
      <div className={styles.archiveContent}>
          <div className={styles.warning}>
                <h5>Warning!</h5>
          </div>
          <div className={styles.warningContent}>
                <p>Are you sure you want to {archiveStatus == true ? 'Unarchive' : 'Archive'} <span className={styles.currencyName}>{markerName}</span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.archiveBtn} onClick={onArchive}>Yes</button>
        <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}

export default ArchiveMarker