import React, { useState, useEffect } from 'react'
import cn from 'classnames';
import { Link, useParams } from "react-router-dom";
import styles from './rewards.module.sass'
import Icon from '../../../../../../../components/Icon';
import TextInput from '../../../../../../../components/TextInput';
import Form from '../../../../../../../components/Form/FormDropdown'
import {currency} from '../../../../../Economy/Currency/GetCurrency/mock/currency'
// import add from '../../../../../../../media/images/icons/add.png'
// import remove from '../../../../../../../media/images/icons/remove.png'
import { useDispatch, useSelector } from 'react-redux';
import {getAllCurrencyAction} from '../../../../../../../redux/action/builds/economy/currency/currencyAction'
import axios from 'axios';
import { BASE_URL, GET_CURRENCY } from '../../../../../../../resources/APIEndpoints';

import currencyPlaceHolder from '../../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../../media/images/placeholders/Items.png'
import bundlePlaceHolder from '../../../../../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../../../../../media/images/placeholders/Progression_Marker.png'
import {ReactComponent as Divider} from '../../../../../../../media/images/icons/Config_Divider.svg'
import {ReactComponent as RemoveIcon } from '../../../../../../../media/images/icons/remove.svg';

const Reawards = ({ className, visible, setVisible, onClose, level, type, setPSLevels }) => {

    const dispatch = useDispatch();
    const {appid} = useParams()

    const [levelName, setLevelName] = useState()
    const [levelParameter, setLevelParameter] = useState()
    const [levels, setLevels] = useState()

    const [currencies, setCurrencies] = useState()
    const [selectedRewards, setSelectedRewards] = useState([])

    const [showReward, setShowReward] = useState(false)

    useEffect(()=>{
        getLevels();
        resetFields();
        getAllRewards();
    }, [visible])

    const getLevels = () => {
        if(type == 'add'){
            const totalLevels = JSON.parse(sessionStorage.getItem('Level'))
            if(totalLevels && level != undefined){
                setLevels(totalLevels)
                setLevelName(totalLevels[level].name)
                setLevelParameter(totalLevels[level].incrementalParameterValue)
            }
        }else{
            const totalLevels = JSON.parse(sessionStorage.getItem('Level'))
            if(totalLevels && level != undefined){
                setLevels(totalLevels)
                setSelectedRewards(totalLevels[level].rewardDetails)
                setLevelName(totalLevels[level].name)
                setLevelParameter(totalLevels[level].incrementalParameterValue)
            }
        }
    }

    const getAllRewards = () => {
        let search = {
            projectId : appid,
            search : ''
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        axios.post(`${BASE_URL}/inventory/search`, search, headers)
        .then(res=>{
            let currencies = res.data.data.currencies.map(i => ({id: i.id, rewardId : i.currencyId, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, type: 'Currency'}));
            let items = res.data.data.inventoryItems.map(i => ({id: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), rewardId : i.itemId, name : i.name, status : false, type: 'Items'}));
            let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
            let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
            const rewardList = [...currencies, ...items, ...bundles, ...pm]
            setCurrencies(rewardList)
        })
    }

    const searchReward = (e) => {
        if(e == ''){
            getAllRewards()
        }else{
            let search = {
                projectId : appid,
                search : e
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                let currencies = res.data.data.currencies.map(i => ({id: i.id, rewardId : i.currencyId, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, type: 'Currency'}));
                let items = res.data.data.inventoryItems.map(i => ({id: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), rewardId : i.itemId, name : i.name, status : false, type: 'Items'}));
                let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
                let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
                const rewardList = [...currencies, ...items, ...bundles, ...pm]
                setCurrencies(rewardList)
            })
        }
    }


    const onAdd = (e, index) => {
        e.status = true
        setSelectedRewards(prevState => [...prevState, e])
    }

    const onRemove = (e,index) => {
       let removedReward = [...selectedRewards]
       let remainingRewards = removedReward.filter((e,i,c)=> {
            return i != index
        })
        setSelectedRewards(remainingRewards)
    }

    const onChangeQuantity = (e, index) => {
        let currencyQuantity = [...selectedRewards]
        currencyQuantity[index].quantity = parseInt(e.target.value)
        setSelectedRewards(currencyQuantity)
    }
    

    const resetFields = () => {
        setCurrencies([])
        if(type == 'add'){
            setSelectedRewards([])
        }
    }

    const save = () => {
        let totalLevels = JSON.parse(sessionStorage.getItem('Level'))
        totalLevels[level].rewardDetails = selectedRewards
        setPSLevels(totalLevels)
        sessionStorage.setItem('Level', JSON.stringify(totalLevels))
        setVisible(false)
    }
    

    if(type == 'add'){
        return (
            <>
                <div className={cn(styles.help, className, {[styles.active]: visible,})}>
                    <div className={styles.head}>
                        <div className={styles.header}>
                            <div className={styles.headingDash}></div>
                            <div className={styles.heading}>Config Level</div>
                        </div>
                        <button className={styles.close} onClick={() => setVisible(false)}>
                            <Icon name="close" size="24" />
                        </button>
                    </div>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <div className={styles.menu}>
                        <div className={styles.menuContainer}>
                            <div className={styles.rewardMenu}>
                                
                                <div className={styles.group}>
                                    <TextInput defaultValue={levelName} className={styles.field} label='Level Name' disabled/>
                                </div>
    
                                <div className={styles.group}>
                                    <TextInput defaultValue={levelParameter} className={styles.field} label='Level Parameter Value' disabled/>
                                </div>
    
                                <div className={styles.groupSearch}>
                                    <div className={styles.inputGroup}>
                                        <div>
                                            <Form id='searchReward' size='sm' placeholder="Search Reward" type="text" name="search" icon="search" setValue={(e)=>{searchReward(e)}} focus={showReward} setFocus={setShowReward} options={currencies} reward={selectedRewards} setReward={setSelectedRewards}/>
                                        </div>
                                        <div className={styles.filter}>
                                            <Icon name='filter' size='24' />
                                        </div>
                                    </div>
                                </div>

                                {selectedRewards && selectedRewards.length > 0 ?
                                    <div className={styles.group}>
                                        <div className={styles.heading}>
                                            <h4>Rewards</h4>
                                        </div>
                                        {selectedRewards && selectedRewards.map((e,i)=>{
                                        return(
                                                
                                                <div className={styles.rewards}>
                                                    <div className={styles.rewardsIcon}>
                                                        <img src={e.iconUrl} alt="icon" width={60}/>
                                                    </div>
                                                    <div className={styles.rewardsName}>
                                                        <span className={styles.name}>{e.name}</span> <br  />
                                                        <span className={styles.id}>{e.rewardId}</span>
                                                    </div>
                                                    <div className={styles.rewardsInput}>
                                                        <label>Quantity</label>
                                                        <input key={e.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={e.quantity} onChange={(e)=>{onChangeQuantity(e,i)}}/>
                                                    </div>
                                                    <div>
                                                        <span className={styles.icon} onClick={(e)=>{onRemove(e,i)}}><RemoveIcon /></span>
                                                    </div>
                                                </div>
                                        )
                                        })}
                                    </div>
                                : ''}
    
                                {/* <div className={styles.group}>
                                    <div className={styles.btnGroup}>
                                        <button className={styles.save} onClick={save}>Save</button>
                                        <button className={styles.cancel} onClick={()=> setVisible(false)}>Cancel</button>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                    <div className={styles.groups}>
                        <div className={styles.btnGroup}>
                            <button className={styles.save} onClick={save}>Save</button>
                            <button className={styles.cancel} onClick={()=> setVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
            </>
        );
    }else{
        return (
            <>
                <div className={cn(styles.help, className, {[styles.active]: visible,})}>
                    <div className={styles.head}>
                        <div className={styles.header}>
                            <div className={styles.headingDash}></div>
                            <div className={styles.heading}>Config Level</div>
                        </div>
                        <button className={styles.close} onClick={() => setVisible(false)}>
                            <Icon name="close" size="24" />
                        </button>
                    </div>
                    <div className={styles.menu}>
                        <div className={styles.menuContainer}>
                            <div className={styles.rewardMenu}>
                            
                            <div className={styles.group}>
                                    <TextInput value={levelName} className={styles.field} label='Level Name' disabled/>
                                </div>
                                <div className={styles.group}>
                                    <TextInput value={levelParameter} className={styles.field} label='Level Parameter Value' disabled/>
                                </div>
    
                                <div className={styles.groupSearch}>
                                    <div className={styles.inputGroup}>
                                        <div>
                                            <Form id='searchReward' size='sm' placeholder="Search Reward" type="text" name="search" icon="search" setValue={(e)=>{searchReward(e)}} focus={showReward} setFocus={setShowReward} options={currencies} reward={selectedRewards} setReward={setSelectedRewards}/>
                                        </div>
                                        <div className={styles.filter}>
                                            <Icon name='filter' size='24' />
                                        </div>
                                    </div>
                                </div>

                                {levels && levels[level].rewardDetails.length > 0 ?
                                    <>
                                        <div className={styles.group}>
                                            <div className={styles.heading}>
                                                <h4>Rewards</h4>
                                            </div>
                                            {selectedRewards && selectedRewards.map((e,i)=>{
                                                return( 
                                                    <div key={i} className={styles.rewards}>
                                                        <div className={styles.rewardsIcon}>
                                                            <img src={e.iconUrl} alt="icon" width={60}/>
                                                        </div>
                                                        <div className={styles.rewardsName}>
                                                            <span className={styles.name}>{e.name}</span> <br  />
                                                            <span className={styles.id}>{e.rewardId}</span>
                                                        </div>
                                                        <div key={i} className={styles.rewardsInput}>
                                                            <label>Quantity</label>
                                                            <input key={e.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={e.quantity} onChange={(e)=>{onChangeQuantity(e,i)}}/>
                                                        </div>
                                                        <div>
                                                            <span className={styles.icon} onClick={(e)=>{onRemove(e,i)}}><RemoveIcon /></span>
                                                        </div>
                                                    </div>
                                                    )
                                            })}
                                        </div>
                                    </>
                                : ''}
                                
                            </div>
                        </div>
                    </div>
                    <div className={styles.groups}>
                        <div className={styles.btnGroup}>
                            <button className={styles.save} onClick={save}>Edit</button>
                            <button className={styles.cancel} onClick={()=> setVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
            </>
        );
    }
}

export default Reawards