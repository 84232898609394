import { GET_ITEM, ADD_ITEM, EDIT_ITEM, FILTER_ITEMS_SUCCESS, GET_ITEM_SUCCESS, GET_ITEM_ERROR, FILTER_ITEMS_ERROR } from "../../../../actionTypes/actionTypes";
const initialState = {
    items : [],
    itemDetail : [],
    isLoading : false,
    isFiltered : false,
    totalCount : null
}

export const itemReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_ITEM : {
            return {
               ...state,
               isLoading: true
            }
        }

        case GET_ITEM_SUCCESS : {
            return {
               ...state,
               isLoading : false,
               isFiltered : false,
               items: action.payload,
               totalCount : action.totalCount,
               error: null,
            }
        }

        case GET_ITEM_ERROR : {
            return {
                ...state,
                isLoading : false,
                error: action.payload
            }
        }

        case FILTER_ITEMS_SUCCESS : {
            return {
               ...state,
               items : action.payload,
               totalCount : action.totalCount,
               isLoading : false,
               isFiltered : true
            }
        }

        case FILTER_ITEMS_ERROR : {
            return {
                ...state,
                isLoading: false,
                error : action.payload
            }
        }

        case ADD_ITEM : {
            return {
               ...state,
               items: action.payload,
               isLoading : false
            }
        }

        case EDIT_ITEM : {
            return {
               ...state,
               items: action.payload,
               isLoading : false
            }
        }

        default : {
            return {
                ...state
            }
        }

    }

}