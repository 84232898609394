import React , { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './apps.module.sass'
import Card from '../../../components/Card'
import Form from '../../../components/Form'
import Icon from '../../../components/Icon'
import addIcon from '../../../media/images/icons/light.png'
import ProductView from './ProductView'
import Overview from './Overview'
import GetApps from './GetApps'
import Features from './Features'
import Modal from '../../../components/Modals/ModalComponent'
import IntroductionModal from '../../../components/Modals/IntroductionModal'
import Lottie from 'lottie-react'
import Spectre from '../../../media/lottieAnimation/Celebration.json'
import GlobalStateContext from '../../../components/Context/GlobalStates/GlobalStateContext'

const Dashboard = ({setShowLoader, setAppName , setLoaderType}) => {

  const globalStates = useContext(GlobalStateContext)

  
  const style = {
    width : '100%',
    height : '100%',
  }

  return (
    <>
        <div className={styles.appWrapper}>
          {globalStates.showAnimation &&
            <div className={styles.animation}>
              <div className={styles.celebration}>
                <Lottie animationData={Spectre} loop={true} autoPlay={true} style={style} />
              </div>
            </div>
          }
          <div className={styles.apps} >
            <GetApps users={globalStates?.user?.type} setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader}/>
          </div>
          <div className={styles.apps} >
            <Features />
          </div>
        </div>
    </>
  )
}

export default Dashboard