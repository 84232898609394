import React from 'react'
import LeaderboardInformation from './LeaderboardInformation'
import styles from './createLeaderboard.module.sass'
import { ToastContainer } from 'react-toastify'


const CreateMacth = ({isChanged, setIsChanged}) => {
  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
          <div className={styles.col}>
            <LeaderboardInformation isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
          </div>
      </div>
    </>
  )
}

export default CreateMacth