import React from 'react'
import styles from './createMission.module.sass'
import MissionInfo from './MissionInfo'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const CreateStepSeries = ({isChanged, setIsChanged}) => {
    return (
        <>
            <ToastContainer />
            <div className={styles.row}>
                <div className={styles.col}>
                    <MissionInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
                </div>
            </div>
        </>
    )
}

export default CreateStepSeries