import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";
import { ReactComponent as AddIconSvg } from "../../../media/images/icons/radio button/element/checked.svg";
import Modal from "../../../components/Modals/ModalComponent";
import ConfirmModal from "../../../components/Modals/Build/Economy/Currency/ConfirmModal";

const Dropdown = ({ className, disabled, groupBy, type, classLabel, classDropdownHead, classDropdownLabel, as, value, setValue, data, setData, options, index, label, small, upBody, tooltip, missingName }) => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const navigate = useNavigate();
    const { appid } = useParams();

    function titleCase(str) {
        var splitStr = str.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    const ModalClose = () => {
        if (type === 'virtual currency' || type === 'RMG') {
            setVisibleModal(false);
            window.scrollTo(0, 0);
            setTimeout(() => {
                navigate(`/create-currency/${appid}`);
            }, 100);
        } else {
            setVisibleModal(false);
            window.scrollTo(0, 0);
        }
    };

    const handleClick = (e) => {
        let datas = [...data];
        if (type === 'virtual currency' || type === 'RMG') {
            datas[index].currencyName = e.name;
            datas[index].currencyId = e.id;
            datas[index].priceTypes = e.type;
            datas[index].code = e.code;
            datas[index].symbol = e.symbol;
            setData(datas);
        } else {
            datas[index].currencyName = e.name;
            datas[index].realWorldCurrencyId = e.realWorldCurrencyId ? e.realWorldCurrencyId : e.currencyId;
            datas[index].priceTypes = e.type;
            datas[index].code = e.code;
            datas[index].symbol = e.symbol;
            setData(datas);
        }
        setVisible(false);
        setFilterText('');
    };

    const filteredOptions = options&&options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div className={groupBy === 1 ? styles.groupDropdown : groupBy === 2 ? styles.groupByTwoDropdown : groupBy === 3 ? styles.groupByThreeDropdown : groupBy === 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(''); }}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                        {label}{" "}
                        {tooltip && (<Tooltip className={styles.tooltip} title={tooltip} icon="info" place="right" />)}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder="Select Currency"
                                value={filterText !== '' ? filterText : value}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions.length > 0 ? filteredOptions.map && filteredOptions.map((x) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={x.id}>
                                    {x.name}
                                </div>
                            )) : (
                                <div style={{ textAlign: "center" }} className={styles.option}>
                                    <div>No <span style={{ color: "#33383F" }}>'{titleCase(type)}'</span> available</div>
                                    <div>Click on <span style={{ color: "#33383F" }}>'+'</span> to create</div>
                                </div>
                            )}
                            {(type === 'virtual currency' || type === 'RMG') && (
                                <div className={styles.fxd} onClick={() => { setVisibleModal(true); }}>
                                    <div className={styles.divider}></div>
                                    <div style={{ textAlign: "center" }} className={cn(styles.opt)}>
                                        <Link onClick={() => { setVisibleModal(true); }} className={styles.button}>
                                            <AddIconSvg style={{ width: 32, height: 32 }} />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                    <ConfirmModal onProceed={ModalClose} onClose={() => setVisibleModal(false)} type={titleCase(type)} />
                </Modal>
            </OutsideClickHandler>
        </div>
    );
};

export default Dropdown;
