import React, { useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import Store from '../../../../../../media/images/placeholders/Store.png'
import CurrencyTooltip from '../../../../../CustomTooltips/CurrencyTooltip'
import TooltipGlodal from "../../../../../TooltipGlodal";
import ToolTip from '../../../../../CustomTooltips/ReusableTooltip'
import cn from "classnames";

const Row = ({ item, className, locationOption, access, isLast, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.iconUrl !== undefined ?
                            <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                                <img src={(item.iconUrl != null && item.iconUrl !="") ? item.iconUrl : Store} alt="Store" />
                            </div>
                            :
                            <div className={styles.preview}></div>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.storeId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-store/${appid}/${item.id}`}>
                        <TooltipGlodal width={180} itemId={`store${item.id}`} title={item.name}><div className={styles.itemName}><span id={`store${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.storeId}</div>
                    </Link>
                </div>
                <div className={styles.col}>
                    {item.storesCategories && item.storesCategories.slice(0,1).map((e,i)=>{
                        return (
                            <>
                            <div className={styles.prices}>
                                <span className={i%2==0 ? styles.virtual : styles.IAP}>{e.name}</span>
                                {(item.storesCategories && item.storesCategories.length > 1) ? 
                                    <CurrencyTooltip type='Stores'  isLast={isLast} text='Categories
                                    ' data={item.storesCategories}><span className={styles.count}>+ {item.storesCategories.length - 1}</span></CurrencyTooltip>  
                                : ''}
                            </div>
                            </>
                        )
                    })}
                </div>
                <div className={styles.col}>
                    {item.storeLocations && item.storeLocations.slice(0,1).map((e,i)=>{
                        return (
                            <>
                                {(item.storeLocations && item.storeLocations.length ===locationOption?.length) ? <span>Global</span>:<span>{e.storeLocationMaster.name}</span>}
                                {(item.storeLocations && item.storeLocations.length > 1) ?
                                    (item.storeLocations && item.storeLocations.length ===locationOption?.length) ?"":
                                    <ToolTip text='Locations' data={item.storeLocations.map(item => item.storeLocationMaster)} isLast={isLast}><span className={styles.count}>+ {item.storeLocations.length - 1}</span></ToolTip>  
                                :''}
                            </>
                        )
                    })}
                </div>
                <div className={styles.col}>
                    {item.storePlatforms && item.storePlatforms.slice(0,1).map((e,i)=>{
                        return (
                            <>
                                <span>{e.storePlatformMaster.name}</span>
                                {(item.storePlatforms && item.storePlatforms.length > 1) ? 
                                    <ToolTip text='Platforms' data={item.storePlatforms.map(item => item.storePlatformMaster)} isLast={isLast}><span className={styles.count}>+ {item.storePlatforms.length - 1}</span></ToolTip>  
                                : ''}
                            </>
                        )
                    })}
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} appid={appid} id={item.id} name={item.name} status={item.archive} type='stores' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
