import { BASE_URL, GET_ITEMS, ADD_ITEMS, EDIT_ITEMS } from "../../../../resources/APIEndpoints";
import axios from "../../../../utils/interceptor";

function itemService(){
    this.getAllItems = (body) => axios.post(BASE_URL + GET_ITEMS, body)
    this.addItem = (body) => axios.post(BASE_URL + ADD_ITEMS, body)
    this.editItem = (body) => axios.post(BASE_URL + EDIT_ITEMS, body)
    this.archiveItem = (body) => axios.post(BASE_URL + EDIT_ITEMS, body)
}

export default new itemService();