import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../../components/Icon";
import Modal from "../../../../../../components/Modals/Overview/PreviewModal/Modal";
import PreviewModal from "../../../../../../components/Modals/Overview/PreviewModal";

const Control = ({className, src, type}) => {

    const [visibleModal, setVisibleModal] = useState(false);

    return (
        <>
            <div className={cn(styles.control, className)}>
                <span onClick={() => setVisibleModal(true)}>View</span>
            </div>
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <PreviewModal src={src} type={type}/>
            </Modal>
        </>
    )
}

export default Control