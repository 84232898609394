import React, { useEffect, useRef, useState } from "react";
import styles from './uploadCSV.module.sass'
import FileUpload from "../../File/Others";
import cn from "classnames";
import Switch from "../../Switch";
import Modal from "../ModalComponent";

const UploadCSVModal = ({type, labelOne, labelTwo, labelThree, validateOne, validateTwo, validateThree, onValidation, error, usedFor, placeHolderOne, placeHolderTwo, placeHolderThree, multiple, accept, valueOne, setValueOne, valueTwo, setValueTwo, valueThree, setValueThree, data, count, onCSV, onFile, onCSVFile, onClose}) => {

    const [show, setShow] = useState(true)
    const [hide, setHide] = useState(false)
    const fileOneRef = useRef(null);
    const fileTwoRef = useRef(null);
    const fileThreeRef = useRef(null);

    const onChangeFileUpload = (e) => {
        onCSV(e)
    }

    const onChangeCSVUpload = (e) => {
        onFile(e)
    }

    const onChangeCSVFileUpload = (e) => {
        onCSVFile(e)
    }

    const validateFile = () => {
        onValidation()
    }

    useEffect(() => {
        if(count === 3 && !hide && !show && validateOne && !validateTwo && !validateThree){
            onClose()
        }
    }, [validateOne, validateTwo, validateThree, hide, show])

    useEffect(() => {
        if(count === 3 && !hide && show && validateOne && validateTwo && !validateThree){
            onClose()
        }
    }, [validateOne, validateTwo, validateThree, hide, show])

    useEffect(() => {
        if(count === 3 && hide && validateOne && validateTwo && validateThree){
            onClose()
        }
    }, [validateOne, validateTwo, validateThree, hide, show])

    useEffect(() => {
        if(validateOne && count === 1){
            onClose()
        }
    }, [validateOne])

    return(
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.modalHeader}>
                        <div className={styles.block}></div>
                        <div className={styles.modalHeading}>Upload CSV</div>
                    </div>
                    <div className={styles.modalBody}>
                        <div className={styles.bodyWrapper}>
                            {(count === 1 || count === 2 || count === 3) &&
                                <>
                                    <div className={styles.notice}>
                                        {type === 'Items' && <span>Upload your items and item prices below. Ensure that both files have consistent item IDs to maintain accurate data linkage.</span>}
                                    </div>
                                    <div className={styles.fileUpload}>
                                        <div className={styles.label}>{labelOne}</div>
                                        <div className={styles.upload}>
                                            <FileUpload reference={fileOneRef} id={`csvOne`} placeHolder={placeHolderOne} multiple={multiple} accept={accept} value={valueOne} setValue={setValueOne} data={data} onChange={onChangeFileUpload}/>
                                        </div>
                                        <div className={styles.sample}>
                                            <span>Sample file of {type === 'Items' ? 'item' : ''} <a href="#" target="_blank" download='Items..xlsx'>Download</a></span>
                                        </div>
                                    </div>
                                </>
                            }
                            {(count === 2 || count === 3) &&
                            <>
                                <div className={styles.label}>
                                    {labelTwo}
                                    <Switch value={show} onChange={() => setShow(!show)}></Switch>
                                </div>
                                <div className={styles.notice}>
                                    {type === 'Items' && <span>If you choose to upload item details without pricing, you can add pricing later by re-uploading the item details along with the pricing data.</span>}
                                </div>
                                <div className={styles.fileUpload}>
                                    {show &&
                                        <>
                                            <div className={styles.upload}>
                                                <FileUpload reference={fileTwoRef} id={`csvTwo`} placeHolder={placeHolderTwo} multiple={multiple} accept={accept} value={valueTwo} setValue={setValueTwo} data={data} onChange={onChangeCSVUpload}/>
                                            </div>
                                            <div className={styles.sample}>
                                                <span>Sample file of {type === 'Items' ? 'Pricing' : ''} <a href="#" target="_blank" download='Items-Pricing.xlsx'>Download</a></span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                            }
                            {count === 3 &&
                            <>
                                <div className={styles.label}>
                                    {labelThree}
                                    <Switch value={hide} onChange={() => setHide(!hide)}></Switch>
                                </div>
                                <div className={styles.notice}>
                                    {type === 'Items' && <span>If you choose to upload item details without lock item/progression system detail, you can lock item later by re-uploading the item details along with the lock item/progression system detail data.</span>}
                                </div>
                                <div className={styles.fileUpload}>
                                    {hide &&
                                        <>
                                            <div className={styles.upload}>
                                                <FileUpload reference={fileThreeRef} id={`csvThree`} placeHolder={placeHolderThree} multiple={multiple} accept={accept} value={valueThree} setValue={setValueThree} data={data} onChange={onChangeCSVFileUpload}/>
                                            </div>
                                            <div className={styles.sample}>
                                                <span>Sample file of {type === 'Items' ? 'Lock By Item/PS' : ''} <a href="#" target="_blank" download='Item(Lock_By_Details).xlsx'>Download</a></span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                            }
                            <div className={styles.warningMessage}>
                                {/* PD Errors */}
                                {error && error.sizeError && error.sizeError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.sizeError}</p>}
                                {error && error.rowsError && error.rowsError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.rowsError}</p>}
                                {error && error.startRankErr && error.startRankErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.startRankErr}</p>}
                                {error && error.EndRankErr && error.EndRankErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.EndRankErr}</p>}
                                {error && error.itemErr && error.itemErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.itemErr}</p>}
                                {error && error.bundleErr && error.bundleErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.bundleErr}</p>}
                                {error && error.currenciesErr && error.currenciesErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.currenciesErr}</p>}
                                {error && error.pmErr && error.pmErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.pmErr}</p>}
                                {error && error.dataTypeError?.length > 0 && 
                                    <>
                                        {error.dataTypeError && error.dataTypeError.map((e, i) => {
                                            return(
                                                <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                            )
                                        })}
                                    </>
                                }
                                {error && error.nonExistingRewards?.bundles?.length > 0 &&
                                    <>
                                        <div className={styles.reward}>
                                            <div className={styles.heading}>Following Bundle with Bundle Id does not exist in your project</div>
                                            {error.nonExistingRewards?.bundles && error.nonExistingRewards?.bundles.map((e,i) => {
                                                return(
                                                    <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                                )
                                            })}
                                        </div>
                                    </>
                                }
                                {error && error.nonExistingRewards?.currencies?.length > 0 &&
                                    <>
                                        <div className={styles.reward}>
                                            <div className={styles.heading}>Following Currencies with Currency Id does not exist in your project</div>
                                            {error.nonExistingRewards?.currencies && error.nonExistingRewards?.currencies.map((e,i) => {
                                                return(
                                                    <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                                )
                                            })}
                                        </div>
                                    </>
                                }
                                {error && error.nonExistingRewards?.items?.length > 0 &&
                                    <>
                                        <div className={styles.reward}>
                                            <div className={styles.heading}>Following Items with Item Id does not exist in your project</div>
                                            {error.nonExistingRewards?.items && error.nonExistingRewards?.items.map((e,i) => {
                                                return(
                                                    <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                                )
                                            })}
                                        </div>
                                    </>
                                }
                                {error && error.nonExistingRewards?.pm?.length > 0 &&
                                    <>
                                    <div className={styles.reward}>
                                        <div className={styles.heading}>Following PM with PM Id does not exist in your project</div>
                                        {error.nonExistingRewards?.pm && error.nonExistingRewards?.pm.map((e,i) => {
                                            return(
                                                <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                            )
                                        })}
                                    </div>
                                    </>
                                }
                                {error && error.nonExistingRewards?.ps?.length > 0 &&
                                    <>
                                    <div className={styles.reward}>
                                        <div className={styles.heading}>Following PS with PS Id does not exist in your project</div>
                                        {error.nonExistingRewards?.ps && error.nonExistingRewards?.ps.map((e,i) => {
                                            return(
                                                <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                            )
                                        })}
                                    </div>
                                    </>
                                }
                                {error && error.existingItems?.length > 0 &&
                                    <>
                                    <div className={styles.reward}>
                                        <div className={styles.heading}>Following Items with Item Id already exist in your project</div>
                                        {error.existingItems && error.existingItems.map((e,i) => {
                                            return(
                                                <p className={styles.danger}>&#8226;&nbsp;{e}</p>
                                            )
                                        })}
                                    </div>
                                    </>
                                }
                                {/* Items, Bundles. Stores Errors */}
                                {error && error.iconError && error.iconError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.iconError}</p>}
                                {error && error.nameError && error.nameError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.nameError}</p>}
                                {error && error.idError && error.idError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.idError}</p>}
                                {error && error.descriptionError && error.descriptionError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.descriptionError}</p>}
                                {error && error.itemTypeError && error.itemTypeError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.itemTypeError}</p>}
                                {error && error.byError && error.byError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.byError}</p>}
                                {error && error.usesError && error.usesError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.usesError}</p>}
                                {error && error.timeError && error.timeError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.timeError}</p>}
                                {error && error.unitError && error.unitError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.unitError}</p>}
                                {error && error.subTypeError && error.subTypeError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.subTypeError}</p>}
                                {error && error.maxCountError && error.maxCountError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.maxCountError}</p>}
                                {error && error.maxInstanceError && error.maxInstanceError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.maxInstanceError}</p>}
                                {error && error.limitedError && error.limitedError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.limitedError}</p>}
                                {error && error.quantityError && error.quantityError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.quantityError}</p>}
                                {error && error.loadOutError && error.loadOutError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.loadOutError}</p>}

                                {error && error.pricingItemIDError && error.pricingItemIDError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.pricingItemIDError}</p>} 
                                {error && error.currencyTypeError && error.currencyTypeError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.currencyTypeError}</p>}
                                {error && error.currencyIdError && error.currencyIdError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.currencyIdError}</p>}
                                {error && error.amountError && error.amountError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.amountError}</p>}
                                {error && error.discountError && error.discountError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.discountError}</p>}
                                {error && error.BCAError && error.BCAError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.BCAError}</p>}
                                {error && error.platformError && error.platformError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.platformError}</p>}
                                {error && error.productIdError && error.productIdError != '' && <p className={styles.danger}>&#8226;&nbsp;{error.productIdError}</p>}

                                {error && error.lockByItemIdErr && error.lockByItemIdErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.lockByItemIdErr}</p>}
                                {error && error.lockByItemBundleErr && error.lockByItemBundleErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.lockByItemBundleErr}</p>}
                                {error && error.lockByProgressionErr && error.lockByProgressionErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.lockByProgressionErr}</p>}
                                {error && error.progressionSystemLevelErr && error.progressionSystemLevelErr != '' && <p className={styles.danger}>&#8226;&nbsp;{error.progressionSystemLevelErr}</p>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        <button className={cn(styles.button, "button-small")} onClick={validateFile}>Validate</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadCSVModal