import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({type, items, title, to, apps, setApps, userType }) => {

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <>
      {to == 'accepted' &&
        <div className={styles.wrapper} style={{overflow:"scroll"}}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Member</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Access</div>
              <div className={styles.col}></div>
            </div>
            {items && items.map((x, index) => (
              <Row userType={userType} to={to} type={type} item={x} key={index} index={index} apps={apps} setApps={setApps}/>))}
          </div>
        </div>
      }

      {to == 'invited' &&
        <div className={styles.wrapper} style={{overflow:"scroll"}}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Member</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Access</div>
              <div className={styles.col}></div>
            </div>
            {items && items.map((x, index) => (
              <Row userType={userType} to={to} type={type} item={x} key={index} index={index} apps={apps} setApps={setApps}/>))}
          </div>
        </div>
      }
    </>
  );
};

export default Table;
