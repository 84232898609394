import React, { useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import Game from '../../../../../media/images/placeholders/Game.png'
import TooltipGlodal from "../../../../TooltipGlodal";
import cn from "classnames";

const Row = ({ item, className, access, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.logo !== undefined ?
                            <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                                <img src={item.logo != null ? item.logo : Game} alt="Games" />
                            </div>
                        :
                            <div className={styles.preview}></div>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div>{item.gameId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-game/${appid}/${item.id}`}>
                        <TooltipGlodal width={180} itemId={`gameName${item.id}`} title={item.name}><div className={styles.itemName}><span id={`gameName${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.gameId}</div>
                    </Link>
                </div>
                <div className={styles.col}>
                    <div className={item.archive === false ? styles.price : item.archive === true ? styles.archive : null}>{item.archive === false ? 'Active' : item.archive === true ? 'Archive' : null}</div>
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} id={item.id} name={item.name} appid={appid} status={item.archive} type='games' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
