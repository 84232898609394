import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import AddContentModal from "../../../../../../components/Modals/Build/Economy/Bundle/AddContent";
import Tooltips from "../../../../../../components/Tooltip";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";

const Control = ({usedFor, pagePM, pageItem, pageBundle, pageCurrency,className, type, width, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundles, setBundle, bundlePage, bundleCount, PM, setPM, PMCount, PMPage, value, setValue,setCalled}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  const actions = [
    {
      icon: "add",
      action: () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
            <div>
              {usedFor === 'Link Rewards' && <span>Add Link Rewards</span>}
              <span onClick={x.action} className={styles.add}><AddMetaIcon /></span>
            </div>
        ))}
      </div>
      <Modal heading={true} width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AddContentModal usedFor={usedFor} onClose={() => setVisibleModal(false)} pagePM={pagePM} pageItem={pageItem} pageBundle={pageBundle} pageCurrency={pageCurrency} type={type} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} item={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundles={bundles} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} value={value} setValue={setValue} setCalled={setCalled}/>
      </Modal>
    </>
  );
};

export default Control;
