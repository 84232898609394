import React, { useContext, useState } from 'react'
import bcrypt from 'bcryptjs'
import styles from './login.module.sass'
import { useNavigate, Link } from 'react-router-dom'
import './style.css'
import Spectre from '../../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../../media/images/x_fill.svg'
import Lottie from 'lottie-react'
import { signUpUserAction } from '../../../../redux/action/authenticate/authenticationAction'
import { useDispatch } from 'react-redux'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const SignIn = ({onSave, user, setUser, props, setShowLoader, setLoaderType}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const globalStates = useContext(GlobalStateContext)

    const style = {
        width : 560,
        height : 608,
    }

    const back = () => {
        onSave(prevState => prevState - 1)
    }

    const next = () => {
        const filteredGoals = user.goals.filter(goal => goal.active === true).map(goal => goal.name);
        const filteredPlatform = user.platform.filter(platform => platform.active === true).map(platform => platform.name);
        const newUser = {
        ...user,
        fullName : user.firstName.concat(" ", user.lastName),
        goals: filteredGoals,
        platform: filteredPlatform,
        }
        delete newUser.firstName
        delete newUser.lastName
        delete newUser.solo
        dispatch(signUpUserAction(newUser, navigate, setShowLoader, setLoaderType, globalStates.setShowQuickGuideModal))
    }

    const togglePills = (index) => {
        let activePills = user.platform
        activePills[index].active = !(activePills[index].active)
        setUser((user) => ({...user, platform : activePills}))
    }


    return (
    <>
        <div className={styles.personalInfo}>
            <div className={styles.formContainer}>
                <div className={styles.form}>
                    <div className={styles.formHeader}>
                        <div className={styles.formTitle}>Your Development<br /> Platform.</div>
                        <div className={styles.formContent}>Choose the platform where you'll bring your project to life. Select all that apply.</div>
                    </div>
                    <div className={styles.formBody}>
                        {user.platform && user.platform.map((e, index) => {
                            return(
                                <>
                                    <div className={e.active ? styles.active : styles.inActive} onClick={()=>{togglePills(index)}}>{e.name}</div> 
                                </>
                            )
                        })}
                    </div>
                    <div className={styles.formFooter}>
                        <div className={styles.skipButton}>
                            <button className={styles.Skip} onClick={next}>Skip</button>
                        </div>
                        <div className={styles.actionButton}>
                            <button className={styles.Back} onClick={back}>Back</button>
                            <button className={styles.Next} onClick={next}>I'm Done!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SignIn