import React, { useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control";
import Schedule from "./Schedule";
import { Link, useParams } from "react-router-dom";
import Task from '../../../../../../media/images/placeholders/TAsk.png'
import SS from '../../../../../../media/images/placeholders/Step_Series.png'
import Mission from '../../../../../../media/images/placeholders/Mission.png'
import Tooltip from '../../../../../CustomTooltips/ReusableTooltip'
import DateTooltip from '../../../../../CustomTooltips/DateTooltip'
import moment from "moment";
import 'moment-timezone';
import { useSelector } from "react-redux";

const Row = ({modalFor, access,isLast, usedFor, to, as, item, currency, setCurrency, currencyCount, currencyPage, items, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setValue, setStepSeries, value, onChange, type, index }) => {
    
    const {appid} = useParams()

    let userTimeZone = useSelector((state) => {
        return state.auth.user.timezone        
    })

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.preview}>
                            <img src={item.iconUrl != null ? item.iconUrl : (item.iconUrl === null && usedFor == 'StepSeries') ? SS : (item.iconUrl === null && usedFor == 'Mission') ? Mission : Task} alt="Item" />
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            {type == 'list' && <div className={styles.itemId}>{item.taskId}</div>}
                            {type == 'table' && <div className={styles.itemId}>{item.taskGroupId}</div>}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={usedFor == 'StepSeries' ? `/view-step-series/${appid}/${item.id}` : `/view-mission/${appid}/${item.id}`}><div className={styles.itemName}>{item.name}</div></Link>
                    {type == 'list' && <div className={styles.itemId}>{item.taskId}</div>}
                    {type == 'table' && <div className={styles.itemId}>{item.taskGroupId}</div>}
                </div>
                {type == 'list' && <div className={styles.col}><div className={styles.itemName}>{item.taskId}</div></div>}
                {/* {type == 'table' && <div className={styles.col}><div className={styles.itemName}>{item.taskGroupId}</div></div>} */}
                {type == 'list' && as == 'add' &&
                    <div className={styles.col}>
                        {
                            item.rewardDetails && item.rewardDetails.slice(0,1).map((e,i)=>{
                                return(
                                    <>
                                        <div className={styles.prices}>
                                            <span>{e.name}</span><span>({e.quantity})</span>
                                            {(item.rewardDetails && item.rewardDetails.length > 1 && i === 0) ? 
                                                <Tooltip type='Task' text='Rewards' data={item.rewardDetails}><span className={styles.count}>+ {item.rewardDetails.length - 1}</span></Tooltip>  
                                            : ''}
                                        </div>
                                    </>   
                                ) 
                            })
                        }
                    </div>
                }
                {type == 'list' && as == 'edit' &&
                    <div className={styles.col}>
                        {
                            item.rewardDetails && item.rewardDetails.slice(0,1).map((e,i)=>{
                                return(
                                    <>
                                        <div className={styles.prices}>
                                            <span>{e.name}</span><span>({e.quantity})</span>
                                            {(item.rewardDetails && item.rewardDetails.length > 1 && i === 0) ? 
                                                <Tooltip type='Task' text='Rewards' data={item.rewardDetails}><span className={styles.count}>+ {item.rewardDetails.length - 1}</span></Tooltip>  
                                            : ''}
                                        </div>
                                    </>   
                                ) 
                            })
                        }
                    </div>
                }
                {type == 'table' &&
                    <div className={styles.col}><div className={styles.itemName}>{item.taskDetails.length}</div></div>
                }
                {type == 'table' && 
                    <>
                        <div className={styles.col}>
                            {item.startDate == null && item.endDate == null && <>-</>}
                            {(item.startDate != null || item.endDate != null) &&
                                <>
                                    <DateTooltip userTimeZone={userTimeZone} isLast={isLast} timeZone={item.timezone} startDate={item.startDate} endDate={item.endDate}>
                                        <>
                                            {item.startDate != null ? moment(item.startDate).tz(userTimeZone).format('MMM DD') : ''}  {item.endDate != null ? <> &#8208; {moment(item.endDate).tz(userTimeZone).format('MMM DD')} </> : <><span>&#45;</span> <span className={styles.infinity}><span className={styles.icon}>&#8734;</span></span></>}
                                        </>
                                    </DateTooltip>
                                    {(item.startDate != null && item.endDate != null && item.recurrenceCount != null && item.recurrenceCountLeft != null) && 
                                        <div className={styles.instance}>
                                            {(item.recurrenceCount != null && item.recurrenceCountLeft != null ? (item.recurrenceCount - item.recurrenceCountLeft) : '')}/{(item.recurrenceCount != null ? item.recurrenceCount : '')}&nbsp; ({item.recurrenceFrequency != null ? item.recurrenceFrequency : ''} {item.intervalUnit != null ? item.intervalUnit.intervalName : ''})
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </>
                }
                {type == 'table' && 
                    <div className={styles.col}>
                        <Schedule userTimeZone={userTimeZone} isLast={isLast} as='schedule' usedFor='instance' type={usedFor} id={item.id} name={item.name} status={item.status} instanceStatus={item.instanceStatus} instanceStart={item.currentStartDate != null ? item.currentStartDate : null} instanceEnd={item.currentEndDate != null ? item.currentEndDate : null} timeZone={item.timezone} />
                    </div>
                }
                {type == 'table' && 
                    <div className={styles.col}>
                        <Schedule userTimeZone={userTimeZone} as='schedule' usedFor='status' type={usedFor} id={item.id} name={item.name} status={item.status} instanceStatus={item.instanceStatus} instanceStart={item.currentStartDate != null ? item.currentStartDate : null} instanceEnd={item.currentEndDate != null ? item.currentEndDate : null} timeZone={item.timezone} />
                    </div>
                }
                <div className={styles.col}>
                    <Control access={access} modalFor={modalFor} to={to} as={as} appid={appid} id={item.id} name={item.name} status={item.archive} type={type} className={styles.control} item={item} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={items} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} />
                </div>
            </div>
        </>
    );
};

export default Row;
