import React, { useState, useEffect } from 'react'
import styles from './tooltip.module.sass'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as  ServerIcon} from "../../../media/images/icons/server_icon.svg";
import OutsideClickHandler from 'react-outside-click-handler';

const Tooltip = ({className, type, appName, environment, data, children, showLoader, setShowLoader, setLoaderType }) => {

    const [visible, setVisible] = useState(false);

    const changeEnvironment = (name) => {
        localStorage.setItem('environment', (name == 'Quality Assurance' ? 'Staging' : name))
        // window.location.reload()
        loaderConfigure(2)
    }

    const loaderConfigure = (loaderType) => {
        setLoaderType(loaderType)
        setShowLoader(true)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
    }

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children} onClick={() => setVisible(!visible)}>{children}</div>
                        {type == 'detail' &&
                            <div className={styles.tooltipContainer}>
                                {data && data.map((e, i) => {
                                    return(
                                        <>
                                               <div onClick={() => {changeEnvironment(e.name)}}>
                                                    <Link key={i} className={cn(styles.itemContainer, {[styles.active]: e.active})}>
                                                        <div className={e.name == 'Production' ? styles.production : e.name == 'Quality Assurance' ? styles.staging : styles.develop}>
                                                            <ServerIcon className={styles.logo} />
                                                        </div>
                                                        <div className={styles.appName}>
                                                            <p className={styles.env}>{e.name}</p>
                                                        </div>
                                                    </Link> 
                                               </div>
                                        </>
                                    )
                                })}
                            </div>
                        }
                    </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip