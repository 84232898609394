import React from 'react'
import styles from './createProgressionMarker.module.sass'
import ProgressionMarkerInfo from './ProgressionMarkerInfo'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

const CreateProgressionMarker = ({isChanged, setIsChanged}) => {

  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
            <ProgressionMarkerInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
        </div>
      </div>
    </>
  )
}

export default CreateProgressionMarker