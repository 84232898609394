import {GET_TASK, CREATE_TASK, EDIT_TASK, FILTER_TASK_SUCCESS, GET_PARAM_VALUES, ARCHIVE_TASK, DELETE_TASK, GET_TASK_SUCCESS, GET_TASK_ERROR, FILTER_TASK_ERROR} from "../../../../actionTypes/actionTypes";
import TaskServices from '../../../../../services/engageServices/Achievement/Task/taskServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getParamValuesAction = (body) => {
    return async (dispatch) => {
        await TaskServices.getParamValues(body)
        .then(res=>{
            dispatch(getParamValues(res.data.data))
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const getAllTaskAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await TaskServices.getAllTask(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllTask(res.data.data.tasks, res.data.data.totalCount, GET_TASK_SUCCESS))
        }).catch(err=>{
            dispatch(err.response, null, GET_TASK_ERROR)
        })
    }
}

export const filterTaskAction = (body, showArchive, isFiltered) => {
    return async (dispatch) => {
        await TaskServices.getAllTask(body)
        .then(res => {
            if(showArchive){
                showArchive()
            }
            dispatch(filterTask(res.data.data.tasks, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_TASK_SUCCESS))
        }).catch(err=>{
            dispatch(filterTask(err.response, null, FILTER_TASK_ERROR))
        })
    }
}

export const createTaskAction = (body, navigate, appid, setDisable,visible, setVisible, setId) => {
    return async (dispatch) => {
        setDisable(true)
        await TaskServices.createTask(body)
        .then(res=>{
            if(setVisible){
                setId(res.data.data.id)
                sessionStorage.removeItem('contents')
                setVisible(true)
                setDisable(false)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            }else{
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                    setTimeout(()=>{
                        setDisable(false)
                        sessionStorage.removeItem('contents')
                        navigate(`/task/${appid}`)
                    }, 2000)
                }
            }
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editTaskAction = (body, navigate, appid, setDisable,visible, setVisible) => {
    return async (dispatch) => {
        setDisable(true)
        await TaskServices.editTask(body)
        .then(res=>{
            if(setVisible){
                sessionStorage.removeItem('contents')
                setVisible(true)
                setDisable(false)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            }else{
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                    setTimeout(()=>{
                        setDisable(false)
                        sessionStorage.removeItem('contents')
                        navigate(`/task/${appid}`)
                    }, 2000)
                }
            }
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archiveTaskAction = (body, appid, onClose) => {
    return async(dispatch) => {
        await TaskServices.archiveTask(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId : appid, offset: 0, limit: 8}
            dispatch(getAllTaskAction(data))
            onClose()
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const deleteTaskAction = (body, appid, onClose) => {
    return async(dispatch) => {
        await TaskServices.deleteTask(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId : appid, offset: 0, limit: 8}
            dispatch(getAllTaskAction(data))
            onClose()
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getParamValues = (data) => {
    return {
        type : GET_PARAM_VALUES,
        payload : data
    }
}

export const getAllTask = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const createTask = (data) => {
    return {
        type : CREATE_TASK,
        payload : data
    }
}

export const editTask = (data) => {
    return {
        type : EDIT_TASK,
        payload : data
    }
}

export const filterTask = (data, count, type) => {
    return{
        type : type,
        totalCount : count,
        payload : data,
    }
}