import React, { useState } from 'react'
import styles from './forgotPassword.module.sass'
import TextInput from '../../../components/TextInput'
import Icon from '../../../components/Icon'
import { useDispatch } from 'react-redux'
import { getResetPasswordLinkAction } from '../../../redux/action/authenticate/authenticationAction'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Spectre from '../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as SpecterLogo} from '../../../media/images/Specter_Logo.svg'
import {ReactComponent as HalfCircle} from '../../../media/images/half_ellipse.svg'
import {ReactComponent as Cross} from '../../../media/images/hollow_crossed.svg'
import {ReactComponent as FullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as SmallerFullCircle} from '../../../media/images/full_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../media/images/full_hollow_circle.svg'
import {ReactComponent as FilledCross} from '../../../media/images/filled_cross.svg'
import FilledCrossed from '../../../media/images/fill_cross.png'
import {ReactComponent as HollowCross} from '../../../media/images/x_fill.svg'
import Lottie from 'lottie-react'

export const ForgotPassword = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({email:''})
    const style = {
        width: 560,
        height: 608,
    }



    function validateEmailAddress(emailAddress) {
        let atSymbol = emailAddress.indexOf("@");
        let dotSymbol = emailAddress.lastIndexOf(".");
        let spaceSymbol = emailAddress.indexOf(" ");
    
        if ((atSymbol != -1) &&
            (atSymbol != 0) &&
            (dotSymbol != -1) &&
            (dotSymbol != 0) &&
            (dotSymbol > atSymbol + 1) &&
            (emailAddress.length > dotSymbol + 1) &&
            (spaceSymbol == -1)) {
            return true;
        } else {
            return false;
        }
    }
      

    const validation = (data) => {
        const error = {}
        let isValid = true
        
        if(!validateEmailAddress(email)){
            error.email = 'Please enter a valid email address.'
            isValid = false
        }
        if(data.email == null || data.email == ''){
            error.email = 'Email is required'
            isValid = false
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }


    const sendEmail = () => {
        let getLink = {
            email : email,
            url : window.location.href.slice(0, -15) + 'reset-password'
        }
        if(validation(getLink)){
            dispatch(getResetPasswordLinkAction(getLink))
        }
    }

    return (
    <>
        <ToastContainer />
        {/* <div className={styles.ForgotPassword}>
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    <div className={styles.Form}>
                            <div className={styles.passWord}>
                                <TextInput type='email' placeholder='Email' label='Email' fieldName='email' currency={<Icon name='mail' size='24' />} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className={styles.ForgotButton}>
                                <button className={styles.btn} onClick={sendEmail}>Get Link</button>
                            </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className={styles.forgotPassword}>
                <div className={styles.forgotPassword_container_outer}>
                    <div className={styles.logo}>
                        <SpecterLogo/>
                    </div>
                    <div className={styles.forgotPassword_container_inner}>
                        <div className={styles.Branding}>
                            <HalfCircle className={styles.halfCircle}/>
                            <Cross className={styles.crossFilled}/>
                            <FullCircle className={styles.fullCircle}/>
                            <FullHollowCircle className={styles.hollowCircle}/>
                            <div className={styles.animation}>
                                <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true}
                                        autoPlay={true} style={style}/>
                            </div>
                        </div>
                        <div className={styles.form}>
                            <HollowCross className={styles.hollowCross}/>
                            <div className={styles.formForgotPassword}>
                                <img src={FilledCrossed} className={styles.filledCross}/>
                                <SmallerFullCircle className={styles.fullCircle}/>
                                <div className={styles.forgotPasswordForm}>
                                    <div className={styles.formTitle}>
                                        <p className={styles.title}>Forgot your password?</p>
                                        <p className={styles.content}>Enter your email below and we'll send you a link to reset it. Email</p>
                                    </div>

                                    <div className={styles.formFiedls}>
                                        <TextInput position="bottom" errorMessage={errors.email} type='email' placeholder='Email' fieldName='email' currency={<Icon name='mail' size='24' />} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>

                                    <div className={styles.forgotPassword}>
                                        <button className={styles.Save} onClick={sendEmail}>Send Reset Link</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
