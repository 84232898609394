import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import styles from './profile.module.sass'
import moment from 'moment'
import Icon from '../../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePlaceholder from '../../../../../../media/images/placeholders/Profile.png'
import { changeMemberPasswordAction, editUserProfileAction, getUserProfileAction } from '../../../../../../redux/action/organisation/ProfileAction/profileAction'
import { toast } from 'react-toastify'
import Toast from '../../../../../../components/Toast'
import { ReactComponent as AddButtonIcon } from '../../../../../../media/images/icons/add_button.svg'
import axios from 'axios'
import SearchDropdown from '../../../../../../components/Dropdown/SearchDropdown'
import {ReactComponent as Internet} from '../../../../../../media/images/icons/internet.svg'
import { getAllMetaDataAction } from '../../../../../../redux/action/common/commonAction'
import Dropdown from '../../../../../../components/TypeHeadDropdown/SingleTypeHeadDropdown'
import newTimezones from '../../../../../../components/CustomTimeZones'
import { BASE_URL } from '../../../../../../resources/APIEndpoints'

const Profile = ({data, setShowLoader}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const logoRef = useRef()
    const [selectedZone, setSelectedZone] = React.useState("");
    const [selectedZoneID, setSelectedZoneID] = React.useState("");
    
    const [rules, setRules] = useState({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})

    let UserProfile = useSelector((state) => {
        return state.organisation.userProfile
    })

    const placeholder = ProfilePlaceholder
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setUserProfile(org => ({...org, profileUrl : res.data.getUrl}))
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

        // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));     
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
        // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setUserProfile(user => ({...user, profileUrl : null}))
        setLogo({src: placeholder , alt: 'Upload an Image'})
        logoRef.current.value = ''
    }

    const [userProfile, setUserProfile] = useState({profileUrl : null, name : '', email : '', location: '', timezone: ''})
    const [disableField, setDisableField] = useState(true)
    const [location, setLocation] = useState('')
    const [locationId, setLocationId] = useState(null)


    const configureUserProfile = () => {
        if(UserProfile && (typeof UserProfile === 'object' && UserProfile !== null && !Array.isArray(UserProfile))){
            setLogo({src: (UserProfile && UserProfile.profile_url != null && UserProfile.profile_url != '' ? UserProfile.profile_url : ProfilePlaceholder), alt: 'Upload an Image'}); 
            setUserProfile(user => ({...user, profileUrl : (UserProfile && UserProfile.profile_url != null && UserProfile.profile_url != '' ? UserProfile.profile_url : null)}))
            setUserProfile(user => ({...user, name : (UserProfile && UserProfile.name != null && UserProfile.name != '' ? UserProfile.name : '')}))
            setUserProfile(user => ({...user, email : (UserProfile && UserProfile.email != null && UserProfile.email != '' ? UserProfile.email : '')}))
            setUserProfile(user => ({...user, location : (UserProfile && UserProfile.location != null && UserProfile.location != '' ? UserProfile.location : '')}))
            setUserProfile(user => ({...user, timezone : (UserProfile && UserProfile.timezone!= null && UserProfile.timezone != '' ? newTimezones && newTimezones.filter((e,i) => (e.zoneName === UserProfile.timezone))[0] : '')}))
            setSelectedZone(newTimezones && newTimezones.filter((e,i) => (e.zoneName === UserProfile.timezone))[0])
        }
    }
    
    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    useEffect(() => {
        getAllLocation()
    }, [])

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }


    useEffect(() => {
        configureUserProfile()
    }, [UserProfile])

    const editUserProfile = () => {
        let userDetail = {
            name : userProfile.name,
            profileUrl : userProfile.profileUrl,
            email : userProfile.email,
            location : location,
            timezone : selectedZone.zoneName
        }
        dispatch(editUserProfileAction(userDetail, setDisableField))
    }

    const cancelChanges = () => {
        configureUserProfile()
        setDisableField(true)
    }

    const [disablePassword, setDisablePassword] = useState(true)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [errors, setErrors] = useState({passWordErr : ''})


    function getCurrentTimeAndZoneCode(timezone) {
        const now = moment.tz(timezone.zoneName);
    
    return " ("+timezone.abbreviation+")";
    }
    
    
    const validation = (passWord, confirmPassword) => {
        let error = {}
        let isValid = true
        if(passWord !== confirmPassword){
            error.passWordErr = 'Password does not match'
            isValid = false
        }

        setErrors(error)
        if(isValid){
            return true
        }
    }

    const onChangePassword = (e) => {
        setNewPassword(e.target.value)
        setRules(validatePassword(e.target.value))
    }

    const validatePassword = (password) => {
        const rules = {
            lengthRule: password.length >= 8,
            capitalRule: /[A-Z]/.test(password),
            smallRule: /[a-z]/.test(password),
            numberRule: /\d/.test(password),
            specialRule: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        }
        return rules
    }

    const editPassword = () => {
        if(validation(newPassword, confirmPassword)){
           if(rules.lengthRule && rules.smallRule && rules.capitalRule && rules.numberRule && rules.specialRule){
            let newPassword = {
                email : UserProfile.email,
                currentPassword : currentPassword,
                newPassword : confirmPassword
            }
            dispatch(changeMemberPasswordAction(newPassword, setDisablePassword, setCurrentPassword, setNewPassword, setConfirmPassword, setRules))
           }
        }
    }

    const cancelPasswordChanges = () => {
        setConfirmPassword('')
        setNewPassword('')
        setCurrentPassword('')
        setDisablePassword(true)
    }

    return (
        <>
            <Card title="Profile" classTitle="title-purple" head={disableField && <div className={styles.icon} onClick={()=>{setDisableField(!disableField)}}><span className={styles.edit}><Icon name='edit' size='24' /></span></div>}>
                <div className={styles.overview}>

                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <img src={src} alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img>
                        </div>
                        <div className={styles.previewInput}> 
                            <input disabled={disableField ? true : false}  id={`icon`} type="file" className='file-input' accept="image/jpeg, image/png, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                            {!disableField && <label for='icon' className={styles.button}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {(!disableField && userProfile != null && userProfile.profileUrl != null) ?
                            <div className={styles.previewCanecl}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        : ''}
                    </div>
                    <div className={styles.group}>
                        <TextInput disabled={disableField ? true : false} className={styles.field} value={userProfile.name != null ? userProfile.name : ''} label='Name' name="name" type="text" onChange={(e) => setUserProfile(user => ({...user, name : e.target.value}))}/>
                        <TextInput disabled={disableField ? true : false} className={styles.field} value={userProfile.email != null ? userProfile.email : ''} label='Email' name='email' type='text' onChange={(e) => setUserProfile(user => ({...user, email : e.target.value}))}/>
                    </div>

                    <div className={styles.group}>
                        <div className={styles.field} ><SearchDropdown disabled={disableField ? true : false}  currency={<Internet />} label='Location' placeholder='Where are you based?' options={locationOption} value={location} setValue={setLocation} setId={setLocationId}/></div>
                    
                    <div className={styles.field}>
                        <Dropdown customFunc={()=>{""}} disabled={disableField ? true : false} currency={<Internet fill="#000000" size="16"/>} label={"Preferred Time Zone"} placeholder={"Preferred Time Zone"} setSelectedZone={setSelectedZone} setId={setSelectedZoneID} value={selectedZone.zoneName&& selectedZone.zoneName+getCurrentTimeAndZoneCode(selectedZone)} options={
                                newTimezones.map((place)=>{return {name:place.zoneName+getCurrentTimeAndZoneCode(place), value:place}})
                            }/>
                    </div>
                    </div>
                    {!disableField &&
                    <div className={styles.group}>
                        <div className={styles.cardAction}>
                            <div className={styles.cardSave}>
                                <button className={styles.Save} onClick={editUserProfile}>Save</button>
                            </div>
                             <div className={styles.cardCancel}>
                                <button className={styles.Cancel} onClick={cancelChanges}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Card>

            <Card title="Password Settings" classTitle="title-purple" head={disablePassword && <div className={styles.icon} onClick={()=>{setDisablePassword(!disablePassword)}}><span className={styles.edit}><Icon name='edit' size='24' /></span></div>}>
                <div className={styles.overview}>
                    {UserProfile.password !== null &&
                        <div className={styles.group}>
                            <TextInput disabled={disablePassword ? true : false} className={styles.field} label='Current Password' name='currentPassword' type='password' value={currentPassword} onChange={(e) => {setCurrentPassword(e.target.value)}} />
                        </div>
                    }
                    <div className={styles.group}>
                        <TextInput errorMessage={errors.passWordErr} disabled={disablePassword ? true : false} className={styles.field} label='New Password' name='newPassword' type='password' value={newPassword} onChange={(e) => {onChangePassword(e)}} />
                        <TextInput errorMessage={errors.passWordErr} disabled={disablePassword ? true : false} className={styles.field} label='Confirm Password' name='confirmPassword' type='password' value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} />
                    </div>
                    {!disablePassword &&
                    <div className={styles.rules}>
                        <div className={styles.passwordRule}>
                            <div className={styles.heading}>
                                <span>Password must contain: </span>
                            </div>
                            <div className={styles.ruleGroup}>
                                <div className={styles.rule}>
                                    {rules.lengthRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                    {rules.lengthRule != null && !rules.lengthRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                    {rules.lengthRule != null && rules.lengthRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                    <span>at least 8 characters</span>
                                </div>
                                <div className={styles.rule}>
                                    {rules.smallRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                    {rules.smallRule != null && !rules.smallRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                    {rules.smallRule != null && rules.smallRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                    <span>at least one lowercase letter (a-z)</span>
                                </div>
                                <div className={styles.rule}>
                                    {rules.capitalRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                    {rules.capitalRule != null && !rules.capitalRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                    {rules.capitalRule != null && rules.capitalRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                    <span>at least one uppercase letter (A-Z)</span>
                                </div>
                                <div className={styles.rule}>
                                    {rules.numberRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                    {rules.numberRule != null && !rules.numberRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                    {rules.numberRule != null && rules.numberRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                    <span>at least one number (0-9)</span>
                                </div>
                                <div className={styles.ruleFull}>
                                    {rules.specialRule == null && <span>&#9679;&nbsp;&nbsp;</span>}
                                    {rules.specialRule != null && !rules.specialRule && <span><Icon name='close' size='20' className={styles.error} /></span>}
                                    {rules.specialRule != null && rules.specialRule && <span><Icon name='check' size='20' className={styles.success} /></span>}
                                    <span>at least one special character (! @ # $ % ^ & )</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {!disablePassword &&
                        <div className={styles.group}>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button className={styles.Save} onClick={editPassword}>Save</button>
                                </div>
                                <div className={styles.cardCancel}>
                                    <button className={styles.Cancel} onClick={cancelPasswordChanges}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>
        </>
    )
}

export default Profile