import React, {useContext, useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import cn from 'classnames'
import styles from './GetCompetitionSchedule.module.sass'
import Form from '../../../../components/Form'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import { useDispatch, useSelector } from 'react-redux'
import CompetitionScheduleComponent from '../../../../components/EngageComponents/LiveOpsComponent/CompetitionScheduleComponent'
import Loader from "../../../../components/Loader";
import EmptyState from '../../../../components/EmptyStates'
import {filterScheduleCompetitionAction, getAllScheduleCompetitionAction} from '../../../../redux/action/engage/LiveOps/LiveOpsAction'
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const sorting = ["list", "grid"];

const GetCompetitionSchedule = () => {
  const {appid} = useParams()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const [offset, setOffset] = useState(0)

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getAllScheduleCompetition = () => {
      let CompSchedule = {
          projectId : appid,
          offset: ((page-1)*limit),
          limit: limit
      }
      dispatch(getAllScheduleCompetitionAction(CompSchedule))
  }


  let CompetitionSchedule = useSelector((state)=>{
    return state.liveOps.CompetitionSchedule
  })

  let scheduleCompetitionCount = useSelector((state) => {
    return state.liveOps.totalCount
  })

  let isLoading = useSelector((state)=>{
    return state.liveOps.isLoading
  })

  let filteredCompetitionSchedule = useSelector((state) => {
    return state.liveOps.filteredScheduleCompetition
  })

  let isSearched = useSelector((state) => {
    return state.liveOps.isSearched
  })

  useEffect(()=>{
    CompetitionSchedule && CompetitionSchedule.map((x)=>{
    })
   
  }, [CompetitionSchedule])

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(()=>{
    configureSkeleton()
    getAllScheduleCompetition()
  }, [page])

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [searchFlag, setSearchFlag] = useState(false)

  const onSearch = (search) => {
    if(search == ''){
      setSearchFlag(false)
      getAllScheduleCompetition()
    }else{
      setSearchFlag(true)
      let searchScheduleCompetition = {search : search, projectId : appid,offset: ((page-1)*limit),limit: limit}
      dispatch(filterScheduleCompetitionAction(searchScheduleCompetition))
    }
  }

  return(

    <>
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Competitions"} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              <div className={styles.add}>
                {showSkeleton && CompetitionSchedule.length > 0 ?
                  <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                : !showSkeleton && CompetitionSchedule.length > 0 ?
                  <>
                  {!userAccess &&
                    <div className={styles.createButton}>
                      <Link to={`/create-competition-schedule/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                    </div>
                  }
                  </>
                : ''}
              </div>
            </>
          )
        }
      >
      
        <div className={styles.wrapper}>
            {CompetitionSchedule.length > 0 && <div className={styles.seprator}></div>}
              <div className={styles.options}>
                <div className={styles.filters}>
                  {(showSkeleton && CompetitionSchedule.length > 0) ?
                    <>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={74} height={34}/>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={130} height={34}/>
                    </>
                  : !showSkeleton && CompetitionSchedule.length > 0 ?
                    <>
                      <div className={styles.buttons}>
                          <Icon name='filter' size='20' className={styles.icon} />
                          <span className={styles.buttonText}>Filter</span>
                      </div>
                      <div className={styles.buttons}>
                          <ArchiveSvg className={styles.icon} />
                          <span className={styles.buttonText}>Show Archived</span>
                      </div>
                    </>
                  : ''}
                  
                </div>
                <div className={styles.searchForm}>
                {showSkeleton && CompetitionSchedule.length > 0 ?
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40}/>
                : !showSkeleton && CompetitionSchedule.length > 0 ?
                  <Form className={styles.form} placeholder="Search Live-Ops" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                : ''}
                </div>
            </div>
            {showSkeleton && 
              (CompetitionSchedule.length == 0 ?
                <SkeletonEmptyState />
              : 
                <SkeletonTable rows={CompetitionSchedule.length} column={6} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[40, 110, 110, 110, 110, 40]} colHeight={[40, 25, 25, 25, 25, 40]} />
              )
            }

            {!showSkeleton && 
              (CompetitionSchedule.length == 0 ?
                <EmptyState access={userAccess} route={`/create-competition-schedule/${appid}`} type="LiveOps" />
              : searchFlag ?
                <>
                  {isSearched && <div className={styles.textCenter}><Loader /></div>}
                  {!isSearched && filteredCompetitionSchedule.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
                  {!isSearched && filteredCompetitionSchedule.length > 0 && <CompetitionScheduleComponent access={userAccess} items={filteredCompetitionSchedule} title="Last edited" /> }
                </>
              : <CompetitionScheduleComponent access={userAccess} items={CompetitionSchedule} title="Last edited" /> 
              )
            }
            
            {!isLoading && CompetitionSchedule.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(scheduleCompetitionCount/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
        </div>

        {/* <div>
          {isLoading ?
            <div className={styles.wrapper}>
              <div className={styles.loading}>
                <Loader className={styles.loader}/>
              </div>
            </div>
          : isLoading == false && CompetitionSchedule.length == 0 ?
            <>
              <div className={styles.wrapper}>
                <EmptyState route={`/create-competition-schedule/${appid}`} type="Schedule Competition" />
              </div>
            </>
          : isLoading == false && CompetitionSchedule.length > 0 ?
          <>
            <div className={styles.wrapper}>
                <div className={styles.seprator}></div>
                  <div className={styles.options}>
                    <div className={styles.filters}>
                      <div className={styles.buttons}>
                        <Icon name='filter' size='20' className={styles.icon} />
                        <span className={styles.buttonText}>Filter</span>
                      </div>
                      <div className={styles.buttons}>
                        <ArchiveSvg className={styles.icon} />
                        <span className={styles.buttonText}>Show Archived</span>
                      </div>
                    </div>
                    <div className={styles.searchForm}>
                      <Form className={styles.form} placeholder="Search Live Ops" type="text" name="search" icon="search" />
                    </div>
                  </div>
                {activeIndex === 0 && 
                <>
                  <CompetitionScheduleComponent items={CompetitionSchedule} title="Last edited" /> 
                </>
                }
            </div>
            <div className={styles.pagination}>
              <Stack>
                <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                      '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                      }
                      }}
                  />
              )} siblingCount={1} count={Math.ceil(scheduleCompetitionCount/limit)} color="primary" onChange={handlePageChange} />
              </Stack>
            </div>
          </>
          : ''}
        </div> */}
      </Card>
      <div className="divider"></div>
      {/* {isLoading == false && CompetitionSchedule.length == 0 && */}
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about Competition Schedule <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/live-ops/schedule-competitions" target="_blank">Click Here</a></p>
          </div>
      {/* } */}
    </>
  )
}

export default GetCompetitionSchedule