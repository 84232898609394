import React, {useContext, useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import cn from 'classnames'
import styles from './bundle.module.sass'
import Form from '../../../../../components/Form'
import Card from '../../../../../components/Card'
import Icon from '../../../../../components/Icon'
import addIcon from '../../../../../media/images/icons/light.png'
import { useDispatch, useSelector } from 'react-redux'
import { createBundleAction, editBundleAction, filterBundlesAction, getAllBundlesAction } from '../../../../../redux/action/builds/economy/bundles/bundleAction'
import BundlesTableLayout from '../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/BundleTableLayout'
import BundlesCardLayout from '../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/BundleCardLayout'
import { ReactComponent as EmptyCurrencyState } from "../../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from "../../../../../components/Loader";
import EmptyState from '../../../../../components/EmptyStates'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import Control from './Filters/Control'
import ExportTooltip from '../../../../../components/CustomTooltips/ExportTooltip'
import XLSX from 'xlsx';
import axios from 'axios'
import { BASE_URL, GET_BUNDLES } from '../../../../../resources/APIEndpoints'
import { getAllProgressionSystemAction } from '../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction'
import { getAllCurrencyAction } from '../../../../../redux/action/builds/economy/currency/currencyAction'
import ControlFileUpload from './Control'
import customAxios from '../../../../../utils/interceptor'
import { ToastContainer } from 'react-toastify'

const sorting = ["list", "grid"];

const GetBundles = () => {

  const {appid} = useParams()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Filters
  const [type, setType] = useState([])
  const [subType, setSubType] = useState([])
  const [isLimited, setIsLimited] = useState(null)
  const filters = {type, setType, subType, setSubType, isLimited, setIsLimited}
  // Sort
  const sortList = [{id: 1, name: 'Bundle Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // Search
  const [search, setSearch] = useState(null);
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getAllBundles = async(search) => {
      let bundles = {
        projectId : appid,
        offset: ((page-1)*limit),
        limit: limit,
        // Archive
        showArchived: showArchive,
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // filter
        types: type,
        subTypes: subType,
        ...(isLimited != null && {isLimited: isLimited}),
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
      }
      let keysToCheck = ['type', 'subType']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      if(!search && isFiltered){
        await dispatch(getAllBundlesAction(bundles, configureSkeleton))
      }else{
        await dispatch(filterBundlesAction(bundles, configureSkeleton, !isFiltered))
      }
  }

  let bundles = useSelector((state)=>{
    return state.bundles.bundles
  })

  let totalBundle = useSelector((state)=>{
    return state.bundles.totalCount
  })


  let isFiltered = useSelector((state)=>{
    return state.bundles.isFiltered
  })

  let isLoading = useSelector((state)=>{
    return state.bundles.isLoading
  })

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getAllBundles(search)
    }
  }, [page,selectedSort, showArchive])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllBundles(null)
    }else{
      setSearch(search)
      getAllBundles(search)
    }
  }

  // Import

  // File Data
  const [bundleData, setBundleData] = useState([])

  // Files
  const [bundleFile, setBundleFile] = useState(null)
  const [pricingFile, setPricingFile] = useState(null)
  const [lockByFile, setLockByFile] = useState(null)

  // File Errors
  const [bundleFileErrors, setBundleFileErrors] = useState(null)
  const [pricingFileErrors, setPricingFileErrors] = useState(null)
  const [lockByFileError, setLockByFileError] = useState(null)

  // File Upload 

  const onCSVBundle = (e) => {
    setBundleFile(e)
  }

  const onCSVPricing = (e) => {
    setPricingFile(e)
  }

  const onCSVFile = (e) => {
    setLockByFile(e)
  }

  // Currency API

    // API Requests

  const [currencyList, setCurrencyList] = useState([])
  const [realWorldCurrency, setRealWorldCurrency] = useState([])
  const getRealWorldCurrency = async() => {
    let currencies = {
      projectId : appid,
      offset: 0,
      limit: 50
    }
    dispatch(getAllCurrencyAction(currencies))
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
    res.data.data && res.data.data.forEach((element) => {
        setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
    })
  }
  
  let currency = useSelector((state) => {
    return state.currencies.currency
  })
  
  const configureCurrency = () => {
    if(currency && currency?.length > 0){
      setCurrencyList(currency)
    }
  }

  useEffect(() => {
    getRealWorldCurrency()
  }, [])
  
  useEffect(() => {
    configureCurrency()
  }, [currency])

  // File Validation and JSON Conversion

  // 1. Bundle File

  // 1(A) - Check Bundle File Headers

  const checkErrors = (header) => {
    let newErrors = []
    let isError = true;
    if(header[0] !== 'Bundle Icon') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Bundle Icon.`)
      isError = false
    }
    if(header[1] !== 'Display Name') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Display Name.`)
      isError = false
    }
    if(header[2] !== 'Bundle ID') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Bundle ID.`)
      isError = false
    }
    if(header[3] !== 'Bundle Description') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Bundle Description.`)
      isError = false
    }
    if(header[4] !== 'Content Currencies') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Content Currencies.`)
      isError = false
    }
    if(header[5] !== 'Content Items') {
      newErrors.push(`Please change 6th Column name from ${header[5]} to Content Items.`)
      isError = false
    }
    if(header[6] !== 'Content Bundles') {
      newErrors.push(`Please change 7th Column name from ${header[6]} to Content Bundles.`)
      isError = false
    }
    if(header[7] !== 'Bundle Type') {
      newErrors.push(`Please change 8th Column name from ${header[7]} to Bundle Type.`)
      isError = false
    }
    if (header[8] !== 'Count/Time Usage') {
      newErrors.push(`Please change 9th Column name from ${header[8]} to Count/Time Usage.`)
      isError = false
    }
    if (header[9] !== 'Uses (if Count)') {
      newErrors.push(`Please change 10th Column name from ${header[9]} to Uses (if Count).`)
      isError = false
    }
    if (header[10] !== 'Time Value (if Time)') {
      newErrors.push(`Please change 11th Column name from ${header[10]} to Time Value (if Time).`)
      isError = false
    }
    if (header[11] !== 'Time Unit (if Time)') {
      newErrors.push(`Please change 12th Column name from ${header[11]} to Time Unit (if Time).`)
      isError = false
    }
    if (header[12] !== 'Bundle Subtypes') {
      newErrors.push(`Please change 13th Column name from ${header[12]} to Bundle Subtypes.`)
      isError = false
    }
    if (header[13] !== 'Max Stack Count') {
      newErrors.push(`Please change 14th Column name from ${header[13]} to Max Stack Count.`)
      isError = false
    }
    if (header[14] !== 'Max Collection Instances') {
      newErrors.push(`Please change 15th Column name from ${header[14]} to Max Collection Instances.`)
      isError = false
    }
    if (header[15] !== 'Limited Availability') {
      newErrors.push(`Please change 16th Column name from ${header[15]} to Limited Availability.`)
      isError = false
    }
    if (header[16] !== 'Limited Quantity') {
      newErrors.push(`Please change 17th Column name from ${header[16]} to Limited Quantity.`)
      isError = false
    }
    if (header[17] !== 'Open Bundle') {
      newErrors.push(`Please change 18th Column name from ${header[17]} to Open Bundle.`)
      isError = false
    }
    if (header[18] !== 'Tags') {
      newErrors.push(`Please change 19th Column name from ${header[18]} to Tags.`)
      isError = false
    }
    if (header[19] !== 'Meta Data') {
      newErrors.push(`Please change 20th Column name from ${header[19]} to Meta Data.`)
      isError = false
    }
    setBundleFileErrors(newErrors);
    if(isError){
      return true
    }else{
      return false
    }
  }

  // 1(B) Convert CSV File To JSON

  const parseValidInt = (str) => {
    if (!isNaN(str) && !isNaN(parseInt(str))) {
      return parseInt(str);
    }
    return str;
  }

  const transformBundleContent = (currencies, items, bundles) => {
    let contentCurrencies = [];
    let contentItems = [];
    let contentBundles = [];

    if(currencies && currencies !== '' && currencies !== null && currencies !== 'N/A'){
      let allCurrency = currencies && currencies.split(/\s*,\s*/)
      const result = allCurrency && allCurrency.map(item => {
        // Step 3: Use a regular expression to match the id and value
        const match = item.match(/\s*([a-zA-Z_]+)\s*\(\s*(\d+)\s*\)/);
        if (match) {
          return {
            currency : match[1].trim(),
            type : 'Currency',
            currencyId: null,
            quantity: parseInt(match[2].trim(), 10),
            errors: {currencyIdErr: [], itemIdErr: [], bundleIdErr: [], contentQuantityErr: []}
          };
        }
        return null;
      }).filter(item => item !== null)
      contentCurrencies = result
    }

    if(items && items !== '' && items !== null && items !== 'N/A'){
      let allItems = items && items.split(/\s*,\s*/)
      const result = allItems && allItems.map(item => {
        // Step 3: Use a regular expression to match the id and value
        const match = item.match(/\s*([a-zA-Z_]+)\s*\(\s*(\d+)\s*\)/);
        if (match) {
          return {
            item : match[1].trim(),
            type : 'Items',
            itemId: null,
            quantity: parseInt(match[2].trim(), 10),
            errors: {currencyIdErr: [], itemIdErr: [], bundleIdErr: [], contentQuantityErr: []}
          };
        }
        return null;
      }).filter(item => item !== null)
      contentItems = result
    }

    if(bundles && bundles !== '' && bundles !== null && bundles !== 'N/A'){
      let allBundles = bundles && bundles.split(/\s*,\s*/)
      const result = allBundles && allBundles.map(item => {
        // Step 3: Use a regular expression to match the id and value
        const match = item.match(/\s*([a-zA-Z_]+)\s*\(\s*(\d+)\s*\)/);
        if (match) {
          return {
            bundle : match[1].trim(),
            type : 'Bundles',
            contentBundleId: null,
            quantity: parseInt(match[2].trim(), 10),
            errors: {currencyIdErr: [], itemIdErr: [], bundleIdErr: [], contentQuantityErr: []}
          };
        }
        return null;
      }).filter(item => item !== null)
      contentBundles = result
    }

    let content = [...contentCurrencies, ...contentItems, ...contentBundles]
    return content
  }

  const transformBundleData = (items) => {
    return items && items.map((entry, index) => ({
      projectId : appid,
      iconUrl : ((entry["Bundle Icon"] !== null && entry["Bundle Icon"] !== '' && entry["Bundle Icon"] !== 'N/A') ? entry["Bundle Icon"] : null),
      name : ((entry["Display Name"] !== null && entry["Display Name"] !== '' && entry["Display Name"] !== 'N/A') ? entry["Display Name"] : null),
      bundleId : ((entry["Bundle ID"] !== null && entry["Bundle ID"] !== '' && entry["Bundle ID"] !== 'N/A') ? entry["Bundle ID"] : null),
      description : ((entry["Bundle Description"] !== null && entry["Bundle Description"] !== '' && entry["Bundle Description"] !== 'N/A') ? entry["Bundle Description"] : null),
      isEquippable : (entry["Bundle Subtypes"] != null && entry["Bundle Subtypes"] != '' && entry["Bundle Subtypes"] !== 'N/A' && entry["Bundle Subtypes"].includes("Equippable") ? true : false),
      isTradable : (entry["Bundle Subtypes"] != null && entry["Bundle Subtypes"] != '' && entry["Bundle Subtypes"] !== 'N/A' && entry["Bundle Subtypes"].includes("Tradable") ? true : false),
      isStackable : (entry["Bundle Subtypes"] != null && entry["Bundle Subtypes"] != '' && entry["Bundle Subtypes"] !== 'N/A' && entry["Bundle Subtypes"].includes("Stackable") ? true : false),
      isRentable : (entry["Bundle Subtypes"] != null && entry["Bundle Subtypes"] != '' && entry["Bundle Subtypes"] !== 'N/A' && entry["Bundle Subtypes"].includes("Rentable") ? true : false),
      isTimeStackable : (entry["Bundle Subtypes"] != null && entry["Bundle Subtypes"] != '' && entry["Bundle Subtypes"] !== 'N/A' && entry["Bundle Subtypes"].includes("TimeStackable") ? true : false),
      itemType : ((entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Durable') ? false : (entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Consumable') ? true : (entry["Bundle Type"] === null || entry["Bundle Type"] === '' || entry["Bundle Type"] === 'N/A') ? false : entry["Bundle Type"]),
      isConsumable : ((entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Durable') ? false : (entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Consumable') ? true : (entry["Bundle Type"] === null || entry["Bundle Type"] === '' || entry["Bundle Type"] === 'N/A') ? false : entry["Bundle Type"]),
      ...((entry["Uses (if Count)"] !== null && entry["Uses (if Count)"] !== '' && entry["Uses (if Count)"] !== 'N/A') && {consumeByUses: parseValidInt(entry["Uses (if Count)"])}),
      ...((entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A') && {consumeByTime: ((entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'seconds') ? parseValidInt(entry["Time Value (if Time)"]) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'minutes') ? parseValidInt(entry["Time Value (if Time)"]*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'hours') ? parseValidInt(entry["Time Value (if Time)"]*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'days') ? parseValidInt(entry["Time Value (if Time)"]*24*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'months') ? parseValidInt(entry["Time Value (if Time)"]*30.42*24*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'years') ? parseValidInt(entry["Time Value (if Time)"]*365*24*60*60) : parseValidInt(entry["Time Value (if Time)"]))}),
      ...(entry["Time Unit (if Time)"] !== null && entry["Time Unit (if Time)"] !== '' && entry["Time Unit (if Time)"] !== 'N/A' && {consumeByTimeFormat: entry["Time Unit (if Time)"]}),
      quantity : ((entry["Limited Quantity"] !== null && entry["Limited Quantity"] !== '' && entry["Limited Quantity"] !== 'N/A') ? parseValidInt(entry["Limited Quantity"]) : null),
      isManual : ((entry["Open Bundle"] && entry["Open Bundle"] !== null && entry["Open Bundle"] !== 'N/A') ? (entry["Open Bundle"] === 'Automatic' ? false : entry["Open Bundle"] === 'Manual' ? true : false) : false),
      stackCapacity : ((entry["Max Stack Count"] !== null && entry["Max Stack Count"] !== '' && entry["Max Stack Count"] !== 'N/A') ? parseValidInt(entry["Max Stack Count"]) : null),
      maxCollectionInstance: ((entry["Max Collection Instances"] !== null && entry["Max Collection Instances"] !== '' && entry["Max Collection Instances"] !== 'N/A') ? parseValidInt(entry["Max Collection Instances"]) : null),
      bundlePrices: [],
      rewardUnlockCondition : [],
      bundleContent : transformBundleContent(entry["Content Currencies"], entry["Content Items"], entry["Content Bundles"]),
      typeId : ((entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Durable') ? "1" : (entry["Bundle Type"] !== null && entry["Bundle Type"] !== '' && entry["Bundle Type"] !== 'N/A' && entry["Bundle Type"] === 'Consumable') ? "2" : (entry["Bundle Type"] === null || entry["Bundle Type"] === '' || entry["Bundle Type"] === 'N/A') ? 1 : entry["Bundle Type"]),
      meta : ((entry["Meta Data"] && entry["Meta Data"] !== null && entry["Meta Data"] !== '' && entry["Meta Data"] !== 'N/A') ? JSON.parse(entry["Meta Data"]) : null),
      tags : ((entry["Tags"] && entry["Tags"] !== null && entry["Tags"] !== 'N/A'  && entry["Tags"] !== '') ? entry["Tags"].split(", ") : null),
      active : true,
      archive : false
    }));
  }

  const convertBundleToJson = async(data, headers) => {
    if(data.length > 1000){
      setBundleFileErrors([`Total number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformBundleData(dataRows)
    }
  }

  const checkBundleContent = (content, data) => {
    return data && data.map((e) => ({
      ...e,
      bundleContent: e.bundleContent && e.bundleContent.map((x, i) => {
        if(x.currency !== undefined){
          let matchObj = content && content.find(content => content.id === x.currency)
          if(matchObj){
            return{
              ...x,
              errors: {
                ...x.errors,
                currencyIdErr : [
                  ...(x.errors && x.errors.currencyIdErr ? x.errors.currencyIdErr : []), `Currency Id with ${x.currency} does not exists`
                ]
              }
            }
          }else{
            return {
              ...x,
              errors: {
                ...x.errors,
                currencyIdErr: x.errors && x.errors.currencyIdErr ? [...x.errors.currencyIdErr] : [] 
              }
            }
          }
        }else if(x.item !== undefined){
          let matchObj = content && content.find(content => content.id === x.item)
          if(matchObj){
            return{
              ...x,
              errors: {
                ...x.errors,
                itemIdErr: [
                  ...(x.errors && x.errors.itemIdErr ? x.errors.itemIdErr : []), `Item Id with ${x.item} does not exists`
                ]
              }
            }
          }else{
            return {
              ...x,
              errors: {
                ...x.errors,
                itemIdErr: x.errors && x.errors.itemIdErr ? [...x.errors.itemIdErr] : [] 
              }
            }
          }
        }else{
          let matchObj = content && content.find(content => content.id === x.bundle)
          if(matchObj){
            return{
              ...x,
              errors: {
                ...x.errors,
                bundleIdErr: [
                  ...(x.errors && x.errors.bundleIdErr ? x.errors.bundleIdErr : []), `Bundle Id with ${x.bundle} does not exists`
                ]
              }
            }
          }else{
            return {
              ...x,
              errors: {
                ...x.errors,
                bundleIdErr: x.errors && x.errors.bundleIdErr ? [...x.errors.bundleIdErr] : [] 
              }
            }
          }
        }
      })
    }))

  }

  const getAllContentId = (content, data) => {
    return data && data.map((e) => ({
      ...e,
      bundleContent: e.bundleContent && e.bundleContent.map((x, i) => {
        if(x.currency !== undefined && x.type === 'Currency'){
          let matchObj = content && content.find(content => content.currencyId === x.currency)
          if(matchObj){
            return{
              ...x,
              currencyId: matchObj.id,
              errors: {
                ...x.errors,
                currencyIdErr: [] 
              }
            }
          }else{
            return {
              ...x
            }
          }
        }else if(x.item !== undefined && x.type === 'Items'){
          let matchObj = content && content.find(content => content.itemId === x.item)
          if(matchObj){
            return{
              ...x,
              itemId: matchObj.id,
              errors: {
                ...x.errors,
                itemIdErr: [] 
              }
            }
          }else{
            return {
              ...x
            }
          }
        }else{
          let matchObj = content && content.find(content => content.bundleId === x.bundle)
          if(matchObj){
            return{
              ...x,
              contentBundleId: matchObj.id,
              errors: {
                ...x.errors,
                bundleIdErr: [] 
              }
            }
          }else{
            return {
              ...x
            }
          }
        }
      })
    }))
  }

  const getContentData = async(rewards, bundleData) => {
    let res = await customAxios.post(`${BASE_URL}/rewards-details/validate`, rewards)
    let data = res.data.data
    let updatedBundleData = bundleData;
    if(data){
      if(data.bundlesDetails.existingBundles?.length > 0){
        let existingData = data.bundlesDetails.existingBundles
        updatedBundleData = updatedBundleData && updatedBundleData.map((e, index) => {
          let matchObj = existingData && existingData.find(x => x.bundleId === e.bundleId)
          if(matchObj){
            return{
              ...e,
              errors: {
                ...e.errors,
                idErr: [
                  ...(e.errors && e.errors.idErr ? e.errors.idErr : []), ...(matchObj.archive ? [`Bundle Id with ${e.bundleId} archived and already exist.`] : [`Bundle Id with ${e.bundleId} already exists.`])
                ]
              }
            }
          }else{
            return {
              ...e,
              errors: {
                ...e.errors,
                idErr: e.errors && e.errors.idErr ? [...e.errors.idErr] : [] 
              }
            }
          }
        })
      }

      let currencyData = data.currenciesDetails?.existingCurrencies && data.currenciesDetails?.existingCurrencies.map((e) => ({id: e.id, currencyId: e.currencyId, type: 'currency'}))
      let itemData = data.itemDetails?.existingItems && data.itemDetails?.existingItems.map((e) => ({id: e.id, itemId: e.itemId, type: 'items'}))
      let bundleData = data.bundlesDetails?.existingBundles && data.bundlesDetails?.existingBundles.map((e) => ({id: e.id, bundleId: e.bundleId, type: 'bundles'}))
      let existingData = [...currencyData, ...itemData, ...bundleData]
      if(existingData?.length > 0){
        updatedBundleData = await getAllContentId(existingData, updatedBundleData)
      }
      let currencyContent = data.currenciesDetails?.nonExistingCurrencies && data.currenciesDetails?.nonExistingCurrencies.map((e) => ({id: e, type: 'currency'}))
      let itemContent = data.itemDetails?.nonExistingItems && data.itemDetails?.nonExistingItems.map((e) => ({id: e, type: 'items'}))
      let bundleContent = data.bundlesDetails?.nonExistingBundles && data.bundlesDetails?.nonExistingBundles.map((e) => ({id: e, type: 'bundles'}))
      let nonExistingData = [...currencyContent, ...itemContent, ...bundleContent]
      if(nonExistingData?.length > 0){
        let checkBundleContentData = checkBundleContent(nonExistingData, updatedBundleData)
        return checkBundleContentData;
      }else{
        return updatedBundleData;
      }
    }
  }

  const convertBundleContent = async(data, type) => {
    const contentRewards = {
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      },
      projectId : appid
    }

    if(type === 'Bundles'){
      data && data.forEach(e => {
        if(e.bundleId && e.bundleId !== null && !contentRewards.rewardDetails.bundles.ids.includes(e.bundleId)){
          contentRewards.rewardDetails.bundles.ids.push(e.bundleId)
        }
        e.bundleContent && e.bundleContent.forEach(x => {
          if(x.currency && x.currency !== null && x.type === 'Currency' && !contentRewards.rewardDetails.currencies.ids.includes(x.currency)){
            contentRewards.rewardDetails.currencies.ids.push(x.currency)
          }
          if(x.item && x.item !== null && x.type === 'Items' && !contentRewards.rewardDetails.items.ids.includes(x.item)){
            contentRewards.rewardDetails.items.ids.push(x.item)
          }
          if(x.bundle && x.bundle !== null && x.type === 'Bundles' && !contentRewards.rewardDetails.bundles.ids.includes(x.bundle)){
            contentRewards.rewardDetails.bundles.ids.push(x.bundle)
          }
        })
      })
  
      let checkContentData = await getContentData(contentRewards, data)
      return checkContentData;
    }

  }

  // 1(C) Check Data Type

  const checkItemFileDataType = (obj) => {
    let errors = {iconErr: [], nameErr: [], idErr: [], descriptionErr: [], typeErr: [], byErr: [], countErr: [], timeErr: [], unitErr: [], subTypeErr: [], isLimitedErr: [], quantityErr: [], maxInstanceErr:[], stackErr:[]}
    
    if(obj.iconUrl !== null && typeof obj.iconUrl !== 'string'){
      errors.iconErr.push(`Bundle Icon should be a valid string.`);
    }
    if(typeof obj.name !== 'string'){
      errors.nameErr.push(`Display Name should be a valid string.`);
    }
    if(typeof obj.bundleId !== 'string'){
      errors.idErr.push(`Bundle ID should be a valid string.`);
    }
    if(obj.description !== null &&  typeof obj.description !== 'string'){
      errors.descriptionErr.push(`Bundle Description should be a valid string.`);
    }
    if(obj.itemType !== null && typeof obj.itemType !== 'boolean'){
      errors.typeErr.push(`Item Type should be a valid string and Either Durable or Consumable`);
    }
    if(!obj.itemType){
      if(obj.consumeByUses && obj.consumeByUses !== null){
        errors.countErr.push(`Item Type Should be Consumable.`);
      }
      if(obj.consumeByTime && obj.consumeByTime !== null){
        errors.timeErr.push(`Item Type Should be Consumable.`);
      }
      if(obj.consumeByTimeFormat && obj.consumeByTimeFormat !== null){
        errors.unitErr.push(`Item Type Should be Consumable.`);
      }
    }
    if(obj.consumeByUses && obj.consumeByUses !== null && typeof obj.consumeByUses !== 'number'){
      errors.countErr.push(`Uses (if Count) should be a valid integer.`);
    }
    if(obj.consumeByTime && obj.consumeByTime !== null && typeof obj.consumeByTime !== 'number'){
      errors.timeErr.push(`Time Value (if Time) should be a valid integer.`);
    }
    if(obj.consumeByTimeFormat && obj.consumeByTimeFormat !== null && typeof obj.consumeByTimeFormat !== 'string'){
      errors.unitErr.push(`Time Unit (if Time) should be a valid string.`);
    }
    if(obj.stackCapacity !== null && typeof obj.stackCapacity !== 'number'){
      errors.stackErr.push(`Stack Capacity should be a valid integer.`);
    }
    if(obj.maxCollectionInstance !== null && typeof obj.maxCollectionInstance !== 'number'){
      errors.maxInstanceErr.push(`Max Collection Instance should be a valid integer.`);
    }
    if(obj.quantity !== null && typeof obj.quantity !== 'number'){
      errors.quantityErr.push(`Limited Quantity should be a valid integer.`);
    }
    return errors
  }
  
  const checkDataType = (obj) => {
    return obj && obj.map((e, i) => ({
      ...e,
      errors: checkItemFileDataType(e)
    }))
  }

  const validateBundleCSV = () => {
    return new Promise((resolve, reject) => {
      if(bundleFile && (bundleFile.size/1024).toFixed(2) < 10000){
        const fileExtension = bundleFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              if(pricingFile === null){
                resolve(true);
              }
              const json = XLSX.utils.sheet_to_json(worksheet);
              let bundleJSON = await convertBundleToJson(json, headers)
              let bundlesInfo = await checkDataType(bundleJSON)
              let bundleContent = await convertBundleContent(bundlesInfo, 'Bundles')
              if(bundleContent){
                setBundleData(bundleContent)
              }
              if(bundleContent && pricingFile){
                let resultPricing = await validatePricingCSV(bundleContent);
                if(!lockByFile){
                  resolve(resultPricing.status)
                }
                else{
                  let resultLockBy = await validateLockByCSV(resultPricing.data)
                  resolve(resultPricing.status && resultLockBy)
                }
              }
              else{
                let resultLockBy = await validateLockByCSV(bundleContent)
                resolve(resultLockBy)
              }
            }else{
              resolve(false)
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              if(pricingFile === null){
                resolve(true);
              }
              const json = XLSX.utils.sheet_to_json(worksheet);
              let bundleJSON = await convertBundleToJson(json, headers)
              let bundlesInfo = await checkDataType(bundleJSON)
              let bundleContent = await convertBundleContent(bundlesInfo, 'Bundles')
              if(bundleContent){
                setBundleData(bundleContent)
              }
              if(bundleContent && pricingFile){
                let resultPricing = await validatePricingCSV(bundleContent);
                if(!lockByFile){
                  resolve(resultPricing.status)
                }
                else{
                  let resultLockBy = await validateLockByCSV(resultPricing.data)
                  resolve(resultPricing.status && resultLockBy)
                }
              }
              else{
                let resultLockBy = await validateLockByCSV(bundleContent)
                resolve(resultLockBy)
              }
            }else{
              resolve(false)
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(bundleFile);
        }else{
          reader.readAsArrayBuffer(bundleFile);
        }
      }else if(bundleFile && (bundleFile.size/1024).toFixed(2) > 10000){
        setBundleFileErrors([`File Size Should be less than 10Mb`])
        resolve(false)
      }
    })
  }


  // 2. Pricing

  const checkPricingError = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Bundle ID') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Bundle ID.`);
      isError = false
    }
    if (header[1] !== 'Currency Type') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Currency Type.`);
      isError = false
    }
    if (header[2] !== 'Currency ID') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Currency ID.`);
      isError = false
    }
    if (header[3] !== 'Real World Currency ID') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Real World Currency ID.`);
      isError = false
    }
    if (header[4] !== 'Price Amount') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Price Amount.`);
      isError = false
    }
    if (header[5] !== 'Discount') {
      newErrors.push(`Please change 6th Column name from ${header[5]} to Discount.`);
      isError = false
    }
    if (header[6] !== 'Bonus Cash Allowance (RMG Only)') {
      newErrors.push(`Please change 7th Column name from ${header[6]} to Bonus Cash Allowance.`);
      isError = false
    }
    if (header[7] !== 'Select Platform (IAP Only)') {
      newErrors.push(`Please change 8th Column name from ${header[7]} to Select Platform (IAP Only).`);
      isError = false
    }
    if (header[8] !== 'Product ID (IAP Only)') {
      newErrors.push(`Please change 9th Column name from ${header[8]} to Product ID.`);
      isError = false
    }
    setPricingFileErrors(newErrors)
    if(isError){
      return true
    }
  }

  const transformPricingData = (data, items) => {
    const filterPricing = data && data.map((entry, index) => ({
      bundleId : entry["Bundle ID"],
      priceTypes : (entry["Currency Type"] === 'Virtual' ? 'virtual currency' : entry["Currency Type"] === 'Real Money Gaming Currency' ? 'RMG' : entry["Currency Type"] === 'IAP' ? 'IAP' : entry["Currency Type"]),
      currencyClientID : (entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' ? entry["Currency ID"] : null),
      ...((entry["Currency Type"] === 'Virtual' || entry["Currency Type"] === 'Real Money Gaming Currency') && {currencyId: (entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' ? currencyList && currencyList.filter(item => item.currencyId == entry["Currency ID"]).map(item => item.id)[0] : null)}),
      ...(entry["Currency Type"] === 'IAP' && {realWorldCurrencyId: (entry["Real World Currency ID"] && entry["Real World Currency ID"] !== null && entry["Real World Currency ID"] !== 'N/A' && entry["Real World Currency ID"] !== '' ? realWorldCurrency && realWorldCurrency.filter(item => item.name.includes(entry["Real World Currency ID"])).map(item => item.realWorldCurrencyId)[0] : null)}),
      discount : (entry["Discount"] !== null && entry["Discount"] !== 'N/A' && entry["Discount"] !== '') ? entry["Discount"] : null,
      price : ((entry["Price Amount"] !== null && entry["Price Amount"] != 'N/A' && entry["Price Amount"] != '') ? entry["Price Amount"].toString()  : "0"),
      ...(entry["Currency Type"] === 'Real Money Gaming Currency' && {bonusCashAllowance :  ((entry["Bonus Cash Allowance (RMG Only)"] && entry["Bonus Cash Allowance (RMG Only)"] !== null && entry["Bonus Cash Allowance (RMG Only)"] != 'N/A' && entry["Bonus Cash Allowance (RMG Only)"] != '') ? parseValidInt(entry["Bonus Cash Allowance (RMG Only)"]) : null)}),
      ...(entry["Currency Type"] === 'IAP' && {gamePlatformMasterId :  ((entry["Select Platform (IAP Only)"] && entry["Select Platform (IAP Only)"] !== null && entry["Select Platform (IAP Only)"] != 'N/A' && entry["Select Platform (IAP Only)"] != '') ? (entry["Select Platform (IAP Only)"] === 'Android' ? 1 : entry["Select Platform (IAP Only)"] === 'iOS' ? 2 : entry["Select Platform (IAP Only)"])  : entry["Select Platform (IAP Only)"])}),
      ...(entry["Currency Type"] === 'IAP' && {productId : ((entry["Product ID (IAP Only)"] !== null && entry["Product ID (IAP Only)"] != 'N/A' && entry["Product ID (IAP Only)"] != '') ? entry["Product ID (IAP Only)"]  : "")})
    }));
    
    return items && items.map((e, i) => {
      const matchObject = filterPricing && filterPricing.filter(x => x.bundleId === e.bundleId)
      matchObject && matchObject.forEach(match => {
        e.bundlePrices.push(
          {
            bundleId: match.bundleId,
            priceTypes: match.priceTypes,
            discount: match.discount, 
            price: match.price,
            currencyClientID: match.currencyClientID,  
            ...(match.priceTypes !== 'IAP' && {currencyId: match.currencyId}), 
            ...(match.priceTypes === 'RMG' && {bonusCashAllowance: match.bonusCashAllowance}),
            ...(match.priceTypes === 'IAP' && {realWorldCurrencyId: match.realWorldCurrencyId}),
            ...(match.priceTypes === 'IAP' && {gamePlatformMasterId: match.gamePlatformMasterId}),
            ...(match.priceTypes === 'IAP' && {productId: match.productId}),
          }
        )
      })
      return e
    })
  }

  const convertPricingToJson = async(data, headers, items) => {
    if(data.length > 1000){
      setPricingFileErrors([`Total number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformPricingData(dataRows, items)
    }
  }

  const checkPricingFileDataType = (obj) => {
    let errors = {currencyTypeErr: [], currencyIdErr: [], realWorldCurrencyErr: [], amountErr: [], discountErr: [], BCAErr: [], platformErr: [], productErr: []}
    
    if(typeof obj.priceTypes !== 'string'){
      errors.currencyTypeErr.push(`Currency Type should be a valid string.`)
    }

    if(typeof obj.discount !== 'number'){
      errors.discountErr.push(`Discount should be a valid integer.`)
    }
    
    if(obj.bonusCashAllowance !== null && obj.bonusCashAllowance !== undefined && (typeof obj.bonusCashAllowance !== 'number' || !Number.isInteger(obj.bonusCashAllowance))){
      errors.BCAErr.push(`Bonus Cash Allowance should be a valid integer.`)
    }
    
    if(obj.gamePlatformMasterId !== null && obj.gamePlatformMasterId !== undefined && (typeof obj.gamePlatformMasterId !== 'number' || !Number.isInteger(obj.gamePlatformMasterId))){
      errors.platformErr.push(`Select Platform (IAP Only) should be a valid string.`)
    }

    if(obj.productId !== undefined && typeof obj.productId !== 'string'){
      errors.productErr.push(`Product ID should be a valid string.`)
    }

    return errors
  }

  const checkPricingDataType = (obj) => {
    return obj && obj.map(item => ({
      ...item,
      bundlePrices: item.bundlePrices && item.bundlePrices.map(price => ({
        ...price,
        errors: checkPricingFileDataType(price)
      }))
    }))
  }

  const validateCurrency = async(rewards, data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
    if(res.data.data){
      let content = res.data.data
      let existingData = content.currenciesDetails.existingCurrencies
      let errorRewards = content.currenciesDetails.nonExistingCurrencies
      if(errorRewards?.length > 0){
        let updatedData = data && data.flatMap(e => ({
            ...e,
            bundlePrices: e.bundlePrices && e.bundlePrices.map((x, i) => {
              let matchObj = errorRewards && errorRewards.find(id => id === x.currencyClientID)
              if(matchObj){
                return{
                  ...x,
                  errors: {
                    ...x.errors, 
                    currencyIdErr: [
                      ...(x.errors && x.errors.currencyIdErr ? x.errors.currencyIdErr : []), `Currency Id with ${x.currencyClientID} does not exist in your project.`
                    ]
                  }
                }
              }else{
                let matchObj = existingData && existingData.find(currency => currency.currencyId === x.currencyClientID)
                return{
                  ...x,
                  errors: {
                    ...x.errors,
                    //currencyIdErr: e.errors && e.errors.currencyIdErr ? [...e.errors.currencyIdErr] : [] 
                    currencyIdErr: [
                      ...(x.errors && x.errors.currencyIdErr ? [...x.errors.currencyIdErr] : []),
                      ...(matchObj && matchObj.archive ? [`Currency Id with ${x.currencyClientID} archived and already exist.`] : [])
                    ]
                  }
                }
              }
            })
        }))
        return updatedData
      }else{
        let updatedData = data && data.flatMap(e => ({
          ...e,
          bundlePrices: e.bundlePrices && e.bundlePrices.map((x,i) => {
            let matchObj = existingData && existingData.find(currency => currency.currencyId === x.currencyClientID)
            if(matchObj){
              return{
                ...x,
                errors: {
                  ...x.errors, 
                  currencyIdErr: [
                    ...(x.errors && x.errors.currencyIdErr ? [...x.errors.currencyIdErr] : []),
                    ...(matchObj && matchObj.archive ? [`Currency Id with ${x.currencyClientID} archived and already exist.`] : [])
                  ]
                }
              }
            }else{
              return{
                ...x
              }
            }
          })
        }))
        return updatedData
      }
    }
  }

  const checkRewards = async(data, type) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }

    if(type === 'Pricing'){
      data && data.map((e, index) => {
        e.bundlePrices && e.bundlePrices.forEach((x, i) => {
          if(x.currencyClientID !== null && !rewards.rewardDetails.currencies.ids.includes(x.currencyClientID)){
              rewards.rewardDetails.currencies.ids.push(x.currencyClientID);
            }
          })
      })
      let validateItemData = await validateCurrency(rewards, data)
      return validateItemData
    }
  }

  const validatePricingCSV = (items) => {
    return new Promise((resolve, reject) => {
      if(pricingFile && (pricingFile.size/1024).toFixed(2) < 10000){
        const fileExtension = pricingFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkPricingError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let pricingJSON = await convertPricingToJson(json, headers, items)
              let pricingInfo = await checkPricingDataType(pricingJSON)
              let checkPricing = await checkRewards(pricingInfo, 'Pricing')
              if(checkPricing){
                setBundleData(checkPricing)
                resolve({status: true, data: checkPricing});
              }
            }else{
              resolve({status: false, data: []});
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            const headers = columnsArray[0];
            if(checkPricingError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let pricingJSON = await convertPricingToJson(json, headers, items)
              let pricingInfo = await checkPricingDataType(pricingJSON)
              let checkPricing = await checkRewards(pricingInfo, 'Pricing')
              if(checkPricing){
                setBundleData(checkPricing)
                resolve({status: true, data: checkPricing});
              }
            }else{
              resolve({status: false, data: []});
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(pricingFile);
        }else{
          reader.readAsArrayBuffer(pricingFile);
        }
      }else if(pricingFile && (pricingFile.size/1024).toFixed(2) > 10000){
        setPricingFileErrors([`File Size Should be less than 10Mb`])
        resolve({status: false, data: []});
      }
    })
  }

  // 3. Lock By

  const checkLockByError = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Bundle ID') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Bundle ID.`);
      isError = false
    }
    if (header[1] !== 'Lock By Item') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Lock By Item.`);
      isError = false
    }
    if (header[2] !== 'Lock By Bundle') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Lock By Bundle.`);
      isError = false
    }
    if (header[3] !== 'Lock By Progression Systems') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Lock By Lock By Progression Systems.`);
      isError = false
    }
    if (header[4] !== 'Lock By Progression System Levels') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Lock By Progression System Levels.`);
      isError = false
    }

    setLockByFileError(newErrors)
    if(isError){
      return true
    }
  }

  const convertUnlockCondition = (arr, data) => {
    let result = []
    arr && arr.forEach(obj => {
      if(obj.unlockItemId){
        result.push({
          bundleId : obj.bundleId,
          unlockItemId: obj.unlockItemId,
          unlockBundleId : null,
          unlockLevelSystemId: null,
          lockedLevel: null
        });
      }else if(obj.unlockBundleId){
        result.push({
          bundleId : obj.bundleId,
          unlockItemId: null,
          unlockBundleId : obj.unlockBundleId,
          unlockLevelSystemId: null,
          lockedLevel: null
        });
      }else if(obj.unlockLevelSystemId){
        // Split unlockLevelSystemId and lockedLevel by comma
        const unlockPSIDs = obj.unlockLevelSystemId.split(', ');
        const PSLevels = typeof obj.lockedLevel === 'string' ? obj.lockedLevel.split(',').map(level => parseInt(level.trim(), 10)) : [null];
        // Create separate object for each combination of unlockLevelSystemId and lockedLevel
        const maxLength = Math.max(unlockPSIDs.length, PSLevels.length);
        for (let i = 0; i < maxLength; i++) {
          result.push({
            bundleId : obj.bundleId,
            unlockItemId: null,
            unlockBundleId : null,
            // unlockLevelSystemId: unlockPSIDs[i] || null,
            unlockLevelSystemId: unlockPSIDs[i],
            lockedLevel: PSLevels[i] !== null ? PSLevels[i] : null
          });
        }
      }
    })
    return result
  };

  const transformLockByData = (data, items) => {
    const lockByFileData = data && data.map((entry, index) => ({
      bundleId : entry["Bundle ID"],
      unlockItemId : (entry["Lock By Item"] && entry["Lock By Item"] !== null && entry["Lock By Item"] !== 'N/A' ? entry["Lock By Item"] : null),
      unlockBundleId : (entry["Lock By Bundle"] && entry["Lock By Bundle"] !== null && entry["Lock By Bundle"] !== 'N/A' ? entry["Lock By Bundle"] : null),
      unlockLevelSystemId : (entry["Lock By Progression Systems"] && entry["Lock By Progression Systems"] !== null && entry["Lock By Progression Systems"] !== 'N/A' ? entry["Lock By Progression Systems"] : null),
      lockedLevel : (entry["Lock By Progression System Levels"] && entry["Lock By Progression System Levels"] !== null && entry["Lock By Progression System Levels"] !== 'N/A' ? entry["Lock By Progression System Levels"].toString() : null),
    }))
    const updatedLockByData = convertUnlockCondition(lockByFileData, items)

    return items && items.map((e, i) => {
      const matchObject = updatedLockByData && updatedLockByData.filter(x => x.bundleId === e.bundleId)
      matchObject && matchObject.forEach(match => {
        e.rewardUnlockCondition.push(
          {
            bundleId: match.bundleId,
            unlockItemId: match.unlockItemId,
            unlockBundleId: match.unlockBundleId,
            unlockLevelSystemId: match.unlockLevelSystemId,
            lockedLevel: match.lockedLevel,
          }
        )
      })
      return e
    })
  }

  const convertLockByToJSON = async(data, headers, items) => {
    if(data.length > 1000){
      setLockByFileError([`Number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformLockByData(dataRows, items)
    }
  }

  const checkLockByFileDataType = (obj) => {
    let errors = {bundleIdErr: [], lockItemIdErr: [], lockBundleIdErr: [], lockPSError: [], lockPSLevelErr: []}

    if(obj.bundleId !== null && typeof obj.bundleId !== 'string'){
      errors.bundleIdErr.push(`Bundle ID should be a valid string.`)
    }

    if(obj.unlockItemId !== null && typeof obj.unlockItemId !== 'string'){
      errors.lockItemIdErr.push(`Lock By Item should be a valid string.`)
    }

    if(obj.unlockBundleId !== null && typeof obj.unlockBundleId !== 'string'){
      errors.lockBundleIdErr.push(`Lock By Bundle should be a valid string.`)
    }

    if(obj.unlockLevelSystemId !== null && typeof obj.unlockLevelSystemId !== 'string'){
      errors.lockPSError.push(`Lock By Progression Systems should be a valid string.`)
    }

    if(obj.lockedLevel !== null && typeof obj.lockedLevel !== 'number'){
      errors.lockPSLevelErr.push(`Lock By Progression System Levels should be a valid integer.`)
    }
    return errors
  }
  

  const checkLockByDataType = (obj) => {
    return obj && obj.map(item => ({
      ...item,
      rewardUnlockCondition: item.rewardUnlockCondition && item.rewardUnlockCondition.map(unlockCondition => ({
        ...unlockCondition,
        errors: checkLockByFileDataType(unlockCondition)
      }))
    }))
  }

  const checkBundleExistInCSV = (data, csv, existing) => {
    const bundleIds = csv.map(obj => obj.bundleId);
    const missingIds = data.rewardDetails.bundles.ids && data.rewardDetails.bundles.ids.filter(id => !bundleIds.includes(id));
    const filterIds = missingIds && missingIds.filter(id => !existing.map(obj => obj.bundleId).includes(id))
    const commonIds = existing && existing.filter(element => filterIds.includes(element));
    if (commonIds.length > 0) {
      return {
        result: false,
        missingIds: commonIds
      };
    }
    // If all IDs are present, return true
    return {
      result: true,
      missingIds: []
    };

  }

  const validateLockByRewards = async(data, csv) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, data, headers)
    if(res.data.data){
      let content = res.data.data
      let errorRewards = [...content.itemDetails.nonExistingItems, ...content.bundlesDetails.nonExistingBundles, ...content.currenciesDetails.nonExistingCurrencies, ...content.progressionMarkersDetails.nonExistingProgressionMarkers, ...content.progressionSystemsDetails.nonExistingProgressionSystems]
      let existingItems = content.itemDetails.existingItems
      let existingBundles = content.bundlesDetails.existingBundles
      let existingPS = content.progressionSystemsDetails.existingProgressionSystems
      if(errorRewards?.length > 0){
        if(content.bundlesDetails.nonExistingBundles?.length > 0 && content.itemDetails.nonExistingItems?.length === 0 && content.currenciesDetails.nonExistingCurrencies?.length === 0 && content.progressionMarkersDetails.nonExistingProgressionMarkers?.length === 0 && content.progressionSystemsDetails.nonExistingProgressionSystems?.length === 0){
          let checkBundles = await checkBundleExistInCSV(data, csv, content.bundlesDetails.nonExistingBundles)
          if(checkBundles.result === true && checkBundles.missingIds?.length === 0){
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: x.errors && x.errors.lockItemIdErr ? [...x.errors.lockItemIdErr] : [] 
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr : [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item Id with ${x.unlockItemId} does not exists`
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = checkBundles?.missingIds && checkBundles?.missingIds.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }
              })
            }))
            return updatedData
          }else{
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item with Id ${x.unlockItemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr : [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item Id with ${x.unlockItemId} does not exists`
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = checkBundles?.missingIds && checkBundles?.missingIds.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }
              })
            }))
            return updatedData
          }
        }else{
          let checkBundles = await checkBundleExistInCSV(data, csv, content.bundlesDetails.nonExistingBundles)
          if(checkBundles.result === true && checkBundles.missingIds?.length === 0){
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: x.errors && x.errors.lockItemIdErr ? [...x.errors.lockItemIdErr] : [] 
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr : [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item Id with ${x.unlockItemId} does not exists`
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = checkBundles?.missingIds && checkBundles?.missingIds.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }
              })
            }))
            return updatedData
          }else{
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item with Id ${x.unlockItemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr : [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item Id with ${x.unlockItemId} does not exists`
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = checkBundles?.missingIds && checkBundles?.missingIds.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }
              })
            }))
            return updatedData
          }
        }
      }else{
        return csv
      }
    }
  }
  

  const checkLockByItemRewards = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }
    
    data && data.map((e, index) => {
      e.rewardUnlockCondition && e.rewardUnlockCondition.forEach((x, i) => {
        if(x.unlockItemId !== '' && x.unlockItemId !== undefined && x.unlockItemId !== null && x.unlockItemId != 'N/A' && !rewards.rewardDetails.items.ids.includes(x.unlockItemId)){
          rewards.rewardDetails.items.ids.push((x.unlockItemId).toString());
        }
        if(x.unlockBundleId !== '' && x.unlockBundleId !== undefined && x.unlockBundleId !== null && x.unlockBundleId != 'N/A' && !rewards.rewardDetails.bundles.ids.includes(x.unlockBundleId)){
          rewards.rewardDetails.bundles.ids.push((x.unlockBundleId).toString());
        }
  
        const extractIds = (id) => {
          if(id){
            const items = id.split(",");
            return items.map(item => item.match(/^([^\(]+)/)[1]);
          }
          return [];
        }
  
        const addUniqueIds = (ids, key) => {
          ids && ids.forEach(id => {
            if(x.unlockLevelSystemId !== undefined && x.unlockLevelSystemId !== null && x.unlockLevelSystemId != 'N/A' && !rewards.rewardDetails.progressionSystem.ids.includes(x.unlockLevelSystemId)){
              rewards.rewardDetails.progressionSystem.ids.push((x.unlockLevelSystemId).toString());
            }
          });
        };
        addUniqueIds(extractIds(x.unlockLevelSystemId));
      })
    })

    let validateRewardData = await validateLockByRewards(rewards, data)
    return validateRewardData
  }

  const validateLockByCSV = (items) => {
    return new Promise((resolve, reject) => {
      if(lockByFile && (lockByFile.size/1024).toFixed(2) < 10000){
        const fileExtension = lockByFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkLockByError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let lockByJSON = await convertLockByToJSON(json, headers, items)
              let lockByDataCheck = checkLockByDataType(lockByJSON)
              let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
              if(checkRewardData){
                setBundleData(checkRewardData)
                resolve(true)
              }
            }else{
              resolve(false)
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            const headers = columnsArray[0];
            if(checkLockByError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let lockByJSON = await convertLockByToJSON(json, headers, items)
              let lockByDataCheck = checkLockByDataType(lockByJSON)
              let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
              if(checkRewardData){
                setBundleData(checkRewardData)
                resolve(true)
              }
            }else{
              resolve(false)
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(lockByFile);
        }else{
          reader.readAsArrayBuffer(lockByFile);
        }
      }else if(lockByFile && (lockByFile.size/1024).toFixed(2) > 10000){
        setLockByFileError([`File Size Should be less than 10Mb`])
        resolve(false)
      }
    })
  }

    // Re-validate

    const revalidate = async(data) => {
      let bundleInfo = await checkDataType(data)
      let checkBundles = await convertBundleContent(bundleInfo, "Bundles")
      if(checkBundles){
        setBundleData(checkBundles)
        if(pricingFile){
          let pricingInfo = await checkPricingDataType(checkBundles)
          let checkPricing = await checkRewards(pricingInfo, 'Pricing')
          setBundleData(checkPricing)
          if(lockByFile){
            let lockByDataCheck = await checkLockByDataType(checkPricing)
            let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
            setBundleData(checkRewardData)
          }
        }else if(lockByFile){
            let lockByDataCheck = await checkLockByDataType(checkBundles)
            let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
            setBundleData(checkRewardData)
        }
      }
    }

  // Functions

  const uploadFunctions = {onCSVItem: onCSVBundle, onCSVPricing: onCSVPricing, onCSVFile: onCSVFile}
  const files = {itemFile: bundleFile, setItemFile: setBundleFile, pricingFile: pricingFile, setPricingFile: setPricingFile, lockByFile: lockByFile, setLockByFile: setLockByFile}
  const fileErrors = {itemFileError: bundleFileErrors, pricingFileErrors: pricingFileErrors, lockByFileError: lockByFileError, setItemFileErrors: setBundleFileErrors, setPricingFileErrors: setPricingFileErrors, setLockByFileError: setLockByFileError}

  const [bulkUpload, setBulkUpload] = useState(false)
  const [lockByBundleData, setLockByBundleData] = useState([])
  const [lockBy, setLockBy] = useState(false)

  const removeErrorKeyObject = (data) => {
    const rewardUnlock = [];
    let updatedData = data && data.map(item => {
      const { errors, rewardUnlockCondition, ...rest } = item;
      rewardUnlock.push(...rewardUnlockCondition)
      const updatedPrices = item.bundlePrices && item.bundlePrices.map(pricing => {
        const { errors, price, currencyClientID, ...priceRest } = pricing;
        return {...priceRest, price: parseValidInt(price)};
      })
      const updatedContents = item.bundleContent && item.bundleContent.map(contents => {
        const { errors, currency, item, bundle, type, ...contentsRest } = contents;
        return contentsRest;
      })
      return {
        ...rest,
        bundlePrices : updatedPrices,
        bundleContent : updatedContents
      }
    })
    setLockByBundleData(prevState => [...prevState, ...rewardUnlock]);
    return updatedData
  }

  const bundleBulkUpload = () => {
    const segregatedBundles = bundleData.slice(0, 100);
    const filterBundles = removeErrorKeyObject(segregatedBundles)
    const bundleList = {
      bundles : filterBundles
    }
    dispatch(createBundleAction(bundleList, null, appid, null))
    .then(res => {
      setBundleData(bundleData.slice(100))
    }).catch(err => {
      console.log(err)
    })
  }

  // Lock By Item Upload

  const validateandGetRewardsId = async(rewards, data) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
    let content = res.data.data
    let allLockByItems = content.itemDetails.existingItems
    let allLockByBundles = content.bundlesDetails.existingBundles
    let allLockByPS = content.progressionSystemsDetails.existingProgressionSystems
    if(res.data.data){
      let result = [];
      let updatedData = data && data.forEach(obj => {
        if(obj.unlockItemId){
          let bundle = allLockByBundles && allLockByBundles.filter(e => e.bundleId === obj.bundleId)
          let matchObj = allLockByItems && allLockByItems.filter(e => e.itemId === obj.unlockItemId)
          result.push({
            id : (bundle && bundle !== null && bundle?.length > 0 ? bundle[0].id : null),
            rewardUnlockCondition : [
              {
                unlockItemId: (matchObj && matchObj !== null && matchObj?.length > 0 ? matchObj[0].id : null),
                unlockBundleId : null,
                unlockLevelSystemId: null,
                lockedLevel: null
              }
            ]
          })
        }
        if(obj.unlockBundleId){
          let bundle = allLockByBundles && allLockByBundles.filter(e => e.bundleId === obj.bundleId)
          let matchObj = allLockByBundles && allLockByBundles.filter(e => e.bundleId === obj.unlockBundleId)
          result.push({
            id : (bundle && bundle !== null && bundle?.length > 0 ? bundle[0].id : null),
            rewardUnlockCondition : [
              {
                unlockItemId: null,
                unlockBundleId : (matchObj && matchObj !== null && matchObj?.length > 0 ? matchObj[0].id : null),
                unlockLevelSystemId: null,
                lockedLevel: null
              }
            ]
          })
        }
        if(obj.unlockLevelSystemId){
          let bundle = allLockByBundles && allLockByBundles.filter(e => e.bundleId === obj.bundleId)
          let matchObj = allLockByPS && allLockByPS.filter(e => e.levelSystemId === obj.unlockLevelSystemId)
          result.push({
            id : (bundle && bundle !== null && bundle?.length > 0 ? bundle[0].id : null),
            rewardUnlockCondition : [
              {
                unlockItemId: null,
                unlockBundleId : null,
                unlockLevelSystemId: (matchObj && matchObj !== null && matchObj?.length > 0 ? matchObj[0].id : null),
                lockedLevel: obj.lockedLevel
              }
            ]
          })
        }
      })
      return result
    }
  }

  const getAllLockByID = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }
    data && data.forEach((e, index) => {
      if(e.bundleId !== null && !rewards.rewardDetails.bundles.ids.includes(e.bundleId)){
        rewards.rewardDetails.bundles.ids.push(e.bundleId);
      }
      if(e.unlockItemId !== null && !rewards.rewardDetails.currencies.ids.includes(e.unlockItemId)){
        rewards.rewardDetails.items.ids.push(e.unlockItemId);
      }
      if(e.unlockBundleId !== null && !rewards.rewardDetails.bundles.ids.includes(e.unlockBundleId)){
        rewards.rewardDetails.bundles.ids.push(e.unlockBundleId);
      }
      if(e.unlockLevelSystemId !== null && !rewards.rewardDetails.progressionSystem.ids.includes(e.unlockLevelSystemId)){
        rewards.rewardDetails.progressionSystem.ids.push(e.unlockLevelSystemId);
      }
    })
    let validateLockByData = await validateandGetRewardsId(rewards, data)
    return validateLockByData

  }

  const getlockByDetail = async(data) => {
    const getAllLockByDetails = await getAllLockByID(data)
    return getAllLockByDetails
  }

  const lockByDataUpload = async() => {
    const filterLockByData = await getlockByDetail(lockByBundleData)
    const bundleList = {
      bundles : filterLockByData
    }
    dispatch(editBundleAction(bundleList, null, appid, null))
    .then(res => {
      setLockByBundleData([])
    }).catch(err => {
      console.log(err)
    })
  }

  const clearAllData = () => {
    setBundleFile(null)
    setPricingFile(null)
    setLockByFile(null)
    setBundleFileErrors(null)
    setLockByFileError(null)
    setPricingFileErrors(null)
  }

  useEffect(() => {
    if(bulkUpload && bundleData?.length > 0){
      bundleBulkUpload()
    }else if(bulkUpload && bundleData?.length === 0){
      setBulkUpload(false)
      setLockBy(true)
      lockByDataUpload()
      clearAllData()
    }
  }, [bulkUpload, bundleData])

  useEffect(() => {
    if(lockBy && lockByBundleData?.length > 0){

    }else if(lockBy && lockByBundleData?.length === 0){
      setLockBy(false)
      getAllBundles()
    }
  }, [lockBy, lockByBundleData])

  // Export

  const [exportOptions, setExportOptions] = useState([{id : 1, name : 'As XLSX'}, {id : 2, name: 'As CSV'}])

  const getBundleDetails = async() => {
    let exportedData = []
    let ids;
    let count = Math.ceil(totalBundle/100)
    for(var i = 0; i < count ; i++){
      let bundles = {
        projectId : appid,
        offset : (i === 0 ? 0 : (i*100)),
        limit : 100
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(BASE_URL + GET_BUNDLES, bundles, headers)
      if(res){
        let bundleInstance = res.data.data.bundles
        ids = bundleInstance && bundleInstance.map(e => e.id)
        exportedData.push(...bundleInstance)
      }
    }
    if(exportedData){
      return {data : exportedData, ids: ids}
    }
  }

  const transformBundleLockByData = (data) => {
    const headers = [
      'Bundle ID',
      'Lock By Item',
      'Lock By Bundle',
      'Lock By Progression Systems',
      'Lock By Progression System Levels',
    ];
    let result = [];
    data && data.forEach(item => {
      item.rewardsUnlockConditions && item.rewardsUnlockConditions.forEach(condition => {
        if (condition.unlockItemId) {
          result.push({
            "Bundle ID" : item.bundleId,
            'Lock By Item': condition.item.itemId,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }else if (condition.unlockBundleId) {
          result.push({
            "Bundle ID" : item.bundleId,
            'Lock By Item': null,
            'Lock By Bundle' : condition.bundle.bundleId,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }
        else if (condition.unlockLevelSystemId) {
          result.push({
            "Bundle ID" : item.bundleId,
            'Lock By Item': null,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': condition.unlockLevel.levelSystemId,
            'Lock By Progression System Levels': condition.lockedLevel
          });
        }
      });
    });
    if(result?.length === 0) {
      result.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return result;
  }

  const transformBundlePricingData = (data) => {
    const headers = [
      'Bundle ID',
      'Currency Type',
      'Currency ID',
      'Real World Currency ID',
      'Price Amount',
      'Discount',
      'Bonus Cash Allowance (RMG Only)',
      'Select Platform (IAP Only)',
      'Product ID (IAP Only)',
    ];
    let pricingData = data && data.flatMap(item => {
      if (item.inventoryBundlesPrices && item.inventoryBundlesPrices.length > 0) {
        return item.inventoryBundlesPrices && item.inventoryBundlesPrices.map(pricing => ({
          'Bundle ID': item.bundleId,
          'Currency Type' : (pricing.priceTypes === 'virtual currency' ? 'Virtual' : pricing.priceTypes === 'RMG' ? 'Real Money Gaming Currency' : pricing.priceTypes === 'IAP' ? 'IAP' : 'N/A'),
          'Currency ID' : ((pricing.currency !== null && pricing.currency.currencyId !== null && pricing.currency.currencyId !== '') ? pricing.currency.currencyId : (pricing.currency !== null && (pricing.currency.currencyId === null || pricing.currency.currencyId === '')) ? 'N/A' : pricing.currency === null ? 'N/A' : ''),
          'Real World Currency ID' : ((pricing.realWorldCurrency !== null && pricing.realWorldCurrencyId !== null && pricing.realWorldCurrencyId !== '') ? pricing.realWorldCurrency.code : (pricing.realWorldCurrency !== null && (pricing.realWorldCurrencyId === null || pricing.realWorldCurrencyId === '')) ? 'N/A' : pricing.realWorldCurrency === null ? 'N/A' : ''),
          'Price Amount' : ((pricing.price !== null && pricing.price !== '') ? pricing.price : (pricing.price === null || pricing.price === '') ? 'N/A' : ''),
          'Discount' : ((pricing.discount !== null && pricing.discount !== '') ? pricing.discount : (pricing.discount === null || pricing.discount === '') ? 'N/A' : ''),
          'Bonus Cash Allowance (RMG Only)' : ((pricing.bonusCashAllowance !== null && pricing.bonusCashAllowance !== '') ? pricing.bonusCashAllowance : (pricing.bonusCashAllowance === null || pricing.bonusCashAllowance === '') ? 'N/A' : ''),
          'Select Platform (IAP Only)' : ((pricing.gamePlatformMasterId !== null && pricing.gamePlatformMasterId !== '' && pricing.gamePlatformMasterId === 1) ? 'Android' : (pricing.gamePlatformMasterId !== null && pricing.gamePlatformMasterId !== '' && pricing.gamePlatformMasterId === 2) ? 'iOS' : (pricing.gamePlatformMasterId === null || pricing.gamePlatformMasterId === '') ? 'N/A' : ''),
          'Product ID (IAP Only)' : ((pricing.productId !== null && pricing.productId !== '') ? pricing.productId : (pricing.productId === null || pricing.productId === '') ? 'N/A' : ''),
        }));
      }
      return [];
    });

    if (pricingData?.length === 0) {
      pricingData.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return pricingData
  }

  const getSubType = (obj) => {
    return Object.entries({
      isEquippable: 'Equippable',
      isTradable: 'Tradable',
      isStackable: 'Stackable',
      isRentable: 'Rentable',
      isTimeStackable: 'TimeStackable'
    }).filter(([key]) => obj[key])
      .map(([, value]) => value)
      .join(', ');
  }

  const transformBundlesData = (data) => {
    const keysToCheck = {isEquippable : 'Equippable',isTradable: 'Tradable',isStackable : 'Stackable',isRentable : 'Rentable',isTimeStackable : 'Time Stackable'}
    return data.map(item => ({
      "Bundle Icon": ((item.iconUrl !== null && item.iconUrl !== '') ? item.iconUrl : 'N/A'),
      "Display Name": item.name,
      "Bundle ID": item.bundleId,
      "Bundle Description": ((item.description !== null && item.description !== '') ? item.description : 'N/A'),
      "Content Currencies" : (item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.currencyId !== null)?.length > 0 ? item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.currencyId !== null).map(e => {return `${e.currency.currencyId}(${e.quantity !== null ? e.quantity : ''})`}).join(', ') : null),
      "Content Items" : (item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.itemId !== null)?.length > 0 ? item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.itemId !== null).map(e => {return `${e.item.itemId}(${e.quantity !== null ? e.quantity : ''})`}).join(', ') : null),
      "Content Bundles" : (item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.contentBundleId !== null)?.length > 0 ? item.inventoryBundlesContents && item.inventoryBundlesContents.filter(x => x.contentBundleId !== null).map(e => {return `${e.contentBundle.bundleId}(${e.quantity !== null ? e.quantity : ''})`}).join(', ') : null),
      "Bundle Type": (item.isConsumable === true ? 'Consumable' : item.isConsumable === false ? 'Durable' : ''),
      "Count/Time Usage": ((item.consumeByUses !== null && item.consumeByTime === null) ? 'Count' : (item.consumeByUses === null && item.consumeByTime !== null) ? 'Time' : ''),
      "Uses (if Count)": ((item.consumeByUses !== null && item.consumeByUses !== '') ? parseInt(item.consumeByUses) : 'N/A'),
      "Time Value (if Time)": ((item.consumeByTime !== null && item.consumeByTime !== '') ? parseInt(item.consumeByTime) : 'N/A'),
      "Time Unit (if Time)": ((item.consumeByTimeFormat && item.consumeByTimeFormat !== null && item.consumeByTimeFormat !== '') ? (item.consumeByTimeFormat).toLowerCase() : 'N/A'),
      "Bundle Subtypes": getSubType(item),
      "Max Stack Count": ((item.stackCapacity !== null && item.stackCapacity !== '') ? parseInt(item.stackCapacity) : 'N/A'),
      "Max Collection Instances": ((item.maxCollectionInstance !== null && item.maxCollectionInstance !== '') ? parseInt(item.maxCollectionInstance) : 'N/A'),
      "Limited Availability": (item.quantity !== null ? 'Yes' : item.quantity === null ? 'No' : ''),
      "Limited Quantity": ((item.quantity !== null && item.quantity !== '') ? parseInt(item.quantity) : null),
      "Open Bundle" : (item.isManual === true ? "Manual" : "Automatic"),
      "Tags": ((item.tagsMapping !== null && item.tagsMapping?.length > 0) ? item.tagsMapping && item.tagsMapping.map(mapping => mapping?.tags?.name).join(', ') : 'N/A'),
      "Meta Data": JSON.stringify(item.meta),
      // Add more transformations if needed
    }));
  }

  const exportData = async(type) => {
    let bundleData = await getBundleDetails()
    let convertBundle = await transformBundlesData(bundleData.data)
    let convertBundlePricing = await transformBundlePricingData(bundleData.data)
    let convertBundleLockBy = await transformBundleLockByData(bundleData.data)
    // Stores
    const worksheetBundles = XLSX.utils.json_to_sheet(convertBundle);
    const workbookBundles = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookBundles, worksheetBundles, "Sheet1");
    // Store Pricing
    const worksheetPricing = XLSX.utils.json_to_sheet(convertBundlePricing);
    const workbookPricing = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookPricing, worksheetPricing, "Sheet1");
    // Store Lock By
    const worksheetLockBy = XLSX.utils.json_to_sheet(convertBundleLockBy);
    const workbookLockBy = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookLockBy, worksheetLockBy, "Sheet1");
    if(type === 'As XLSX'){
      XLSX.writeFile(workbookBundles, "Bundles_Details.xlsx");
      XLSX.writeFile(workbookPricing, "Bundles_Pricing.xlsx");
      XLSX.writeFile(workbookLockBy, "Bundles_Lock_By.xlsx");
    }else{
      XLSX.writeFile(workbookBundles, "Bundles_Details.csv");
      XLSX.writeFile(workbookPricing, "Bundles_Pricing.csv");
      XLSX.writeFile(workbookLockBy, "Bundles_Lock_By.csv");
    }
  }

  return(
    <>
      <ToastContainer />
      <Card layOut={`Get`}  className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Bundles"} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              <div className={styles.add}>
                {showSkeleton && (bundles?.length > 0 || (bundles?.length === 0 && isFiltered)) ?
                  <>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={75} height={40}/>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px', marginLeft: '10px'}} animation="wave" variant="recatangle" width={75} height={40}/>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px', marginLeft: '10px'}} animation="wave" variant="recatangle" width={95} height={40}/>
                  </>
                : !showSkeleton && (bundles?.length > 0 || (bundles?.length === 0 && isFiltered)) ?
                  <>
                  {!userAccess &&
                    <>
                      <ExportTooltip data={exportOptions} action={exportData}>
                        <button className={cn(styles.button, "button-white-small")}>Export</button>
                      </ExportTooltip>
                      <ControlFileUpload size='small' type='Bundles' onFileChange={uploadFunctions} onValidation={validateBundleCSV} data={bundleData} setData={setBundleData} files={files} fileErrors={fileErrors} action={getAllBundles} revalidate={revalidate} setBulkUpload={setBulkUpload} override={getBundleDetails}/>
                      <div className={styles.createButton}>
                        <Link to={`/create-bundle/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                      </div>
                    </>
                  }
                  </>
                : ''}
              </div>
            </>
          )
        }
      >

        <div className={styles.wrapper}>
          <div className={styles.seprator}></div>
          <div className={styles.options}>
            {showSkeleton && bundles.length > 0 &&
              <>
                <div className={styles.filters}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={120} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                </div>

                <div className={styles.searchForm}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                </div>
              </>
            }
                    
            {!showSkeleton &&
              (bundles?.length > 0 || (bundles?.length === 0 && isFiltered) ?
                <>
                  <div className={styles.filters}>
                    <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
                      <ArchiveSvg className={styles.icon} />
                      <span className={styles.buttonText}>Show Archived</span>
                    </div>
                    <Control setPage={setPage} filters={filters} action={getAllBundles} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Form className={styles.form} placeholder="Search Bundles" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                  </div>
                </>
              : '')
            }
          </div>

          {/* Bundle Table View */}
          {showSkeleton && bundles?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && bundles?.length > 0 && 
            <SkeletonTable usedFor='Bundles' rows={bundles.length} column={5} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[44, 110, 110, 110, 40]} colHeight={[44, 15, 25, 25, 40]} />
          }
          {!showSkeleton && !isFiltered && bundles?.length === 0 &&
            <EmptyState access={userAccess} route={`/create-bundle/${appid}`} type="Bundle" exportOption={<><ControlFileUpload size='large' type='Bundles' onFileChange={uploadFunctions} onValidation={validateBundleCSV} data={bundleData} setData={setBundleData} files={files} fileErrors={fileErrors} action={getAllBundles} revalidate={revalidate} setBulkUpload={setBulkUpload} override={getBundleDetails}/></>}/>
          }
          {!showSkeleton && !isFiltered && bundles?.length > 0 &&
            <BundlesTableLayout items={bundles} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (bundles?.length > 0 ?
              <BundlesTableLayout items={bundles} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>
        
        {!isLoading && bundles.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalBundle/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }

      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about bundles <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/bundles"  target="_blank">Click Here</a></p>
      </div>
    </>
  )

  // return(
  //   <>
  //     <ToastContainer />
  //     <Card layOut={`Get`}  className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Bundles"} classTitle={cn("title-purple", styles.title)}
  //       head={
  //         (
  //           <>
  //             <div className={styles.add}>
  //               {showSkeleton && bundles.length > 0 ?
  //                 <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
  //               : !showSkeleton && bundles.length > 0 ?
  //                 <>
  //                 {!userAccess &&
  //                   <>
  //                     <ExportTooltip data={exportOptions} action={exportData}>
  //                       <button className={cn(styles.button, "button-white-small")}>Export</button>
  //                     </ExportTooltip>
  //                     <ControlFileUpload size='small' type='Bundles' onFileChange={uploadFunctions} onValidation={validateBundleCSV} data={bundleData} setData={setBundleData} files={files} fileErrors={fileErrors} action={getAllBundles} revalidate={revalidate} setBulkUpload={setBulkUpload} override={getBundleDetails}/>
  //                     <div className={styles.createButton}>
  //                       <Link to={`/create-bundle/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
  //                     </div>
  //                   </>
  //                 }
  //                 </>
  //               : ''}
  //             </div>
  //           </>
  //         )
  //       }
  //     >

  //         <div className={styles.wrapper}>
  //           {bundles.length > 0 && <div className={styles.seprator}></div>}
  //             <div className={styles.options}>
  //               <div className={styles.filters}>
  //                 {(showSkeleton && bundles.length > 0) ?
  //                   <>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={74} height={34}/>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={130} height={34}/>
  //                   </>
  //                 : !showSkeleton && bundles.length > 0 ?
  //                   <>
  //                     <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
  //                       <ArchiveSvg className={styles.icon} />
  //                       <span className={styles.buttonText}>Show Archived</span>
  //                     </div>
  //                     <Control filters={filters} action={getAllBundles} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
  //                   </>
  //                 : ''}
                  
  //               </div>
  //               <div className={styles.searchForm}>
  //               {showSkeleton && bundles.length > 0 ?
  //                 <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40}/>
  //               : !showSkeleton && bundles.length > 0 ?
  //                 <Form className={styles.form} placeholder="Search Bundles" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
  //               : ''}
  //               </div>
  //           </div>
  //           {showSkeleton && 
  //             (bundles.length == 0 ?
  //               <SkeletonEmptyState />
  //             : 
  //               <SkeletonTable usedFor='Bundles' rows={bundles.length} column={5} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[44, 110, 110, 110, 40]} colHeight={[44, 15, 25, 25, 40]} />
  //             )
  //           }

  //           {!showSkeleton && 
  //             (bundles.length == 0 ?
  //               <EmptyState access={userAccess} route={`/create-bundle/${appid}`} type="Bundle" exportOption={<><ControlFileUpload size='large' type='Bundles' onFileChange={uploadFunctions} onValidation={validateBundleCSV} data={bundleData} setData={setBundleData} files={files} fileErrors={fileErrors} action={getAllBundles} revalidate={revalidate} setBulkUpload={setBulkUpload} override={getBundleDetails}/></>}/>
  //             : searchFlag ?
  //               <>
  //                 {isSearched && <div className={styles.textCenter}><Loader /></div>}
  //                 {!isSearched && filteredBundles.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
  //                 {!isSearched && filteredBundles.length > 0 && <BundlesTableLayout items={filteredBundles} title="Last edited" />}
  //               </>
  //             :  <BundlesTableLayout access={userAccess} items={bundles} title="Last edited" />
  //             )
  //           }
            
  //           {!isLoading && bundles.length > 0 &&
  //             <div className={styles.pagination}>
  //               <Stack spacing={2}>
  //                 <Pagination renderItem={(item) => (
  //                   <PaginationItem
  //                     {...item}
  //                     sx={{
  //                       '&.Mui-selected': {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       },
  //                       '&.MuiPaginationItem-page.Mui-selected:hover' : {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       }
  //                     }}
  //                   />
  //                 )} siblingCount={1} count={Math.ceil(totalBundle/limit)} color="primary" onChange={handlePageChange} />
  //               </Stack>
  //             </div>
  //           }
  //         </div>

  //       {/* <div>
  //         {isLoading ?
  //           <div className={styles.wrapper}>
  //             <div className={styles.loading}>
  //               <Loader className={styles.loader}/>
  //             </div>
  //           </div>
  //         : isLoading == false && bundles.length == 0 ?
  //           <>
  //             <div className={styles.wrapper}>
  //               <EmptyState route={`/create-bundle/${appid}`} type="Bundle" />
  //             </div>
  //           </>
  //         : isLoading == false && bundles.length > 0 ?
  //           <>
  //             <div className={styles.wrapper}>
  //                 <div className={styles.seprator}></div>
  //                   <div className={styles.options}>
  //                     <div className={styles.filters}>
  //                       <div className={styles.buttons}>
  //                         <Icon name='filter' size='20' className={styles.icon} />
  //                         <span className={styles.buttonText}>Filter</span>
  //                       </div>
  //                       <div className={styles.buttons}>
  //                         <ArchiveSvg className={styles.icon} />
  //                         <span className={styles.buttonText}>Show Archived</span>
  //                       </div>
  //                     </div>
  //                     <div className={styles.searchForm}>
  //                       <Form className={styles.form} placeholder="Search Bundles" type="text" name="search" icon="search" />
  //                     </div>
  //                   </div>
  //                 {activeIndex === 0 && 
  //                   <>
  //                     <BundlesTableLayout items={bundles} title="Last edited" />
  //                   </>
  //                 }
  //             </div>
  //             <div className={styles.pagination}>
  //               <Stack spacing={2}>
  //                 <Pagination renderItem={(item) => (
  //                   <PaginationItem
  //                     {...item}
  //                     sx={{
  //                       '&.Mui-selected': {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       },
  //                       '&.MuiPaginationItem-page.Mui-selected:hover' : {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       }
  //                     }}
  //                   />
  //                 )} siblingCount={1} count={Math.ceil(totalBundle/limit)} color="primary" onChange={handlePageChange} />
  //               </Stack>
  //             </div>
  //           </>
  //         : ''}
  //       </div> */}
  //     </Card>
  //     <div className="divider"></div>
  //     {activeIndex === 1 && (
  //       <>
  //         <div className={styles.list}>
  //           {bundles.map((x, index) => (
  //               <BundlesCardLayout className={styles.product} item={x} key={index} released />
  //           ))}
  //         </div>
  //       </>
  //     )}
  //     <div className={styles.msg}>
  //       <p className={styles.alert}>Learn more about bundles <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/bundles"  target="_blank">Click Here</a></p>
  //     </div>
  //   </>
  // )
}

export default GetBundles