import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../../../../Tooltip";

const Dropdown = ({ className, id, placeholder, errorMessage, groupBy, classLabel, classDropdownHead, classDropdownLabel, value, setValue, options, label, small, upBody, tooltip }) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState( '');

    const handleClick = (selectedOption) => {
        setValue(selectedOption.currencyType);
        setVisible(false);
        setFilterText('');
    };

    const filteredOptions = options&&options.filter(option =>
        option.currencyType.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    useEffect(() => {
        const container = document.getElementById(id);
        if (container) {
            const containerWidth = container.offsetWidth;
            const contentWidth = container.scrollWidth;
        }
    }, [value, id]);

    return (
        <div id={id} className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText( ''); }}>
                {label && (
                    <div className={styles.groupLabel}>
                        <div className={cn(styles.label, classDropdownLabel)}>
                            {label}{" "}
                            {tooltip && (
                                <Tooltip
                                    className={styles.tooltip}
                                    title={tooltip}
                                    icon="info"
                                    place="right"
                                />
                            )}
                        </div>
                        <div className={styles.error}>
                            {(value === null || value === undefined || value === '') && errorMessage}
                        </div>
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible })}>
                    <div className={(errorMessage && (value === null || value === undefined || value === '')) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input
                                type="text"
                                style={inputStyle}
                                placeholder={placeholder}
                                value={filterText !== '' ? filterText : value}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                            />
                        </div>
                    </div>
                    { visible && (
                        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions.map && filteredOptions.map((x, index) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.currencyType === value })} key={index}>
                                    {x.currencyType}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default Dropdown;
