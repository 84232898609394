import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({ items, type, title }) => {

  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  console.log(type)

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          {type === 'Progression Marker' &&
            <>
              <div className={styles.col}></div>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>ID</div>
              <div className={styles.col}>Balance</div>
              <div className={styles.col}>Last Updated</div>
            </>
          }
          {type === 'Progression System' &&
            <>
              <div className={styles.col}></div>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Progression Marker</div>
              <div className={styles.col}>Current Level</div>
              <div className={styles.col}>Last Updated</div>
            </>
          }
        </div>
        {items && items.map((x, index) => (
          <Row type={type} item={x} key={index} index={index} value={selectedFilters.includes(x.id)} onChange={() => handleChange(x.id)} />))}
      </div>
    </div>
  );
};

export default Table;
