import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, index}) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>{item.authProvider != null ? item.authProvider : '-'}</div>
                <div className={styles.col}>{item.userId != null ? item.userId : '-'}</div>
                <div className={styles.col}></div>
                <div className={styles.col}>
                    {(item.authProvider == 'email' || item.authProvider == 'userName') && <Control authProvider={item.authProvider} className={styles.control} />}
                </div>
            </div>
        </>
    );
};

export default Row;
