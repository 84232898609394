import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./searchDropdown.module.sass"
import Tooltip from "../../Tooltip";
import Tooltips from "../../Tooltip";

const Dropdown = ({className, disabled, id, currency, check, groupBy, error, errorMessage, classLabel, classDropdownHead, classDropdownLabel, placeholder, value, setValue, setId, setType, setDisplayId, setField, options, label, small, upBody, tooltip, place}) => {

    const [visible, setVisible] = useState(false)
    const containerRef = React.useRef(null);
    const [filterText, setFilterText] = useState('')
  
    const outClick = () => {
      setVisible(false)
      setFilterText('')
    }

    const handleClick = (values) => {
        setValue(values.name)
        setId(values.id)
        setVisible(false)
        setFilterText('')
    };

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        const containerWidth = container.offsetWidth;
        const contentWidth = container.scrollWidth;
      }
    }, [value]);
      
    return (
        <div id={id} className={styles.dropdown}>
            <OutsideClickHandler onOutsideClick={() => outClick()}>
            {label && (
                <div className={styles.groupLabel}>
                <div className={cn(styles.label, classDropdownLabel)}>
                    {label}
                    {tooltip && (
                      <Tooltips
                        className={styles.tooltip}
                        title={tooltip}
                        icon="info"
                        place={place ? place : "right"}
                      />
                    )}
                </div>
                <div className={styles.error}>
                    {(value == null || value == undefined || value == '') && errorMessage}
                </div>
                </div>
            )}
            <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible}, { [styles.disabled]: disabled })}>
                <div className={(errorMessage && (value == null || value == undefined || value == '')) ? cn(styles.head, styles.head_error, classDropdownHead, { [styles.disabled]: disabled }) : cn(styles.head, classDropdownHead, { [styles.disabled]: disabled })} onClick={() => setVisible(!visible)}>
                <div className={styles.placeholder} ref={containerRef}>
                    {currency && <div className={styles.currency}>{currency}</div>}
                    {(value == null || value == undefined || value == '') ? 
                      <>
                        <input disabled={disabled} type="text" placeholder={placeholder} className={currency ? styles.locInput : styles.normalInput} value={filterText} onChange={(e) => {setFilterText(e.target.value); setVisible(true)}}/>
                      </> 
                    : 
                      <>
                        {/* {value} */}
                        <input disabled={disabled}  type="text" className={cn(currency ? styles.locInput : styles.normalInput, { [styles.disabled]: disabled })} value={(filterText!=='')?filterText:value} onChange={(e) => {setFilterText(e.target.value); setVisible(true)}}/>
                      </>
                    }
                </div>
                </div>
                {!disabled &&
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                {options && options.filter(option => option.name&& option.name.toLowerCase().includes(filterText.toLowerCase())).map((x, index) => (
                    <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                        {x.name}
                    </div>
                ))}
                </div>
                }
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown