import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './progression.module.sass'
import ProgressionTableLayout from '../../../../../components/EngageComponents/PlayersComponent/progressionHistory'
import ProgressionOverviewTable from '../../../../../components/EngageComponents/PlayersComponent/overviewTable'
import Dropdown from '../../../../../components/Dropdown'
import Control from './Control'
import { useParams } from 'react-router-dom';
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

const Progression = ({activeIndex, access}) => {

  const {appid} = useParams()
  const {id} = useParams()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const getPlayerDetails = async() => {
      let playerDetail = {
        entities : {
          value : 'progression',
          offset: ((page-1)*limit),
          limit: limit
        },
        projectId : appid,
        ids : [id]
      }
      await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
  }

  const playerProgressionMarkers = useSelector((state) => {
    return state.players.progressionOverview
  })

  const playerProgressionHistory = useSelector((state) => {
    return state.players.progressionHistory
  })

  const playerProgressionSystem = useSelector((state) => {
    return state.players.progressionSystems
  })

  let totalCount = useSelector((state) => {
    return state.players.totalProgressionCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
  }

  useEffect(()=>{
    getPlayerDetails()
  }, [activeIndex, page])

  return (
    <>
        <Card title="Progression Marker" classTitle="title-purple">
          {playerProgressionMarkers && playerProgressionMarkers.length > 0 ?
            <ProgressionOverviewTable type="Progression Marker" items={playerProgressionMarkers} />
          : <div className={styles.warning}>No Data Available</div>}
        </Card>

        <Card title="Progression System" classTitle="title-purple">
          {playerProgressionSystem && playerProgressionSystem.length > 0 ?
            <ProgressionOverviewTable type="Progression System" items={playerProgressionSystem} />
          : <div className={styles.warning}>No Data Available</div>}
        </Card>

        <Card title="Progression History" classTitle="title-purple" head={
          <>
            <div className={styles.dropDown}>
                <Dropdown placeholder='Default' small={true} />
            </div>
          </>
        }>
          <div>
            <Control access={access}/>
          </div>
          <div className={styles.tableWrapper}>
            <div className={styles.group}>
            {playerProgressionHistory && playerProgressionHistory.length > 0 ?
              <>
                <ProgressionTableLayout items={playerProgressionHistory}/>
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                      <Pagination renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                              '&.Mui-selected': {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              },
                              '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              }
                            }}
                        />
                      )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
                    </Stack>
                    {/* <div className={styles.icons}>
                      <button disabled={page <= 1 ? true : false}>
                        <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
                      </button>
                      <button disabled={playerProgressionHistory.length < 10 ? true : false}>
                        <Next className={styles.next} onClick={onNext}/>
                      </button>
                    </div> */}
                </div>
              </>  
            : <div className={styles.warning}>No Data Available</div>}
            </div>
          </div>
        </Card> 
    </>
  )
}

export default Progression