export const theme = {
  primary: {
    600: "#FCFCFC",
    900: "#FCFCFC",
  },
  grey: { 300: "#11142d" },
  white: "#fff",
  teal: {
    100: "#5DDADB",
  },
  green: {
    200: "#389493",
    300: "#2C7A7B",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1",
    },
  },
  loader: {
    teal: "#2A84FF",
    purple: "#F9C600",
    pink: "#00D59C",
    bg: "#171923db",
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },

  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
    },
  },

  timeline: {
    divider: {
      bg: "#33383F",
    },
  },
  grid: {
    item: "#7180961a",
    highlight: "#38B2AC",
  },
};
