import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multiselect.module.sass";
import Tooltip from "../../Tooltip";
import Icon from "../../Icon";

const Dropdown = ({className, disabled, classDropdownHead, classDropdownLabel, classCheckboxTick, value, setValue, error, placeholder, options, label, tooltip, small, upBody}) => {

  const [visible, setVisible] = useState(false);
  const [filterText, setFilterText] = useState('');

  const handleClick = (selectedOption) => {
    if(value.some(e => e.name === selectedOption.name)){
      setValue(value.filter((values) => values.name !== selectedOption.name));
    } else {
      setValue([...value, selectedOption]);
      if(error){
        error(false);
      }
    }
    setFilterText('');
  };

  const removeOption = (selectedOption) => {
    if(value.some(e => e.name === selectedOption.name)){
      setValue(value.filter((values) => values.name !== selectedOption.name));
    } else {
      setValue([...value, selectedOption]);
    }
  };

  const filteredOptions = options&&options.filter(option =>
    option.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const inputStyle = {
    background: "#FCFCFC",
    color: "#6F767E",
    height: "40px",
    fontSize: "inherit",
    fontWeight: "inherit"
  };

  return (
    <div className={styles.main}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
        </div>
      )}
      <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(''); }}>
        <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
          <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(true)}>
            <div className={styles.selection}>
              {value.length > 0 ?
                <>
                  {value && value.slice(0, 2).map((e, i) => {
                    return (
                      <span className={styles.pills} key={i}>{e.name}<span className={styles.remove} onClick={() => { removeOption(e) }}><Icon name='close' size='12' /></span></span>
                    )
                  })}
                  <span>{value.length > 2 ? <span>&nbsp;&#43;&nbsp;{value.length - 2}</span> : ''}</span>
                  <input
                    id="inputFocus"
                    type="text"
                    style={{ ...inputStyle, marginLeft: "10px"}}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </>
                : <input
                  id="inputFocus"
                  type="text"
                  style={{...inputStyle,width:"100%"}}
                  placeholder={placeholder}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              }
            </div>
          </div>
          {!disabled && visible && (
            <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
              {filteredOptions.map && filteredOptions.map((x, index) => (
                <div className={cn(styles.option)} key={index}>
                  <label className={cn(styles.checkbox, className)} onChange={() => handleClick(x, index)} checked={value.some(e => e.name === x.name)}>
                    <input className={styles.input} type="checkbox" checked={value.some(e => e.name === x.name)} />
                    <span className={styles.inner}>
                      <span className={cn(styles.tick, classCheckboxTick)}></span>
                      <span className={styles.optionLabel}>{x.name}</span>
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
