import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './editStepsModal.module.sass'
import Dropdown from '../../../../../Dropdown'
import TaskDropdown from '../../../../../Dropdown/TaskDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTaskAction } from '../../../../../../redux/action/engage/Achievement/Task/TaskAction'
import { useParams } from 'react-router-dom'
// import CreateTask from '../../../../../../screens/Engage/Achievement/Tasks/CreateTask'
import EditTask from './CreateTaskInfo'
 
const EditStepModal = ({modalFor, as, onClose, className, item, setValue, index, currency, setCurrency, currencyCount, currencyPage, items, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setStepSeries}) => {

    const options = [{id : 1, name : 'Choose Exsisting Task'}, {id : 2, name : 'Create New Task'}]
    const [taskOptions, setTaskOptions] = useState([])

    const [task, setTask] = useState(null)
    const [taskId, setTaskId] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [taskName, setTaskName] = useState(null)

    const dispatch = useDispatch()
    const {appid} = useParams()

    useEffect(() => {
        getAllTask()
    }, [])

    let tasks = useSelector((state) => {
        return state.task.tasks
    })

    const getAllTask = async() => {
        let getTask = {
            projectId : appid,
            ids : [],
            offset : 0,
            limit : 100
        }
        await dispatch(getAllTaskAction(getTask))
    }

    const configTask = () => {
        setTaskOptions(tasks)
    }

    useEffect(()=>{
        configTask()
    }, [tasks])

    useEffect(()=>{
        setTaskName(null)
        setSelectedTask(null)
    }, [task])

    return (
        <>
            <div className={styles.addStepsModal}>
                <div className={styles.addStepsContainer}>
                    <div className={styles.addStepsHeader}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Configure {modalFor == 'Mission' ? 'Task' : 'Step'}</span>
                        </div>
                    </div>
                    <div className={styles.addStepsBody}>
                        <div className={styles.addStepsBodyContainer}>
                            <div className={styles.group}>
                                <EditTask modalFor={modalFor} as={as} onClose={onClose} index={index} task={selectedTask} item={item} setValue={setValue} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={items} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setStepSeries={setStepSeries}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.addStepsFooter}></div>
                </div>
            </div>
        </>
    )
}

export default EditStepModal