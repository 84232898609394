import React, {useState} from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import styles from './editPermissionModal.module.sass'
import { editInvitedMemberPermissionAction, editMemberPermissionAction } from '../../../../redux/action/AppSettings/Members/MembersAction'

const EditMembers = ({className, to, onClose, id, modalValue, modalId, setPermission, setPermissionId}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const onChangePermission = () => {
    if(to === 'invited'){
      let memberPermission = {
        id : id,
        permissions : [{projectId : appid, permissionId : modalId}]
      }
      setPermission(modalValue)
      setPermissionId(modalId)
      dispatch(editInvitedMemberPermissionAction(memberPermission, appid))
    }else{
      let memberPermission = {
        updateMemberAccess : [{
        memberId : id,
        projectId : appid,
        permissionId : modalId
      }]}
      setPermission(modalValue)
      setPermissionId(modalId)
      dispatch(editMemberPermissionAction(memberPermission, appid))
    }
    onClose()
  }
  
  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>
              <h5>Warning!</h5>
          </div>
          <div className={styles.warningContent}>
              <p>Are you sure you want to change member permission?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.deleteBtn} onClick={onChangePermission}>Yes</button>
        <button className={styles.cancelBtn} onClick={onClose}>No</button>
      </div>
    </div>
  )
}

export default EditMembers