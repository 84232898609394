import React, {useContext, useState} from 'react'
import axios from 'axios';
import customAxios from '../../../../../../utils/interceptor';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cn from 'classnames';
import styles from './eventInformation.module.sass'
import Toast from '../../../../../../components/Toast';
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import Dropdown from '../../../../../../components/Dropdown/EventDropdown/DataTypesDropdown';
import DefaultParameterDropdown from '../../../../../../components/BuildComponents/EventComponent/ParameterMultiselect';
import TextArea from '../../../../../../components/TextArea';
import Icon from '../../../../../../components/Icon';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../media/images/icons/remove.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getAllMetaDataAction } from '../../../../../../redux/action/common/commonAction';
import { WithContext as ReactTags } from "react-tag-input";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import Tooltip from '../../../../../../components/Tooltip';
import { useRef } from 'react';
import Radio from '../../../../../../components/Radio';
import { useEffect } from 'react';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import { getCustomEventAction, createCustomEventAction, editCustomEventAction, getDefaultEventAction, editDefaultEventAction } from '../../../../../../redux/action/builds/events/eventActions';
import { BASE_URL } from '../../../../../../resources/APIEndpoints';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext';

const EventInfo = ({className,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const globalStates = useContext(GlobalStateContext)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()

    const placeholder = Event
    const[{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 

    const [displayName, setDisplayName] = useState('')
    const [eventIcon, setEventIcon] = useState(null)
    const [eventId, setEventId] = useState('')
    const [eventDescription, setEventDescription] = useState()
    const [dataTypes, setDataTypes] = useState([])

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setEditAccess(globalStates.access)
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
                setEditAccess(globalStates.access)
            }
        }else{

        }
    }

    useEffect(() => {
        getUserAccess()
    }, [globalStates.access])


    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //             setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //             globalStates.setAccess(true)
    //         }
    //     }
    // }

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //         setEditAccess(!editAccess)
    //         globalStates.setAccess(false)
    //     }
    // }


    // useEffect(()=>{
    //     getUserAccess()
    //     getMetaData()
    // }, [])

    const getMetaData = () => {
        dispatch(getAllMetaDataAction())
    }

    let data = useSelector((state) => {
        return state.common.metaData.dataTypes
    })

    const setAllData = () => {
        const allDataType = data && data.map((e,i) => ({id : e.id, name : e.dataTypeName}))
        setDataTypes(allDataType)
    }

    useEffect(()=>{
        setAllData()
    }, [data])

    const onDisplayName= (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onEventId = (e) => {
        if(e.target.value == ''){
            setEventId('')
        }else{
            errors.idError = ''
            setEventId(e.target.value)
        }
    }

    const onEventDescription = (e) => {
        setEventDescription(e.target.value)
    }

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setEventIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
 toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setEventIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            if (/^[a-zA-Z0-9_ ]+$/i.test(tag.text)) {
                setTags([...tags, tag]);
            } 
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('eventName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.eventId == '') {
            error.idError = 'Event ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('eventId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const save = () => {        
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let eventTags = []
        tags && tags.forEach((e)=>{
            eventTags.push(e.text)
        })
        let specterParams = defaultParameter.filter(item => item.params == 'specter').map(e=> e.id)
        let event = {
            id : id,
            projectId : appid,
            specterParameterIds : specterParams,
            customParameterDetails : customParameter.map(({status, dataType, ...rest}) => {return rest;}).filter(obj => obj.name != ''),
            meta : finalMetaData,
            tags : eventTags
        }
        if(validation(event)){
            dispatch(editDefaultEventAction(event, navigate, appid, setDisable))
            setIsChanged(false)
        }
    }

    const cancel = () => {
        setEditAccess(true)
        globalStates.setAccess(true)
        // navigate(`/events/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [defaultParameter, setDefaultParameter] = useState([])
    const [customParameter, setCustomParameter] = useState([])

    const addParaMeters = () => {
        setCustomParameter(prevState => [...prevState, {name : '', status : true, type: 'state', dataType : 'integer', dataTypeId : 2}])
    }

    const onChangeParameterName = (value, i) => {
        let customParameters = [...customParameter]
        customParameters[i].name = value
        setCustomParameter(customParameters)
    }

    const changeCustomeParameter = (status, value, i) => {
        let customParameters = [...customParameter]
        customParameters[i].status = status
        customParameters[i].type = value
        if(value == 'statistic'){
            customParameter[i].dataType = 'integer'
            customParameter[i].dataTypeId = 2
        }
        setCustomParameter(customParameters)
    }

    const getCustomEventsById = async() => {
        let eventById = {
            projectId : appid,
            ids : [id]
        }
        await dispatch(getDefaultEventAction(eventById))
    }

    let eventDetail = useSelector((state) => {
        return state.event.defaultEvents
    })

    const [checkCustomParams, setCheckCustomParams] = useState([])

    const [checkObject, setCheckObject] = useState()

    const setStateData = () => {
        if(eventDetail && eventDetail.length > 0){
            setCheckObject(eventDetail[0])
            setDisplayName(eventDetail[0].name)
            setEventId(eventDetail[0].id)
            setEventDescription(eventDetail[0].eventDescription)
            eventDetail[0].tagsMapping && eventDetail[0].tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(eventDetail[0].meta == null || JSON.stringify(eventDetail[0].meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                eventDetail[0].meta && Object.keys(eventDetail[0].meta).forEach(e => {
                    meta.push({key : e, value : eventDetail[0].meta[e]})
                })
                setMetaData(meta)
            }
            if(eventDetail[0].customParameterDetails.length == 0){
                setCustomParameter([{name : '', status : true, type: 'state', dataType : 'integer', dataTypeId : 2}])
                setCheckCustomParams([{name : '', status : true, type: 'state', dataType : 'integer', dataTypeId : 2}])
            }else{
                setCheckCustomParams(eventDetail[0].customParameterDetails && eventDetail[0].customParameterDetails.map(obj => ({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id})))
                setCustomParameter(eventDetail[0].customParameterDetails && eventDetail[0].customParameterDetails.map(obj => ({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id})))
            }
            let defaultParams = []
            eventDetail[0].defaultParameterDetails && eventDetail[0].defaultParameterDetails.forEach((obj,i) => {
                return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : true, params : 'default'})
            })
            eventDetail[0].optionalParameterDetails && eventDetail[0].optionalParameterDetails.forEach((obj,i) => {
                return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : true, params : 'optional'})
            })
            eventDetail[0].specterParameterDetails && eventDetail[0].specterParameterDetails.forEach((obj,i) => {
                return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : false, params : 'specter'})
            })
            setDefaultParameter(defaultParams)
        }
    }

    useEffect(()=>{

    }, [customParameter])

    useEffect(() => {
        setStateData()
    }, [eventDetail])

    useEffect(()=>{
        getMetaData()
        getCustomEventsById()
        getSpecterParams()
        setMetaData([])
        setTags([])
    }, [])

    useEffect(()=>{
        getSpecterParams()
    }, [defaultParameter])

    const removeParameter = (index) => {
        let removeField = customParameter
        let metaFields = customParameter.filter((e,i,c)=>{
            return i != index
        })
        setCustomParameter(metaFields)
    }

    const [specterOptions, setSpecterOption] = useState([])

    const getSpecterParams = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(`${BASE_URL}/app-event/get/specter-params`, headers)
        setSpecterOption(diffrentObject(defaultParameter, res.data.data.specterParams))
        
    }

    const diffrentObject = (arr1, arr2) => {        
    const differentObjects = [];
    // Check objects in arr1 that are not in arr2
    for (const obj1 of arr1) {
        if (!arr2.some(obj2 => obj1.id === obj2.id)) {
            obj1.params = 'specter'
            differentObjects.push(obj1);
        }
    }

    // Check objects in arr2 that are not in arr1
    for (const obj2 of arr2) {
        if (!arr1.some(obj1 => obj2.id === obj1.id)) {
            obj2.params = 'specter'
            differentObjects.push(obj2);
        }
    }
        return differentObjects;
    }

    const [disable, setDisable] =  useState(false)


    function compareCustomParameterArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length==0 &&(array2==null || array2==undefined)) {
            return true
        }
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            let [ranks, score] = Object.entries(array2[i])[0];
            if (
                (array1[i].name == array2[i].name)&&
                (array1[i].dataTypeId == array2[i].dataTypeId )&&
                (array1[i].dataType==array2[i].dataType)&&
                (array1[i].type==array2[i].type)
            ) {
            }
            else{
                return false;
            }
        }
  
        return true;
      }

    const setchange = () => {
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })

        let defaultParams = []
        checkObject&&checkObject.defaultParameterDetails && checkObject.defaultParameterDetails.forEach((obj,i) => {
            return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : true, params : 'default'})
        })
        checkObject&&checkObject.optionalParameterDetails && checkObject.optionalParameterDetails.forEach((obj,i) => {
            return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : true, params : 'optional'})
        })
        checkObject&&checkObject.specterParameterDetails && checkObject.specterParameterDetails.forEach((obj,i) => {
            return defaultParams.push({id: obj.id, name : obj.name, status : (obj.type == 'statistic' ? false : true), type: obj.type, dataType : obj.dataType.dataTypeName, dataTypeId : obj.dataType.id, disable : false, params : 'specter'})
        })

        
        let customParameterCheck = checkObject&&( compareCustomParameterArrays(defaultParameter, defaultParams))

        if (checkObject&&
            (displayName==checkObject.name)&&
            (eventId==checkObject.id)&&
            (((eventDescription==undefined||eventDescription==''||eventDescription==null)&& checkObject.eventDescription==null)||eventDescription==checkObject.eventDescription)&&
            (customParameterCheck)&&
            // (JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta)|| (checkObject.meta==undefined && JSON.stringify(finalMetaData)=='{"":""}'))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
    },[displayName,eventIcon,eventId,eventDescription,defaultParameter,tags,metaData])

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-blue">
                    <div className={styles.description}>
                        <div className={styles.group}>
                            <TextInput id='eventName' disabled className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.EventName} label="Display Name*" name="DisplayName" type="text" required value={displayName} onChange={(e)=>{e.target.value == '' ? setDisplayName('') : setDisplayName(e.target.value); errors.nameError = ''}}/>
                            <TextInput id='eventId' disabled className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.EventId} label="Event ID*" name="eventId" type="text" required value={eventId} onChange={(e)=>{e.target.value == '' ? setEventId('') : setEventId(e.target.value); errors.idError = ''}}/>
                        </div>
                        <TextArea disabled className={styles.field} rows={5} tooltip={TooltipTitle.EventDescription} label="Event Description" value={eventDescription} onChange={(e)=>{setEventDescription(e.target.value)}}/>
                    </div>
                </Card>
                <Card className={cn(styles.card, className)} title="Parameters" classTitle="title-blue">
                    <div className={styles.description}>
                        <div className={styles.groups}>
                            <DefaultParameterDropdown disabled={editAccess} value={defaultParameter} setValue={setDefaultParameter} options={specterOptions} label='Paramters' placeholder="Select Specter Parameters"/>
                        </div>
                    </div>
                    <div className={styles.description}>
                        <div className={styles.groups}>
                            <span>Custom Parameters</span><span className={cn(styles.addIcon, {[styles.disabled] : editAccess})} onClick={!editAccess ? addParaMeters : null}><AddMetaIcon /></span>
                        </div>
                        {customParameter && customParameter.map((e,i) => {
                            return(
                                <>
                                    <div className={styles.groupbyThree}>
                                        <TextInput disabled={editAccess} className={styles.field} label="Name" name="Name" type="text" required value={e.name} onChange={(e) => {onChangeParameterName(e.target.value, i)}}/>
                                        <div className={styles.field}>
                                            <div className={styles.radioLabel}>
                                                <span>Is Parameters<span><Tooltip className={styles.tooltip} title={TooltipTitle.EventParameter} icon="info"/></span></span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio disabled={editAccess} className={styles.radio} key={i} name={`parameter${i}`} value={!e.status} onChange={() => {changeCustomeParameter(false, 'statistic', i)}} content="Statistic" />
                                                <Radio disabled={editAccess} className={styles.radio} key={i} name={`parameter${i}`} value={e.status} onChange={() => {changeCustomeParameter(true, 'state', i)}} content="State" />
                                            </div>
                                        </div>
                                        <div className={styles.field}>
                                            <Dropdown disabled={editAccess} label='Type' value={e.type == 'statistic' ? 'integer' : e.dataType} setValue={setCustomParameter} data={customParameter} index={i} setData={setCustomParameter} options={e.type == 'statistic' && dataTypes && dataTypes.length > 0 ? dataTypes && dataTypes.filter((e, i) => {return (e.name == 'integer')}) : dataTypes}/>
                                        </div>
                                        {!editAccess && 
                                            <span className={styles.remove} onClick={()=>{removeParameter(i)}}>
                                                <Remove className={styles.icon} />
                                            </span>
                                        }
                                    </div>
                                </>
                            )
                        })}
                    </div>

                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                    <TagInputWithDropdown disabled={editAccess} tooltip={TooltipTitle.EventTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createCurrency}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.EventMetaData} icon="info"/></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                            </div>

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                        return(
                                            <>
                                                <TextInput disabled={editAccess} className={styles.field} name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                                <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                                {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><img className={styles.icon} src={removeIcon} alt="remove" /></span>}
                                            </>
                                        )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>
                {!editAccess && 
                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button disabled={disable} className={styles.Save} onClick={save}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={styles.Cancel} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
                }
        </>
    )
}

export default EventInfo