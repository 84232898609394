import { BASE_URL, GET_STORES, ADD_STORES, EDIT_STORES } from "../../../../resources/APIEndpoints";
import axios from "../../../../utils/interceptor";

function StoreServices(){
    this.getAllStores = (body) => axios.post(BASE_URL + GET_STORES, body)
    this.getStoresById = (body) => axios.post(BASE_URL + GET_STORES)
    this.addStore = (body) => axios.post(BASE_URL + ADD_STORES, body)
    this.editStores = (body) => axios.post(BASE_URL + EDIT_STORES, body)
    this.archiveStore = (body) => axios.post(BASE_URL + EDIT_STORES, body)
}

export default new StoreServices()