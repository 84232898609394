import axios from "../../../utils/interceptor";
import { BASE_URL, DELETE_MEMBER, EDIT_INVITED_PERMISSION, EDIT_PERMISSION, GET_APP_ACCEPTED_MEMBERS, GET_APP_INVITED__MEMBERS, SEND_INVITE } from "../../../resources/APIEndpoints";

function MemberServices(){
    this.getAcceptedMembers = (body) => axios.post(BASE_URL + GET_APP_ACCEPTED_MEMBERS, body)
    this.getInvitedMembers = (body) => axios.post(BASE_URL + GET_APP_INVITED__MEMBERS, body)
    this.sendInvite = (body) => axios.post(BASE_URL + SEND_INVITE, body)
    this.editMemberPermission = (body) => axios.post(BASE_URL + EDIT_PERMISSION, body)
    this.editInvitedMemberPermission = (body) => axios.post(BASE_URL + EDIT_INVITED_PERMISSION, body)
    this.deleteMember = (body) => axios.post(BASE_URL + DELETE_MEMBER, body)
}

export default new MemberServices();