import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>{item.transactionType == 'credit' ? <div className={styles.earned}>{item.transactionType}</div> : item.transactionType == 'debit' ? <div className={styles.lost}>{item.transactionType}</div> : ''}</div>
                <div className={styles.col}>{item.amount}</div>
                <div className={styles.col}>{item?.currency != null ? item?.currency?.name : '-'}</div>
                <div className={styles.col}>{item.walletTransactionPurpose != null ? item.walletTransactionPurpose.name : '-'}</div>
                <div className={styles.col}>{item.activity != null ? item.activity : '-'}</div>
                <div className={styles.col}>{item.status == 'processed' ? <div className={styles.success}>{item.status}</div> : item.status == 'failed' ? <div className={styles.danger}>{item.status}</div> : item.status == 'in-progress' ? <div className={styles.warning}>{item.status}</div> : ''}</div>
                <div className={styles.col}>{item.remarks != null ? item.remarks : '-'}</div>
                <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
            </div>
        </>
    );
};

export default Row;
