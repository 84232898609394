import React, {useContext, useState} from 'react'
import cn from "classnames";
import styles from "./getPlayer.module.sass";
import Form from '../../../../components/Form';
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import PlayersTableLayout from '../../../../components/EngageComponents/PlayersComponent/PlayersTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { filterPlayerAction, getAllPlayersAction } from '../../../../redux/action/engage/Players/PlayersAction';
import { Link } from 'react-router-dom';
import addIcon from '../../../../media/images/icons/light.png'
import Control from './Control';
import { ToastContainer } from 'react-toastify';
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import EmptyStates from '../../../../components/EmptyStates';
import Loader from '../../../../components/Loader';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton';
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext';

const sorting = ["list", "grid"];

const GetPlayers = () => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);

    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(8)

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)

    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
  
    useEffect(() => {
      getUserAccess()
    }, [])

    const getAllPlayers = async() => {
        let playersData = {
          offset: ((page-1)*limit),
          limit: limit,
          projectId : appid
        }
        await dispatch(getAllPlayersAction(playersData))
    }

    let allPlayers = useSelector((state) => {
      return state.players.players
    })

    let totalPlayers = useSelector((state) => {
      return state.players.totalCount
    })

    let isLoading = useSelector((state) => {
      return state.players.isLoading
    })

    let filteredPlayer = useSelector((state) => {
      return state.players.filteredPlayer
    })
  
    let isSearched = useSelector((state) => {
      return state.players.isSearched
    })

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const [showSkeleton, setShowSkeleton] = useState(true)

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }
  
    useEffect(()=>{
        configureSkeleton()
        getAllPlayers()
    }, [page])

    const [searchFlag, setSearchFlag] = useState(false)

    const onSearch = (search) => {
      if(search == ''){
        setSearchFlag(false)
        getAllPlayers()
      }else{
        setSearchFlag(true)
        let searchPlayer = {search : search, projectId : appid,}
        dispatch(filterPlayerAction(searchPlayer))
      }
    }
      
    return (
        <>
            <ToastContainer />
            <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Players"} classTitle={cn("title-purple", styles.title)}
            head={
                (
                    <>
                      <div className={styles.add}>
                        {showSkeleton && allPlayers.length > 0 ?
                          <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                        : !showSkeleton && allPlayers.length > 0 ?
                          <>
                            {!userAccess &&
                              <div className={styles.createButton}>
                                <Control type='small' action={`Create Player`}/>
                              </div>
                            }
                          </>
                        : ''}
                      </div>
                    </>
                )
            }
            >

            <div className={styles.wrapper}>
            {allPlayers.length > 0 && <div className={styles.seprator}></div>}
              <div className={styles.options}>
                <div className={styles.filters}>
                  {(showSkeleton && allPlayers.length > 0) ?
                    <>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={74} height={34}/>
                      <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={130} height={34}/>
                    </>
                  : !showSkeleton && allPlayers.length > 0 ?
                    <>
                      <div className={styles.buttons}>
                          <Icon name='filter' size='20' className={styles.icon} />
                          <span className={styles.buttonText}>Filter</span>
                      </div>
                      {/* <div className={styles.buttons}>
                          <ArchiveSvg className={styles.icon} />
                          <span className={styles.buttonText}>Show Archived</span>
                      </div> */}
                    </>
                  : ''}
                  
                </div>
                <div className={styles.searchForm}>
                {showSkeleton && allPlayers.length > 0 ?
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40}/>
                : !showSkeleton && allPlayers.length > 0 ?
                  <Form className={styles.form} placeholder="Search Players" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
                : ''}
                </div>
            </div>
            {showSkeleton && 
              (allPlayers.length == 0 ?
                <SkeletonEmptyState />
              : 
                <SkeletonTable usedFor='Players' rows={allPlayers.length} column={5} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[44, 80, 80, 80, 40]} colHeight={[44, 25, 25, 25, 40]} />
              )
            }

            {!showSkeleton && 
              (allPlayers.length == 0 ?
                <EmptyStates  type="Player" action={`Create Player`}/>
              : searchFlag ?
                <>
                  {isSearched && <div className={styles.textCenter}><Loader /></div>}
                  {!isSearched && filteredPlayer.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
                  {!isSearched && filteredPlayer.length > 0 && <PlayersTableLayout items={filteredPlayer} title="Last edited" /> }
                </>
              : <PlayersTableLayout items={allPlayers} title="Last edited" /> 
              )
            }
            
            {!isLoading && allPlayers.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(totalPlayers/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
          </div>

            </Card>
            <div className="divider"></div>
          <div className={styles.msg}>
            <p className={styles.alert}>Learn more about Players <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/players" target="_blank">Click Here</a></p>
          </div>
        </>
    )
}

export default GetPlayers