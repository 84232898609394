import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./emailvalidate.module.sass";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {signUpUserAction} from '../../../../redux/action/authenticate/authenticationAction'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import Spectre from '../../../../media/lottieAnimation/Specter_Animation.json'
import {ReactComponent as Cross} from '../../../../media/images/hollow_crossed.svg'
import {ReactComponent as TopCircle} from '../../../../media/images/tpo_circle.svg'
import {ReactComponent as FullHollowCircle} from '../../../../media/images/full_hollow_circle.svg'
import {ReactComponent as HollowCross} from '../../../../media/images/x_fill.svg'
import {ReactComponent as FullCircle} from '../../../../media/images/full_circle.svg'
import FilledCrossed from '../../../../media/images/fill_cross.png'
import Lottie from 'lottie-react'

const Code = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const style = {
      width : 560,
      height : 608,
  }

    const [otp, setOtp] = useState();
    const [user, setUser] = useState()


    const getEmail = () => {
        let user = JSON.parse(sessionStorage.getItem('Information'))
        setUser(user)
    }

    useEffect(()=>{
        getEmail()
    }, [])

    const verifyAccount = () => {
        user.code = parseInt(otp)
        dispatch(signUpUserAction(user, navigate))
    }

  return (
    <>
      <ToastContainer />
      <div className={styles.otpScreen}>
        <div className={styles.otp_outer_container}>
          <div className={styles.otp_inner_container}>
            <div className={styles.Branding}>
              <Cross className={styles.crossFilled}/>
              <TopCircle className={styles.topCircle}/>
              <FullCircle className={styles.fullCircle}/>
              <FullHollowCircle className={styles.hollowCircle}/>
              <div className={styles.specterBranding}>
                  <div className={styles.animation}>
                      <Lottie className={styles.lottieAnimation} animationData={Spectre} loop={true} autoPlay={true} style={style} />
                  </div>
              </div>
            </div>
            <div className={styles.otpWrapper}>
              <HollowCross className={styles.hollowCross}/>
              <div className={styles.wrapper}>
                <img src={FilledCrossed} className={styles.filledCross} />
                {/* <FilledCross className={styles.filledCross}/> */}
                <div className={styles.code}>
                  <div className={styles.body}>
                    <div className={cn("h2", styles.title)}>Sign up</div>
                    <div className={styles.info}>
                      We just send you a verify code. Check your inbox to get them.
                    </div>
                    <div className={styles.fieldset}>
                      <div className={styles.field}>
                        <OtpInput inputType='number' inputStyle={styles.input} containerStyle={styles.fieldset} value={otp}
                        onChange={setOtp} numInputs={4} renderInput={(props) => <input {...props} />} />
                      </div>
                    </div>
                      <button className={cn("button", styles.button)} onClick={verifyAccount}>
                        <span>Continue</span>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.wrapper}>
        <div className={styles.code}>
          <div className={styles.body}>
            <div className={cn("h2", styles.title)}>Sign up</div>
            <div className={styles.info}>
              We just send you a verify code. Check your inbox to get them.
            </div>
            <div className={styles.fieldset}>
              <div className={styles.field}>
                <OtpInput inputType='number' inputStyle={styles.input} containerStyle={styles.fieldset} value={otp}
                onChange={setOtp} numInputs={4} renderInput={(props) => <input {...props} />} />
              </div>
            </div>
              <button className={cn("button", styles.button)} onClick={verifyAccount}>
                <span>Continue</span>
              </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Code;
