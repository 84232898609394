import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import "./index.css";
import UseCalendar from "./UseCalendar";

const Calendar = ()=>{
    const CalendarType = window.location.pathname.includes('leaderboard')?"leaderboard":window.location.pathname.includes('competition')?"competition":window.location.pathname.includes('task')?"tasks":window.location.pathname.includes('mission')?"missions":window.location.pathname.includes('step-series')?"step-series":""

    return(
        <>
                <div>
                    <UseCalendar CalendarType={CalendarType}/>
                </div>
        </>
    )
}

export default Calendar;