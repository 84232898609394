import react from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './progression.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import PsPlaceHolder from '../../../media/images/placeholders/Progression_System.png'
import { Link, useParams } from 'react-router-dom'
import { routes } from '../../../routes/routes'

const Progression = ({className, data}) => {
    
    const {appid} = useParams()
    
    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Progression" classTitle="title-purple">
                    <div className={styles.progressionContainer}>
                        <div className={styles.progression}>
                            {(data && data.length == 0) &&
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <a href='#' target='_blank'>Progression</a> yet!</span></div>
                                </div>
                            }

                            {(data && data.length > 0) &&
                            <>
                                <div className={styles.wrapper}>
                                    <div className={styles.table}>
                                        <div className={styles.row}>
                                        <div className={styles.col}>Icon</div>
                                        <div className={styles.col}>Name</div>
                                        <div className={styles.col}>Marker</div>
                                        <div className={styles.col}>No of levels</div>
                                        </div>
                                        {data && data.slice(0,4).map((e, index) => (
                                            <>
                                                <div className={styles.tableRow}>
                                                    <div className={styles.tableCol}>
                                                        <img src={(e.iconUrl && e.iconUrl != null && e.iconUrl != '') ? e.iconUrl : PsPlaceHolder} alt={`${e.name}`} />
                                                    </div>
                                                    <div className={styles.tableCol}><div className={styles.overflowTextPS}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                                    <div className={styles.tableCol}><div className={styles.overflowTextPM}>{(e.progressionMarker && e.progressionMarker != null && e.progressionMarker.name != null) ? e.progressionMarker.name : '-'}</div></div>
                                                    <div className={styles.tableCol}>{(e.levelsCount && e.levelsCount != '' && e.levelsCount != null) ? e.levelsCount : '-'}</div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                {(data && data.length > 4) &&
                                    <div className={styles.viewAll}>
                                        <Link className={styles.all} to={`/progression-system/${appid}`}>View All</Link>
                                    </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Progression