import {GET_APP_LIST, GET_APPS, GET_APP_OVERVIEW, ADD_APPS, EDIT_APPS, FILTER_APPS, GET_APP_DETAILS } from "../../actionTypes/actionTypes";

const initialState = {
    app : [],
    appDetails : [],
    appList : [],
    appOverview : [],
    filterApp : [],
    isSearched : true,
    isFetched : true,
    isLoading : true,
    totalCount : null
};

export const appReducer =( state=initialState, action )=>{
    switch(action.type){
        case GET_APPS : {
            return {
                ...state,
                app: action.payload,
                isFetched : false,
                totalCount : action.count,
                filterApp : [],
                isSearched : true,
            }
        }

        case GET_APP_DETAILS : {
            return{
                ...state,
                appDetails : action.payload,
                filterApp : [],
                isSearched : true,
            }
        }

        case GET_APP_LIST : {
            return {
                ...state,
                appList : action.payload,
                totalAppCount : action.count,
                filterApp : [],
                isSearched : true,
            }
        }

        case FILTER_APPS : {
            return {
                ...state,
                filterApp : action.payload,
                isSearched : false
            }
        }

        case GET_APP_OVERVIEW : {
            return {
                ...state,
                appOverview : action.payload,
                isLoading : false,
                filterApp : [],
                isSearched : true,
            }
        }

        case ADD_APPS : {
            return {
                ...state,
                app: action.payload,
                filterApp : [],
                isSearched : true,
            }
        }

        case EDIT_APPS : {
            return {
                ...state,
                app: action.payload,
                filterApp : [],
                isSearched : true,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}    