import {LOGIN, LOGOUT, LOGIN_FAILED, SIGNUP} from '../../actionTypes/actionTypes'
import AuthService from '../../../services/authServices/authenticationServices';
import Toast from '../../../components/Toast'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const loginUser = (body, navigate, setShowLoader, setLoaderType) => {
    return (dispatch) => {
        AuthService.login(body)
            .then(res => {
                let authToken = res.data.data.organisation.memberDetails.authToken
                let user = res.data.data.organisation.memberDetails
                localStorage.setItem('token', authToken)
                localStorage.setItem('user', JSON.stringify(user))
                let orgId = res.data.data.organisation.id
                localStorage.setItem('orgId', orgId)
                if(res.data.data.organisation.typeId == 1){
                    let orgName = res.data.data.organisation.name.split(" ")[0]  
                    localStorage.setItem('orgName', `${orgName}'s Organization`)
                }else{
                    let orgName = res.data.data.organisation.name
                    localStorage.setItem('orgName', orgName)
                }    
                let memberName = ((res.data.data.organisation.memberDetails && res.data.data.organisation.memberDetails.name != null) ? res.data.data.organisation.memberDetails.name : '-')
                localStorage.setItem('userName', JSON.stringify(memberName))
                let apiKeys = res.data.data.organisation.apiKeys
                localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
                let environment = 'Development'
                localStorage.setItem('environment', environment)
                localStorage.setItem('darkMode', false)
                dispatch(getLoggedInUser(res.data.data.organisation.memberDetails));
                navigate('/dashboard')
                setShowLoader(true)
                setLoaderType(1)
                setTimeout(() => {
                    setShowLoader(false)
                }, 4000)
            })
            .catch(error => {
                error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
            })
    }
}

export const signUpUserAction = (body, Navigate, setShowLoader, setLoaderType, setShowQuickGuideModal) => {
    return (dispatch) => {
        AuthService.SignUp(body)
        .then(res=>{
            let authToken = res.data.data.organisation.memberDetails.authToken
            let user = res.data.data.organisation.memberDetails
            if(res.data.data.organisation.typeId == 1){
                let orgName = res.data.data.organisation.name.split(" ")[0]  
                localStorage.setItem('orgName', `${orgName}'s Organization`)
            }else{
                let orgName = res.data.data.organisation.name 
                localStorage.setItem('orgName', orgName)
            }
            localStorage.setItem('token', authToken)
            localStorage.setItem('user', JSON.stringify(user))
            let orgId = res.data.data.organisation.id
            localStorage.setItem('orgId', orgId)
            let apiKeys = res.data.data.organisation.apiKeys
            localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
            let environment = 'Development'
            localStorage.setItem('environment', environment)
            localStorage.removeItem('userEmail')
            localStorage.setItem('darkMode', false)
            localStorage.setItem('showModal', JSON.parse(true))
            dispatch(getLoggedInUser(res));
            Navigate('/dashboard')
            setLoaderType(0)
            setShowLoader(true)
            setTimeout(() => {
                setShowLoader(false)
                setShowQuickGuideModal(true)
            }, 4000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getEmailVerificationAction = (body, navigate, showModal) => {
    return async (dispatch) => {
        AuthService.getverifyEmail(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                showModal(true)
            }, 1000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const verifyEmailAction = (body, navigate, hideModal, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        AuthService.sendverifyEmail(body)
        .then(res=>{
            hideModal(false)
            navigate('/user-detail')
            setLoaderType(0)
            setShowLoader(true)
            setTimeout(() => {
                setShowLoader(false)
            }, 2000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getResetPasswordLinkAction = (body) => {
    return async (dispatch) => {
        AuthService.resetPasswordLink(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const ResetPasswordAction = (body, navigate, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        AuthService.resetPassword(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(() => {
                navigate('/')
                // dispatch(loginUser({email : body.email, password : body.newPassword}, navigate, setShowLoader, setLoaderType))
            }, 2000)
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getLoggedInUser = (data)=> {
    return{
        type: LOGIN,
        payload: data
    }
}

export const SignUp = (data) => {
    return {
        type : SIGNUP, 
        payload : data
    }
}

export const onLoginFailed = (data)=> {
    return{
        type: LOGIN_FAILED,
        payload: data
    }
}