import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../../Icon";
import Modal from "../../../../../../Modals/ModalComponent";
import DeleteStepModal from "../../../../../AchievementComponent/StepSeriesComponent/Modal/DeleteStepsModal";
import EditStepModal from "../../../../../AchievementComponent/StepSeriesComponent/Modal/EditStepsModal";
import EyeIcon from '../../../../../../../media/images/icons/eye.png'
import DeleteModal from "../../../../../../Modals/Others/DeleteModal";
import ArchiveModal from "../../../../../../Modals/Others/ArchieveModal";

const Control = ({modalFor, access, className, appid, id, name, status, to, as, type, index, item, currency, setCurrency, currencyCount, currencyPage, items, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setValue, setStepSeries}) => {

  const [isEditModal, setIsEditModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const [actions, setActions] = useState([])

  useEffect(() => {
    if(type == 'list'){
        setActions([
          {
            icon: "edit",
            action:  () => setIsEditModal(true),
          },
          {
            icon: "trash",
            action:  () => setIsDeleteModal(true),
          }
        ])
    }else{
      if(to == 'mission'){
        setActions([
          {
            pathName : `/view-mission/${appid}/${id}`
          },
          {
            icon: "edit",
            pathName : `/edit-mission/${appid}/${id}`
          },
          // {
          //   icon: "download",
          //   action: () => setVisibleArchiveModal(true),
          //   id : id
          // },
          {
            icon: "trash",
            action:  () => setVisibleDeleteModal(true),
          }
        ])
      }else{
        setActions([{
            pathName : `/view-step-series/${appid}/${id}`
          },
          {
            icon: "edit",
            pathName : `/edit-step-series/${appid}/${id}`
          },
          // {
          //   icon: "download",
          //   action: () => setVisibleArchiveModal(true),
          // },
          {
            icon: "trash",
            action:  () => setVisibleDeleteModal(true),
          }
        ])
      }
    }
  }, [type, id])

  return (
    <>
      <div className={cn(styles.control, className)}>
      {(access && type == 'table') ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : (!access && type == 'table') ?
          (actions && actions.slice(1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id}} className={styles.button} key={index} onClick={x.action}>
                <Icon name={x.icon} size="20" />
              </Link>
            )
          }))
        :(!access && type == 'list') ?
          (actions && actions.map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id}} className={styles.button} key={index} onClick={x.action}>
                <Icon name={x.icon} size="20" />
              </Link>
            )
          }))
        : ''}  
      </div>
      <Modal visible={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
        <DeleteStepModal modalFor={modalFor} index={index} setStepSeries={setStepSeries} onClose={() => setIsDeleteModal(false)} />
      </Modal>
      <Modal heading={true} width='medium' visible={isEditModal} onClose={() => setIsEditModal(false)}>
        <EditStepModal modalFor={modalFor} as={as} item={item} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={items} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} onClose={() => setIsEditModal(false)} />
      </Modal>
      <Modal visible={visibleDeleteModal} onClose={() => setVisibleDeleteModal(false)}>
        <DeleteModal id={id} name={name} type={to} onClose={() => setVisibleDeleteModal(false)}/>
      </Modal>
      {/* <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveModal id={id} name={name} status={status} type={to} onClose={() => setVisibleArchiveModal(false)}/>
      </Modal> */}
    </>
  );
};

export default Control;
