import  {React, useEffect, useState } from 'react';
import InstanceDropdown from '../InsatanceDropdown'
import cn from 'classnames'
import styles from './InstanceSection.module.sass'
import moment from 'moment';

const InstanceDropdownSection = ({className, instances, value, setValue}) => {

    const [options, setOptions] = useState([]) 

    const configureOptions = () => {
        if(instances && instances.length > 0){
            let instanceOption = instances && instances.map((e, i) => ({id : e.slotId, name: `Match ${i+1} : ${moment(e.createdAt).format('MMMM DD, YYYY hh:mm A')}`}))                                                                               
            const filteredObject = instanceOption.filter((_, index) => index === instanceOption.length - 1).map(obj => obj)[0];
            if(filteredObject){
                setValue(filteredObject)    
            }  
            setOptions(instanceOption)
        }
    }

    useEffect(() => {
        configureOptions()
    }, [instances])
 
    return (
        <>
            <div className={cn(styles.calendar, className)}>
                <InstanceDropdown options={options} value={value} setValue={setValue}/>
            </div>
        </>
    );
}

export default InstanceDropdownSection