import React, { useEffect, useState, useRef} from "react";
import cn from "classnames";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ScheduleCompetitionInformation.module.sass";
import Card from "../../../../../components/Card";
import TextInput from "../../../../../components/TextInput";
import Radio from '../../../../../components/Radio';
import Tooltip from "../../../../../components/Tooltip";
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg';
import {ReactComponent as Remove} from '../../../../../media/images/icons/remove.svg';
import CategoryDropdown from '../../../../../components/Dropdown/RadioDropdown'
import CreatableDropdown from '../../../../../components/Dropdown/CreatableDropdown'
import Dropdown from '../../../../../components/Dropdown'
import { getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import { getCompetitionInstantBattleAction} from '../../../../../redux/action/engage/Competitions/competitionAction';
import { getLiveOpsCategoryAction,createScheduleLiveOpsAction} from '../../../../../redux/action/engage/LiveOps/LiveOpsAction';
import TagInputWithDropdown from "../../../../../components/TagInputWithDropdown";
import moment from 'moment';
import Tooltips from "../../../../../components/Tooltip";
import TooltipTitle from "../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../components/MetaInput";
import Checkbox from "../../../../../components/Checkbox";
// import DatePicker from 'react-date-picker';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import '../../../Leaderboard/CreateLeaderboard/LeaderboardInformation/style.css'
import DatePicker from "react-datepicker";
import Item from '../../../../../components/Schedule/Item'
import { format } from "date-fns";

const StoreInformation = ({className,isChanged, setIsChanged}) => {

    const [CategoryOptions, setCategoryOptions] = useState([])
    const [CategoryValue, setCategoryValue] = useState()
    const [CategoryID, setCategoryID] = useState()
    const [CompetitionOptions, setCompetitionOption] = useState()
    const [Competitions, setCompetitions] = useState()
    const [frequency, setFrequency] = useState(false)
    const [recurringOptions, setRecurringOption] = useState()
    const [recurring, setRecurring] = useState('')
    const [recurringId, setRecurringId] = useState()
    const [competitionId,setCompetitionId] = useState(null)
    const [competitionIdName,setCompetitionIdName] = useState()
    const [startDateTime, setStartDateTime] = useState('')
    const [endDateTime, setEndDateTime] = useState('')
    const [offset, setOffset] = useState(true)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const {appid} = useParams();
    const navigate = useNavigate();

    const onChangeStartDateTime = (e) => {
        setStartDateTime(e.target.value)
    }
  
    const onChangeEndDateTime = (e) => {
        setEndDateTime(e.target.value)
    }

    const [startDate, setStartDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endDate, setEndDate] = useState('')
    const [endTime, setEndTime] = useState('')

    const onChangeStartDate = (e) => {
        setStartDate(e)
      setEndDate('')
    }

    const onChangeStartTime = (e) => {
      setStartTime(e.target.value)
    }

    const onChangeEndDate = (e) => {
      setEndDate(e)
    }

    const onChangeEndTime = (e) => {
      setEndTime(e.target.value)
    }

    const onChangeHours = (e) => {
        setHours(parseInt(e.target.value))
    }
    const [bgColor, setBgColor] = useState();

    const onChangeMinutes = (e) => {
        setMinutes(parseInt(e.target.value))
    }

    const getCurrentDateTimeForInput = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };

    const currentDateTime = getCurrentDateTimeForInput();
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning("Only 10 tags are allowed")
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };
    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }


    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const handleInputChange = (e) => {

    }
    useEffect(()=>{
        getMetaData()
    },[])
      
    const getMetaData = () => {
        dispatch(getAllMetaDataAction())
    }

    let allMetaData = useSelector((state) => {
        return state.common.metaData
      })
  
      useEffect(()=>{ 
        configureMetaData()
      }, [allMetaData])


    const configureMetaData = () => {

      setRecurringOption(allMetaData.leaderboardInterval && allMetaData.leaderboardInterval.filter(obj => obj.intervalName !== 'monthly' && obj.intervalName !== 'yearly' && obj.intervalName !== 'all_time' && obj.intervalName !== 'custom').map((obj) => {
        return {
          id: obj.id,
          name: (obj.intervalName.charAt(0).toUpperCase() + obj.intervalName.slice(1))?.replace(/_/g, ' '),
        };
      }))

    }
    const getCompetitionTournament = async() => {
        let getTournaments = {
            projectId : appid,
        }
        dispatch(getCompetitionInstantBattleAction(getTournaments))
    }

    const getLiveOpsCategory = async() => {
        let getLiveOpsCategory ={
            projectId:appid,
        }
        dispatch(getLiveOpsCategoryAction(getLiveOpsCategory))
    }

    useEffect(() => {
          getCompetitionTournament()
    }, [])

    useEffect(() => {
        getLiveOpsCategory()
    }, [])

    let allLiveOpsCategory = useSelector((state) => {
        return state.liveOps.liveOpsCategory
    })
    useEffect(()=>{ 
        configureCategoryData()
    }, [allLiveOpsCategory])

    const cancel = () => {
        navigate(`/competition-schedule/${appid}`)
        setIsChanged(false)
    }


    let allCompetitions = useSelector((state) => {
      return state.competition.instantBattle
    })
    const configureCategoryData = () => {
        setCategoryOptions(allLiveOpsCategory && allLiveOpsCategory.map((obj) => {
            return {
                id: obj.id,
                name: obj.name,
                colorCode: obj.colorCode,
            };
        }))

    }

    const configureCompetitionData = () => {
    setCompetitionOption(allCompetitions && allCompetitions.map((obj) => {
        return {
            id: obj.id,
            name: obj.name,
            competitionId: obj.competitionId,
        };
    }))
    }
    useEffect(()=>{
        configureCompetitionData()
    }, [allCompetitions])

    useEffect(()=>{
        allCompetitions.map((obj)=>{
            if (obj.id==competitionId) {
                setCompetitionIdName(obj.competitionId)
            }
        })
    },[competitionId])

 // Create API for ScheduleCompetitons

 const [errors, setErrors] = useState({compError : ''})

 const validation = (data) => {

     const error = {}
     let isValid = true

     if (data.competitionId == null) {
        error.compError = 'Competition is required';
        isValid = false;
        const element = document.getElementById('scheduleName');
        const offset = 140;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({top: offsetPosition, behavior: 'smooth'});
     }


     setErrors(error);

     if(isValid){
       return true
     }
 }

 const [disable, setDisable] = useState(false)

 const submit = () => {


    const metaDataList = [...metaData];
    const finalMetaData = {};
    for(var m = 0; m < metaDataList.length ; m++){
        finalMetaData[metaDataList[m].key] = metaDataList[m].value;
    }

    let storeTags = []
    tags && tags.forEach((e)=>{
        storeTags.push(e.text)
    })

    let formatedStartDate = null
    let formatedEndDate = null
    if(startDate != '' && startTime !=''){
      const parsedDate = moment(startDate);
      const parsedTime = moment(startTime);
      const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
      const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
      formatedStartDate = formattedDateTime
    }

    if(endDate != '' && endTime != ''){
      const parsedDate = moment(endDate);
      const parsedTime = moment(endTime);
      const combinedDateTime = moment(parsedDate.format('YYYY-MM-DD') + ' ' + parsedTime.format('HH:mm:ss')).utc();
      const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');
      formatedEndDate = formattedDateTime
    }

    const ScheduleCompetition={
        competitionId:competitionId,
        projectId: appid,
        startDate:  formatedStartDate.concat('+00:00'),
        endDate : formatedEndDate.concat('+00:00'),
        categoryId: CategoryID,
        scheduleType:(frequency ? "recurring" : "normal"),
        recurrenceFrequencyId: (frequency?recurringId:null),
        offset: (offset ? null : parseInt(((hours * 60 * 60) + (minutes * 60)))),
        meta: finalMetaData,
        tags: storeTags,
        recurrenceCount : ((frequency && recurrence != null) ? recurrence : 0)
    }
    if(validation(ScheduleCompetition)){
        dispatch(createScheduleLiveOpsAction(ScheduleCompetition, navigate, appid, setDisable))
        setIsChanged(false)
    }
}

const setchange = () => {
    if (
        (CategoryID===undefined||CategoryID==''||CategoryID==""||CategoryID===null)&&
        (recurrence===undefined||recurrence==''||recurrence==""||recurrence===null)&&
        (competitionId===undefined||competitionId==''||competitionId==""||competitionId===null)&&
        (frequency===false)&&
        (recurringId===undefined||recurringId==''||recurringId==""||recurringId===null)&&
        (frequency||(startDateTime===undefined||startDateTime==''||startDateTime==""||startDateTime===null))&&
        (frequency||(endDateTime===undefined||endDateTime==''||endDateTime==""||endDateTime===null))&&
        (offset===true)&&
        (offset||(hours===undefined||hours==''||hours==""||hours===null))&&
        (offset||(minutes===undefined||minutes==''||minutes==""||minutes===null))&&
        (tags.length==0)&&
        (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
    )
    {
        setIsChanged(false)
    }
    else
    {
        setIsChanged(true)
    }
}



    const [recurrence, setRecurrence] = useState(null)

    const onChangeCount = (e) => {
        setRecurrence(parseInt(e.target.value))
    }

    useEffect(()=>{
        setchange()
    },[CategoryID,recurrence, competitionId,frequency,recurringId,startDateTime,endDateTime,offset,hours,minutes,tags,metaData])

    const [isRecurring, setIsRecurring] = useState(false)

    const onChangeEndDateToWeek = (e) => {
      if(e.target.checked){
        if(recurringId === 1){
          const date = moment(startDate).clone().add(0, 'days').format('YYYY-MM-DD');
          const time = '23:59:00';
          const dateTimeString = `${date} ${time}`;
          const formattedDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm A').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
          setEndDate(new Date(moment(startDate).clone().add(0, 'days').format('YYYY-MM-DD')))
          setEndTime(new Date(formattedDateTime.concat(" ", "(India Standard Time)")))
        }else if(recurringId === 2){
          const date = moment(startDate).clone().add(6, 'days').format('YYYY-MM-DD');
          const time = '23:59:00';
          const dateTimeString = `${date} ${time}`;
          const formattedDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm A').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
          setEndDate(new Date(moment(startDate).clone().add(6, 'days').format('YYYY-MM-DD')))
          setEndTime(new Date(formattedDateTime.concat(" ", "(India Standard Time)")))
        }else if(recurringId === 3){
          const date = moment(startDate).clone().add(30, 'days').format('YYYY-MM-DD');
          const time = '23:59:00';
          const dateTimeString = `${date} ${time}`;
          const formattedDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm A').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
          setEndDate(new Date(moment(startDate).clone().add(30, 'days').format('YYYY-MM-DD')))
          setEndTime(new Date(formattedDateTime.concat(" ", "(India Standard Time)")))
        }else{
          const date = moment(startDate).clone().add(365, 'days').format('YYYY-MM-DD');
          const time = '23:59:00';
          const dateTimeString = `${date} ${time}`;
          const formattedDateTime = moment(dateTimeString, 'YYYY-MM-DD h:mm A').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
          setEndDate(new Date(moment(startDate).clone().add(365, 'days').format('YYYY-MM-DD')))
          setEndTime(new Date(formattedDateTime.concat(" ", "(India Standard Time)")))
        }
      }else{
        setEndDate('')
        setEndTime('')
      }
    }
  
    const [visibleStartDate, setVisibleStartDate] = useState(false);
    const [visibleStartTime, setVisibleStartTime] = useState(false);
    const [visibleEndDate, setVisibleEndDate] = useState(false);
    const [visibleEndTime, setVisibleEndTime] = useState(false);
  
    useEffect(() => {
      setEndDate('')
    }, [recurringId])

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
            <div className={styles.description}>
                <div className={styles.group}>
                  <div className={styles.field}>
                <CreatableDropdown tooltip={TooltipTitle.CompetitionName} id='scheduleName' errorMessage={errors.compError} label="Select Competition*" type="competition" placeholder="Select Competition" className={styles.selectField} groupBy={1} options={CompetitionOptions} setId={setCompetitionId} value={Competitions} setValue={setCompetitions}/>
                </div>
                <TextInput tooltip={TooltipTitle.CompetitionId} className={styles.field} type="text" label="ID" onWheelCapture={e => {e.currentTarget.blur()}} disabled value={competitionIdName} />
                </div>
                <div className={styles.group}>
                  <div className={styles.field}>
                    <CategoryDropdown placeholder='Select Category' label="Category" className={styles.selectField} setId={setCategoryID} setBgColor={setBgColor} bgColor={bgColor} options={CategoryOptions} value={CategoryValue} setValue={setCategoryValue}/>
                    </div>
                </div>
            </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Schedule" classTitle="title-blue">
                <div className={styles.group}>
                    {/* <div className={styles.fieldRadio}>
                        <div className={styles.radioLabel}>
                            <span>Schedule Type<span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionSchedule} icon="info"/></span></span>
                        </div>
                        <div className={styles.variants}>
                            <Radio className={styles.radio} name="frequency" value={!frequency} onChange={() => setFrequency(false)} content="All Time" />
                            <Radio className={styles.radio} name="frequency" value={frequency} onChange={() => setFrequency(true)} content="Time Bound" />
                        </div>
                        <Checkbox size='small' className={styles.checkbox} content={'Is Recurring'} value={isRecurring} onChange={() => {setIsRecurring(!isRecurring);setRecurring(''); setRecurringId(null); setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}}/>
                    </div> */}
                    <div className={styles.fieldRadio}>
                      <div className={styles.radioLabel}>
                        <span>{'Is Recurring'}</span>
                      </div>
                      <div className={styles.variants}>
                        <Radio className={styles.radio} name="recurring" value={isRecurring} onChange={() => {setIsRecurring(true)}} content="Yes" />
                        <Radio className={styles.radio} name="recurring" value={!isRecurring} onChange={() => {setIsRecurring(false)}} content="No" />
                      </div>
                      {/* <Checkbox size='small' className={styles.checkbox} content={scheduleType ? 'Set Start Date/Time' : 'Is Recurring'} value={isRecurring} onChange={() => {setIsRecurring(!isRecurring);setStartDate(''); setEndDate(''); setStartTime(''); setEndTime('')}}/> */}
                    </div>
                </div>


                {isRecurring &&
                  <div className={styles.group}>
                    <div className={styles.field}>
                      <Dropdown tooltip={TooltipTitle.LeaderboardRecurring} label='Recurring' placeholder='Choose Leaderboard Recurring' value={recurring} setValue={setRecurring} setId={setRecurringId} options={recurringOptions}/>
                    </div>
                    <TextInput className={styles.field} tooltip={TooltipTitle.LeaderboardRecurring} label='Recurrance Count' />
                  </div>
                }

                <>
                    <div className={styles.group}>
                      <div className={styles.field}>
                        <span className={styles.label}>Set Start Date and Start Time</span>
                      </div>
                      <div className={styles.field}>
                        <span className={styles.label}>Set End Date and End Time</span>
                      </div>
                    </div>

                    <div className={styles.group}>
                      
                      <Item disabled={isRecurring && recurringId == null ? true : false} className={styles.field} category="Start Date" icon="calendar" value={startDate && format(startDate, "MMMM dd, yyyy")} visible={visibleStartDate} setVisible={setVisibleStartDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={new Date()} selected={startDate} onChange={(date) => {setStartDate(date); setVisibleStartDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                      <Item disabled={(isRecurring && (recurringId || recurringId == null) && startDate == '') ? true : false} className={styles.field} category="End Date" icon="calendar" value={endDate && format(endDate, "MMMM dd, yyyy")} visible={visibleEndDate} setVisible={setVisibleEndDate}>
                        <div className={styles.date}>
                          <DatePicker minDate={startDate && recurringId == 1 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 2 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 3 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 4 ? new Date(moment(startDate).format('YYYY-MM-DD')) : new Date()} maxDate={startDate && recurringId == 1 ? new Date(moment(startDate).clone().add(0, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 2 ? new Date(moment(startDate).clone().add(6, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 3 ? new Date(moment(startDate).clone().add(30, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 4 ? new Date(moment(startDate).clone().add(365, 'days').format('YYYY-MM-DD')) : ''} selected={endDate} onChange={(date) => {setEndDate(date); setVisibleEndDate(false)}} dateFormatCalendar={"MMMM yyyy"} inline />
                        </div>
                      </Item>

                    </div>

                    <div className={styles.group}>
    
                      <Item disabled={isRecurring && recurringId == null ? true : false} className={styles.field} category="Start Time" icon="clock" value={startTime && format(startTime, "h:mm aa")} visible={visibleStartTime} setVisible={setVisibleStartTime}>
                        <div className={styles.date}>
                          <DatePicker selected={startTime} onChange={(time) => {setStartTime(time); setVisibleStartTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>

                      <Item disabled={(isRecurring && (recurringId || recurringId == null) && startDate == '') ? true : false} className={styles.field} category="End Time" icon="clock" value={endTime && format(endTime, "h:mm aa")} visible={visibleEndTime} setVisible={setVisibleEndTime}>
                        <div className={styles.date}>
                          <DatePicker selected={endTime} onChange={(time) => {setEndTime(time); setVisibleEndTime(false)}} showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={false} dateFormat="h:mm aa" inline />
                        </div>
                      </Item>
                      {isRecurring && startDate != '' &&
                        <Checkbox size='small' className={styles.checkbox} content='Set end time same as recurring interval' onChange={(e) => {onChangeEndDateToWeek(e)}}/>
                      }
                    </div>
                </>
                

                {/* {!frequency && isRecurring &&
                    <div className={styles.group}>
                        <TextInput label='Start Date' name='startDate' type='date' className={styles.field} onChange={(e)=>{onChangeStartDate(e)}}/>
                        <TextInput label='Start Time' name='startTime' type='time' className={styles.field} onChange={(e)=>{onChangeStartTime(e)}}/>
                        // <TextInput tooltip={TooltipTitle.CompetitionStartDate} label='Start Date/Time' min={currentDateTime} step={1} name='startDateTime' type='datetime-local' value={startDateTime} className={styles.field} onChange={(e)=>{onChangeStartDateTime(e)}}/>
                    </div>
                } */}

                {/* {isRecurring &&
                    <>
                        <div className={styles.group}>
                            <div className={styles.field}>
                                <Dropdown tooltip={TooltipTitle.CompetitionRecurring} label='Recurring' placeholder='Select Recurring Frequency' className={styles.selectField} value={recurring} setValue={setRecurring} setId={setRecurringId} options={recurringOptions}/>
                            </div>
                            <TextInput label='Recurrence Count' name='count' type='number' value={recurrence} onChange={(e) => {onChangeCount(e)}} className={styles.field} onWheelCapture={e => {e.currentTarget.blur()}}/>
                        </div>
                    </>
                } */}

                    {/* <div className={styles.group}>
                        <div className={styles.field}>
                          <div className={styles.label}>Start Date</div>
                          <DatePicker disabled={isRecurring && recurringId == null ? true : false} format='dd-MM-y' onChange={(e)=>{onChangeStartDate(e)}} value={startDate} />
                        </div>
                        <TextInput disabled={isRecurring && recurringId == null ? true : false} label='Start Time' name='startTime' type='time' className={styles.field} onChange={(e)=>{onChangeStartTime(e)}}/>
                    </div>
                    <div className={styles.group}>
                        <div className={styles.field}>
                          <div className={styles.label}>End Date</div>
                          <DatePicker minDate={startDate && recurringId == 1 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 2 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 3 ? new Date(moment(startDate).format('YYYY-MM-DD')) : startDate && recurringId == 4 ? new Date(moment(startDate).format('YYYY-MM-DD')) : ''} maxDate={startDate && recurringId == 1 ? new Date(moment(startDate).clone().add(0, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 2 ? new Date(moment(startDate).clone().add(6, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 3 ? new Date(moment(startDate).clone().add(30, 'days').format('YYYY-MM-DD')) : startDate && recurringId == 4 ? new Date(moment(startDate).clone().add(365, 'days').format('YYYY-MM-DD')) : ''} disabled={(isRecurring && (recurringId || recurringId == null) && startDate == '') ? true : false}  format='dd-MM-y' onChange={(e)=>{onChangeEndDate(e)}} value={endDate} />
                        </div>
                        <TextInput disabled={(isRecurring && (recurringId || recurringId == null) && startDate == '') ? true : false} label='End Time' name='endTime' type='time' className={styles.field} value={endTime} onChange={(e)=>{onChangeEndTime(e)}}/>
                    </div>
                    {isRecurring && startDate != '' &&
                        <div className={styles.check}>
                            <div className={styles.checkbox}></div>
                            <Checkbox size='small' className={styles.checkbox} content='Set end time same as recurring interval' onChange={(e) => {onChangeEndDateToWeek(e)}}/>
                        </div>
                    } */}
                 
                <div className={styles.pdGroup}>
                    <div className={styles.fieldRadio}>
                    <div className={styles.radioLabel}>
                        <span>Prize Distribution<span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionOffeset} icon="info"/></span></span>
                    </div>
                    <div className={styles.variants}>
                        <Radio className={styles.radio} name="PDOffset" value={offset} onChange={() => setOffset(true)} content="On End" />
                        <Radio className={styles.radio} name="PDOffset" value={!offset} onChange={() => setOffset(false)} content="Offset" />
                    </div>
                    </div>
                    {!offset && 
                    <>
                        <TextInput type='number' className={styles.fields} label='Hours' onChange={(e) => {onChangeHours(e)}} onWheelCapture={e => {e.currentTarget.blur()}}/>
                        <TextInput type='number' className={styles.fields} label='Minutes' onChange={(e) => {onChangeMinutes(e)}} onWheelCapture={e => {e.currentTarget.blur()}}/>
                    </>
                    }
                </div>
                
            </Card>
            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.CompetitionTags} tags={tags} setTags={setTags}/>


                    <div className={styles.appInformation}>
                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltips className={styles.tooltip} title={TooltipTitle.CompetitionMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }
                        
                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required  onChange={(e)=>{onChangeKey(e,i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><Remove className={styles.icon}/></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button disabled={disable} onClick={submit} className={styles.Save}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button onClick={cancel} className={styles.Cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default StoreInformation;
