import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
import JsonEditor from "../../../../../../components/EngageComponents/PlayersComponent/Modals/AceEditor/PD";

const JSONMetaDataValueInput = ({className, disabled, index, data, value, setValue, type, width}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className={cn(styles.control, className)} onClick={() => setVisibleModal(true)}>
        <div className={styles.code}>&#123; &#125;</div>
      </div>
      <Modal width={width} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <JsonEditor index={index} data={data} value={value} setValue={setValue} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </>
  );
};

export default JSONMetaDataValueInput;