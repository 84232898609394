import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import Tooltip from '../../../../CustomTooltips/ReusableTooltip'
import {ReactComponent as GoldMedal} from '../../../../../media/images/placeholders/Gold.svg'
import {ReactComponent as SilverMedal} from '../../../../../media/images/placeholders/Silver.svg'
import {ReactComponent as BronzeMedal} from '../../../../../media/images/placeholders/Bronze.svg'
import {ReactComponent as UserProfile} from '../../../../../media/images/icons/Player_Icon.svg'
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({item, value, onChange, usedFor}) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    if(usedFor === 'leaderboard'){
        return (
            <>
                <div className={styles.row}>
                    <div className={styles.col}>{item.ranks == 1 ? <GoldMedal /> : item.ranks == 2 ? <SilverMedal /> : item.ranks == 3 ? <BronzeMedal /> : item.ranks}</div>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.playerInsigne}>
                                {(item.user && item.user.thumbUrl != null) && <img src={(item.user && item.user.thumbUrl && item.user.thumbUrl != null) ? item.user.thumbUrl : ''} alt={`${item.userName}`}/> }
                                {(item.user && item.user.thumbUrl == null) && <UserProfile /> }
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.user && item.user != null && item.user.displayName != null) ? item.user.displayName : (item.user && item.user != null && item.user.username != null) ? item.user.username : (item.user && item.user != null && item.user.firstName != null) ? item.user.firstName : (item.user && item.user != null && item.user.customId != null) ? item.user.customId : (item.user && item.user != null && item.user.linkedAccounts != null && item.user.linkedAccounts.length > 0) ? item.user.linkedAccounts[0].userId : (item.user && item.user != null && item.user.email != null && item.user.email != '') ? item.user.email : '-'}</div>
                                <div className={styles.playerId}>{(item.user && item.user.id != null) ? item.user.id : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>{item.score}</div>
                </div>
            </>
        );
    }else if(usedFor === 'competition'){
        return(
            <>
                <div className={styles.row}>
                    <div className={styles.col}>{item.ranks == 1 ? <GoldMedal /> : item.ranks == 2 ? <SilverMedal /> : item.ranks == 3 ? <BronzeMedal /> : item.ranks}</div>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.playerInsigne}>
                                {(item.users && item.users.thumbUrl != null) && <img src={(item.users && item.users.thumbUrl && item.users.thumbUrl != null) ? item.users.thumbUrl : ''} alt={`${item.userName}`}/> }
                                {(item.users && item.users.thumbUrl == null) && <UserProfile /> }
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.users && item.users != null && item.users.displayName != null) ? item.users.displayName : (item.users && item.users != null && item.users.username != null) ? item.users.username : (item.users && item.users != null && item.users.firstName != null) ? item.users.firstName : (item.users && item.users != null && item.users.customId != null) ? item.users.customId : (item.users && item.users != null && item.users.linkedAccounts != null && item.users.linkedAccounts.length > 0) ? item.users.linkedAccounts[0].userId : (item.users && item.users != null && item.users.email != null && item.users.email != '') ? item.users.email : '-'}</div>
                                <div className={styles.playerId}>{(item.users && item.users.id != null) ? item.users.id : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>{item.score}</div>
                </div>
            </>
        )
    }else if(usedFor === 'instant battle'){
        return(
            <>
                {item.results && item.results.map((e, i) => {
                    if(e.users){
                        return(
                            <>
                                <div className={styles.row}>
                                    <div className={styles.col}>{e.rank == 1 ? <GoldMedal /> : e.rank == 2 ? <SilverMedal /> : e.rank == 3 ? <BronzeMedal /> : e.rank}</div>
                                    <div className={styles.col}>
                                        <div className={styles.player}>
                                            <div className={styles.playerInsigne}>
                                                {(e.users && e.users.thumbUrl != null) && <img src={(e.users && e.users.thumbUrl && e.users.thumbUrl != null) ? e.users.thumbUrl : ''} /> }
                                                {(e.users && e.users.thumbUrl == null) && <UserProfile /> }
                                            </div>
                                            <div className={styles.playerDetails}>
                                                <div className={styles.userName}>{(e.users && e.users != null && e.users.displayName != null) ? e.users.displayName : (e.users && e.users != null && e.users.username != null) ? e.users.username : (e.users && e.users != null && e.users.firstName != null) ? e.users.firstName : (e.users && e.users != null && e.users.customId != null) ? e.users.customId : (e.users && e.users != null && e.users.linkedAccounts != null && e.users.linkedAccounts.length > 0) ? e.users.linkedAccounts[0].userId : (e.users && e.users != null && e.users.email != null && e.users.email != '') ? e.users.email : '-'}</div>
                                                <div className={styles.playerId}>{(e.users && e.users.id != null) ? e.users.id : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.col}>{e.score}</div>
                                    <div className={styles.col}>
                                        {e.prizes && e.prizes != null ?
                                            <>
                                                {[...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers] && [...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers].slice(0,1).map((x,i) => {
                                                    return(
                                                        <>
                                                            <span className={styles.name}>{x.name}</span><span className={styles.quantity}>({x.amount})</span>
                                                            {([...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers] && [...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers].length > 1) ? 
                                                                <Tooltip type='Task' text='Rewards' data={[...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers]}><span className={styles.count}>+ {[...e.prizes.items, ...e.prizes.currencies, ...e.prizes.bundles, ...e.prizes.progressionMarkers].length - 1}</span></Tooltip>  
                                                            : ''}
                                                        </>
                                                    )
                                                })}
                                            </>
                                        : ''}
                                    </div>
                                </div>
                            </>
                        )
                    }
                })}
            </>
        )
    }else{
        return(
            <></>
        )
    }
};

export default Row;