import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({ items, title}) => {

  const [chooseAll, setСhooseAll] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Auth Provider</div>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Created At</div>
          <div className={styles.col}></div>
        </div>
        {items && items.map((x, index) => {
          return(
            <Row item={x} key={index} index={index}  />
          )
        })}
      </div>
    </div>
  );
};

export default Table;
