import {BASE_URL, GET_GAMES, ADD_GAMES, EDIT_GAMES} from '../../../resources/APIEndpoints'
import axios from '../../../utils/interceptor'

function gameServices() {
    this.getGames = (body) => axios.post(BASE_URL + GET_GAMES, body)
    this.addGames = (body) => axios.post(BASE_URL + ADD_GAMES, body)
    this.editGames = (body) => axios.post(BASE_URL + EDIT_GAMES, body)
    this.archiveGames = (body) => axios.post(BASE_URL + EDIT_GAMES, body)
}

export default new gameServices()