import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import ProgressionMarker from '../../../../../media/images/placeholders/Progression_Marker.png'
import moment from "moment";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>{item.transactionType == 'credit' ? <div className={styles.earned}>Earned</div> : item.transactionType == 'debit' ? <div className={styles.lost}>Lost</div> : ''}</div>
                <div className={styles.col}>{parseInt(item.amount)}</div>
                <div className={styles.col}><div className={styles.source}>{item.taskId != null ? 'Task' : item.taskGroupId != null ? 'Task Group' : item.levelSystemId != null ? `${item.levelSystem && item.levelSystem.name ? item.levelSystem.name : ''}` : item.leaderboardId !== null ? 'Leaderboard' : item.competitionId !== null ? 'Competition' : 'Custom'}</div></div>
                <div className={styles.col}><div className={styles.ids}>{(item.taskId != null && item.tasks != null) ? item.tasks.taskId : (item.taskGroupId != null && item.taskGroup != null) ? item.taskGroup.taskGroupId :  (item.levelSystemLevelId != null && item.levelSystemLevel != null) ? item.levelSystemLevel.levelName : (item.leaderboardId != null && item.leaderboard != null) ? item.leaderboard.leaderboardId :  (item.competitionId != null && item.competition != null) ? item.competition.competitionId : item.instanceId}</div></div>
                <div className={styles.col}>{item.status == 'processed' ? <div className={styles.success}>{item.status}</div> : item.status == 'created' ? <div className={styles.danger}>{item.status}</div> : item.status == 'in-progress' ? <div className={styles.warning}>{item.status}</div> : ''}</div>
                <div className={styles.col}>{item.remarks != null ? item.remarks : '-'}</div>
                <div className={styles.col}>{moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
            </div>
        </>
    );
};

export default Row;
