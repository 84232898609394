import React, { useState, useEffect } from 'react'
import styles from './tooltip.module.sass'
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

const Tooltip = ({className, data, children, setValue, action}) => {

    const [visible, setVisible] = useState(false);

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible})}>
                    <div className={styles.children} onClick={() => setVisible(!visible)}>{children}</div>
                        <div className={styles.tooltipContainer}>
                            {data && data.map((e, i) => {
                                return(
                                    <>
                                        <div className={styles.text} onClick={() => {action(e.name)}}>
                                            {e.name}    
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip