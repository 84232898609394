import axios from "../../../utils/interceptor";
import { BASE_URL, GET_COMPETITION, GET_COMPETITION_RESULT, CREATE_COMPETITION, EDIT_COMPETITION, DELETE_COMPETITION } from "../../../resources/APIEndpoints";

function CompetitionServices(){
    this.getCompetition = (body) => axios.post(BASE_URL + GET_COMPETITION, body)
    this.getCompetitionResult = (body) => axios.post(BASE_URL + GET_COMPETITION_RESULT, body)
    this.getCompetitionDetail = (body) => axios.post(BASE_URL + GET_COMPETITION, body)
    this.addCompetition = (body) => axios.post(BASE_URL + CREATE_COMPETITION, body)
    this.editCompetition = (body) => axios.post(BASE_URL + EDIT_COMPETITION, body)
    this.archiveCompetition = (body) => axios.post(BASE_URL + EDIT_COMPETITION, body)
    this.deleteCompetition = (body) => axios.post(BASE_URL + DELETE_COMPETITION, body)
}

export default new CompetitionServices();