import React, { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../Tooltip";
import Tooltips from "../Tooltip";
import { getCustomEventAction, getDefaultEventAction } from "../../redux/action/builds/events/eventActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const Dropdown = ({ className, disabled, setIsCalled, setEventPage, eventPage, eventLimit, limit, id, position, disable, currency, check, groupBy, error, customHandleFunction, customFunc, setSelectedZone, errorMessage, classLabel, classDropdownHead, classDropdownLabel, placeholder, value, setValue, setId, setType, setDisplayId, setField, options, label, small, upBody, tooltip, place }) => {

    const [visible, setVisible] = useState(false);
    const [filterText, setFilterText] = useState((Array.isArray(value) ? '' : (value||'')));
    const containerRef = useRef();
    const dispatch = useDispatch();
    const { appid } = useParams();

    const handleClick = (values) => {
        if (customHandleFunction || customFunc) {
            const abc = {target: {value: values.value}};
            customHandleFunction && customHandleFunction(abc);
            setFilterText((Array.isArray(value) ? '' : (value||'')))
            setSelectedZone(values.value);
            setVisible(false);
        } else {
            setValue(values.name);
            setId(values.id);
            if (check) {
                check(values.id);
            }
            if (error) {
                error(false);
            }
            if (setDisplayId) {
                if (values.type === 'custom') {
                    setDisplayId(values.eventId);
                } else {
                    setDisplayId(values.id);
                }
            }
            if (setField) {
                let field = [...values.customParameterDetails, ...values.defaultParameterDetails];
                if (values.specterParameterDetails) {
                    field = [...field, ...values.specterParameterDetails];
                }
                if (values.optionalParameterDetails) {
                    field = [...field, ...values.optionalParameterDetails];
                }
                const fieldOptions = field && field.map(e => ({
                    id: e.id,
                    name: e.name,
                    inputType: e.dataType.dataTypeName,
                    type: e.type
                }));
                setField(fieldOptions);
            }
            if (setType) {
                setType(values.type);
            }
            setVisible(false);
            setFilterText((Array.isArray(value) ? '' : (value||'')));
        }
    };

    const anotherAPICall = () => {
        // const container = containerRef.current
        // const remainingScroll = container.scrollHeight - container.clientHeight - container.scrollTop;
        // const threshold = 1;
        // if (remainingScroll <= threshold) {
        //     if(eventPage <= Math.ceil(limit/20)){
        //         setEventPage(eventPage+1)
        //         getAllEvents(eventPage)
        //     }
        // }
    };

    const getAllEvents = async (page) => {
        // let getEvents = {
        //     projectId : appid,
        //     offset: ((page)*eventLimit),
        //     limit: 100
        // }
        // Promise.all([await dispatch(getCustomEventAction(getEvents)), await dispatch(getDefaultEventAction(getEvents))]).then(res =>{
        //     setIsCalled(true)
        // })
    };
    
    useEffect(()=>{
        setFilterText(Array.isArray(value) ? '' : (value||''))
    },[value])

    const filteredOptions = options&&options.filter(option =>
        (filterText && filterText !== '' && filterText !== null) ? option?.name?.toLowerCase()?.includes(filterText?.toLowerCase()) : options
    );

    const inputStyle = {
        margin:"4px",
        background: "#FFFFFF",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div id={id} className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText((Array.isArray(value) ? '' : (value||''))); }}>
                {label && (
                    <div className={styles.groupLabel}>
                        <div className={cn(styles.label, classDropdownLabel)}>
                            {label}
                            {tooltip && (
                                <Tooltips
                                    className={styles.tooltip}
                                    title={tooltip}
                                    icon="info"
                                    place="right"
                                />
                            )}
                        </div>
                        <div className={styles.error}>
                            {(value == null || value == undefined || value == '' || (Array.isArray(value) && value.length === 0)) && errorMessage}
                        </div>
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={(errorMessage && (value == null || value == undefined || value == '' || (Array.isArray(value) && value.length === 0))) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)} style={customHandleFunction && { height: "40px",}}>
                        <div className={styles.selection} style={{display:"flex"}}>
                        {currency && <div className={styles.icon} style={{marginRight:"8px"}}>{currency}</div>}
                            <input
                                type="text"
                                style={customHandleFunction||small ? { ...inputStyle,height: "36px",margin:"2px",verticalAlign:"top" }:inputStyle}
                                placeholder={placeholder}
                                value={filterText}
                                onChange={(e) => { setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div ref={containerRef} onScroll={anotherAPICall} className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions&&filteredOptions.map((x, index) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={index}>
                                    {x.name != null ? x.name : x.levelName}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
            {position === 'bottom' &&
                <div className={styles.error}>
                    {errorMessage}
                </div>
            }
        </div>
    );
}

export default Dropdown;
