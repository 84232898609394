import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./getStepSeries.module.sass";
import Form from '../../../../../components/Form';
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import addIcon from '../../../../../media/images/icons/light.png'
import StepSeriesTableLayout from '../../../../../components/EngageComponents/AchievementComponent/StepSeriesComponent/TableComponent'
import EmptyState from "../../../../../components/EmptyStates";
import Loader from "../../../../../components/Loader";
import { filterTaskGroupAction, getAllStepSeriesAction } from "../../../../../redux/action/engage/Achievement/StepSeries/StepSeriesAction";
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from "@mui/material";
import SkeletonTable from "../../../../../components/Skeleton/TableSkeleton";
import SkeletonEmptyState from "../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../components/Context/GlobalStates/GlobalStateContext";
import { ToastContainer } from "react-toastify";
import Calendar from "../../../LiveOps/Calendar";
import Control from "./Filters/Control";
import moment from "moment";

const sorting = ["list", "grid"];

const GetMission = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
  
    const [activeIndex, setActiveIndex] = useState(0);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(8)
    const [activeViewIndex, setActiveViewIndex] = React.useState(0);
    const modes = [{value:"list",icon:"list"}, {value:"calendar",icon:"calendar"}];
    // Filters
    const [recurrance, setRecurrance] = useState(null)
    const [recurranceInterval, setRecurranceInterval] = useState(null)
    const [recurranceIntervalId, setRecurranceIntervalId] = useState(null)
    const [frequency, setFrequency] = useState(null)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState([])
    const [instanceStatus, setInstanceStatus] = useState([])
    const filters = {frequency, setFrequency, startDate, setStartDate, endDate, setEndDate, status, setStatus, instanceStatus, setInstanceStatus, recurrance, setRecurrance, recurranceInterval, setRecurranceInterval, recurranceIntervalId, setRecurranceIntervalId}
    // Search
    const [search, setSearch] = useState(null)
    // Sort
    const sortList = [{id: 1, name: 'Mission Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
    const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
    const [selectedSort, setSelectedSort] = useState('updated_at')
    const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
    const [sortIndex, setSortIndex] = useState(0)
    // User Access
    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])


    const getMission = async(search) => {
      let mission = {
        typeId : 1,
        projectId : appid,
        ids : [],
        // Sort
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // Filters
        ...((recurrance !== null && recurrance !== '') && {recurrenceFrequency: recurrance}),
        ...((recurranceIntervalId !== null && recurrance !== '') && {intervalUnitId: recurranceIntervalId}),
        ...(frequency !== null && {isRecurring  : frequency}),
        ...(startDate !== '' && {startDate : moment(startDate).format('YYYY-MM-DD')}),
        ...(endDate !== '' && {endDate : moment(endDate).format('YYYY-MM-DD')}),
        ...(status?.length > 0 && {status : status}),
        ...(instanceStatus?.length > 0 && {instanceStatus : instanceStatus}),
        offset: ((page-1)*limit),
        limit: limit
      }
      let keysToCheck = ['status', 'instanceStatus']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      let isRecurring = await (filters.hasOwnProperty('frequency') && filters.frequency === null) && (filters.hasOwnProperty('startDate') && filters.startDate === '') && (filters.hasOwnProperty('endDate') && filters.endDate === '') && (filters.hasOwnProperty('recurrance') && filters.recurrance === null) && (filters.hasOwnProperty('recurranceIntervalId') && filters.recurranceIntervalId === null) 
      if(!search && isFiltered && isRecurring){
        await dispatch(getAllStepSeriesAction(mission, configureSkeleton))
      }else{
        await dispatch(filterTaskGroupAction(mission, configureSkeleton, (!isFiltered || !isRecurring)))
      }
    }

    let stepSeries = useSelector((state) => {
      return state.stepSeries.stepSeries
    })
  
    let isFiltered = useSelector((state) => {
      return state.stepSeries.isFiltered
    })

    let isLoading = useSelector((state) => {
      return state.stepSeries.isLoading
    })

    let totalMission = useSelector((state) => {
      return state.stepSeries.totalCount
    })

    useEffect(()=>{
      sessionStorage.removeItem('step-series')
      sessionStorage.removeItem('contents')
      sessionStorage.removeItem('task-rewards')

    }, [])

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const [showSkeleton, setShowSkeleton] = useState(true)

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }

    useEffect(() => {
      if(selectedSort !== null){
        getMission(search)
      }
    }, [page, selectedSort])

    const onSearch = (search) => {
      if(search == ''){
        setSearch(null)
        getMission()
      }else{
        setSearch(search)
        getMission(search)
      }
    }

    return(
      <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Missions'} classTitle={cn("title-purple", styles.title)}
          head={
            (
            <>
             {showSkeleton && stepSeries.length > 0 ?
                <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={72} height={32}/>
              : !showSkeleton && stepSeries.length > 0 &&
                <>
                <div className={styles.toggle}>
                    {modes && modes.map((x, index) => (
                        <button style={{height:"32px",width:"32px"}} className={cn(styles.link, { [styles.active]: index === activeViewIndex,})} onClick={() => setActiveViewIndex(index)} key={index}>
                          <Icon name={x.icon} size='20'/>
                        </button>
                    ))}
                </div>
                </>}
                <div className={styles.add}>
                  {showSkeleton && (stepSeries?.length > 0 || (stepSeries?.length === 0 && isFiltered)) ?
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                  : !showSkeleton && (stepSeries?.length > 0 || (stepSeries?.length === 0 && isFiltered)) ?
                    <>
                    {!userAccess &&
                      <div className={styles.createButton}>
                        <Link to={`/create-mission/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                      </div>
                    }
                    </>
                  : ''}
                </div>
              </>
            )
          }
        >

        {activeViewIndex === 0 &&
          <div className={styles.wrapper}>
            <div className={styles.seprator}></div>
              <div className={styles.options}>
              {showSkeleton && stepSeries.length > 0 &&
                <>
                  <div className={styles.filters}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                  </div>
                </>
              }
                    
              {!showSkeleton &&
                (stepSeries?.length > 0 || (stepSeries?.length === 0 && isFiltered) ?
                  <>
                    <div className={styles.filters}>
                      <Control type='Mission' setPage={setPage} filters={filters} action={getMission} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                    </div>
                    <div className={styles.searchForm}>
                      <Form className={styles.form} placeholder="Search Mission" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                    </div>
                  </>
                : '')
              } 
              </div>
              {/* Mission Table View */}
              {showSkeleton && stepSeries?.length === 0 && 
                <SkeletonEmptyState />
              }
              {showSkeleton && stepSeries?.length > 0 && 
                <SkeletonTable rows={stepSeries.length} column={5} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[44, 100, 100, 100, 40]} colHeight={[44, 25, 25, 25, 40]} />
              }
              {!showSkeleton && !isFiltered && stepSeries?.length === 0 &&
                <EmptyState access={userAccess} route={`/create-mission/${appid}`} type="mission" />
              }
              {!showSkeleton && !isFiltered && stepSeries?.length > 0 &&
                <StepSeriesTableLayout access={userAccess} usedFor='Mission' to='mission' type='table' items={stepSeries} title="Last edited" />
              }
              {!showSkeleton && isFiltered &&
                (stepSeries?.length > 0 ?
                  <StepSeriesTableLayout access={userAccess} usedFor='Mission' to='mission' type='table' items={stepSeries} title="Last edited" />
                :
                  <div className={styles.message}>No Result Found</div>
                )
              }

              {!isLoading && stepSeries.length > 0 &&
                <div className={styles.pagination}>
                  <Stack spacing={2}>
                    <Pagination page={page} renderItem={(item) => (
                      <PaginationItem
                      {...item}
                      sx={{
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(totalMission/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
          </div>
        }
        {activeViewIndex === 1 && <div><Calendar/></div>}
        </Card>
        <div className="divider"></div>
        <div className={styles.msg}>
          <p className={styles.alert}>Learn more about Mission <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/achievements/missions" target="_blank">Click Here</a></p>
        </div>
      </>
    )

    
};

export default GetMission;
