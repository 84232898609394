import React, {useState, useEffect} from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './tasks.module.sass'
import TasksTableLayout from '../../../../../components/EngageComponents/PlayersComponent/PlayerTasks'
import { useParams } from 'react-router-dom';
import { getPlayerDetailAction } from '../../../../../redux/action/engage/Players/PlayersAction';
import Dropdown from '../../../../../components/Dropdown'
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

const Task = ({activeIndex}) => {

  const {appid} = useParams()
  const {id} = useParams()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const getPlayerDetails = async() => {
      let playerDetail = {
          entities : {
              value : 'task-bus',
              offset: ((page-1)*limit),
              limit: limit
          },
          projectId : appid,
          ids : [id]
      }
      await dispatch(getPlayerDetailAction(playerDetail, activeIndex))
  }

  const onPrevious = () => {
    setPage(prevPage => prevPage - 1)
  }

  const onNext = () => {
    setPage(nextPage => nextPage + 1)
  }

  useEffect(()=>{
    getPlayerDetails()
  }, [activeIndex, page])

  const playerTasks = useSelector((state) => {
    return state.players.tasks
  })

  let totalCount = useSelector((state) => {
    return state.players.totalTaskCount
  })

  const handlePageChange = (event, value)  => {
    setPage(value)
  }


  return (
    <>
        <Card title="Achievements History" classTitle="title-purple">
          <div className={styles.overview}>
            <div className={styles.groups}>
              {playerTasks && playerTasks.length > 0 ?
                <>
                  <TasksTableLayout items={playerTasks} />
                  <div className={styles.pagination}>
                      <Stack spacing={2}>
                      <Pagination renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                              '&.Mui-selected': {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              },
                              '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                color: 'rgb(255, 255, 255)',
                                fontWeight : 'bold',
                                border : '1px solid rgb(42, 133, 255)',
                                background : 'rgba(42, 133, 255)'
                              }
                            }}
                        />
                      )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
                    </Stack>
                  </div>
                </>  
              : <div className={styles.warning}>No Data Available</div>}
            </div>
          </div>
        </Card>
    </>
  )
}

export default Task