import axios from "../../../utils/interceptor";
import { BASE_URL, GET_LEADERBOARD, ADD_LEADERBOARD, EDIT_LEADERBOARD, GET_LEADERBOARD_RESULT, DELETE_LEADERBOARD} from "../../../resources/APIEndpoints";

function PlayerServices(){
    this.getLeaderboard = (body) => axios.post(BASE_URL + GET_LEADERBOARD, body)
    this.getLeaderboardResult = (body) => axios.post(BASE_URL + GET_LEADERBOARD_RESULT, body)
    this.createLeaderboard = (body) => axios.post(BASE_URL + ADD_LEADERBOARD, body)
    this.editLeaderboard = (body) => axios.post(BASE_URL + EDIT_LEADERBOARD, body)
    this.archiveLeaderboard = (body) => axios.post(BASE_URL + EDIT_LEADERBOARD, body)
    this.deleteLeaderboard = (body) => axios.post(BASE_URL + DELETE_LEADERBOARD, body)
}

export default new PlayerServices();