import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import { useParams } from "react-router-dom";
import App from '../../../../../media/images/placeholders/App.png'
import MemberPermissionDropdown from "../../MemberPermissionDropdown";
/* Deleted Schedule, Modal, Modal Products and Actions */
import UserProfile from '../../../../../media/images/icons/Player_Icon.svg'
import Tooltip from "../../../../CustomTooltips/ReusableTooltip";

const Row = ({type, item, value, onChange, to, apps, setApps, userType }) => {

    // const {appid} = useParams()
    // const [permissionOption, setPermissionOption] = useState([{id : 1, name : 'View Only'},{id : 2, name : 'Can Edit'}])
    // const [permissionId, setPermissionId] = useState(appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid)).permissionId === 1) ? 1 : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid)).permissionId === 2) ? 2  : null)
    // const [permission, setPermission] = useState(appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid)).permissionId === 1) ? 'View Only' : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid)).permissionId === 2) ? 'Can Edit' : '')

    return (
        <>
            {to == 'accepted' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.memberIcon}>
                                <img src={(item.memberDetails != null && item.memberDetails.profile_url != null) ? item.memberDetails.profile_url : UserProfile} alt={item.name}/>
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.name != null) ? item.name : '-'}</div>
                                <div className={styles.playerId}>{(item.email != null) ? item.email : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : <div className={styles.price}>InActive</div> }</div>
                    <div className={styles.col}>
                        <div className={styles.appIcon}>
                            {item.permissions && item.permissions.slice(0,5).map((e,i) => (
                                <>
                                    <Tooltip  text={e.projectName}>
                                    {(e.projectId != null && e.iconUrl != null) ? <img src={e.iconUrl} width={32} height={32} alt={e.name}/> : (e.projectId != null && e.iconUrl == null) ? <img src={App} width={32} height={32} alt={e.name}/>  : ''}

                                    </Tooltip>
                                </>
                            ))}
                            {(item.permissions && item.permissions.length > 5) ? <Tooltip type='' text='Apps' data={item.permissions.map((e)=>{return {name:e.projectName}})}><span className={styles.count}>+ {item.permissions.length - 5}</span></Tooltip>  
                            : ''}
                        </div>
                    </div>
                    <div className={styles.col}>
                        {userType ==='admin' &&
                            <Control memberAction='edit' to={to} className={styles.control} id={(item && item.id) ? item.id : null} apps={apps} setApps={setApps} permissions={item.permissions}/>
                        }
                    </div>
                </div>
            }
            {to == 'invited' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.memberIcon}>
                                <img src={UserProfile} alt={item.name}/>
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{item.name ? item.name : '-'}</div>
                                <div className={styles.playerId}>{item.email ? item.email : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : <div className={styles.price}>InActive</div> }</div>
                    <div className={styles.col}>
                        <div className={styles.appIcon}>
                            {item.permissions && item.permissions.slice(0,5).map((e,i) => (
                                <>
                                    <Tooltip  text={e.projectName}>
                                    {(e.projectId != null && e.iconUrl != null) ? <img src={e.iconUrl} width={32} height={32} alt={e.name}/> : (e.projectId != null && e.iconUrl == null) ? <img src={App} width={32} height={32} alt={e.name}/>  : ''}

                                    </Tooltip>
                                </>
                            ))}
                            {(item.permissions && item.permissions.length > 5) ? <Tooltip type='' text='Apps' data={item.permissions.map((e)=>{return {name:e.projectName}})}><span className={styles.count}>+ {item.permissions.length - 5}</span></Tooltip>  
                            : ''}
                        </div>
                    </div>
                    <div className={styles.col}>
                    {userType ==='admin' &&
                        <Control memberAction='edit' to={to} className={styles.control} id={(item && item.id != null && item.id != undefined) ? item.id : null} apps={apps} setApps={setApps} permissions={item.permissions}/>
                    }
                    </div>
                </div>
            }
        </>
    );
};

export default Row;
