import React, { useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import ProgressionSystem from '../../../../../../media/images/placeholders/Progression_System.png'
import TooltipGlodal from "../../../../../TooltipGlodal";
import cn from "classnames";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, className, access, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.iconUrl !== undefined ?
                            <div className={cn(styles.preview, className, {[styles.bg] : true})}>
                                <img src={item.iconUrl != null ? item.iconUrl : ProgressionSystem} alt="PS" />
                            </div>
                            :
                            <div className={styles.preview}></div>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div>{item.levelSystemId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-progression-system/${appid}/${item.id}`}>
                        <TooltipGlodal width={200} itemId={`PS${item.id}`} title={item.name}><div className={styles.itemName}><span id={`PS${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.levelSystemId}</div>
                    </Link>
                </div>
                
                <div className={styles.col}><div className={item.archive === false ? styles.price : item.archive === true ? styles.archive : null}>{item.archive === false ? 'Active' : item.archive === true ? 'Archive' : null}</div></div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} id={item.id} appid={appid} status={item.archive} name={item.progressionMarkerName} type='ps' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
