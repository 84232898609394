import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./InstanceDropdown.module.sass"
import Icon from "../../../../../../components/Icon";
import moment from "moment";

const InstanceDropdown = ({className, disabled, classLabel, classDropdownHead, classDropdownLabel, value, setValue, options, label, small, upBody}) => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value)
        setVisible(false)
    };

    return(
        <>
            <div className={styles.calendarDropdown}>
                <OutsideClickHandler onOutsideClick={() => {setVisible(false)}}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                        {label}{" "}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible},{[styles.disabled] : disabled})}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => {setVisible(!visible)}}>
                        <div className={styles.valueContainer}>
                            <div className={styles.selection}>{value && value !== null ? value.name : ''}</div>
                        </div>
                    </div>
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options && options.map((x, index) => (
                            <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                                {x.name}
                            </div>
                        ))}
                    </div>
                </div>
                </OutsideClickHandler>
            </div>
        </>
    )
}

export default InstanceDropdown