import {combineReducers} from 'redux'
import { commonReducer } from '../commonReducer/commonReducer'
import { authReducer } from '../authReducer/authenticationReducer'
import { currencyReducer } from '../buildReducers/economyReducer/currencyReducer/currencyReducer'
import { itemReducer } from '../buildReducers/economyReducer/itemReducer/itemReducer';
import { bundleReducer } from '../buildReducers/economyReducer/bundleReducer/bundleReducer';
import { progressionMarkerReducer } from '../buildReducers/progressionReducer/progressionMarkerReducer/progressionMarkerReducer';
import { progressionSystemReducer } from '../buildReducers/progressionReducer/progressionSystemReducer/progressionSystemReducer'
import { appReducer } from '../appReducer/appReducer';
import { eventReducer } from '../buildReducers/eventReducer/eventReduces';
import { taskReducer } from '../engageReducer/achievementReducer/taskReducer/taskReducer'
import { stepSeriesReducer } from '../engageReducer/achievementReducer/stepSeriesReducer/stepSeriesReducer'
import { PlayerReducer } from '../engageReducer/playerReducer/playerReducer';
import { gameReducer } from '../buildReducers/gamesReducer/gamesReducer'
import { matchReducer } from '../buildReducers/matchesReducer/matchReducer';
import { leaderboardReducer } from '../engageReducer/leaderboardReducer/leaderboardReducer';
import { competitionReducer } from '../engageReducer/competitionReducer/competitionReducer';
import { storeReducer } from "../buildReducers/economyReducer/storeReducer/storeReducer"
import { memberReducer } from "../appSettings/MemberReducer/memberReducer"
import { liveOpsReducer } from "../engageReducer/liveOpsReducer/liveOpsReducer"
import { mediaReducer } from '../appSettings/MediaReducer/mediaReducer'
import { profileReducer } from '../organisationReducer/profileReducer/profileReducer';
import { documentReducer } from '../appSettings/DocumentReducer/documentReducer';

export default combineReducers(
    {
        common : commonReducer,
        auth : authReducer,
        currencies : currencyReducer,
        item : itemReducer,
        progressionMarkers : progressionMarkerReducer,
        progressionSystem : progressionSystemReducer,
        apps : appReducer,
        bundles : bundleReducer,
        event : eventReducer,
        task : taskReducer,
        stepSeries : stepSeriesReducer,
        players : PlayerReducer,
        games : gameReducer,
        matches : matchReducer,
        leaderBoards : leaderboardReducer,
        competition : competitionReducer,
        stores : storeReducer,
        liveOps : liveOpsReducer,
        members : memberReducer,
        media: mediaReducer,
        organisation : profileReducer,
        document: documentReducer
    }
);