import React, { useEffect, useState } from 'react'
import Card from '../../../../../components/Card'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../../components/Radio'
import styles from './profile.module.sass'
import LinkAccountTable from '../../../../../components/EngageComponents/PlayersComponent/LinkAccountTable'
import moment from 'moment'
import Icon from '../../../../../components/Icon'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { editPlayerProfileAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import {ReactComponent as AddMetaIcon} from '../../../../../media/images/icons/add_meta.svg'
import Modal from '../../../../../components/Modals/ModalComponent'
import CreatePlayer from '../../../../../components/EngageComponents/PlayersComponent/Modals/CreatePlayer'

const Profile = ({data, access, activeIndex, setIsChanged}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()

    const[editForm, setEditForm] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [userName, setUserName] = useState(null)
    const [isVerified, setIsVerified] = useState(null)

    const configureProfileData = () => {
        if(data && data.length > 0){
            setFirstName(data[0].firstName != null ? data[0].firstName : '')
            setLastName(data[0].lastName != null ? data[0].lastName : '')
            setDisplayName(data[0].displayName != null ? data[0].displayName : '')
            setUserName(data[0].username != null ? data[0].username : null)
            setIsVerified(data[0].isKycComplete != null ? data[0].isKycComplete : false)
        }
    }

    useEffect(() => {
        configureProfileData()
    }, [data])

    const saveProfile = () => {
        let profileData = {
            projectId : appid,
            userId : id,
            firstName : firstName,
            lastName : lastName,
            isKycComplete : isVerified,
            displayName : displayName,
            username : userName
        }
        dispatch(editPlayerProfileAction(profileData, appid, id, setEditForm, activeIndex))
        setIsChanged(false)
    }



    const setchange = () => {
         
        if (data[0] && ((firstName==data[0].firstName)||((firstName==''||firstName==null)&&(data[0].firstName==null||data[0].firstName=='')))&&((lastName==data[0].lastName)||((lastName==''||lastName==null)&&(data[0].lastName==null||data[0].lastName=='')))&&     ((displayName==data[0].displayName)||((displayName==''||displayName==null)&&(data[0].displayName==null||data[0].displayName=='')))&&
        ((userName==data[0].username)||((userName==''||userName==null)&&(data[0].username==null||data[0].username==''))) &&(isVerified==(data[0].isKycComplete != null ? data[0].isKycComplete : false))){
            setIsChanged(false)
        }
        else{
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
    },[firstName, lastName, displayName, userName, isVerified])

    const onChangeUserName = (e) => {
        if(e===''){
            setUserName(null)
        }else{
            setUserName(e)
        }
    }
    
    return (
        <>
            <Card title="Overview" classTitle="title-purple" head={(!access && !editForm) && <div className={styles.icon}><span className={styles.edit} onClick={()=>{setEditForm(!editForm)}}><Icon name='edit' size='24' /></span></div>}>
                <div className={styles.overview}>
                    <div className={styles.group}>
                        <TextInput disabled={editForm ? false : true} value={data.length > 0 && (displayName != null && displayName != '') ? displayName : ''} className={styles.field} label='Display Name' name="displayName" type="text" onChange={(e)=>{setDisplayName(e.target.value)}}/>
                        <TextInput disabled={editForm ? false : true} value={data.length > 0 && (userName != null && userName != '') ? userName : ''} className={styles.field} label='Username' name='userName' type='text' onChange={(e)=>{onChangeUserName(e.target.value)}}/>
                    </div>

                    <div className={styles.group}>
                        <TextInput disabled={editForm ? false : true} value={data.length > 0 && (firstName != null && firstName != '') ? firstName : ''} className={styles.field} label='First Name' name="firstName" type="text" onChange={(e)=>{setFirstName(e.target.value)}}/>
                        <TextInput disabled={editForm ? false : true} value={data.length > 0 && (lastName != null && lastName != '') ? lastName : ''} className={styles.field} label='Last Name' name='lastName' type='text' onChange={(e)=>{setLastName(e.target.value)}}/>
                    </div>

                    <div className={styles.group}>
                        <TextInput currency={<Icon name='calendar' size='20'/>} disabled value={data.length > 0 && data[0].createdAt != null ? moment(data[0].createdAt).format('DD-MM-YYYY hh:mm A') : '-'} className={styles.field} label='Date Joined' name='dateJoined' type='text'/>
                        <TextInput currency={<Icon name='clock' size='20'/>} disabled value={data.length > 0 && data[0].updatedAt != null ? moment(data[0].updatedAt).format('DD-MM-YYYY hh:mm A') : '-'} className={styles.field} label='Last Login' name='lastLogin' type='text' />
                    </div>

                    <div className={styles.group}>
                        <TextInput disabled value='India' className={styles.field} label='Player Location' name='playerLocation' type='text' />
                        <div className={styles.fieldRadio}>
                            <div className={styles.radioLabel}>
                                <span>Verified</span>
                            </div>
                            <div className={styles.variants}>
                                <Radio disabled={editForm ? false : true} status={editForm ? false : true} value={isVerified} onChange={()=>{setIsVerified(true)}} className={styles.radio} name="verify" content="Yes" />
                                <Radio disabled={editForm ? false : true} status={editForm ? false : true} value={!isVerified} onChange={()=>{setIsVerified(false)}}  className={styles.radio} name="verify" content="No" />
                            </div>
                        </div>
                    </div>
                    {editForm &&
                        <div className={styles.group}>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button className={styles.Save} onClick={saveProfile}>Save</button>
                                </div>
                                <div className={styles.cardCancel}>
                                    <button className={styles.Cancel} onClick={()=>{setEditForm(false); setIsChanged(false)}}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>
            <Card title="Linked Account" classTitle="title-purple">
                {/* {(data && data.length > 0 && data[0].linkedAccounts && data[0].linkedAccounts.length == 0) && */}
                    <div className={styles.addAccount}>
                        <span className={styles.add}>Add Account {!access && <span onClick={() => setVisibleModal(true)}><AddMetaIcon className={styles.addIcon} /></span>}</span>
                    </div>
                {/* } */}
                <div className={styles.group}>
                    <LinkAccountTable items={data && data.length > 0 && data[0].linkedAccounts  ? data[0].linkedAccounts : []}/>
                </div>
            </Card>
            <Modal  visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <CreatePlayer action={`Add Custom Account`} onClose={() => setVisibleModal(false)}/>
            </Modal>
        </>
    )
}

export default Profile