import react from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './activity.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import {ReactComponent as Game} from '../../../media/images/icons/game.svg'
import {ReactComponent as Key} from '../../../media/images/icons/key.svg'
import {ReactComponent as Design} from '../../../media/images/icons/design.svg'
import {ReactComponent as Edit} from '../../../media/images/icons/edit.svg'
import Icon from '../../../components/Icon'

const Activity = ({className, data}) => {

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Activity" classTitle="title-blue">
                    <div className={styles.progressionContainer}>
                        <div className={styles.progression}>
                            {/* {data && data.length == 0 && */}
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}>
                                        {/* <EmptyFolder /> */}
                                        <Icon name='clock' size='48' />
                                    </div>
                                    <div className={styles.emptyTitle}><span>coming soon!</span></div>
                                </div>
                            {/* } */}

                            {/* {data && data.length > 0 &&
                                <div className={styles.activityFeedsContainer}>
                                    {data && data.slice(0,10).map((e,i) => {
                                        return(
                                            <>
                                                <div className={styles.activityFeed}>
                                                    <div className={styles.activityFeedIcon}><span className={styles.icon}>{(e.details && e.details != null && e.details.action != null && e.details.action != '' && (e.details.action == 'created' || e.details.action == 'updated' || e.details.action == 'removed') && e.type != null && e.type != '' &&  e.type == 'App') ? <Game className={styles.icons} /> : (e.details && e.details != null && e.details.action != null && e.details.action != '' && (e.details.action == 'created' || e.details.action == 'removed') && e.type != null && e.type != '' &&  e.type == 'Item') ? <Design className={styles.icons}/> : (e.details && e.details != null && e.details.action != null && e.details.action != '' && e.type != null && e.type != '' && e.type == 'User') ? <Key className={styles.icons} /> : (e.details && e.details != null && e.details.action != null && e.details.action != '' && e.type == 'Item' && e.details.action == 'updated') ? <Edit className={styles.icons} /> : ''}</span></div>
                                                    <div className={styles.activityFeedContent}>
                                                        <span className={styles.type}>{(e.type && e.type != null && e.type != '') ? e.type : ' '} &nbsp;</span>
                                                        <span className={styles.identifier}>{(e.details && e.details != null && e.details.identifier != null && e.details.identifier != '') ? e.details.identifier : ''} &nbsp;</span><span>was &nbsp;</span>
                                                        <span className={styles.action}>{(e.details && e.details != null && e.details.action != null && e.details.action != '') ? e.details.action : ''}</span>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            } */}

                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Activity