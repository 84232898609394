import React, { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useNavigate, useParams } from "react-router-dom";
import cn from 'classnames';
import styles from "./dropdown.module.sass";
import Tooltip from "../../Tooltip";
import { ReactComponent as AddIconSvg } from "../../../media/images/icons/radio button/element/checked.svg";
import ConfirmModal from "../../../components/Modals/Build/Economy/Currency/ConfirmModal";
import Modal from "../../../components/Modals/ModalComponent";

const Dropdown = ({ className, disabled, page, limit, setPage, id, groupBy, errorMessage, classLabel, classDropdownHead, type, classDropdownLabel, placeholder, value, setValue, setId, setOtherId, setObj, setType, setDisplayId, setField, options, label, small, upBody, tooltip, place }) => {

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [filterText, setFilterText] = useState('');


    const navigate = useNavigate();
    const { appid } = useParams();

    const containerRef = useRef();

    const handleClick = (values) => {
        setValue(values.name);
        setId(values.id);
        setOtherId(values.gameId);
        setVisible(false);
        setFilterText('')
        if(setObj){
            setObj(values)
        }
    };

    function titleCase(str) {
        if (str) {
            var splitStr = str.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }

    const ModalClose = () => {
        if (type === "match") {
            window.scrollTo(0, 0);
            setTimeout(() => {
                navigate(`/create-match/${appid}`);
            }, 100);
        }
    };

    const anotherAPICall = () => {
        const container = containerRef.current;
        const remainingScroll = container.scrollHeight - container.clientHeight - container.scrollTop;
        const threshold = 1;
        if (remainingScroll <= threshold) {
            if (page <= Math.ceil(limit / 10)) {
                setPage(page + 1);
            }
        }
    };

    const filteredOptions = options&&options.filter(option =>
        option.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const inputStyle = {
        background: "#FCFCFC",
        color: "#6F767E",
        border: "none",
        height: "40px",
        fontSize: "inherit",
        fontWeight: "inherit",
        width: "100%",
    };

    return (
        <div id={id} className={groupBy == 1 ? styles.groupDropdown : groupBy == 2 ? styles.groupByTwoDropdown : groupBy == 3 ? styles.groupByThreeDropdown : groupBy == 4 ? styles.groupByFourDropdown : 'dropdown'}>
            <OutsideClickHandler onOutsideClick={() => { setVisible(false); setFilterText(value || ''); }}>
                {label && (
                    <div className={styles.groupLabel}>
                        <div className={cn(styles.label, classDropdownLabel)}>
                            {label}
                            {tooltip && (
                                <Tooltip className={styles.tooltip} title={tooltip} icon="info" place={place ? place : "right"} />
                            )}
                        </div>
                        <div className={styles.error}>
                            {(value == null || value == undefined || value == '') && errorMessage}
                        </div>
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small }, { [styles.active]: visible }, { [styles.disabled]: disabled })}>
                    <div className={(errorMessage && (value == null || value == undefined || value == '')) ? cn(styles.head, styles.head_error, classDropdownHead) : cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={styles.selection}>
                            <input type="text"
                                style={inputStyle}
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => {setValue(e.target.value); setFilterText(e.target.value); setVisible(true); }}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && visible && (
                        <div ref={containerRef} onScroll={anotherAPICall} className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                            {filteredOptions&&filteredOptions.length > 0 ?
                                filteredOptions.map && filteredOptions.map((x, index) => (
                                <div onClick={() => handleClick(x)} className={cn(styles.option, { [styles.selectioned]: x.name === value })} key={index}>
                                    {x.name != null ? x.name : x.levelName}
                                </div>
                            )) : (
                                <div style={{ textAlign: "center" }} className={styles.option}>
                                    <div>No <span style={{ color: "#33383F" }}>'{titleCase(type)}'</span> available</div>
                                    <div>Click on <span style={{ color: "#33383F" }}>'+'</span> to create</div>
                                </div>
                            )}
                            <div className={styles.fxd} onClick={() => { setVisibleModal(true); }}>
                                <div className={styles.divider}></div>
                                <div style={{ textAlign: "center" }} className={cn(styles.option)}>
                                    <Link onClick={() => { setVisibleModal(true); }} className={styles.button}>
                                        <AddIconSvg style={{ width: 32, height: 32 }} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                    <ConfirmModal onProceed={() => ModalClose()} onClose={() => setVisibleModal(false)} type={titleCase(type)} />
                </Modal>
            </OutsideClickHandler>
        </div>
    );
}

export default Dropdown;
