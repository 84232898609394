import React, { useState, useEffect} from 'react'
import cn from 'classnames'
import styles from './customValueEditor.module.sass'
import Card from '../../../../../../../components/Card'
import {ReactComponent as AddMetaIcon} from '../../../../../../../media/images/icons/add_meta.svg'
import { Rule, ValueEditor, ValueEditorProps } from 'react-querybuilder'
import TextInput from '../../../../../../../components/TextInput'
import Dropdown from '../../../../../../../components/Dropdown/CustomDropdown'
import Radio from '../../../../../../../components/Radio'
import Tooltips from '../../../../../../../components/Tooltip'
import TooltipTitle from '../../../../../../../Tooltip/TooltipTitle'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {getParamValuesAction} from '../../../../../../../redux/action/engage/Achievement/Task/TaskAction'

const CustomValueEditor = (ValueEditorProps, query) => {

    const [constructedValue, setCunstructedValue] = useState({value : null, type : false, allTime : true, noOfRecords : null})
    const isDrpdwn=['currencyId','itemId','bundleId','progressionMarkerId','matchId','gameId','leaderboardId','competitionId','taskGroupId','taskId']
    const [paramValuesOptions,setParamValuesOptions]=useState({})
    const {appid} = useParams()
    const dispatch = useDispatch()

    const onInputChange = (e) => {
        const name = e?.target?.name
        if(name == 'parameter'||name==undefined){
            let values = {...constructedValue, value : e.value?e.value:e.target.value}
            setCunstructedValue(values)
            ValueEditorProps.handleOnChange(values)
        }
        else if(name.includes("type") == true){
            if(e.target.value == 'oneshot'){
                let values = {...constructedValue, type : false}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }else{
                let values = {...constructedValue, type : true}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }
        }
        else if(name == 'noOfRecords'){
            let values = {...constructedValue, noOfRecords : ((e.target.value != '' && e.target.value != null) ? parseInt(e.target.value) : null)}
            setCunstructedValue(values)
            ValueEditorProps.handleOnChange(values)
        }
        else if(name.includes('allTime') == true){
            if(e.target.value == 'yes'){
                let values = {...constructedValue, allTime : true}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }else{
                let values = {...constructedValue, allTime : false}
                setCunstructedValue(values)
                ValueEditorProps.handleOnChange(values)
            }
        }
    }
    
    useEffect(()=>{

        paramValues=[]
        
        if (ValueEditorProps?.field?.name&&(paramValuesOptions?.[ValueEditorProps?.field?.name]==undefined||paramValuesOptions?.[ValueEditorProps?.field?.name]?.length==0)) {
            let body
            if (ValueEditorProps?.field?.name=='currencyId') {
                    body={
                    "entities" : "currency",
                    "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='itemId') {
                body={
                "entities" : "item",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='bundleId') {
                body={
                "entities" : "bundle",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='progressionMarkerId') {
                body={
                "entities" : "progressionMarker",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='matchId') {
                body={
                "entities" : "match",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='gameId') {
                body={
                "entities" : "game",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='leaderboardId') {
                body={
                "entities" : "leaderboard",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='competitionId') {
                body={
                "entities" : "competition",
                "projectId" : appid
                }
            }else if (ValueEditorProps?.field?.name=='taskGroupId') {
                body={
                "entities" : "taskGroup",
                "projectId" : appid
                }
            }
            else if (ValueEditorProps?.field?.name=='taskId') {
                body={
                "entities" : "task",
                "projectId" : appid
                }
            }
            // else if (ValueEditorProps?.field?.name=='storeId') {
            //     body={
            //     "entities" : "store",
            //     "projectId" : appid
            //     }
            // }

            (isDrpdwn.includes(ValueEditorProps?.field?.name))&&dispatch(getParamValuesAction(body))

        }
        
    },[ValueEditorProps?.field?.name])

    let paramValues = useSelector((state) => {
        return state.task.paramValues
    })
    
    useEffect(()=>{
        if (ValueEditorProps?.field?.name&&(paramValuesOptions?.[ValueEditorProps?.field?.name]==undefined||paramValuesOptions?.[ValueEditorProps?.field?.name]?.length==0)) {
            setParamValuesOptions(prevOptions => ({
                ...prevOptions,
                [ValueEditorProps?.field?.name]: paramValues
            }));

        }
    },[paramValues])

    return (
        <>
        {(isDrpdwn.includes(ValueEditorProps?.field?.name))?
                <div style={{display:"inline-block",width:"344px",verticalAlign:"top"}}>
                    <Dropdown 
                        placeholder={"Select Parameter Value"} 
                        tooltip={TooltipTitle.TaskParameterValue} 
                        label={"Parameter Value"} 
                        setSelectedZone={(e)=>{}} 
                        options={
                            (ValueEditorProps?.field?.name&&paramValuesOptions?.[ValueEditorProps?.field?.name]&&paramValuesOptions?.[ValueEditorProps?.field?.name]?.length>0)&&
                            paramValuesOptions?.[ValueEditorProps?.field?.name]?.map((e,i)=>{
                                return{name:e?.[ValueEditorProps?.field?.name],value:e?.[ValueEditorProps?.field?.name]}
                            })} 
                        customHandleFunction={(e)=> onInputChange(e)} 
                        value={(ValueEditorProps.rule.value == '' || ValueEditorProps.rule.value == undefined || ValueEditorProps.rule.value == null) ? '' : ValueEditorProps.value.value}
                    />
                </div>
                :
            <TextInput tooltip={TooltipTitle.TaskParameterValue} groupBy='value' label='Parameter Value' name='parameter' value={(ValueEditorProps.rule.value == '' || ValueEditorProps.rule.value == undefined || ValueEditorProps.rule.value == null) ? '' : ValueEditorProps.value.value} onChange={(e)=> onInputChange(e)}/>
        }
                {ValueEditorProps.field.type == 'statistic' &&
                <div className={styles.groupByThree}>
                    {ValueEditorProps.value != null  &&
                        <div className={styles.fieldRadio}>
                            <div className={styles.radioLabel}>
                                <span>Incremental Type<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskIncrementalType} icon="info"/></span></span>
                            </div>
                            <div className={styles.variants}>
                                <Radio className={styles.radio} value={!ValueEditorProps.value.type} name={`type${ValueEditorProps.rule.id}`} id={`oneshot`} content="One Shot" onChange={(e)=>onInputChange(e)}/>
                                <Radio className={styles.radio} value={ValueEditorProps.value.type} name={`type${ValueEditorProps.rule.id}`} id={`cumulative`} content="Cumulative" onChange={(e)=>onInputChange(e)}/>
                            </div>
                        </div>
                    }
                    {(ValueEditorProps.value != null && ValueEditorProps.value != '' && ValueEditorProps.value.type == true) &&
                    <div className={styles.fieldRadio}>
                        <div className={styles.radioLabel}>
                            <span>Include All Records<span><Tooltips className={styles.tooltip} title={TooltipTitle.TaskTimePeriod} icon="info"/></span></span>
                        </div>
                        <div className={styles.variants}>
                            <Radio className={styles.radio} value={ValueEditorProps.value.allTime} name={`allTime${ValueEditorProps.rule.id}`} id={`yes`} content="Yes" onChange={(e)=>onInputChange(e)}/>
                            <Radio className={styles.radio} value={!ValueEditorProps.value.allTime} name={`allTime${ValueEditorProps.rule.id}`} id={`no`} content="No" onChange={(e)=>onInputChange(e)}/>
                        </div>
                    </div>}
                    {(ValueEditorProps.value != null && ValueEditorProps.value != '' && (ValueEditorProps.value.type == true && ValueEditorProps.value.allTime == false)) &&
                        <TextInput tooltip={TooltipTitle.TaskRecords} groupBy='noOfRecords' className={styles.field} label='Number of records to be included (Optional)' name='noOfRecords' onChange={(e)=>{onInputChange(e)}}/>
                    }
                </div>
            }
        </>
    )
}

export default CustomValueEditor