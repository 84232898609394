import { EDIT_ORG_PROFILE, EDIT_USER_PROFILE, GET_ORG_PROFILE, GET_USER_PROFILE } from '../../../actionTypes/actionTypes'

const initialState = {
    userProfile : [],
    orgProfile : [],
    isLoading : false
};

export const profileReducer = (state = initialState, action )=>{
    switch(action.type){
        case GET_USER_PROFILE : {
            return {
                ...state,
                userProfile: action.payload,
            }
        }

        case GET_ORG_PROFILE : {
            return {
                ...state,
                orgProfile: action.payload,
            }
        }

        case EDIT_USER_PROFILE : {
            return {
                ...state,
                userProfile: action.payload,
            }
        }

        case EDIT_ORG_PROFILE : {
            return {
                ...state,
                orgProfile: action.payload,
            }
        }

        default: {
            return {
                ... state
            }
        }
    }    
}   