import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './changePassword.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../Radio'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Dropdown from '../../../../Dropdown'
import { BASE_URL, GET_USER_WALLET } from '../../../../../resources/APIEndpoints'
import axios from 'axios'
import { resetPlayerPasswordAction } from '../../../../../redux/action/engage/Players/PlayersAction'
 
const ChangePasswordModal = ({className, onClose, authProvider}) => {

    const {appid} = useParams()
    const {id} = useParams()

    const dispatch = useDispatch()

    const [userEmail, setUserEmail] = useState('')
    const [password, setPassword] = useState('')

    const resetPassword = () => {
        let resetPassword = {
            password : password
        }
        dispatch(resetPlayerPasswordAction(resetPassword))
    }

    return (
        <>
            <div className={styles.resetPasswordModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.resetPassword}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Reset Password</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.changePasswordBody}>
                                <div className={styles.changePassword}>
                                    <div className={styles.group}>
                                        <TextInput className={styles.field} label='Email' type='email' placeholder='Enter Email' onChange={(e) => setUserEmail(e.target.value)} />
                                        <TextInput className={styles.field} label='Password' type='password' placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={resetPassword}>Save</button>
                                <button className={styles.cancel} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordModal