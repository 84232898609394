import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import {ReactComponent as ExcelSvg} from '../../../../../media/images/placeholders/SpreadSheetPlaceholder.svg'
import styles from './fileUploadInformation.module.sass'
import Icon from "../../../../Icon";

const FileUploadInformation = ({className, type}) => {

    return(
        <>
            <div className={styles.fileInformation}>
               <div className={styles.container}>
                    <div className={styles.notice}>
                        <div className={styles.sampleFiles}>
                            <p>Sample CSV files:</p>
                            <p><b>{type} Details:</b> Contains essential information about each {type}.</p>
                            {type !== 'Currency' &&
                                <>
                                    <p><b>{type} Pricing:</b> Includes pricing information for each {type}.</p>
                                    <p><b>{type} Locked By:</b> Specifies restrictions on each {type} either by an Item/Bundle or Progression System Levels.</p>
                                </>
                            }
                        </div>

                        <div className={styles.process}>
                            <p>Upload Process:</p>
                            <p>Prepare Your CSV Files: Ensure that each CSV file is formatted correctly according to the specifications provided in our template.</p>
                            <p>Select Your Files: Click on the respective upload buttons to select your files from your computer.</p>
                            <p>Verify and Upload: After selecting your files, click 'Next' to proceed to the verification stage where you can review your data before final upload.</p>
                            <p>Post-Upload</p>
                            <p>Once uploaded, the data will be processed and integrated into your existing dataset. You will receive a confirmation once the upload is successfully completed.</p>
                        </div>
                        {type !== 'Currency' &&
                            <div className={styles.note}>
                                <p>Important Note</p>
                                <p>If you choose to upload only {type} Details initially, and later decide to add pricing or locking information, you must re-upload both the {type} Details and the new information together. This requirement ensures data consistency and integrity across your {type}.</p>
                            </div>
                        }
                    </div>

                    <div className={styles.fileSamples}>
                        <div className={styles.sampleTitle}>Sample file</div>
                        <div className={styles.group}>
                            
                            <div className={styles.files}>
                                <div className={styles.fileIcon}>
                                    <ExcelSvg />
                                </div>
                                <div className={styles.fileInfo}>
                                    <div className={styles.fileName}>File_{type}</div>
                                    <div className={styles.fileDetail}><span className={styles.format}>.CSV</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>5.0 MB</span></div>
                                </div>
                                <a href="#" download={type === 'Currency' ? 'File_Currency' : type === 'Items' ? 'File_Items' : type === 'Bundles' ? 'File_Bundles' : type === 'Stores' ? 'File_Stores' : ''}>
                                    <div className={styles.downloadIcon}>
                                        <Icon className={styles.icon} name='download' size='14'/>
                                    </div>
                                </a>
                            </div>
                            {type !== 'Currency' &&
                                <div className={styles.files}>
                                    <div className={styles.fileIcon}>
                                        <ExcelSvg />
                                    </div>
                                    <div className={styles.fileInfo}>
                                        <div className={styles.fileName}>File_Pricing</div>
                                        <div className={styles.fileDetail}><span className={styles.format}>.CSV</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>5.0 MB</span></div>
                                    </div>
                                    <a href="#" download={type === 'Currency' ? 'File_Currency' : type === 'Items' ? 'File_Items' : type === 'Bundles' ? 'File_Bundles' : type === 'Stores' ? 'File_Stores' : ''}>
                                        <div className={styles.downloadIcon}>
                                            <Icon className={styles.icon} name='download' size='14'/>
                                        </div>
                                    </a>
                                </div>
                            }
                        </div>
                        {type !== 'Currency' &&
                            <div className={styles.group}>
                                <div className={styles.files}>
                                    <div className={styles.fileIcon}>
                                        <ExcelSvg />
                                    </div>
                                    <div className={styles.fileInfo}>
                                        <div className={styles.fileName}>File_LockBy</div>
                                        <div className={styles.fileDetail}><span className={styles.format}>.CSV</span> <span className={styles.seprator}>|</span> <span className={styles.fileSize}>3.6 MB</span></div>
                                    </div>
                                    <a href="#" download={type === 'Currency' ? 'File_Currency' : type === 'Items' ? 'File_Items' : type === 'Bundles' ? 'File_Bundles' : type === 'Stores' ? 'File_Stores' : ''}>
                                        <div className={styles.downloadIcon}>
                                            <Icon className={styles.icon} name='download' size='14'/>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
               </div>
            </div>
        </>
    )
}

export default FileUploadInformation