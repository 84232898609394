import React from 'react'
import styles from './editProgressionMarker.module.sass'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import ProgressionMarkerInfo from './ProgressionMarkerInfo'

const EditProgressionMarker = ({isChanged, setIsChanged}) => {

  return (
    <>
      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
            <ProgressionMarkerInfo  isChanged={isChanged} setIsChanged={setIsChanged} />
        </div>
      </div>
    </>
  )
}

export default EditProgressionMarker