import { GET_GOALS, GET_META_DATA,GET_PLATFORMS,GET_TAGS, UPDATE_TAGS} from "../../actionTypes/actionTypes";
import CommonSevices from "../../../services/commonServices/commonSevices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllMetaDataAction = () => {
    return async (dispatch) => {
        await CommonSevices.getMetaData()
        .then(res=>{
            dispatch(getMetaData(res.data.data))
        })
    }
}

export const getPlatformAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getPlatforms(body)
        .then(res=>{
            dispatch(getPlatforms(res.data.data))
        })
    }
}

export const getGoalsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getGoals(body)
        .then(res=>{
            dispatch(getGoals(res.data.data))
        })
    }
}


export const getTagsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.getTags(body)
        .then(res=>{
            dispatch(getTags(res.data.data))
        })
    }
}
export const getTags = (data) => {
    return {
        type : GET_TAGS,
        payload : data
    }
}
export const updateTagsAction = (body) => {
    return async (dispatch) => {
        await CommonSevices.updateTags(body)
        .then(res=>{
            dispatch(updateTags(res.data.data))
        })
    }
}
export const updateTags = (data) => {
    return {
        type : UPDATE_TAGS,
        payload : data
    }
}

export const getMetaData = (data) => {
    return {
        type : GET_META_DATA,
        payload : data
    }
}

export const getPlatforms = (data) => {
    return {
        type : GET_PLATFORMS,
        payload : data
    }
}

export const getGoals = (data) => {
    return {
        type : GET_GOALS,
        payload : data
    }
}