import React, { useState } from 'react'
import styles from './tooltip.module.sass'

const Tooltip = ({ primaryText, secondaryText, defaults, custom, optional, specter, children }) => {
    
    const [showTooltip, setShowTooltip] = useState(false)

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    
    const handleMouseLeave = () => {
        setTimeout(() => {
            setShowTooltip(false)
        }, 50)
    };

    return (
        <div className={styles.custom} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <div className={styles.tooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {showTooltip && 
                    <div className={styles.eventTooltip}>
                        {((defaults && defaults.length > 0) || (specter && specter.length > 0 ) || (optional && optional.length > 0)) &&
                            <>
                                <div className={styles.defaultParams}>
                                    <div className={styles.groupHeading}>
                                        <span className={styles.heading}>{primaryText}</span>
                                    </div>
                                    {defaults && defaults.map((e, i) => {
                                        return(
                                            <>
                                                <div className={styles.defaults}>{e.name}</div> 
                                            </>
                                        )
                                    })}
                                    {optional && optional.map((e, i) => {
                                        return(
                                            <>
                                                <div className={styles.optional}>{e.name}</div> 
                                            </>
                                        )
                                    })}
                                    {specter && specter.map((e, i) => {
                                        return(
                                            <>
                                                <div className={styles.specter}>{e.name}</div>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        <div className={styles.customParams}>
                            {(custom && custom.length) > 0 &&
                                <>
                                    <div className={styles.groupHeading}>
                                        <span className={styles.heading}>{secondaryText}</span>
                                    </div>
                                    {custom && custom.map((e, i) => {
                                        return(
                                            <>
                                                <div className={styles.customs}>{e.name}</div>
                                            </>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Tooltip