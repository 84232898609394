import axios from "axios";
import customAxios from "../../utils/interceptor";
import { BASE_URL, GET_META_DATA, UPDATE_TAGS, GET_TAGS, GET_PLATFORMS, GET_GOALS } from "../../resources/APIEndpoints";

function CommonServices(){
    this.getMetaData = (body) => axios.get(BASE_URL + GET_META_DATA)
    this.getPlatforms = (body) => axios.post(BASE_URL + GET_PLATFORMS, body)
    this.getGoals = (body) => axios.post(BASE_URL + GET_GOALS, body)
    this.getTags = (body) => customAxios.post(BASE_URL+GET_TAGS,body)
    this.updateTags = (body) => customAxios.post(BASE_URL+UPDATE_TAGS,body)
}

export default new CommonServices();