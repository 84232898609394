import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./dropdown.module.sass"
import Tooltip from "../../Tooltip";

const Dropdown = ({className, classLabel, classDropdownHead, type, customHandleFunction, usedFor, value, setValue, options, icon, placeholder, label, upBody, tooltip}) => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        if(type === 'Calendar'){
            const timezone={target:{value:value.name}}
            customHandleFunction(timezone)
            setValue(value.name)
            setVisible(false)
        }else{
            if(usedFor === 'user'){
                setValue(value.name)
                setVisible(false)
            }else{
                setValue(value)
                setVisible(false)
            }
        }
    }

    useEffect(() => {

    }, [value])
 
    return (
        <div className={styles.groupDropdown}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (<div className={cn(classLabel, styles.label)}>{label}</div>)}
            <div className={cn(styles.dropdown, className,{[styles.active]: visible})}>
                <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                    <div className={styles.placeholder}>
                        {icon && <div className={styles.icon}>{icon}</div>}
                        {(value == null || value == undefined || value == '') ? placeholder : value}
                    </div>
                </div>
                <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                    {options && options.map((x, index) => (
                        <div onClick={() => handleClick(x, index)} className={cn(styles.option)} key={index}>
                            {x.name}
                        </div>
                    ))}
                </div>
            </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown