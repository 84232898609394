import React from 'react'
import styles from './createCompetition.module.sass'
import CompetitionInfo from './CompetitionInfo'
import { ToastContainer } from 'react-toastify'

const CreateCompetition = ({isChanged, setIsChanged}) => {
  
    return (
        <>
            <ToastContainer />
            <div className={styles.row}>
                <div className={styles.col}>
                    <CompetitionInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
                </div>
            </div>
        </>
    )
}

export default CreateCompetition