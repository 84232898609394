import axios from "../../../utils/interceptor";
import { BASE_URL, RESET_PLAYER_PASSWORD, GET_PLAYERS, GET_PLAYERS_DETAILS, GET_CUSTOM_PLAYER, CREATE_PLAYER_PAYOUT, CREATE_GRANT_PM, EDIT_PLAYERS_PROFILE } from "../../../resources/APIEndpoints";

function PlayerServices(){
    this.getPlayers = (body) => axios.post(BASE_URL + GET_PLAYERS, body)
    this.getPlayerDetails = (body) => axios.post(BASE_URL + GET_PLAYERS_DETAILS, body)
    this.editPlayerProfile = (body) => axios.post(BASE_URL + EDIT_PLAYERS_PROFILE, body)
    this.createCustomPlayer = (body) => axios.post(BASE_URL + GET_CUSTOM_PLAYER, body)
    this.createPayout = (body) => axios.post(BASE_URL + CREATE_PLAYER_PAYOUT, body)
    this.grantPM = (body) => axios.post(BASE_URL + CREATE_GRANT_PM, body)
    this.resetPlayerPassword = (body) => axios.post(BASE_URL + RESET_PLAYER_PASSWORD, body)
}

export default new PlayerServices();