import React, { useState } from 'react'
import styles from './tooltip.module.sass'

const Tooltip = ({ text, type, isLast, data, children }) => {
    
    const [showTooltip, setShowTooltip] = useState(false)

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    
    const handleMouseLeave = () => {
        setTimeout(() => {
            setShowTooltip(false)
        }, 50)
    };

    return (
        <div className={styles.customTooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <div className={styles.tooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {showTooltip && 
                <div className={styles.tooltips} style={isLast?{top:"unset",bottom:"100%"}:{}}>
                    {(data && data.length > 0) ?
                        <>
                            <div className={styles.defaultParams}>
                                <span className={styles.heading}>{text}</span>
                                {data && data.map((e, i) => {
                                    return(
                                        <>
                                            <div className={styles.defaults}>{e.name} {(type == 'Task' && e.quantity) && <span>({e.quantity})</span>}</div>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                        :
                            <span className={styles.heading}>{text}</span>
                    }
                </div>
            }
            </div>
        </div>
    )
}

export default Tooltip