import React, {useState} from 'react'
import cn from 'classnames'
import styles from './deleteModal.module.sass'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { editProgressionMarkerAction } from '../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction'

const DeleteMarker = ({className, onClose}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const appId = location.state.appid
  const markerId = location.state.id
  const deleteStatus = location.state.deleteStatus
  const markerName = location.state.markerName

  const onDelete = () => {
    let markerData = {
      projectId : appId,
      id : markerId,
      active : (deleteStatus == true ? false : true)
    }
    //dispatch(editProgressionMarkerAction(markerData, null , appId))
    onClose()
  }

  return (
    <div className={styles.deleteBody}>
      <div className={styles.deleteContent}>
          <div className={styles.warning}>
                <h5>Warning!</h5>
          </div>
          <div className={styles.warningContent}>
                <p>Are you sure you want to {deleteStatus == true ? 'Restore' : 'Delete'} <span className={styles.currencyName}>{markerName}</span>?</p>
          </div>
      </div>
      <div className={styles.btns}>
        <button className={styles.deleteBtn} onClick={onDelete}>Yes</button>
        <button className={styles.cancelBtn} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}

export default DeleteMarker