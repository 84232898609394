import {BASE_URL, GET_DEFAULT_EVENTS, GET_CUSTOM_EVENTS, CREATE_CUSTOM_EVENTS, EDIT_DEFAULT_EVENTS, EDIT_CUSTOM_EVENTS} from '../../../resources/APIEndpoints'
import axios from '../../../utils/interceptor'

function progressionMarkerServices() {
    this.getDefaultEvent = (body) => axios.post(BASE_URL + GET_DEFAULT_EVENTS, body)
    this.getCustomEvent = (body) => axios.post(BASE_URL + GET_CUSTOM_EVENTS, body)
    this.createCustomEvent = (body) => axios.post(BASE_URL + CREATE_CUSTOM_EVENTS, body)
    this.editDefaultEvent = (body) => axios.post(BASE_URL + EDIT_DEFAULT_EVENTS, body)
    this.editCustomEvent = (body) => axios.post(BASE_URL + EDIT_CUSTOM_EVENTS, body)
}

export default new progressionMarkerServices()