import {BASE_URL, GET_PROGRESSION_MARKER, ADD_PROGRESSION_MARKER, EDIT_PROGRESSION_MARKER} from '../../../../resources/APIEndpoints'
import axios from '../../../../utils/interceptor'

function progressionMarkerServices() {
    this.getAllProgressionMarker = (body) => axios.post(BASE_URL + GET_PROGRESSION_MARKER, body)
    this.createProgressionMarker = (body) => axios.post(BASE_URL + ADD_PROGRESSION_MARKER, body)
    this.editProgressionMarker = (body) => axios.post(BASE_URL + EDIT_PROGRESSION_MARKER, body)
    this.archivePM = (body) => axios.post(BASE_URL + EDIT_PROGRESSION_MARKER, body)
}

export default new progressionMarkerServices()