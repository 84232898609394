import React from 'react'
import styles from './viewProgressionSystem.module.sass'
import ProgressionSystemInfo from './ProgressionSystemInfo'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewProgressionSystem = ({isChanged, setIsChanged}) => {
  return (
    <>
        <ToastContainer />
        <div className={styles.row}>
            <div className={styles.col}>
                <ProgressionSystemInfo isChanged={isChanged} setIsChanged={setIsChanged} className={styles.card}/>
            </div>
        </div>
    </>
  )
}

export default ViewProgressionSystem