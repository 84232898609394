import { GET_DOCUMENTS_SUCCESS, GET_DOCUMENTS_ERROR, CREATE_DOCUMENT, EDIT_DOCUMENT, FILTER_DOCUMENT_SUCCESS, FILTER_DOCUMENT_ERROR } from "../../../actionTypes/actionTypes";
import DocumentServices from '../../../../services/appSettingsServices/DocumentServices/documentServices'
import Toast from "../../../../components/Toast";
import { toast } from "react-toastify";

export const getAllDocumentsAction = (body, showSkeleton) => {
    return async(dispatch) => {
        await DocumentServices.getDocument(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getDocumet(res.data.data.documents, res.data.data.totalCount, GET_DOCUMENTS_SUCCESS))
        }).catch(err => {
            dispatch(getDocumet(err.response, null, GET_DOCUMENTS_ERROR))
        })
    }
}

export const filterDocumentsAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await DocumentServices.getDocument(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
           dispatch(filterDocuments(res.data.data, ((body?.search && body?.search !== '') ? res.data.data.searchCount : res.data.data.totalCount), FILTER_DOCUMENT_SUCCESS))
        }).catch(err=>{
            dispatch(filterDocuments(err, null, FILTER_DOCUMENT_ERROR))
        })
    }
}

export const createDocumentsAction = (body, navigate, appid) => {
    return async(dispatch) => {
        await DocumentServices.addDocument(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={`Document Created Successfully.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(() => {
                navigate(`/app-documents/${appid}`)
            }, 2000)
        }).catch(err => {
            console.log(err)
        })
    }
}

export const editDocumentsAction = (body, navigate, appid) => {
    return async(dispatch) => {
        await DocumentServices.editDocument(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={`Document Updated Successfully.`}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(() => {
                navigate(`/app-documents/${appid}`)
            }, 2000)
        }).catch(err => {
            console.log(err)
        })
    }
}

export const getDocumet = (data, count, type) => {
    return {
        payload : data,
        type : type,
        totalCount: count
    }
}

export const filterDocuments = (data, count, type) => {
    return {
        type : type,
        totalCount : count,
        payload : data
    }
}

export const addDocument = (data, type) => {
    return {
        payload : data,
        type : type
    }
}

export const editDocument = (data, type) => {
    return {
        payload : data,
        type : type
    }
}